
export const optionsSourceBooking = [
  { value: 0, label: 'Tất nguồn đặt lịch' },
  { value: 1, label: 'Gọi điện' },
  { value: 2, label: 'FB Messenger' },
  { value: 3, label: 'Instagram' },
  { value: 4, label: 'Zalo' },
  { value: 5, label: 'Website' },
  { value: 6, label: 'Từ cơ sở' }
]


export const customStylesFilter = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    width: 120,
    textAlign: "center",
    minHeight: 32,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
}

export const idCategory = [
  {
    id: 47,
    value: "nail",
    label: "Nails",
    color: '#f99033'
  },
  {
    id: 46,
    value: "mi",
    label: "Mi",
    color: '#f99033'
  },
  {
    id: 45,
    value: "goi",
    label: "Gội",
    color: '#f99033'
  }
]

export const idCategorySale = [
  {
    id: 4,
    value: "phun_xam",
    label: "Phun Xăm",
    color: '#e500fb',
  },
]

export const idCategoryLt = [
  {
    id: 2,
    value: "da",
    label: "Da",
    color: '#ff0000'
  },
  {
    id: 5,
    value: "triet",
    label: "Triệt",
    color: '#01bbb0'
  },
  {
    id: 4,
    value: "phun_xam",
    label: "Phun Xăm",
    color: '#e500fb',
  },
]

