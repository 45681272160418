import React from "react";
import axiosService from "../../utils/axios.service";
import Select from 'react-select'
import DatePicker from 'rsuite/DatePicker';
import { CheckOutlined } from "@ant-design/icons"
import { message, Button, Checkbox } from "antd"
import { customStylesOption, customStylesMultiSelect } from '../constant/GeneralData'
import { customStylesBirthday } from '../customer/constant/CustomerBirthday'
import * as convert from "../constant/Convert.helper"
import * as generalData from "../constant/GeneralData"
import { optionsDays, optionsMonths } from "../customer/constant/CustomerBirthday";
import Avatar from 'react-avatar';
import location from "../../context/vietnam.json";
import comboPackagesEnum from "../../enums/comboPackages.enum";
import _ from "lodash";
import moment from "moment"
import { Spin } from "antd"
import PackageFullBody from "./PackageFullBody";
import { idCategory } from './constant/BookingConstant'
import { ViewImgCustomer } from "../customer/ViewImgCustomer"

function formatGroupLabel(data) {
  return (
    <div className="custom-head">
      <span className="custom-label">{data.label}</span>
      {/* <span className="custom-count">{data.options.length}</span> */}
    </div>
  );
}

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 2,
      book_status: 2,
      source_from: '',
      id_customer: '',
      gender: 1,
      full_name: '',
      mobile: '',
      zalo_account: '',
      city: '',
      district: '',
      day: '',
      month: '',
      year: '',
      address: '',
      book_hour: (new Date().getHours() < 10) ? 9 : new Date().getHours() + 1,
      book_minute: 0,
      book_date: (props.quickSetDate !== '') ? props.quickSetDate : new Date(),
      description: '',
      stores: localStorage.getItem("currentStore"),
      searchCustomer: [],
      statusSearchCustomer: false,
      oldCustomer: {},
      editOldCustomer: false,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      newDataService: [{ label: 'Thẻ liệu trình', options: [], }, { label: 'Dịch vụ', options: [] }],
      countCustomer: 1,
      booking_item: [{ "product_ids": [], "user_ids": (props.quickSetUser !== '') ? [props.quickSetUser] : [], "intend_time": 0 }],
      total_intend_time: 0,
      keyCity: null,
      keyDistrict: null,
      dataDistrict: [],
      source: null,
      loadSearchCustomer: false,
      fullBodies: [],
      item_full_body: [],
      rootDataService: [],
      typeBooking: 1,
      dataPackage: [],
      serviceNamigo: [],
      assign_for: 0,
      voucher_code: '',
      voucher: {},
      isAddVoucher: false,
      description_internal: '',
      showImgCustomer: false,
      identifier: 0
    };
  }
  selectDistrictRef = null;
  getDataCustomerDetail = async () => {
    let customerId = this.props.customerId
    axiosService("api/customer/" + customerId, "GET")
      .then(async (res) => {
        const response = res.data;
        let dataLocation = convert.getDistricts(response.city, response.district)

        let packageByCustomer = []
        packageByCustomer = await this.props.getServiceByCustomer(this.props.customerId);
        
        this.setState({
          oldCustomer: response,
          gender: response.gender,
          full_name: response.full_name,
          mobile: response.mobile,
          zalo_account: response.zalo_account,
          city: response.city,
          district: response.district,
          address: response.address,
          keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
          keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
          dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : [],
          source: response.source,
          rootDataService: packageByCustomer,
          identifier: response.identifier
        })

        await this.getDetailCustomer(response)
      }).catch((error) => console.log(error));
  }

  onChangeType = (event) => {
    let book_status = this.state.book_status
    let source_from = this.state.source_from
    if (event === 1) book_status = 1
    if (event === 2) book_status = 2
    if (event === 3) {
      book_status = 3
      source_from = 6
    }
    this.setState({
      status: event,
      book_status: book_status,
      source_from: source_from
    });
  }

  onChangeTypeNamigo = (event) => {
    let usedService = [];
    let labelService = ""
    if (event == 1) {
      usedService = this.state.dataPackage;
      labelService = "Thẻ liệu trình"
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == event) {
          usedService = x.product
          labelService = "Dịch vụ " + x.name
        }
      })
    }
    this.setState({
      newDataService: [{ label: labelService, options: usedService }],
      typeBooking: event,
      book_status: 2,
    });
  }
  onChangeBookStatus = (event) => {
    this.setState({
      book_status: event
    });
  }
  onChangeSource = (event) => {
    this.setState({
      source_from: event
    });
  }
  onChangeGender = (event) => {
    this.setState({
      gender: event.value
    });
  };
  onChangeName = (event) => {
    this.setState({
      full_name: event.target.value
    });
  };
  onChangeMobile = _.debounce(async (event) => {
    let keyword = event.target.value;
    if (keyword && keyword != '' && keyword.length > 0) keyword = keyword.replace(/\s/g, '').toLowerCase();

    if (keyword.length == 10) {
      this.setState({
        loadSearchCustomer: true
      })
      let dataCustomer = await axiosService("api/customer?keyword=" + keyword, "GET");
      dataCustomer = dataCustomer.data.data.data
      if (dataCustomer.length == 1) await this.getDetailCustomer(dataCustomer[0]);
      this.setState({
        loadSearchCustomer: false
      })
    }
  }, 1000);
  onChangeCity = (event) => {
    this.setState({
      city: event.label,
      dataDistrict: event.districts,
      district: ''
    });
    this.selectDistrictRef.clearValue();
  };
  onChangeDistrict = (event) => {
    if (event) this.setState({ district: event.label });
  };
  onChangeAddress = (event) => {
    this.setState({
      address: event.target.value,
    });
  };
  onChangeIdentifier = (e) => {
    this.setState({
      identifier: e.target.checked ? 1 : 0,
    })
  }
  onChangeHourBooking = (event) => {
    let book_hour = ("0" + event.value).slice(-2),
      book_minute = ("0" + this.state.book_minute).slice(-2),
      book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_hour: event.value,
    });

  }
  onChangeMinuteBooking = (event) => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
      book_minute = ("0" + event.value).slice(-2),
      book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_minute: event.value
    });
  }
  onChangeDateBooking = (event) => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
      book_minute = ("0" + this.state.book_minute).slice(-2),
      book_date = convert.convertDate(event) + " " + [book_hour, book_minute].join(":");
    this.updatePackages(book_date)
    this.setState({
      book_date: event
    });
  }
  changeTotalIntendTime = (event) => {
    this.setState({
      total_intend_time: (event.target.validity.valid) ? event.target.value : this.state.total_intend_time
    });
  }
  onSubmitVoucher = async (event) => {
    try {
      const body = {
        // orderAt: props.orderSelected.created_at,
        total: 0,
        products: [],
        store: localStorage.getItem("currentStore"),
        customer: this.state.oldCustomer,
        type_order: 5,
      }
      const res = await axiosService("api/vouchers/check/" + this.state.voucher_code, "POST", body)
      const { data } = res
      if (data.code === 404) {
        message.warning(data.message)
      } else if (data.code === 200) {
        this.setState({
          voucher: data.data,
          isAddVoucher: true
        })
        message.success(data.data.message)
      } else {
        message.error("Có lỗi xảy ra")
      }
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }
  handleClearVoucher = (key) => {
    try {
      this.setState({
        voucher: {},
        isAddVoucher: false
      })
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }
  changeVoucherCode = (event) => {
    this.setState({
      voucher_code: (event.target.validity.valid) ? event.target.value : this.state.voucher_code
    });
  }
  onChangeNote = (event) => {
    this.setState({
      description: event.target.value
    });
  }

  onChangeNoteInternal = (event) => {
    this.setState({
      description_internal: event.target.value
    });
  }
  
  onChangeStore = (event) => {
    this.setState({
      stores: event.value
    });
  }
  onChangeService = (key, data) => {
    let booking_item = this.state.booking_item,
        item_full_body = this.state.item_full_body,
        new_service = [],
        timeUsed = 0,
        timeWax = 0;

    data.map(function (item) {
      let timePerService = Number(item.time) ?? 0;
      let category_id = item.category_id ?? item.product?.category_id;

      if (category_id == 5) {
        timeWax += timePerService;
      } else if (category_id == 2) {
        timeUsed += (timePerService >= 90) ? timePerService - 15 : timePerService;
      } else {
        timeUsed += timePerService
      }

      if (item.package_code) {
        new_service.push({
          id: item.value,
          product_name: item.product_name,
          package_code: item.package_code,
          left_use: item.left_use,
          price: 0,
          product_id: item.product_id,
          category_id: item.product?.category_id,
          parent_package: item.parent_package
        })
      } else {
        new_service.push({
          id: item.value,
          product_name: item.label,
          price: item.price,
          product_id: item.product_id ?? item.value,
          category_id: item.category_id,
          parent_package: item.parent_package || null
        })
      }
    })

    if (timeWax > 0) timeWax += 5;
    timeWax = (timeWax > 60) ? 60 : timeWax;

    booking_item[key].product_ids = new_service;
    booking_item[key].intend_time = timeUsed + timeWax;

    let total_intend_time = Math.max(...booking_item.map(item => Number(item.intend_time)))
    total_intend_time += _.sumBy(item_full_body[0], function(o) { return o.intend_time; });

    this.setState({
      booking_item: booking_item,
      total_intend_time: total_intend_time
    })
  }
  onChangeItemFullBody = (key, data, indexParent) => {
    try {
      let item_full_body = this.state.item_full_body
      if (!item_full_body[indexParent]) {
        item_full_body[indexParent] = []
        item_full_body[indexParent][key] = data
      } else {
        item_full_body[indexParent][key] = data
      }

      let total_intend_time = Math.max(...this.state.booking_item.map(item => item.intend_time))
      total_intend_time += _.sumBy(item_full_body[0], function(o) { return o.intend_time; });

      this.setState({ 
        item_full_body: item_full_body,
        total_intend_time: total_intend_time
      })
    } catch (error) {
      console.log(error)
    }

  }
  onChangeUser = (key, data) => {
    let booking_item = this.state.booking_item,
        new_user = [],
        assign_for = 0;

    if (Array.isArray(data)) {
      data.map(function (item) {
        new_user.push({
          id: item.value,
          name: item.label,
          image: item.image
        })
      })
      assign_for = new_user[0]?.id ?? 0
    } else {
      new_user.push({
        id: data.value,
        name: data.label,
        image: data.image
      })
      assign_for = data.value
    }

    booking_item[key].user_ids = new_user;
    this.setState({ 
      booking_item: booking_item,
      assign_for: assign_for
    })
  }
  onChangeCountCustomer = (event) => {
    let count = this.state.countCustomer

    if (event.target.validity.valid) count = event.target.value
    if (event.target.value > 20) count = 20;
    this.setState({
      countCustomer: count,
    });
    this.setArrayServiceUser(count)
  }
  addMoreCustomer = () => {
    let count = Number(this.state.countCustomer);
    if (this.state.countCustomer <= 19) {
      this.setState({ countCustomer: count + 1 });
      this.setArrayServiceUser(count + 1)
    }
  }
  setArrayServiceUser = (count) => {
    let booking_item = this.state.booking_item;
    if (count > booking_item.length) {
      booking_item = [
        ...booking_item,
        ...Array(count - booking_item.length).fill({ product_ids: [], user_ids: (this.props.quickSetUser !== '') ? [this.props.quickSetUser] : [], intend_time: 0 })
      ];
    }
    if (count > 0 && count < booking_item.length) {
      booking_item.length = count
    }
    this.setState({
      booking_item: booking_item,
    });
  }
  onChangeSearchCustomer = (event) => {
    let keyword = event.target.value;

    if (keyword.length >= 2)
      axiosService("api/customer?keyword=" + keyword, "GET")
        .then((res) => {
          const response = res.data;
          this.setState({
            searchCustomer: response.data.data ?? [],
            statusSearchCustomer: true
          })
        }).catch((error) => console.log(error));
  }
  addNewData = () => {
    let book_hour = ("0" + this.state.book_hour).slice(-2),
        book_minute = ("0" + this.state.book_minute).slice(-2),
        book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");

    const fullBodies = this.state.item_full_body
    const bookingItem = this.state.booking_item
    for (let x = 0; x < bookingItem.length; x++) {
      for (let i = 0; i < fullBodies.length; i++) {
        if (x == i) {
          const mapProduct = []
          fullBodies[i].forEach(element => {
            mapProduct.push(...element.product_ids)
          });
          bookingItem[x].product_ids = [...bookingItem[x].product_ids, ...mapProduct]
        }
      }
    }

    let data = {
      "status": this.state.status,
      "book_status": this.state.book_status,
      "source_from": this.state.source_from,
      "description": this.state.description,
      "description_internal": this.state.description_internal,
      "book_date": book_date,
      "stores": this.state.stores,
      "created_by": this.state.userAdmin.id ?? '',
      "intend_time": this.state.total_intend_time,
      "booking_type": this.state.typeBooking,
      "assign_for": this.state.assign_for,
      "voucher_code": this.state.isAddVoucher ? this.state.voucher.code : null,
      "voucher_id": this.state.isAddVoucher ? this.state.voucher.id : null,
      "voucher_name": this.state.isAddVoucher ? this.state.voucher.name : null,
      "customers": {
        "id": Object.keys(this.state.oldCustomer).length > 0 ? this.state.oldCustomer.id : '',
        "full_name": this.state.full_name,
        "mobile": this.state.mobile,
        "zalo_account": this.state.zalo_account,
        "gender": this.state.gender,
        "city": this.state.city,
        "district": this.state.district,
        "address": this.state.address,
        "birthday": this.state.day + '/' + this.state.month + '/' + this.state.year,
        "day_birthday": parseInt(this.state.day) || 0,
        "month_birthday": parseInt(this.state.month) || 0,
        "year_birthday": parseInt(this.state.year) || 0,
        "source": this.state.source,
        "identifier": this.state.identifier
      },
      "booking_item": bookingItem
    };
    if (!this.props.disableButtonAdd) {
      this.props.addBooking(data)
    }
    this.props.handleDisableButtonAdd()
  };


  getDetailCustomer = async (data) => {
    let listPackage = await this.props.getServiceByCustomer(data.id);
    let book_hour = ("0" + this.state.book_hour).slice(-2),
        book_minute = ("0" + this.state.book_minute).slice(-2),
        book_date = convert.convertDate(this.state.book_date) + " " + [book_hour, book_minute].join(":");
    listPackage = listPackage.map(item => {
      const date = new Date(moment(new Date(item.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      let dateDiff = 0
      let isValid = true
      if (item.product) {
        if (item.range_date_new > 0) {
          item.product.range_date = item.range_date_new
        }
        date.setDate(date.getDate() + (item.product.range_date - 2))
        isValid = date.getTime() <= new Date(book_date).getTime()
        const a = moment(date);
        const b = moment(new Date());
        dateDiff = a.diff(b, 'days')
      }
      return {
        ...item,
        disabled: !isValid,
        checkDate: !isValid,
        dateDiff
      }
    })

    // group
    const filter = listPackage.filter(x => x.parent_package != null)
    const filterPackages = listPackage.filter(x => {
      if (!x.product) {
        return true
      }
      return x.parent_package == null && !comboPackagesEnum.includes(x.product.id)
    })
    const grouped = _.groupBy(filter, (x) => {
      return x.parent_package
    })

    const newObject = [];
    for (const property in grouped) {
      const findIndex = listPackage.findIndex(x => {
        return x.id == property
      })
      grouped[property].forEach(x => {
        x.isSelect = false
      })

      if (findIndex != -1) {
        newObject.push({
          label: `${listPackage[findIndex].package_code}-${listPackage[findIndex].product.product_name}${listPackage[findIndex].is_convert_bhvv === 1 ? " - CD" : ''}`,
          options: grouped[property],
          parent: listPackage[findIndex],
          last_used: listPackage[findIndex].left_use,
          package_type_using: listPackage[findIndex].package_type_using
        })
      }
    }
    let dataLocation = convert.getDistricts(data.city, data.district)
    let dataServiceByType = [];
    let labelService = "";

    let listDataServiceOption = []
    if (this.state.typeBooking == 1) {
      let listPackageBase = filterPackages.filter( x => (x.package_type_using != 1 && x.is_convert_bhvv == 0))
      let listPackageConvert = filterPackages.filter(x => x.is_convert_bhvv == 1)
      let newListPackageConvert = [];
      listPackageConvert.map((item) => {
        if (item.package_type_using != 1) {
          newListPackageConvert.push({...item, label: item.label + " - CD"})
        }
      })
      listDataServiceOption = [{ label: "Thẻ liệu trình", options: listPackageBase }, { label: "Thẻ chuyển đổi từ BHVV", options: newListPackageConvert } ]
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == this.state.typeBooking) {
          dataServiceByType = x.product
          labelService = "Dịch vụ " + x.name
        }
      })
      listDataServiceOption = [{ label: labelService, options: dataServiceByType }]
    }

    this.setState({
      dataPackage: filterPackages,
      newDataService: listDataServiceOption,
      rootDataService: listPackage,
      oldCustomer: data,
      statusSearchCustomer: false,
      editOldCustomer: false,
      gender: data.gender,
      full_name: data.full_name,
      mobile: data.mobile,
      zalo_account: data.zalo_account,
      city: data.city,
      district: data.district,
      day: data.day_birthday,
      month: data.month_birthday,
      year: data.year_birthday,
      address: data.address,
      source: data.source,
      fullBodies: newObject,
      keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
      keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
      dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : []
    })
  };
  closeSearch = (event) => {
    this.setState({
      statusSearchCustomer: false
    })
  }
  createNewCustomer = (event) => {
    let dataServiceByType = [];
    let labelService = "";

    if (this.state.typeBooking == 1) {
      dataServiceByType = [];
      labelService = "Thẻ liệu trình"
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == this.state.typeBooking) {
          dataServiceByType = x.product
          labelService = "Dịch vụ " + x.name
        }
      })
    }

    this.setState({
      oldCustomer: {},
      newDataService: [{ label: labelService, options: dataServiceByType, }],
      rootDataService: [],
      fullBodies: []
    })
  }
  editOldCustomer = (event) => {
    this.setState({
      editOldCustomer: true,
    })
  }

  async componentDidMount() {
    let packageByCustomer = []
    if (this.props.customerId) {
      await this.getDataCustomerDetail()
      // packageByCustomer = await this.props.getServiceByCustomer(this.props.customerId);
    }
    if (this.props.isNewCustomer) {
      this.setState({
        full_name: this.props.newFullName,
        mobile: this.props.newPhone,
        zalo_account: this.props.newZaloAccount,
        gender: this.props.newGender,
        editOldCustomer: true
      })
    }
  }
  onChangeDay = (event) => {
    this.setState({
      day: event.value,
    });
  };
  onChangeMonth = (event) => {
    this.setState({
      month: event.value,
    });
  }
  onChangeYear = (event) => {
    this.setState({
      year: event.target.value,
    });
  };
  updatePackages(book_date) {
    let listPackage = this.state.rootDataService.map(item => {
      const date = new Date(moment(new Date(item.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
      let dateDiff = 0
      let isValid = true
      if (item.product) {
        if (item.range_date_new > 0) {
          item.product.range_date = item.range_date_new
        }
        date.setDate(date.getDate() + (item.product.range_date - 2))
        isValid = date.getTime() <= new Date(book_date).getTime()
        const a = moment(date);
        const b = moment(new Date());
        dateDiff = a.diff(b, 'days')
      }
      return {
        ...item,
        disabled: !isValid,
        checkDate: !isValid,
        dateDiff
      }
    })

    const filter = listPackage.filter(x => x.parent_package != null)
    const filterPackages = listPackage.filter(x => {
      if (!x.product) {
        return true
      }
      return x.parent_package == null && !comboPackagesEnum.includes(x.product.id)
    })
    const grouped = _.groupBy(filter, (x) => {
      return x.parent_package
    })
    const newObject = [];
    for (const property in grouped) {
      const findIndex = listPackage.findIndex(x => {
        return x.id == property
      })
      grouped[property].forEach(x => {
        x.isSelect = false
      })

      if (findIndex != -1) {
        newObject.push({
          label: `${listPackage[findIndex].package_code}-${listPackage[findIndex].product.product_name}`,
          options: grouped[property],
          parent: listPackage[findIndex]
        })
      }
    }

    let dataServiceByType = [];
    let labelService = "";

    if (this.state.typeBooking == 1) {
      dataServiceByType = filterPackages;
      labelService = "Thẻ liệu trình"
    } else {
      this.props.serviceNamigo.map(x => {
        if (x.id == this.state.typeBooking) {
          dataServiceByType = x.product
          labelService = "Dịch vụ " + x.name
        }
      })
    }

    this.setState({
      dataPackage: filterPackages,
      newDataService: [{ label: labelService, options: dataServiceByType, }],
      fullBodies: newObject,
    })
  }

  changeCurrenAvatar = (dataImage) => {
    let dataCus = { ...this.state.oldCustomer }
  
    dataCus = {
      ...dataCus,
      avata_url: dataImage
    }
    
    this.setState({
      oldCustomer: { ...dataCus },
    })
  }
  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent add-booking" onClick={this.closeSearch}>
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Lịch hẹn</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-md-12 col-lg-10">
                  <div className="items-data row">
                    <div className="col-3 col-md-2">Loại lịch</div>
                    <div className="value col-md-3 col-lg-2 code"></div>
                    <div className="value col-md-1 col-lg-2"></div>
                    <div className="value col-md-6 col-lg-6">
                      <div className="booking-type">
                        <div className={(this.state.typeBooking === 1) ? "item-type active" : "item-type"} onClick={() => this.onChangeTypeNamigo(1)}>Da - Triệt - PX</div>
                        {idCategory.map((data, key) => (
                          <div key={key} className={(this.state.typeBooking === data.id) ? "item-type active" : "item-type"} onClick={() => this.onChangeTypeNamigo(data.id)}>{data.label}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-3 col-md-2">Mã đặt lịch</div>
                    <div className="value col-md-3 col-lg-2 code"></div>
                    <div className="value col-md-1 col-lg-4"></div>
                    <div className="value col-md-6 col-lg-4">
                      <div className="booking-type">
                        <div className={(this.state.status === 1) ? "item-type active" : "item-type"} onClick={() => this.onChangeType(1)}>Pre Order</div>
                        <div className={(this.state.status === 2) ? "item-type active" : "item-type"} onClick={() => this.onChangeType(2)}>Đặt lịch trước</div>
                        <div className={(this.state.status === 3) ? "item-type active" : "item-type"} onClick={() => this.onChangeType(3)}>Đến trực tiếp</div>
                      </div>
                    </div>
                  </div>
                  { this.props.typeHandleInfoCustomer ?
                     <div className="items-data row">
                     <div className="title col-3 col-md-2">Tìm khách hàng</div>
                     <div className="value col-9 col-md-10">
                       <input onChange={this.onChangeSearchCustomer} placeholder="Tìm khách hàng theo tên hoặc số điện thoại" type="text" />
                       {this.state.statusSearchCustomer === true ?
                         <div className="result-search">
                           {this.state.searchCustomer.length === 0 ?
                             <div className="empty-customer">
                               Không tìm thấy dữ liệu phù hợp
                             </div>
                             :
                             <div className="items-customer">
                               {this.state.searchCustomer.map((data, key) => (
                                 <div className="item" key={key} onClick={() => { this.getDetailCustomer(data) }}>
                                   <div className="avatar">
                                     {data.avata_url ?
                                       <img src={data.avata_url} alt="" />
                                       : <Avatar name={data.full_name} size="36" textSizeRatio={1.75} round="50%" />}
                                   </div>
                                   <div className="info">
                                     <div className="name">
                                       {data.full_name} 
                                       {data?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                     </div>
                                     {data.ranking ? <span className="ranking">({generalData.optionsRanking[data.ranking - 1].label})</span> : null}
                                     <div className="phone">{data.mobile}</div>
                                     <div className="address">
                                       {data.district}
                                       {(data.district && data.city) ? "-" : null}
                                       {data.city}
                                     </div>
                                   </div>
                                 </div>
                               ))}
                             </div>
                           }
                         </div>
                         : ""}
                     </div>
                   </div>
                  : null}
                  <Spin spinning={this.state.loadSearchCustomer}>
                    <div className="items-data row">
                      <div className="title col-3 col-md-2">Khách hàng</div>
                      {Object.keys(this.state.oldCustomer).length === 0 || this.state.editOldCustomer === true ?
                        <div className="value col-9 col-md-10" style={{ position: 'relative', zIndex: 4 }}>
                          <div className="row">
                            <div className="col-6 col-md-2">
                              <Select className="select"
                                isSearchable={false} onChange={this.onChangeGender}
                                styles={customStylesOption}
                                defaultValue={(this.state.editOldCustomer === true ? this.props.optionsGender[this.state.oldCustomer.gender - 1] : this.props.optionsGender[this.state.gender - 1] ?? this.props.optionsGender[0])}
                                options={this.props.optionsGender} />
                            </div>
                            <div className="col-6 col-md-4">
                              <input onChange={this.onChangeName} defaultValue={this.state.full_name ?? (this.state.editOldCustomer === true ? this.state.oldCustomer.full_name : '')} placeholder="Họ và tên" className="required" type="text" />
                            </div>
                            <div className="col-6 col-md-3">
                              <input onChange={(event) => {this.setState({mobile: event.target.value}); this.onChangeMobile(event)}}
                                disabled={this.state.editOldCustomer}
                                defaultValue={(this.state.oldCustomer.mobile)}
                                placeholder="Điện thoại" type="text" />
                            </div>
                            <div className="col-6 col-md-3">
                              <input onChange={(event) => {this.setState({zalo_account: event.target.value})}}
                                defaultValue={(this.state.oldCustomer.zalo_account)}
                                placeholder="Zalo" type="text" />
                            </div>
                          </div>
                        </div>
                        : <div className="old-customer col-9 col-md-10">
                          <div className="row">
                            <div className="col-3 col-md-2 avatar">
                              {this.state.oldCustomer.avata_url ?
                                <img onClick={() => this.setState({showImgCustomer: true})} src={this.state.oldCustomer.avata_url} alt="" />
                                : <Avatar onClick={() => this.setState({showImgCustomer: true})} name={this.state.oldCustomer.full_name} size="60" textSizeRatio={1.75} round="50%" />}
                            </div>
                            <div className="col-9 col-md-10 info-customer">
                              <div className="top-info">
                                <span className="gender">{this.state.oldCustomer.gender === 1 ? "Anh" : "Chị"}</span>
                                <span className="name">{this.state.oldCustomer.full_name}{this.state.oldCustomer?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                </span>
                                { this.props.typeHandleInfoCustomer ? 
                                  <>
                                    <span className="button-edit" onClick={this.editOldCustomer}><i className="fas fa-pencil-alt"></i> Sửa</span>
                                    <span className="button-create-new" onClick={this.createNewCustomer}><i className="fas fa-user-plus"></i>Tạo khách mới</span>
                                  </>
                                  
                                : null }
                              </div>
                              {this.state.oldCustomer.ranking ?
                                <div className="ranking"><i className="fas fa-trophy"></i>{generalData.optionsRanking[this.state.oldCustomer.ranking - 1].label}</div>
                                : null}
                              {this.state.oldCustomer.mobile ?
                                <div className="phone"><i className="fas fa-phone-square"></i>{this.state.oldCustomer.mobile}</div>
                              : null}
                              {this.state.oldCustomer.zalo_account ?
                                <div className="phone"><i className="fas fa-phone-square"></i>{this.state.oldCustomer.zalo_account}</div>
                              : null}
                              {(this.state.oldCustomer.district || this.state.oldCustomer.city) ?
                                <div className="address"><i className="fas fa-map-marker-alt"></i>
                                  {this.state.oldCustomer.district}
                                  {(this.state.oldCustomer.district && this.state.oldCustomer.city) ? "-" : null}
                                  {this.state.oldCustomer.city}
                                </div>
                                : null}
                              <div className="address"><i className="fas fa-birthday-cake"></i>
                                {this.state.day ? ("0" + this.state.day).slice(-2) + "/" : null}
                                {this.state.month ? ("0" + this.state.month).slice(-2) + "/" : null}
                                {this.state.year ? this.state.year : null}
                              </div>
                              {this.state.oldCustomer.note && this.state.oldCustomer.note != '' ? <div className="note">{this.state.oldCustomer.note}</div> : null}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    {Object.keys(this.state.oldCustomer).length === 0 || this.state.editOldCustomer === true ?
                      <>
                        <div className="items-data row">
                          <div className="title col-3 col-md-2">Địa chỉ</div>
                          <div className="value col-9 col-md-10" style={{ position: 'relative', zIndex: 3 }}>
                            <div className="row">
                              <div className="col-6 col-md-3">
                                <Select className="select"
                                  isSearchable={true}
                                  placeholder="Tỉnh/Thành"
                                  onChange={this.onChangeCity}
                                  styles={customStylesOption}
                                  defaultValue={(this.state.editOldCustomer === true ? location[this.state.keyCity] : '')}
                                  options={location} />
                              </div>
                              <div className="col-6 col-md-4">
                                <Select className="select"
                                  ref={ref => { this.selectDistrictRef = ref }}
                                  isSearchable={true}
                                  placeholder="Quận/Huyện"
                                  onChange={this.onChangeDistrict}
                                  styles={customStylesOption}
                                  defaultValue={(this.state.editOldCustomer === true ? this.state.dataDistrict[this.state.keyDistrict] : '')}
                                  options={this.state.dataDistrict} />
                              </div>
                              <div className="col-12 col-md-5">
                                <input onChange={this.onChangeAddress} defaultValue={(this.state.editOldCustomer === true ? this.state.oldCustomer.address : '')} placeholder="Địa chỉ" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="items-data row">
                          <div className="title col-3 col-md-2">Sinh nhật</div>
                          <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 2 }}>
                            <div className="row">
                              <div className="col-4">
                                <Select className="select"
                                  defaultValue={() => {
                                    const findIndex = optionsDays.findIndex(x => x.value == this.state.day)
                                    return optionsDays[findIndex]
                                  }}
                                  onChange={this.onChangeDay} placeholder="Ngày" styles={customStylesBirthday} options={optionsDays} />
                              </div>
                              <div className="col-4">
                                <Select className="select"
                                  defaultValue={() => {
                                    const findIndex = optionsDays.findIndex(x => x.value == this.state.month)
                                    return optionsDays[findIndex]
                                  }}
                                  onChange={this.onChangeMonth} placeholder="Tháng" styles={customStylesBirthday} options={optionsMonths} />
                              </div>
                              <div className="col-4">
                                <input onChange={this.onChangeYear} placeholder="Năm" defaultValue={this.state.year} type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="items-data row">
                          <div className="title col-3 col-md-2">Định danh</div>
                          <div className="value col-9 col-md-10" style={{ position: "relative", zIndex: 2 }}>
                            <div className="row">
                              <div className="col-4">
                                <Checkbox checked={this.state.identifier === 1} onChange={this.onChangeIdentifier}>Có</Checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      : ""}
                  </Spin>
                  <div className="items-data row border-top pdt-10">
                    <div className="title col-3 col-md-2">Giờ hẹn</div>

                    <div className="value col-9 col-md-10">
                      <div className="row">
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select" isSearchable={false} onChange={this.onChangeHourBooking} styles={customStylesOption} defaultValue={this.props.optionsHour[(this.state.book_hour - 9)]} options={this.props.optionsHour} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                          <Select className="select" isSearchable={false} onChange={this.onChangeMinuteBooking} styles={customStylesOption} defaultValue={this.props.optionsMinute[0]} options={this.props.optionsMinute} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4" style={{ position: 'relative', zIndex: 1, top: '-3px' }}>
                          <DatePicker
                            onChange={this.onChangeDateBooking}
                            className="custom-date-picker"
                            oneTap
                            format={'dd-MM-yyyy'}
                            placement={'bottomStart'}
                            defaultValue={(this.props.quickSetDate !== '') ? this.props.quickSetDate : new Date()}
                            size="sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Thời gian dự kiến</div>
                    <div className="value col-4 col-md-2">
                      <input className="right-text-input none-border-right" pattern="[0-9]*" onInput={this.changeTotalIntendTime} value={this.state.total_intend_time} type="text" />
                      <span className="measure">phút</span>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Mã voucher</div>
                    {!this.state.isAddVoucher ?
                        <div className="value col-9 col-md-5">
                        <span className='d-flexs'>
                          <input className="none-border-right" placeholder="Vui lòng nhập một mã voucher" onInput={this.changeVoucherCode} value={this.state.voucher_code} type="text" />
                          <Button className="ml-4" icon={<CheckOutlined style={{ color: "green" }} onClick={this.onSubmitVoucher} />} />
                        </span>
                      </div>
                    : 
                      <div className="value col-9 col-md-5">
                        <span className='d-flexs'>
                          <span style={{ color: "black", fontSize: "11px" }}>{this.state.voucher.name} </span>
                          (<span style={{ color: "red", cursor: "pointer", textDecoration: "underline", fontSize: "11px"  }} onClick={() => this.handleClearVoucher(this.state.voucher.id)}>Xóa</span>)
                        </span>
                      </div>
                    }
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú khách hàng</div>
                    <div className="value col-9 col-md-10">
                      <textarea onChange={this.onChangeNote} type="text" />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Ghi chú nội bộ</div>
                    <div className="value col-9 col-md-10">
                      <textarea onChange={this.onChangeNoteInternal} type="text" />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3 col-md-2">Số lượng khách</div>
                    <div className="value col-9 col-md-2">
                      <div className="row">
                        <div className="col-6">
                          <input className="right-text-input" pattern="[0-9]*" onInput={this.onChangeCountCustomer} defaultValue={this.state.countCustomer} type="text" />
                        </div>
                      </div>
                    </div>
                    <div className="title col-3 col-md-2">Tại chi nhánh</div>
                    <div className="value col-9 col-md-6 col-lg-4">
                      <Select className="select"
                        isSearchable={false}
                        onChange={this.onChangeStore}
                        styles={customStylesOption}
                        defaultValue={this.props.dataStore.map((item, key) => { if (item.value == this.state.stores) return this.props.dataStore[key] })}
                        options={this.props.dataStore} />
                    </div>
                  </div>
                  <div className="items-data row border-top">
                    <div className="title col-3 col-md-2 md-10">
                      <div className="bold">Khách hàng</div>
                      {this.state.typeBooking!= 47 ?
                        <div className="add-more" onClick={this.addMoreCustomer}><i className="fas fa-plus-circle"></i>Thêm khách</div>
                      : ""}
                    </div>
                    <div className="title col-6 d-none d-md-block bold">Dịch vụ</div>
                    <div className="title col-4 d-none d-md-block  bold">Nhân viên phục vụ</div>
                  </div>
                  {this.state.booking_item.map((item, key) => (
                    <div className="items-data row item-more-customer" key={key}>
                      <div className="value col-12 col-md-2">KHÁCH {key + 1} <span className={(item.intend_time > 0) ? "count-time active" : "count-time"}>(<i className="far fa-clock"></i> {item.intend_time} phút)</span></div>
                      <div className="title col-3 d-block d-md-none bold md-10">Dịch vụ</div>
                      <div className="value col-9 col-md-6 service md-10">
                        <Select className="select booking service"
                          isSearchable={true}
                          isMulti closeMenuOnSelect={false}
                          onChange={(event) => this.onChangeService(key, event)}
                          styles={customStylesMultiSelect}
                          formatGroupLabel={formatGroupLabel}
                          isOptionDisabled={(option) => option.disabled}
                          menuPlacement="top"
                          options={this.state.newDataService} />
                        {this.state.fullBodies.length > 0 && this.state.typeBooking != 47 ?
                          this.state.fullBodies.map((x, i) => {
                            return <div key={i}>
                              <PackageFullBody index={i} indexParent={key} onChangeItemFullBody={this.onChangeItemFullBody} data={x} />
                            </div>
                          })
                          : ""
                        }
                      </div>
                      <div className="title col-3 d-block d-md-none bold md-10">Nhân viên phục vụ</div>
                      <div className="value col-9 col-md-4 service md-10">
                        <Select className="select booking user"
                          isSearchable={true}
                          isMulti={this.state.typeBooking != 47 ? true : false} 
                          closeMenuOnSelect={this.state.typeBooking != 47 ? false : true}
                          onChange={(event) => this.onChangeUser(key, event)}
                          styles={customStylesMultiSelect}
                          options={this.props.dataOperators}
                          menuPlacement="top"
                          defaultValue={
                            this.props.dataOperators.map((val, key) => ((val.value === this.props.quickSetUser.id) ? this.props.dataOperators[key] : ''))
                          } />
                      </div>
                    </div>
                  ))}

                </div>
                <div className="col-md-12 col-lg-2">
                  <div className="item-sidebar status">
                    <div className="label-sidebar">Trạng thái</div>
                    <div className="value-sidebar">
                      {this.state.status === 1 ?
                        <div className="list-items">
                          <div className={(this.state.book_status === 1) ? "item active" : "item"} onClick={() => this.onChangeBookStatus(1)}><span className="small-circle status-1"></span>Chưa xác nhận</div>
                        </div>
                        : this.state.status === 2 ?
                          <div className="list-items">
                            <div className={(this.state.book_status === 2) ? "item active" : "item"} onClick={() => this.onChangeBookStatus(2)}><span className="small-circle status-2"></span>Đã xác nhận</div>
                          </div>
                          : <div className="list-items">
                            <div className={(this.state.book_status === 3) ? "item active" : "item"} onClick={() => this.onChangeBookStatus(3)}><span className="small-circle status-3"></span>Chờ phục vụ</div>
                          </div>}
                    </div>
                  </div>
                  <div className="item-sidebar source">
                    <div className="label-sidebar">Đặt lịch từ</div>
                    <div className="value-sidebar">
                      <div className={(this.state.source_from === 1) ? "item active" : "item"} onClick={() => this.onChangeSource(1)}>Gọi điện</div>
                      <div className={(this.state.source_from === 2) ? "item active" : "item"} onClick={() => this.onChangeSource(2)}>FB Messenger</div>
                      <div className={(this.state.source_from === 3) ? "item active" : "item"} onClick={() => this.onChangeSource(3)}>Instagram</div>
                      <div className={(this.state.source_from === 4) ? "item active" : "item"} onClick={() => this.onChangeSource(4)}>Zalo</div>
                      <div className={(this.state.source_from === 5) ? "item active" : "item"} onClick={() => this.onChangeSource(5)}>Website</div>
                      <div className={(this.state.source_from === 6) ? "item active" : "item"} onClick={() => this.onChangeSource(6)}>Từ cơ sở</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup border-top">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className={this.props.disableButtonAdd || this.state.source_from == '' ? "button-cent disable" : "button-cent"} onClick={this.props.disableButtonAdd || this.state.source_from == '' ? () => {} : this.addNewData}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          {this.state.showImgCustomer ?
            <ViewImgCustomer
              show={this.state.showImgCustomer}
              close={() => {this.setState({showImgCustomer: false})}}
              customer={this.state.oldCustomer}
              changeCurrenAvatar={this.changeCurrenAvatar}
            >
            </ViewImgCustomer>
          : null}
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null
  }
}
export default Add;
