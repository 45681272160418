export const PackageStatus = {
  1: 'Sử dụng',
  2: 'Đã khoá',
  3: 'Hết hạn',
  4: 'Hoàn thành',
  5: 'Đã hoàn tiền',
  6: 'Đã nâng cấp',
  7: "Đã hoàn ví triệt"
}
export const PackageStatusColor = {
  1: 'success',
  2: 'warning',
  3: 'danger',
  4: 'primary',
}