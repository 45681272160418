import { useState, useEffect } from "react";
import axiosService from "../../utils/axios.service";
import Webcam from 'webcam-easy';
import $ from 'jquery';
import { notifications } from "../constant/Notify";
import { Spin } from "antd"

export function ViewImgCustomer(props) {
  const [webcam, setWebcam] = useState(null)

  const [camDisplay, setCamDisplay] = useState(false)
  const [shot, setShot] = useState(false)
  const [imageShot, setImageShot] = useState(null)
  const [loading, setLoading] = useState(false)
  const [base64Image, setBase64Image] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    // const snapSoundElement = document.getElementById('snapSound');
    setWebcam(new Webcam(webcamElement, 'user', canvasElement, null));
  }, []);


  const openCam = () => {
    if (!props.customer.full_name) {
      notifications({ type: 'error', mess: 'Vui lòng cập nhật tên khách hàng trước' });
    } else {
      setCamDisplay(true)
      setBase64Image(null)
      webcam.start()
      .catch(err => {
        console.log(err);
      })
    }
  }

  const closePopup = () => {
    if (loading) return false
    setImageShot(null) 
    setBase64Image(null)
    setShot(false);
    setCamDisplay(false);
    webcam.stop();
    props.close()
  }

  const takePhoto = () => {
    if (loading) return false
    if (!shot) {
      let picture = webcam.snap();
      setImageShot(picture)
      setShot(true);
      webcam.stop();
    }
  }

  const uploadPhoto = async (type) => {
    if (loading) return false
    setLoading(true)
    let select = {
      placeID: 17956,
      photo: type === 1 ? imageShot :base64Image
    }
    let res = null;
    if (props.customer && props.customer.avata_url && props.customer.avata_url.length > 10) {
      res = await axiosService("api/hanet-ai/update-customer/" + props.customer.id, "POST", select)
    } else {
      res = await axiosService("api/hanet-ai/add-customer/" + props.customer.id, "POST", select)
    }
    if (res?.data?.success) {
      if (res?.data?.data?.returnCode === 1 && (res?.data?.data?.data?.path || res?.data?.data?.data?.file)) {
        setLoading(false)
        closePopup();
        props.changeCurrenAvatar(res?.data?.data?.data?.path ?? res?.data?.data?.data?.file)
        notifications({ type: 'success', mess: 'Cập nhật thành công' });
      } else {
        setLoading(false)
        if (res?.data?.data) setError(res?.data?.data)
        notifications({ type: 'error', mess: res.data.data.returnMessage ?? 'Đã có lỗi, Vui lòng thử lại sau'  });
      }
    } else {
      setLoading(false)
      notifications({ type: 'error', mess: res.data.data.returnMessage ?? 'Đã có lỗi, Vui lòng thử lại sau' })
    }
  }

  const closePhoto = () => {
    if (loading) return false
    setImageShot(null)
    setBase64Image(null)
    setError(null)
    setShot(false);
    setCamDisplay(false);
    webcam.stop();
  }

  const resumePhoto = () => {
    if (loading) return false
    setImageShot(null);
    setBase64Image(null)
    setShot(false);
    webcam.stream()
  }

  const uploadImageFromLocal = () => {
    if (loading) return false
    setBase64Image(null)
    setError(null)
    closePhoto();
    $('.customer-avatar-input').trigger('click');
  }

  const onFileChange = (e) => {
    try {
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      if (reader !== undefined && file !== undefined) {
        reader.onloadend = () => {
          setBase64Image(reader.result)
        }
        reader.readAsDataURL(file);
      }

    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className="popup-cent">
      <div className="content-popup fadeInDown" style={{width: '1024px'}}>
        <div className="head-popup">
          <div className="title">Ảnh khách hàng</div>
          <div className="close" onClick={() => closePopup()}>×</div>
        </div>
        <div className="body-popup">
          <div className="row">
            <div className="col-12">
              <Spin spinning={loading}>
                <div className="customer-avatar" style={{textAlign: "center"}}>
                {!props.customer.avata_url && !camDisplay && !base64Image ?
                  <div className="empty-avt">Khách hàng này chưa có ảnh</div>
                :
                  base64Image ?
                    <div className="upload-local">
                      <img style={{width: "70%", height: '70%'}} src={base64Image} alt="" />
                      <div className="cameraControls">
                        <div onClick={() => setBase64Image(null)} id="close"><i className="fas fa-times"></i></div>
                        <div onClick={() => uploadPhoto(2)} id="upload"><i className="fas fa-upload"></i></div>
                        <div onClick={() => uploadImageFromLocal()} id="resume"><i className="fas fa-redo"></i></div>
                      </div>
                    </div>
                  :
                    !camDisplay ? <a href={props.customer.avata_url} target="blank"><img style={{width: "70%", height: '70%'}} src={props.customer.avata_url} alt="" /></a> : null
                  }
                  <div className="camera" style={(!camDisplay) ? {display: "none"} : {}}>
                    {/* <video id="webcam" autoPlay playsInline width="1280" height="736"></video> */}
                    <video id="webcam" autoPlay playsInline width={1280} height={700}></video> 
                    <canvas id="canvas" className={`${!imageShot ? "d-none" : ""}`}></canvas>
                    {/* <audio id="snapSound" src="/audio/snap.wav" preload = "auto"></audio> */}
                    {imageShot ? 
                      <div className="cameraControls">
                        <div onClick={() => closePhoto()} id="close"><i className="fas fa-times"></i></div>
                        <div onClick={() => uploadPhoto(1)} id="upload"><i className="fas fa-upload"></i></div>
                        <div onClick={() => resumePhoto()} id="resume"><i className="fas fa-redo"></i></div>
                      </div>
                    : 
                      <div className="cameraControls">
                        <div onClick={() => closePhoto()} id="close"><i className="fas fa-times"></i></div>
                        <div onClick={() => takePhoto()} id="take-photo" className={`${shot ? "disable" :"" }`}><i className="fas fa-camera"></i></div>
                      </div>
                    }
                  </div>
                </div>
                <div hidden>
                  <input className="customer-avatar-input" accept=".jpeg, .png, .jpg" type="file" onChange={(e) => onFileChange(e)} />
                </div>
                {error ?
                  <div style={{textAlign: "center", color: "red", fontSize: "18px", marginTop: "20px"}}>
                    {error.returnMessage ? <p>{error.returnMessage}</p> : null}
                    {error.data?.name ? <p>Tên: {error.data.name}</p> : null}
                    {error.data?.mobile ? <p>SĐT: {error.data.mobile}</p> : null}
                  </div>

                : null}
              </Spin>
            </div>
          </div>
        </div>
        <div className="bottom-popup">
          <div className="row">
            <div className="col-4">
              <div className="button-container" style={{float: "left"}}>
                <div className="button-cent style3" onClick={() => closePopup()}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="button-container">
                <div className="button-cent style2" onClick={() => uploadImageFromLocal()}>
                  <span><i className="fas fa-upload"></i></span>Tải ảnh lên
                </div>
                {camDisplay ?
                  <div className="button-cent style" onClick={() => closePhoto()}>
                    <span><i className="fas fa-camera"></i></span>Đóng Camera
                  </div>
                :
                  <div className="button-cent style" onClick={() => openCam()}>
                    <span><i className="fas fa-camera"></i></span>Chụp ảnh
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={() => closePopup()}></div>
    </div>
  )
}