import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import { Tag } from "rsuite"
import StorageService from "../../utils/storage.service";
import Loading from "../customer/Loading";


const ReportServiceDetail = () => {
    const [title, setTiltle] = useState("Tiền thu từng dịch vụ")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [totalProductOrder, setTotalProductOrder] = useState([])
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47, 53, 54])
    const [totalMyPham, setTotalMyPham] =   useState(0)
    const [totalDeposit, setTotalDeposit] = useState(0)
    const [showloading, setShowloading] = useState(false)
    const [optionCategory, setOptionCategory] = useState([])
    const [catId, setCatId] = useState(0)

    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }

    const hanldeCategory = (x) => {
        setCatId(x)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/mysql/report-detail-service/all?start=${sD}&end=${eD}&store=${store}&keyword=${keyword}&catId=${catId}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const { dataChart, dataTable, dataCategory, optionCategory } = data.data
            // setChart(dataChart)
            setTable(dataTable)
            setTotalProductOrder(dataCategory)
            setOptionCategory(optionCategory)
            if (dataCategory.length > 0) {
           
                let totalMyPham = 0
                let totalDeposit = 0
                for(let o of dataCategory) {
                    if (o.category_id && !listCategoryIds.includes(o.category_id)) {
                        totalMyPham += Number(o.sum_paid_order_item)
                    }
                    if (o.category_id == 50 || !o.category_id) {
                        totalDeposit += Number(o.sum_paid_order_item)
                    }
                }
                setTotalMyPham(totalMyPham)
                setTotalDeposit(totalDeposit)
            }
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword, catId])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const columns = [
        {
            title: 'Tên dịch vụ',
            dataIndex: 'product_name',
            render: (data) => {
                return (
                    <>
                        <p>{data}</p>
                    </>
                )
            },
            sorter: (a, b) => a.product_name.length - b.product_name.length,
        },
        {
            title: 'Nhóm dịch vụ',
            dataIndex: 'category_name',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Số dịch vụ bán mới',
            dataIndex: 'count_order',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{Number(data)}</p>
                )
            },
        },
        {
            title: 'Tiền thực thu dịch vụ',
            dataIndex: 'total_paid',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.total_paid - b.total_paid,
                multiple: 3,
            },
        },
    ];
    const hanldeExportData = () => {
        setShowloading(true)
      
        var url = `api/mysql/report-detail-service/all?type=1&start=${startDate}&end=${endDate}&store=${store}&keyword=${keyword}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    return (
        <div className={`p-2 ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} 
                store={store}
                hanldeStore={hanldeStore}
                title={title} type={"report-detail-service"}
                csvData={table} isExport={isExport}
                hanldeExportData={hanldeExportData}
                onChangeKeyword={onChangeKeyword}
                header={
                    [
                        { label: 'Tên dịch vụ', key: "product_name" },
                        { label: "Nhóm dịch vụ", key: "category_name" },
                        { label: "Tiền thực thu dịch vụ", key: "total_paid" },
                    ]
                }
                userAdmin={userAdmin}
                optionCategory={optionCategory}
                hanldeCategory={hanldeCategory}
            />
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="p-2">
                <Col xs={12} md={8}>
                        <Row className="mh-172">
                            <Col xs={6}>
                                <Tag color="green" className=" w-100 h-100 p-2">
                                    <p className={styles.textTag3}>THỰC THU NHÓM DỊCH VỤ</p>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                        {totalProductOrder?.map(x => (
                                             listCategoryIds.includes(x.category_id) ?
                                                  <p className={styles.textTag4}>{x.category_name }</p> : null
                                            ))
                                        }
                                         <p className={styles.textTag4}>Mỹ Phẩm</p>
                                        </div>
                                        <div>
                                            {totalProductOrder.map(x => (
                                                 listCategoryIds.includes(x.category_id) ?
                                                    <p className={styles.textTag3}>{convertCurrency(x.sum_paid_order_item || 0)}</p> : null

                                                ))
                                            }
                                             <p className={styles.textTag3}>{convertCurrency(totalMyPham || 0)}</p> 
                                           
                                        </div>
                                    </div>
                                </Tag>
                            </Col>
                            <Col xs={6}>
                                <Tag color="blue" className=" w-100 h-100 p-2">
                                    <p className={styles.textTag3}>Tiền Nạp Vào Tài Khoản</p>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            
                                            <p className={styles.textTag4}>Tiền nạp vào tài khoản</p>
                                              
                                        </div>
                                        <div>
                                            <p className={styles.textTag3}>{convertCurrency(totalDeposit || 0)}</p>
                                           
                                        </div>
                                    </div>
                                </Tag>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Bảng số liệu chi tiết
                            </div>
                            <Table 
                                columns={columns}
                                dataSource={table}
                                pagination={{ pageSize: 10 }} 
                                scroll={{ x: 1300 }}
                                bordered
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>
            <Loading
            show={showloading}
             ></Loading>
        </div>
    )
}
export default ReportServiceDetail