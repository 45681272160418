export const UniqueCodeEnum = {
    isUnique: 2,
    isNotUnique: 1
}
export const ActivesEnum = {
    isActive: 2,
    isUnActive: 1
}
export const IfEnum = {
    ALL: 1,
    ANY: 2
}
export const ThenEnum = {
    TRUE: 1,
    FALSE: 2
}
export const onlyEnum = {
    TRUE: 1,
    FALSE: 2
}
export const ApplyEnum = {
    PERCENT: 1,
    PRICE: 2,
    PRODUCT: 3,
    PRODUCT_PERCENT: 4,
    PRODUCT_PRICE: 5
}
export const TypeContionEnum = {
    AND: "and",
    OR: "or"
}
export const typConditions = {
    THAN_TOTAL_ORDER: 1,
    LESS_TOTAL_ORDER: 2,
    ORDERS_CONTAIN: 3,
    ORDERS_NOT_CONTAIN: 4,
    CATEGORIES: 5,
    COMBO: 6,
    SETUP_MONEY_CART: 7,
    NOT_CATEGORIES: 8
}
export const typeVoucher = {
    VOUCHER: 0,
    COMBO: 1
}
export const IsForVoucher = {
    YES: 0,
    NO: 1
}

export const optionGroupUser = [
    {id: 1 , name: 'admin'},
    {id: 2 , name: 'KTV'},
    {id: 3 , name: 'LT'},
    {id: 4 , name: 'Cổ Đông'},
    {id: 5 , name: 'Sale'},
    {id: 6 , name: 'CSKH'}
]

export const optionRankCustomer = [
    {id: 1 , name: 'Member'},
    {id: 2 , name: 'Silver'},
    {id: 3 , name: 'Gold'},
    {id: 4 , name: 'Platinum'},
]