import { useState } from "react";
import axiosService from "../../utils/axios.service";



export function PopupConfirm(props) {
    const [isEdit, setEdit] = useState(true)

    var acceptCancelOrder = (event) => {
        if (isEdit) {
          props.actionConfirm()
        }
        setEdit(false)
    }
    var hiddenPopup = () => {
        props.setShow(false)
    }
    
    return (
        <div>
            <div className="head-popup">
                <div className="title">Hoàn tất thao tác thẻ <span></span> </div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn hoàn thành thao tác thẻ không ?</div>
            </div>
            <div className="bottom-popup">
            {isEdit ? 
              <div className="button-container">
                  <div className="button-cent style3" onClick={hiddenPopup} >
                    <span><i className="fas fa-times" ></i></span>Không
                  </div>
                  <div className="button-cent" onClick={acceptCancelOrder}>
                    <span><i className="fas fa-save"></i></span>Có
                  </div>
              </div>
            : ''}
            </div>
        </div>
    )
}