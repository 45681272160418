import location from "../../context/vietnam.json";
import _ from "lodash";

export function getYear(data) {
    let date = new Date(data);
    let res = date.getFullYear();
    return res;
};

export function getMonth(data) {
    let date = new Date(data);
    let res = ("0" + (date.getMonth() + 1)).slice(-2);
    return res;
};

export function getDay(data) {
    let date = new Date(data);
    let res = ("0" + date.getDate()).slice(-2);
    return res;
};

export function getHour(data) {
    let date = new Date(data);
    let res = ("0" + date.getHours()).slice(-2);
    return res;
};

export function getMinute(data) {
    let date = new Date(data);
    let res = ("0" + date.getMinutes()).slice(-2);
    return res;
};

export function convertTime(data, entity = ':') {
    let res = [getHour(data), getMinute(data)].join(entity);
    return res;
};

export function convertDate(data, entity = '-') {
    let res = [getYear(data), getMonth(data), getDay(data)].join(entity);
    return res;
};

export function convertDateDMY(data, entity = '-') {
  let res = [getDay(data), getMonth(data), getYear(data)].join(entity);
  return res;
};

export function convertDateTime(data, entity = ['-', ':']) {
    let res = [getYear(data), getMonth(data), getDay(data)].join(entity[0]) + ' ' + [getHour(data), getMinute(data)].join(entity[1]);
    return res;
};

export function convertCurrency(data) {
    let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
    return res;
};

export function convertCurrencyLoyalty(data) {
  let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
  let array = res.split('₫')
  return array[0] + 'điểm';
};

export function convertViToEn(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g," ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");

    return str;
}

const defaultNumbers =' hai ba bốn năm sáu bảy tám chín';

const chuHangDonVi = ('1 một' + defaultNumbers).split(' ');
const chuHangChuc = ('lẻ mười' + defaultNumbers).split(' ');
const chuHangTram = ('không một' + defaultNumbers).split(' ');

const  convert_block_three = (number) =>{
  if(number == '000') return '';
  var _a = number + ''; //Convert biến 'number' thành kiểu string

  //Kiểm tra độ dài của khối
  switch (_a.length) {
    case 0: return '';
    case 1: return chuHangDonVi[_a];
    case 2: return convert_block_two(_a);
    case 3: 
      var chuc_dv = '';
      if (_a.slice(1,3) != '00') {
        chuc_dv = convert_block_two(_a.slice(1,3));
      }
      var tram = chuHangTram[_a[0]] + ' trăm';
      return tram + ' ' + chuc_dv;
  }
}

const convert_block_two = (number) => {
  var dv = chuHangDonVi[number[1]];
  var chuc = chuHangChuc[number[0]];
  var append = '';

  // Nếu chữ số hàng đơn vị là 5
  if (number[0] > 0 && number[1] == 5) {
    dv = 'lăm'
  }

  // Nếu số hàng chục lớn hơn 1
  if (number[0] > 1) {
    append = ' mươi';
    
    if (number[1] == 1) {
      dv = ' mốt';
    }
  }

  return chuc + '' + append + ' ' + dv; 
}

const dvBlock = '1 nghìn triệu tỷ'.split(' ');

export function to_vietnamese(number) {
  var str = parseInt(number) + '';
  var i = 0;
  var arr = [];
  var index = str.length;
  var result = [];
  var rsString = '';

  if (index == 0 || str == 'NaN') {
    return '';
  }

  // Chia chuỗi số thành một mảng từng khối có 3 chữ số
  while (index >= 0) {
    arr.push(str.substring(index, Math.max(index - 3, 0)));
    index -= 3;
  }

  // Lặp từng khối trong mảng trên và convert từng khối đấy ra chữ Việt Nam
  for (i = arr.length - 1; i >= 0; i--) {
    if (arr[i] != '' && arr[i] != '000') {
      result.push(convert_block_three(arr[i]));

      // Thêm đuôi của mỗi khối
      if (dvBlock[i]) {
        result.push(dvBlock[i]);
      }
    }
  }

  // Join mảng kết quả lại thành chuỗi string
  rsString = result.join(' ');

  // Trả về kết quả kèm xóa những ký tự thừa
  return rsString.replace(/[0-9]/g, '').replace(/ /g,' ').replace(/ $/,'');
}

export function getDistricts(city, district) {
  let keyCity, keyDistrict = null;
  let dataDistrict = [];

  keyCity = _.findIndex(location, (o) => o.label == city);

  if (keyCity >= 0) {
    dataDistrict = location[keyCity].districts;
    if (district) keyDistrict = _.findIndex(dataDistrict, (o) => o.label == district);
  }

  return {keyCity, keyDistrict, dataDistrict};
}


export function getStoreByRole(data) {
  const newLocations = data.stores.map(x => {
      return { label: x.name_store, value: x.id }
  })

  return newLocations
}

export function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}
