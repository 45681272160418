import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios.service";
import AvatarCustomer from './AvatarCustomer';
import _ from "lodash"

var currentStore = localStorage.getItem("currentStore")
const PopupSelectAvatarCustomer = (props) => {
  const _props = props;
  const [optionValue, setOptionValue] = useState([])
  const [searchString, setSearchString] = useState('')
  const [avatarSelect, setAvatarSelect] = useState(null)
  
  useEffect( async () => {
  const res = await axiosService("api/hanet-ai/all/" + currentStore, "GET")
  if(res.data.success) {
    setOptionValue(res.data.data)
  }

  }, [_props.customer])

  const handleSelectVoucher = (e) => {
    let check = true
    if (avatarSelect && e.detected_image_url == avatarSelect.detected_image_url) {
      check = false
    }

    if (!check) {
      setAvatarSelect(null)
      _props.handleAddAvatarSelect(null)
      return
    }
    setAvatarSelect(e)
    _props.handleAddAvatarSelect(e)

    return 
  }


  const checkSelectVoucher = (item) => {
    let classAc = "grid-two-column-avatar"
    if (!avatarSelect) {
      classAc = "grid-two-column-avatar"
    }

    if (avatarSelect && avatarSelect.detected_image_url == item.detected_image_url) {
      classAc = "grid-two-column-avatar active"
    }
    if (avatarSelect && avatarSelect.detected_image_url != item.detected_image_url) {
      classAc = "grid-two-column-avatar disable-package2"
    }
    return classAc
  }

  const funcSaveSelect =(e) => {
      if (avatarSelect) {
        _props.saveAvatarCustomer(avatarSelect)
      }
  }

  const handleShowAvatarList = () => {
    _props.handleShowAvatarList(0)
  }

 
  return (
    <div className="popup-cent voucher-list avatar-list">
      <div className="content-popup fadeInDown">
        <div className="head-popup">
          <div className="title">Danh sách ảnh của khách hàng</div>
          <div className="close" onClick={handleShowAvatarList}>
            ×
          </div>
        </div>
        <div className="body-popup">
          <div className="total-price payment-block">
            { optionValue && optionValue.length > 0  ?
              <div className="avatar-customer-grid">
                  <div  className="title-list">Chọn ảnh của khách hàng : {_props.customer.full_name} {_props.customer?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</div>
                  {optionValue?.map((item, key) => (
                    <li className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} 
                    role="button" tabIndex="0" key={key}>
                      <AvatarCustomer voucher={item} />
                    </li>
                  ))}
              </div>
             : null}
         
          </div>
         
        </div>
        <div className="bottom-popup bottom-popup-save-avatar">
          <div className="button-container">
              <button className="button-cent" onClick={(e) => funcSaveSelect(e)}>
                <span>
                  <i className="fas fa-save"></i>
                </span>
                Hoàn thành
              </button>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={handleShowAvatarList}></div>
    </div>
  );
};

export default PopupSelectAvatarCustomer;
