import { Checkbox, Divider } from "antd"
import { Popover } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
export default function PackageChild({ packageSubs, onCheckAllChange, handleChange, checkAll, service }) {
    const convertDateUse = (diffDate, last_used) => {
        var date = new Date(moment(new Date(last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
        date.setDate(date.getDate() + diffDate)
        const dateUse = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        return dateUse
    }

    const [newPackageSubs, setNewPackageSubs] = useState([])

    useEffect(() => {

        let last_used =  service.max_used - service.count_used
        let package_type_using = service.package_type_using
        const filter = packageSubs.filter(x=>{
            return x.isSelect
        })
        
        let newPackageSubs = []
        if (package_type_using) {
            for (let item of packageSubs) {
                if ( filter.length >= last_used) {
                    if (!item.isSelect) {
                        item.isValid = false
                    }
                } else {
                    if (item.checkDate) {
                        item.isValid = true
                    }
                }
                newPackageSubs.push(item)
            }
        } else {
            newPackageSubs = [...packageSubs]
        }
        setNewPackageSubs([...newPackageSubs])
    }, [packageSubs]);


    return (
        <div onClick={(e) => {
            e.stopPropagation()
        }}>
            <Checkbox onChange={(e) => {
                onCheckAllChange()
            }} checked={checkAll} disabled= {service.package_type_using && newPackageSubs.length >  (service.max_used - service.count_used) ? true : false}>
                Chọn tất cả
            </Checkbox>
            <Divider />
            {newPackageSubs.map((x, i) => {
                if (x.isValid) {
                    return <div key={i}>
                        <Checkbox checked={x.isSelect} onChange={() => {
                            handleChange(x, i)
                        }} disabled={!x.isValid} >{x.product_name}</Checkbox>
                        <br />
                    </div>
                } else {
                    return <Popover content={`còn ${x.diffDate} ( ${convertDateUse(x.diffDate, x.last_used)} ) ngày mới được sử dụng`} title={`Chú ý thẻ ${x.product_name}`}>
                        <div key={x.id}>
                            <Checkbox checked={x.isSelect} onChange={() => {
                                handleChange(x, i)
                            }} disabled={!x.isValid} >{x.product_name}</Checkbox>
                            <br />
                        </div>
                    </Popover>
                }

            })}

        </div>
    )
}