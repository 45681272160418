import React, { useState }  from "react";
import { format } from "date-fns";
import { notifications } from "../constant/Notify";

const Copy = (props) => {
  if (props.showCopy === false) return "";

  const copyConfirm = () => {
    let product_name = [];
    props.dataBooking.booking_item.forEach(async (product) => {
      product.product_ids.forEach(async (productDetail) => {
        product_name.push(productDetail['product_name']);
      });
    });
    let address = props.dataBooking.stores.address + ", " + props.dataBooking.stores.district + ", " + props.dataBooking.stores.city;
    const a = `[ĐẶT LỊCH THÀNH CÔNG]
*Cent Beauty xin thông báo đã xác nhận lịch hẹn dịch vụ của bạn.
- Tên khách hàng: ${props.dataBooking.customers.full_name}
- Số điện thoại: ${props.dataBooking.customers.mobile}
- Mã lịch hẹn: ${props.dataBooking.book_code}
- Dịch vụ: ${product_name}
- Thời gian: ${format(new Date(props.dataBooking.book_date), "HH:mm")}
- Ngày: ${format(new Date(props.dataBooking.book_date), "dd-MM-yyyy")}
- Cơ sở: ${address}

Hẹn gặp lại bạn tại Cent Beauty.`;
    const textarea = document.createElement('textarea');
    textarea.value = a;

    document.body.appendChild(textarea);
    textarea.select();

    document.execCommand('copy');

    document.body.removeChild(textarea);
    notifications({ type: 'success', mess: 'Sao chép nội dung thành công!' });
    props.close()
  };

  return (
    <div className="popup-cent">
      <div className="content-popup fadeInDown">
        <div className="head-popup">
          <div className="title">Thông báo</div>
          <div className="close" onClick={props.close}>×</div>
        </div>
        <div className="body-popup">
          <div className="title-body">Bạn có muốn copy nội dung xác nhận đặt lịch không?</div>
        </div>
        <div className="bottom-popup">
          <div className="button-container">
            <div className="button-cent style3" onClick={props.close}>
              <span><i className="fas fa-times"></i></span>Không
            </div>
            <div className="button-cent" onClick={copyConfirm}>
              <span><i className="fas fa-save"></i></span>Có
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={props.close}></div>
    </div>
  );
}

export default Copy;
