import React from "react";
import location from "../../context/vietnam.json";
import { customStylesOption } from '../constant/GeneralData'
import Select from 'react-select'

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataDistrict: props.dataDistrict,
      keyCity: props.keyCity,
      keyDistrict: props.keyDistrict,
    };
  }
  selectDistrictRef = null;

  onChangeCity = (event) => {
    this.selectDistrictRef.clearValue();

    this.props.changeDataDistrict(event.districts)
    this.props.changeDataStore('district', '')
    this.props.changeDataStore('city', event.label)
  };

  onChangeDistrict = (event) => {
    if (event) {
      this.props.changeDataStore('district', event.label)
    }
  };
  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent store-edit">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Sửa thông tin chi nhánh</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="items-data row">
                    <div className="title col-3">Tên chi nhánh</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataStore('name_store', e.target.value)} className="required" defaultValue={this.props.detailStore.name_store} type="text"placeholder="Tên chi nhánh"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Mô tả</div>
                    <div className="value col-9"><textarea onChange={(e) => this.props.changeDataStore('description', e.target.value)} defaultValue={this.props.detailStore.description} placeholder="Mô tả về chi nhánh" name="description"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Điện thoại</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataStore('mobile', e.target.value)} defaultValue={this.props.detailStore.mobile} className="required" placeholder="012345678" type="text" name="phone_number"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Địa chỉ</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataStore('address', e.target.value)} defaultValue={this.props.detailStore.address} type="text" placeholder="Địa chỉ" name="address"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Địa chỉ google map</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataStore('google_map', e.target.value)} defaultValue={this.props.detailStore.google_map} type="text" name="link_google_map" placeholder="https://www.google.com/maps/place/Cent+Beauty+-+Tr%E1%BA%A7n+Duy+H%C6%B0ng/@21.0118922,105.7980533,17z/data=!3m1!4b1!4m5!3m4!1s0x3135ad362928d68b:0x804fa682efaa78c4!8m2!3d21.0118926!4d105.8002441?hl=vi-VN"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Thành phố</div>
                    <div className="value col-9">
                      <Select className="select"
                        isSearchable={true} 
                        placeholder="Tỉnh/Thành" 
                        onChange={this.onChangeCity}
                        styles={customStylesOption}
                        defaultValue={location[this.props.keyCity]}
                        options={location} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Quận/Huyện</div>
                    <div className="value col-9">
                      <Select className="select"
                        ref={ ref => { this.selectDistrictRef = ref }}
                        isSearchable={true} 
                        placeholder="Quận/Huyện" 
                        onChange={this.onChangeDistrict} 
                        styles={customStylesOption} 
                        defaultValue={this.props.dataDistrict[this.props.keyDistrict]} 
                        options={this.props.dataDistrict} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Quốc gia</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataStore('country', e.target.value)} defaultValue={this.props.detailStore.country} type="text" name="country"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className="button-cent" onClick={this.props.updateStore}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Edit;
