import React, { useState, useEffect } from "react";

var currentStore = localStorage.getItem("currentStore")
const AvatarCustomer = (props) => {
  const _props = props;
  return (
      <div className="avatar">
          <img src={_props.voucher.detected_image_url} alt="" />
      </div>
  );
};

export default AvatarCustomer;
