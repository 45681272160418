import React, { useState, useEffect } from "react";
import * as convert from "../constant/Convert.helper";
import _ from "lodash"
import { DatePicker, Spin } from 'antd';
import moment from 'moment';
import { format } from "date-fns";
import axiosService from "../../utils/axios.service";
import { notifications } from "../constant/Notify";

const DisableBooking = (props) => {
    const [dataDisable, setDataDisable] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        const { data } = await axiosService("api/store-disable/" + Number(props.detailStore.id), "GET");
        let listOperation = []
        if (data.success && data.data.length > 0) {
            data.data.map(x => {
                listOperation.push({
                    ...x,
                    edit: false
                })
            })
        }
        setDataDisable([...listOperation])
        setLoading(false)
    }

    const onChangeData = (title, value, key) => {
        let arrayData = dataDisable;
        let edit = value ? true : false;

        arrayData[key] = {
            ...arrayData[key],
            [title]: value ? format(value?._d, 'yyyy-MM-dd HH:mm') : null,
            edit: edit
        }

        setDataDisable([...arrayData])
    };

    const onChangeReason = (value, key) => {
        let arrayData = dataDisable;
        let edit = value ? true : false;

        arrayData[key] = {
            ...arrayData[key],
            edit: edit,
            reason: value
        }

        setDataDisable([...arrayData])
    };

    const addDataClose = () => {
        let dataNew = {
            id: null,
            store_id: Number(props.detailStore.id),
            start_disable: new Date(),
            end_disable: new Date(),
            edit: true
        }

        setDataDisable([...dataDisable, dataNew])
    }

    const deleteData = async (key) => {
        let arrayData = dataDisable;
        if (arrayData[key].id) {
            setLoading(true)
            const res = await axiosService("api/store-disable/delete/" + Number(arrayData[key].id), "PUT");
            if (res.data.success) {
                notifications({ type: 'success', mess: 'Cập nhật thành công' });
                await fetchData()
            } else {
                notifications({ type: 'warning', mess: res.data.message });
                setLoading(false)
            }
        } else {
            arrayData.splice(key, 1)
            setDataDisable([...arrayData])
        }
    }

    const saveData = async (key) => {
        setLoading(true)
        let arrayData = dataDisable;

        let payload = arrayData[key];
        console.log(payload)
        const res = await axiosService("api/store-disable/save", "POST", payload);
        if (res.data.success) {
            notifications({ type: 'success', mess: 'Cập nhật thành công' });
            await fetchData()
        } else {
            notifications({ type: 'warning', mess: res.data.message });
            setLoading(false)
        }
    }

    useEffect(async () => {
        await fetchData();
    }, [])

    return (
        <div className="popup-cent edit-slot-operation">
            <div className="content-popup fadeInDown">
            <div className="head-popup">
                <div className="title">Khoá Booking</div>
                <div className="close" onClick={() => {props.displayPopupDisableBooking()}}>×</div>
            </div>
            <Spin spinning={loading} size="large" tip="Đang tải...">
                <div className="body-popup">
                    {dataDisable.length > 0 ?
                        <div className="block-data">
                            {dataDisable.map((x,y) => (
                                <div className="row items-data" key={y}>
                                    <div className="col-12 col-md-10">
                                        <div className="row">
                                            <div className="col-2 col-lg-1 title">Bắt đầu:</div>
                                            <div className="col-10 col-md-4">
                                                <DatePicker showTime
                                                    defaultValue={moment(new Date(x.start_disable), 'DD-MM-yyyy HH:mm')} 
                                                    onChange={(e) => onChangeData('start_disable', e, y)} 
                                                    onOk={(e) => onChangeData("start_disable", e, y)} 
                                                    format={'DD-MM-yyyy HH:mm'} placeholder="dd-mm-yyyy hh:mm" />
                                            </div>
                                            <div className="col-2 col-lg-1 title">Kết thúc:</div>
                                            <div className="col-10 col-md-4">
                                                <DatePicker showTime
                                                    defaultValue={moment(new Date(x.end_disable), 'DD-MM-yyyy HH:mm')} 
                                                    onChange={(e) => onChangeData('end_disable', e, y)} 
                                                    onOk={(e) => onChangeData("end_disable", e, y)} 
                                                    format={'DD-MM-yyyy HH:mm'} placeholder="dd-mm-yyyy hh:mm" />
                                            </div>
                                            <div className="col-12 textarea">
                                                <div className="row">
                                                    <div className="col-2 col-lg-1 title">Lý do: </div>
                                                    <div className="col-10 col-lg-9">
                                                        <textarea defaultValue={x.reason} onChange={(e) => {onChangeReason(e.target.value, y)}} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-2 col-md-1">
                                        <div className={`${!x.edit ? "disable" : ""} button-cent style1`} onClick={x.edit ? () => {saveData(y)} : () => {}}>
                                            <span><i className="fas fa-save"></i></span> Lưu
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-1">
                                        <div className="button-cent style3" onClick={() => {deleteData(y)}}>
                                            <span><i className="fas fa-trash-alt"></i></span> Xoá
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    : null}
                    <div className="action-title"><span onClick={addDataClose}><i className="fas fa-plus-circle"></i> Thêm khoảng thời gian mới</span></div>
                </div>
                <div className="bottom-popup">
                    <div className="button-container">
                        <div className="button-cent style3" onClick={() => {props.displayPopupDisableBooking()}}>
                            <span><i className="fas fa-times"></i></span>Đóng
                        </div>
                    </div>
                </div>
            </Spin>
            </div>
            <div className="overlay" onClick={() => {props.displayPopupDisableBooking()}}></div>
        </div>
    );
};

export default DisableBooking;
