import React from "react";
import axiosService from "../../utils/axios.service";

class Loading extends React.Component {
  constructor() {
    super();
  }

  acceptDelete = (event) => {
    this.props.deleteCustomer()
  };

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent loading">
          <div className="overlay" onClick={this.props.close}>
            <div className="icon-loading"><i className="fa fa-spinner fa-spin"></i></div>
          </div>
        </div>
      );
    } else return <div></div>;
  }
}
export default Loading;
