import React from "react";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
let _ = require('lodash');


class Edit extends React.Component {
  getCurrentDataSelect = (listData, currentData) => {
    let res;
    listData.findIndex((item) => {
      if (item.value === currentData) res = item
    }) 
    return res
  }
  
  onChangeCheckbox = (key, event) => {
    let data = event.target.checked === true ? 1 : 2;
    this.props.changeDataService(key, data);
  };
  
  onChangePrice = (key, event) => {
    let dataPrice = event.replaceAll(",", "");
    this.props.changeDataService(key, dataPrice);
  };

  checkKey = (value) => {
    var checkKey = _.findIndex(this.props.optionsHourLast, function (o) { return o.value == value && o.parent_package == null; });
    return checkKey
  }

  

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Tạo dịch vụ mới</div>
              <div className="close" onClick={this.props.close}>
                ×
              </div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12">
                  <div className="items-data row">
                    <div className="title col-4">Mã dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <input
                        onChange={(e) =>
                          this.props.changeDataService("code", e.target.value)
                        }
                        defaultValue={this.props.detailService.code}
                        type="text"
                      />
                      * Chú ý: Hệ thống sẽ tự sinh mã nếu bạn không nhập
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Tên dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <input
                        className="required"
                        onChange={(e) =>
                          this.props.changeDataService(
                            "product_name",
                            e.target.value
                          )
                        }
                        defaultValue={this.props.detailService.product_name}
                        placeholder="Nhập tên dịch vụ"
                        name="service_name"
                      />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thời gian phục vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-6">
                          <Select
                            className="select"
                            onChange={(e) =>
                              this.props.changeDataService("hour-meta", e.value)
                            }
                            defaultValue={
                              this.props.optionsHour[
                                this.props.detailService.meta_object.hour
                              ]
                            }
                            isSearchable={true}
                            options={this.props.optionsHour}
                          />
                        </div>
                        <div className="col-6">
                          <Select
                            className="select"
                            onChange={(e) =>
                              this.props.changeDataService("minute-meta", e.value)
                            }
                            defaultValue={
                              this.props.optionsMinus[
                                this.props.detailService.meta_object.minute / 5
                              ]
                            }
                            isSearchable={true}
                            options={this.props.optionsMinus}
                          />
                        </div>
                        <div className="col-12">
                          <label className="checkbox">
                            <input
                              onChange={(e) =>
                                this.onChangeCheckbox("status_print_hour-meta", e)
                              }
                              defaultChecked={
                                Number(this.props.detailService.meta_object.status_print_hour) === 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            In giờ phục vụ lên hóa đơn
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thời gian đặt lịch muộn nhất</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-6">
                          <Select
                            className="select"
                            onChange={(e) =>
                              this.props.changeDataService("last_hour-meta", e.value)
                            }
                            defaultValue={
                              this.props.optionsHourLast[
                                this.checkKey(this.props.detailService.meta_object.last_hour)
                              ]
                            }
                            isSearchable={true}
                            options={this.props.optionsHourLast}
                          />
                        </div>
                        <div className="col-6">
                          <Select
                            className="select"
                            onChange={(e) =>
                              this.props.changeDataService("last_minute-meta", e.value)
                            }
                            defaultValue={
                              this.props.optionsMinus[
                                this.props.detailService.meta_object.last_minute / 5
                              ]
                            }
                            isSearchable={true}
                            options={this.props.optionsMinus}
                          />
                        </div>
                        <div className="col-12">
                          <label className="checkbox">
                            <input
                              onChange={(e) =>
                                this.onChangeCheckbox("status_print_hour-meta", e)
                              }
                              defaultChecked={
                                Number(this.props.detailService.meta_object.status_print_hour) === 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            In giờ phục vụ lên hóa đơn
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            placeholder="Nhập giá dịch vụ"
                            defaultValue={this.props.detailService.price}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            onChange={(e) =>
                              this.onChangePrice("price", e.target.value)
                            }
                          />
                          <div className="currency">₫</div>
                        </div>
                        <div className="col-12 col-md-6 edit-price">
                          <label className="checkbox">
                            <input
                              onChange={(e) =>
                                this.onChangeCheckbox("status_edit_price-meta", e)
                              }
                              defaultChecked={
                                Number(this.props.detailService.meta_object.status_edit_price) === 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            Sửa giá khi thanh toán
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá gốc dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            defaultValue={this.props.detailService.base_price}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            onChange={(e) =>
                              this.onChangePrice("base_price", e.target.value)
                            }
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Phân nhóm dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <Select
                        className="select"
                        options={this.props.listSelectGroup}
                        onChange={(e) =>
                          this.props.changeDataService("category_id", e.value)
                        }
                        defaultValue={this.getCurrentDataSelect(this.props.listSelectGroup, this.props.detailService.category_id)}
                      />
                    </div>
                  </div>
                  {this.props.detailService.category_id && this.props.all_sub_category[this.props.detailService.category_id] ? 
                    <div className="items-data row">
                      <div className="title col-4">Nhóm con</div>
                      <div className="value col-8 col-md-7">
                        <Select 
                          className="select" 
                          options={this.props.all_sub_category[this.props.detailService.category_id]}
                          onChange={(e) =>
                            this.props.changeDataService("sub_category_id", e.value)
                          }
                          defaultValue={this.getCurrentDataSelect(this.props.all_sub_category[this.props.detailService.category_id], this.props.detailService.sub_category_id)}
                        />
                      </div>
                    </div>
                  : null }
                  {this.props.detailService.category_id && this.props.all_nails_type[this.props.detailService.category_id] ? 
                    <div className="items-data row">
                      <div className="title col-4">Kiểu nails</div>
                      <div className="value col-8 col-md-7">
                        <Select className="select" 
                          options={this.props.all_nails_type[this.props.detailService.category_id]}
                          onChange={(e) =>
                            this.props.changeDataService("nails_type-meta", e.value)
                          }
                          defaultValue={this.getCurrentDataSelect(this.props.all_nails_type[this.props.detailService.category_id], Number(this.props.detailService.meta_object.nails_type))}
                         />
                      </div>
                    </div>
                  : null }
                  <div className="items-data row">
                    <div className="title col-4">Xếp hạng nổi bật (app KH)</div>
                    <div className="value col-8 col-md-7">
                      <Select
                        className="select"
                        onChange={(e) =>
                          this.props.changeDataService("ranking", e.value)
                        }
                        defaultValue={this.getCurrentDataSelect(this.props.optionsRanking, this.props.detailService.ranking)}
                        options={this.props.optionsRanking}
                      />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thông tin chi tiết</div>
                    <div className="value col-8 col-md-7">
                      <textarea
                        onChange={(e) =>
                          this.props.changeDataService(
                            "description",
                            e.target.value
                          )
                        }
                        defaultValue={this.props.detailService.description}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="status-service">
                <Select
                  className="select"
                  onChange={(e) => this.props.changeDataService("status", e.value)}
                  defaultValue={this.getCurrentDataSelect(this.props.optionsStatus, this.props.detailService.status)}
                  options={this.props.optionsStatus}
                />
              </div>
              <div className="button-container">
                <div className="button-cent" onClick={this.props.updateService}>
                  <span>
                    <i className="fas fa-save"></i>
                  </span>
                  Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Edit;
