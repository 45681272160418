import React, { useState, useEffect } from 'react'
import axiosService from "../../utils/axios.service";
import { axiosGet } from "../../utils/axios.service";
import Select from "react-select";
import { customStylesBirthday } from './constant/ConstantPackage';
import { customStylesOption, customStylesMultiSelect, optionsDays, optionsMonths } from '../constant/GeneralData'
import Avatar from 'react-avatar';






const Edit = (props) => {
    const  getDataBirthday = (birthday, type) => {
        let date = new Date(birthday),
        year = date.getFullYear(),
        month = date.getMonth()+1,
        day = date.getDate(),
        res = '';
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (type == 'day') {
          res = day;
        }
    
        if (type == 'month') {
          res = month;
        }
        if (type == 'year') {
          res = year;
        }
    
        return res;
      };
      let dayOld = getDataBirthday(props.package.expiration_date, 'day'),
      monthOld = getDataBirthday(props.package.expiration_date, 'month'),
      yearOld = (getDataBirthday(props.package.expiration_date, 'year'));

    const [storeId, setStoreId] = useState(props.package.store_id)
    const [statusSearchCustomer, setStatusSearchCustomer] = useState(false)
    const [searchCustomer, setSearchCustomer] = useState([])
    const [customerId, setCustomerId] = useState(props.package.customer_id)
    const [customerName, setCustomerName] = useState(props.package.customer.full_name)
    const [showInputSearch, setShowInputSearch] = useState(false)
    const [expirationDate, setExpirationDate] = useState("")
    const [day, setDay] = useState(dayOld)
    const [month, setMonth] = useState(monthOld)
    const [year, setYear] = useState(yearOld)

    var storeList = [...props.listStore]
    storeList.shift()

    const updateData = (event) => {
        var  expirationDate = year +'/' +  month + '/' + day
             setExpirationDate(expirationDate)
        var data = {
            expiration_date: expirationDate,
            store_id: storeId,
            customer_id: customerId,
            status: props.package.status
            
        }

        axiosService(
            "api/package/" + props.package.id,
            "PUT",
            data
        )
            .then((res) => {
                const response = res.data;
                if (response.message === "success") {
                    window.location.reload()
                }
            })
            .catch((error) => console.log(error));
    };

    const onChangeSearchCustomer = (event) => {
        let keyword = event.target.value;

        if (keyword.length >= 2)
            axiosService("api/customer?keyword=" + keyword, "GET")
                .then((res) => {
                    const response = res.data;
                    setStatusSearchCustomer(true)
                    setSearchCustomer(response.data.data)
                }).catch((error) => console.log(error));

    }
    const closeSearch = (event) => {
        setStatusSearchCustomer(false)
    }


    const hendelClose = (event) => {
        props.close(false)
    }
    const onChangeStoreId = (event) => {
        setStoreId(event.value)
    }

    const onChangeDay = (event) => {
        setDay(event.value)
    }

    const onChangeMonth = (event) => {
        setMonth(event.value)
    }

    const onChangeYear = (event) => {
        setYear(event.target.value)
    }
    const getDetailCustomer = (data) => {
        setCustomerId(data.id)
        setCustomerName(data.full_name)
        setShowInputSearch(false)
    };

    const clickChangeCustomerName = (envent) => {
        setShowInputSearch(true)
    }
    

    return (
        <div className="popup-cent"  onClick={closeSearch}>
            <div className="content-popup fadeInDown popup-package-edit">
                <div className="head-popup">
                    <div className="title">Chỉnh sửa thông tin thẻ</div>
                    <div className="close" onClick={hendelClose}>×</div>
                </div>
                <div className="body-popup">
                    <form className="form-horizontal ng-valid ng-dirty ng-valid-parse">
                        <div className="modal-body">
                            {/* <div className="form-group flex">
                                <label className="control-label col-3 ng-binding">Mã thẻ</label>
                                <div className="col-6">
                                    <input defaultValue={props.} type="text" className="form-control ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="selectedEditingCard.code" aria-invalid="false"  />
                                </div>
                            </div> */}

                            <div className="form-group flex">
                                <label className="control-label col-3 ng-binding">Thời hạn thẻ</label>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4">
                                            <Select className="select" onChange={onChangeDay} placeholder="Ngày" styles={customStylesBirthday} defaultValue={optionsDays[dayOld-1]} options={optionsDays} />
                                        </div>
                                        <div className="col-4">
                                            <Select className="select" onChange={onChangeMonth} placeholder="Tháng" styles={customStylesBirthday} defaultValue={optionsMonths[monthOld-1]} options={optionsMonths} />
                                        </div>
                                        <div className="col-4">
                                            <input className="form-control input-xs ng-pristine ng-valid ng-not-empty ng-touched" onChange={onChangeYear} defaultValue={yearOld} placeholder="Năm" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group flex">
                                <label className="control-label col-3 ng-binding">Khách hàng</label>
                                <div className="items-data col-6">
                                    <div className="value ">
                                        {showInputSearch ? 
                                         <input onChange={onChangeSearchCustomer} placeholder="Tìm khách hàng theo tên hoặc số điện thoại" defaultValue={customerName} type="text" />
                                         : <div className='div-search' onClick={clickChangeCustomerName} > {customerName}</div>
                                         }
                                        {statusSearchCustomer === true ?
                                            <div className="result-search">
                                                {searchCustomer.length === 0 ?
                                                    <div className="empty-customer">
                                                        Không tìm thấy dữ liệu phù hợp
                                                    </div>
                                                    :
                                                    <div className="items-customer">
                                                        {searchCustomer.map((data, key) => (
                                                            <div className="item" key={key} onClick={() => { getDetailCustomer(data) }}>
                                                                <div className="avatar">
                                                                {data.avata_url ? 
                                                                <img src={data.avata_url} alt="" />
                                                                : <Avatar name={data.full_name}  size="50" textSizeRatio={1.75} round="50%" /> }
                                                                </div>
                                                                <div className="info">
                                                                    <div className="name">
                                                                        {data.full_name}
                                                                        {data?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                                                    </div>
                                                                    <div className="phone">{data.mobile}</div>
                                                                    <div className="address">{data.district} - {data.city}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group flex">
                                <label className="control-label col-3 ng-binding">Tại chi nhánh</label>
                                <div className="col-6">
                                    {storeList[0] ? <Select
                                        isSearchable={false}
                                        className="select"
                                        defaultValue={storeList.map((item, key) => ((props.package.store_id === item.value) ? storeList[key] : ''))}
                                        options={storeList}
                                        onChange={onChangeStoreId}
                                    /> : ""}
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
                <div className="bottom-popup">
                    <div className="button-container">
                        <div className="button-cent style3" onClick={hendelClose}>
                            <span><i className="fas fa-times"></i></span>Không
                        </div>
                        <div className="button-cent" onClick={updateData}>
                            <span><i className="fas fa-save"></i></span>Có
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" onClick={hendelClose}></div>
        </div>
    );
}
export default Edit;
