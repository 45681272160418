import React, { useState } from "react";
export const UserContext = React.createContext("");

const LoginContext = ({ subPages }) => {
  let currentDay = new Date().getTime() / 1000,
      end_session = (new Date(localStorage.getItem("end_session")).getTime()) / 1000;
      
  if (currentDay >= end_session) {
    localStorage.removeItem('end_session');
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_info');
  }
  
  let userInfo = localStorage.getItem("user_info");
  if (typeof userInfo === "string") userInfo = JSON.parse(userInfo);

  const [user, setUser] = useState(userInfo || null);
  return (
    <UserContext.Provider value={[user, setUser]}>
      {subPages}
    </UserContext.Provider>
  );
};
export default LoginContext;
