import React from "react";
import axiosService from "../../utils/axios.service";
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Hour: this.props.optionsHour[0].value,
      Minute: this.props.optionsMinus[6].value,
      LastHour: this.props.optionsHourLast[12].value,
      LastMinute: this.props.optionsMinus[0].value,
      ServiceGroupId: "",
      ServiceSubGroupId: "",
      nailsTypeId: "",
      ServiceName: "",
      Code: "",
      Description: "",
      Price: "",
      BasePrice: "",
      Ranking: this.props.optionsRanking[0].value,
      Status: 1,
      StatusEditPrice: false,
      StatusPrintHour: false,
      CreatedBy: 1
    }
  }
  
  onChangeCode = (event) => {
    this.setState({
      Code: event.target.value,
    });
  };

  onChangeServiceName = (event) => {
    this.setState({
      ServiceName: event.target.value,
    });
  };

  onChangeTimeHour = (event) => {
    this.setState({
      Hour: event.value,
    });
  };

  onChangeTimeMinus = (event) => {
    this.setState({
      Minute: event.value,
    });
  };

  onChangeLastHour = (event) => {
    this.setState({
      LastHour: event.value,
    });
  }

  onChangeLastMinus = (event) => {
    this.setState({
      LastMinute: event.value,
    });
  }

  onChangeStatusPrintHour = (event) => {
    this.setState({
      StatusPrintHour: !this.state.StatusPrintHour,
    });
  };

  onChangePrice = (event) => {
    let dataPrice = event.target.value.replaceAll(",", "");
    this.setState({
      Price: dataPrice,
    });
  };

  onChangeStatusEditPrice = (event) => {
    this.setState({
      StatusEditPrice: !this.state.StatusEditPrice,
    });
  };

  onChangeBasePrice = (event) => {
    let dataBasePrice = event.target.value.replaceAll(",", "");
    this.setState({
      BasePrice: dataBasePrice,
    });
  };

  onChangeGroup = (event) => {
    this.setState({
      ServiceGroupId: event.value,
    });
  };

  onChangeSubGroup = (event) => {
    this.setState({
      ServiceSubGroupId: event.value,
    });
  };

  onChangeTypeNails = (event) => {
    this.setState({
      nailsTypeId: event.value,
    });
  };

  onChangeRanking = (event) => {
    this.setState({
      Ranking: event.value,
    });
  };

  onChangeDescription = (event) => {
    this.setState({
      Description: event.target.value,
    });
  };

  onChangeStatus = (event) => {
    this.setState({
      Status: event.value,
    });
  };

  addNewServices = () => {
    let StatusEditPrice = (this.state.StatusEditPrice === true ) ? 1 : 2,
        StatusPrintHour = (this.state.StatusEditPrice === true ) ? 1 : 2;
    let meta_object = {
      "hour" : this.state.Hour,
      "minute": this.state.Minute,
      "status_print_hour": StatusPrintHour,
      "status_edit_price": StatusEditPrice,
      "last_hour": this.state.LastHour,
      "last_minute": this.state.LastMinute,
    }
    if (this.state.ServiceGroupId == 47 && this.state.nailsTypeId) {
      meta_object = {
        ...meta_object,
        "nails_type": this.state.nailsTypeId
      }
    } 
    const dataServices = {
      "code": this.state.Code,
      "product_name": this.state.ServiceName,
      "price": this.state.Price,
      "base_price": this.state.BasePrice,
      "category_id": this.state.ServiceGroupId,
      "sub_category_id": this.state.ServiceSubGroupId,
      "ranking": this.state.Ranking,
      "description": this.state.Description,
      "status": this.state.Status,
      "type": 1,
      "meta_object": meta_object
    };
    this.props.addServices(dataServices)
  };
  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Tạo dịch vụ mới</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              {/* <div className="header-tab">
                <div className={"item" + (this.props.currentAddTab === 1 ? ' active' : '')} onClick={() => this.props.changeAddTabs(1)}>Thông tin dịch vụ</div>
                <div className={"item" + (this.props.currentAddTab === 2 ? ' active' : '')} onClick={() => this.props.changeAddTabs(2)}>Tùy chọn giá</div>
                <div className={"item" + (this.props.currentAddTab === 3 ? ' active' : '')} onClick={() => this.props.changeAddTabs(3)}>Địa điểm cung cấp</div>
              </div> */}
              <div className="row">
                <div className="col-12">
                  <div className="items-data row">
                    <div className="title col-4">Mã dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <input onChange={this.onChangeCode} type="text"/>
                      * Chú ý: Hệ thống sẽ tự sinh mã nếu bạn không nhập
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Tên dịch vụ</div>
                    <div className="value col-8 col-md-7"><input className="required" onChange={this.onChangeServiceName} placeholder="Nhập tên dịch vụ" name="service_name"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thời gian phục vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-6">
                          <Select className="select" onChange={this.onChangeTimeHour} defaultValue={this.props.optionsHour[0]} isSearchable={true} options={this.props.optionsHour} />
                        </div>
                        <div className="col-6">
                          <Select className="select" onChange={this.onChangeTimeMinus} defaultValue={this.props.optionsMinus[6]} isSearchable={true} options={this.props.optionsMinus} />
                        </div>
                        <div className="col-12">
                          <label className="checkbox">
                            <input onChange={this.onChangeStatusPrintHour} type="checkbox"/>
                            In giờ phục vụ lên hóa đơn
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thời gian đặt lịch muộn nhất</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-6">
                          <Select className="select" onChange={this.onChangeLastHour} defaultValue={this.props.optionsHourLast[12]} isSearchable={true} options={this.props.optionsHourLast} />
                        </div>
                        <div className="col-6">
                          <Select className="select" onChange={this.onChangeLastMinus} defaultValue={this.props.optionsMinus[0]} isSearchable={true} options={this.props.optionsMinus} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            placeholder="Nhập giá dịch vụ"
                            defaultValue={0}
                            groupSeparator={','}
                            decimalSeparator={'.'}
                            onChange={this.onChangePrice}
                          />
                          <div className="currency">₫</div>
                        </div>
                        <div className="col-12 col-md-6 edit-price">
                          <label className="checkbox">
                            <input onChange={this.onChangeStatusEditPrice} type="checkbox"/>
                            Sửa giá khi thanh toán
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá gốc dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            defaultValue={0}
                            groupSeparator={','}
                            decimalSeparator={'.'}
                            onChange={this.onChangeBasePrice}
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Phân nhóm dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeGroup} options={this.props.listSelectGroup} />
                    </div>
                  </div>
                  {this.state.ServiceGroupId && this.props.all_sub_category[this.state.ServiceGroupId] ? 
                    <div className="items-data row">
                      <div className="title col-4">Nhóm con</div>
                      <div className="value col-8 col-md-7">
                        <Select className="select" onChange={this.onChangeSubGroup} options={this.props.all_sub_category[this.state.ServiceGroupId]} />
                      </div>
                    </div>
                  : null }
                  {this.state.ServiceGroupId && this.props.all_nails_type[this.state.ServiceGroupId] ? 
                    <div className="items-data row">
                      <div className="title col-4">Kiểu nails</div>
                      <div className="value col-8 col-md-7">
                        <Select className="select" onChange={this.onChangeTypeNails} options={this.props.all_nails_type[this.state.ServiceGroupId]} />
                      </div>
                    </div>
                  : null }

                  <div className="items-data row">
                    <div className="title col-4">Xếp hạng nổi bật (app KH)</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeRanking} defaultValue={this.props.optionsRanking[0]} options={this.props.optionsRanking} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thông tin chi tiết</div>
                    <div className="value col-8 col-md-7">
                      <textarea onChange={this.onChangeDescription} type="text"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="status-service">
                <Select className="select" onChange={this.onChangeStatus} defaultValue={this.props.optionsStatus[0]} options={this.props.optionsStatus} />
              </div>
              <div className="button-container">
                <div className="button-cent" onClick={this.addNewServices}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Add;
