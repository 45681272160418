import React, { useState, useEffect } from "react";
import { optionsPaymentMethods, paymentMethodManyForms, accountPayment, accountSwipe } from "../constant/GeneralData";
import CurrencyInput from "react-currency-input-field";
import * as convert from "../constant/Convert.helper";
import Select from "react-select";
import _ from "lodash"
import axiosService from "../../utils/axios.service";

var currentStore = localStorage.getItem("currentStore")
// let paymentMethodManyForm = paymentMethodManyForms
const Payment = (props) => {
  const _props = props;
  const [statusCheckout, setStatusCheckout] = useState(true);
  const [moneyBack, setMoneyBack] = useState(0);
  const [moneyOwed, setMoneyOwed] = useState(0);
  const [description, setDescription] = useState('');
  const [typePay, setTypePay] = useState(null);
  const [countPayment, setCountPayment] = useState(1);
  const [valueDes, setValueDes] = useState(0)
  const [copiedText, setCopiedText] = useState('');
  const [isCopyText, setIsCopyText] = useState('')
  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([])
  const [paymentMethodManyForm, setPaymentMethodManyForm] = useState([])
  const [isDepositDownSalse, setIsDepositDownSalse] = useState(0)
  const [checkUsingWallet, setCheckUsingWallet] = useState(false)
  const [paidDepositMoney, setPaidDepositMoney] = useState(props.orderSelected.customers.deposit_money)
  const [paymentItem, setPaymentItem] = useState([{
    total_amount: props.dataPrice,
    paid_amount: 0,
    pay_type: optionsPaymentMethods[0].value,
    store_id: currentStore
  }])
  const [warningQr, setWarningQr] = useState(false)
  const [priceQr, setPriceQr] = useState(0)

  useEffect(() => {
    funcDefault()
  }, [props.showPayment, paidDepositMoney]);

  useEffect(() => {
    if (typePay == "Sử dụng thẻ tiền") {
      if (props.orderSelected.customers.deposit_money >= props.dataPrice) {
        paymentItem[0].paid_amount = props.dataPrice
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.dataPrice)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(0)
        _props.handleChangeState(props.dataPrice, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      } else {
        paymentItem[0].paid_amount = props.orderSelected.customers.deposit_money
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.orderSelected.customers.deposit_money)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(props.dataPrice - props.orderSelected.customers.deposit_money)
        _props.handleChangeState(props.orderSelected.customers.deposit_money, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      }
    } else if (typePay == "Điểm tích luỹ") {
      if (props.orderSelected.customers.gift_money >= props.dataPrice) {
        paymentItem[0].paid_amount = props.dataPrice
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.dataPrice)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(0)
        _props.handleChangeState(props.dataPrice, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      } else {
        paymentItem[0].paid_amount = props.orderSelected.customers.gift_money
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.orderSelected.customers.gift_money)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(props.dataPrice - props.orderSelected.customers.gift_money)
        _props.handleChangeState(props.orderSelected.customers.gift_money, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      }
    } else if (typePay == "Ví thẻ triệt") {
      if (props.orderSelected.customers.wallet_package_money >= props.dataPrice) {
        paymentItem[0].paid_amount = props.dataPrice
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.dataPrice)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(0)
        _props.handleChangeState(props.dataPrice, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      } else {
        paymentItem[0].paid_amount = props.orderSelected.customers.wallet_package_money
        setPaymentItem([
          ...paymentItem
        ])
        setValueDes(props.orderSelected.customers.wallet_package_money)
        setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
        setMoneyOwed(props.dataPrice - props.orderSelected.customers.wallet_package_money)
        _props.handleChangeState(props.orderSelected.customers.wallet_package_money, 'money_paid');
        _props.handleChangeState(paymentItem, 'transaction');
      }
    } else if (typePay == "Nhiều hình thức") {
      setMoneyBack( props.paidAmountBefore - props.totalAmountUsingUpsale)
      setMoneyOwed(props.dataPrice)
      _props.handleChangeState(props.dataPrice, 'money_paid');
      _props.handleChangeState(paymentItem, 'transaction');
    } else {
      paymentItem[0].paid_amount = props.dataPrice
      setPaymentItem([
        ...paymentItem
      ])
      setValueDes(props.dataPrice)
      if (typePay === "Chuyển khoản") setPriceQr(props.dataPrice)
      setMoneyBack(props.paidAmountBefore - props.totalAmountUsingUpsale)
      setMoneyOwed(0)
      _props.handleChangeState(props.dataPrice, 'money_paid');
      _props.handleChangeState(paymentItem, 'transaction');
    }
    _props.handleChangeState(typePay, 'payment_type');
  }, [typePay])

  if (_props.showPayment === false) return "";

  const getKeyOption = (option) => {
    let key = _.findIndex(option, function (o) { return o.store == currentStore });
    return key
  }
  
  const handleChangeData = (event) => {
    if (event.target.value == "Nhiều hình thức") {
      paymentItem[0].pay_type = "Tiền mặt"
      paymentItem[0].paid_amount = 0
      addMorePayment(event)
      getMoneyPaid()
    } else {
      paymentItem[0].pay_type = event.target.value
      paymentItem[0].paid_amount = 0
      if (event.target.value == "Chuyển khoản") {
        paymentItem[0] = { ...paymentItem[0], pay_account_number: accountPayment[_.findIndex(accountPayment, function (o) { return o.store == currentStore })].value }
      } else if (event.target.value == "Quẹt thẻ") {
        paymentItem[0] = { ...paymentItem[0], pay_account_number: accountSwipe[_.findIndex(accountSwipe, function (o) { return o.store == currentStore })].value }
      } else {
        delete paymentItem[0].pay_account_number
      }
      setValueDes(0)
      setPriceQr(0)
      setPaymentItem([paymentItem[0]])
      getMoneyPaid()
    }
    setTypePay(event.target.value)
    _props.handleChangeState(event.target.value, event.target.name);
  };

  const addMorePayment = (event) => {
    let count = Number(countPayment)
    if (count < 10) {
      setCountPayment(count + 1)
      setArrayPayItem(count + 1)
    }
  }

  const removeModelItemPay = (key) => {
    let count = Number(countPayment)
    let paymentItemNew = [...paymentItem]
    if (count > 2) {
      paymentItemNew.splice(key, 1)
      setCountPayment(count - 1)
      setPaymentItem(paymentItemNew)
    }

    getMoneyPaid()
    _props.handleChangeState(paymentItemNew, 'transaction');
  }

  const setArrayPayItem = (count) => {
    var paymentItemNew = paymentItem
    paymentItemNew = [...paymentItem, { total_amount: props.dataPrice, paid_amount: 0, pay_type: optionsPaymentMethod[0].value, store_id: currentStore }]
    setPaymentItem(paymentItemNew)
    _props.handleChangeState(paymentItemNew, 'transaction');
  }

  const onSelectTypePayment = (key, e) => {
    var paymentItemNew = paymentItem
    paymentItemNew[key].pay_type = e.value;

    if (e.value == "Chuyển khoản") {
      paymentItemNew[key] = { ...paymentItemNew[key], pay_account_number: accountPayment[getKeyOption(accountPayment)].value }
    } else if (e.value == "Quẹt thẻ") {
      paymentItem[0] = { ...paymentItem[0], pay_account_number: accountSwipe[getKeyOption(accountSwipe)].value }
    } else {
      delete paymentItemNew[key].pay_account_number
    }

    _props.handleChangeState(paymentItemNew, 'transaction');
    setPaymentItem(paymentItemNew)

  }

  const handleMoneyPaid = (event, key, val) => {
    event.target.value = event.target.value.replaceAll(",", "");
    var paymentItemNew = paymentItem

    if (typePay === "Sử dụng thẻ tiền") {
      if (Number(event.target.value) <= props.orderSelected.customers.deposit_money) {
        paymentItemNew[key].paid_amount = Number(event.target.value)
        setPaymentItem(paymentItemNew)
        getMoneyPaid()
        setValueDes(Number(event.target.value))
      }
    } else if (typePay === "Điểm tích luỹ") {
      if (Number(event.target.value) <= props.orderSelected.customers.gift_money) {
        paymentItemNew[key].paid_amount = Number(event.target.value)
        setPaymentItem(paymentItemNew)
        getMoneyPaid()
        setValueDes(Number(event.target.value))
      }
    } else if (typePay === "Ví thẻ triệt") {
      if (Number(event.target.value) <= props.orderSelected.customers.wallet_package_money) {
        paymentItemNew[key].paid_amount = Number(event.target.value)
        setPaymentItem(paymentItemNew)
        getMoneyPaid()
        setValueDes(Number(event.target.value))
      }
    } else if (typePay === "Nhiều hình thức") {
      if (val === "Sử dụng thẻ tiền") {
        const remove = paymentItemNew.map((x, i) => {
          if (i != key) {
            return x
          }
        }).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Sử dụng thẻ tiền") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        setPaidDepositMoney(sum)
        if (sum + Number(event.target.value) <= props.orderSelected.customers.deposit_money) {
          paymentItemNew[key].paid_amount = Number(event.target.value)
          setPaymentItem(paymentItemNew)
          getMoneyPaid()
        }
      } else if (val == "Điểm tích luỹ") {
        const remove = paymentItemNew.map((x, i) => {
          if (i != key) {
            return x
          }
        }).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Điểm tích luỹ") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        if (sum + Number(event.target.value) <= props.orderSelected.customers.gift_money) {
          paymentItemNew[key].paid_amount = Number(event.target.value)
          setPaymentItem(paymentItemNew)
          getMoneyPaid()
        }
      } else if (val == "Ví thẻ triệt") {
        const remove = paymentItemNew.map((x, i) => {
          if (i != key) {
            return x
          }
        }).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Ví thẻ triệt") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        if (sum + Number(event.target.value) <= props.orderSelected.customers.wallet_package_money) {
          paymentItemNew[key].paid_amount = Number(event.target.value)
          setPaymentItem(paymentItemNew)
          getMoneyPaid()
        }
      } else {
        paymentItemNew[key].paid_amount = Number(event.target.value)
        setPaymentItem(paymentItemNew)
        getMoneyPaid()
        setValueDes(Number(event.target.value))
        if (val === "Chuyển khoản") setPriceQr(event.target.value)
      }
    } else {
      paymentItemNew[key].paid_amount = Number(event.target.value)
      setPaymentItem(paymentItemNew)
      getMoneyPaid()
      if (typePay === "Chuyển khoản") setPriceQr(event.target.value)
      setValueDes(Number(event.target.value))
    }
  }

  const handleSelectAccountPayment = (event, key) => {
    var paymentItemNew = paymentItem;
    paymentItemNew[key].pay_account_number = event.target;
    setPaymentItem(paymentItemNew)
  }

  const getMoneyPaid = () => {
    var total = 0
    paymentItem.map((val, key) => {
      total += val.paid_amount
    })

    setMoneyBack(total - props.dataPrice +  (props.paidAmountBefore - props.totalAmountUsingUpsale))
    setMoneyOwed(props.dataPrice - total)
    _props.handleChangeState(total, 'money_paid');
    _props.handleChangeState(paymentItem, 'transaction');
  }

  const handleChangeDataNote = (e) => {
    setDescription(e.target.value)
    _props.handleChangeState(e.target.value, e.target.name);
  }

  const funcSaveOrderPopup = (event, type_booking = null) => {
    _props.funcSaveOrder(type_booking)
  }

  const funcDefault = () => {
    getMoneyPaid()
    let checkWallet = false
    var orderItemSelect = props.selectOrderItem

    let filterCheckWallet = _.filter(orderItemSelect, function(o) { return (o.type_radical == 1 || o.price == 0) ; });
    if (filterCheckWallet.length == orderItemSelect.length) {
      checkWallet = true
    }

    setCheckUsingWallet(checkWallet)

    let optionsPayMethod = [...optionsPaymentMethods]
    let payMethodManyForm = [...paymentMethodManyForms]

    let filterWallet = _.filter(optionsPayMethod, function(o) { return o.value == 'Ví thẻ triệt'; });
    let filterDeposit = _.filter(optionsPayMethod, function(o) { return o.value == 'Sử dụng thẻ tiền'; });
    let filterGiftMoney = _.filter(optionsPayMethod, function(o) { return o.value == 'Điểm tích luỹ'; });
    
    let checkLength = optionsPayMethod.length + 1
    if (props.orderSelected.customers.gift_money > 0 & props.orderSelected.customers.isMember == 1 && filterGiftMoney.length == 0  && paidDepositMoney == 0) {
      optionsPayMethod.push({ value: 'Điểm tích luỹ', label: checkLength + '. Điểm tích luỹ' })
      payMethodManyForm.push({ value: 'Điểm tích luỹ', label: checkLength + '. Điểm tích luỹ' })
      checkLength += 1
    }


    if (props.isDeposit == false) {
      if (_props.orderSelected.customers.deposit_money > 0 && filterDeposit.length == 0) {
        optionsPayMethod.push({ value: 'Sử dụng thẻ tiền', label: checkLength + '. Sử dụng thẻ tiền' })
        payMethodManyForm.push({ value: 'Sử dụng thẻ tiền', label: checkLength + '. Sử dụng thẻ tiền' })
        checkLength += 1
      }
      if(paymentItem.length == 1) {
        paymentItem[0].pay_type = optionsPayMethod[0].value
        paymentItem[0].paid_amount = props.orderSelected.customers.deposit_money
      }
      setPaymentItem([
        ...paymentItem
      ])
      if(props.dataPrice == 0) {
        setTypePay('Không thanh toán');
      }
    }

    if (props.orderSelected.customers.wallet_package_money > 0 && filterWallet.length == 0 && checkWallet) {
      optionsPayMethod.push({ value: 'Ví thẻ triệt', label: checkLength + '. Ví thẻ triệt' })
      payMethodManyForm.push({ value: 'Ví thẻ triệt', label: checkLength + '. Ví thẻ triệt' })
    }
    setOptionsPaymentMethod([...optionsPayMethod])
    setPaymentMethodManyForm([...payMethodManyForm])
    
    _props.handleChangeState(typePay, 'payment_type');
    _props.handleChangeState(paymentItem, 'transaction');
  }

  const copyText = (text) => {
    setIsCopyText('isCopy')
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setIsCopyText('')
    }, 1000);
  }
  
  const syncQrCode = async () => {
    setWarningQr(false)
    let res = await axiosService("api/sync-qr-code", "POST", { price: priceQr, id: props.orderSelected.id });
    if (res.data.success) {
      let order = res.data.data
      props.notifications({ type: 'success', mess: 'Cập nhật mã QR thành công' });
      props.socket.emit("client-qr-code", {order: order, status: true, store_id: order.stores.id});
    } else {
      props.notifications({ type: 'error', mess: res.data.message });
    }
  }

  const checkQrCode = async () => {
    let res = await axiosService("api/check-qr-code", "POST", { id: props.orderSelected.id, store_id: props.currentStore });
    if (res.data.success) {
      let countOrder = res.data.data
      if (countOrder === 0) {
        syncQrCode()
      } else {
        setWarningQr(true)
      }
    } else {
      props.notifications({ type: 'error', mess: res.data.message });
    }
  }

  return (
    <>
      <div className="popup-cent payment">
        <div className="content-popup fadeInDown">
          <div className="head-popup">
            <div className="title">Thanh toán đơn hàng</div>
            <div className="close" onClick={_props.paymentOrder}>×</div>
          </div>
          <div className="body-popup">
            <div className="total-price payment-block">
              <div className="title-block left-block">Tổng tiền</div>
              <div className="price-block right-block">
                {convert.convertCurrency(props.dataPrice )}
              </div>
            </div>
            <div className="payment-method">
              <div className="title-block">Hình thức thanh toán</div>
              <div className="body-block">
                <div className="row">
                  {optionsPaymentMethod.map((item, key) => (
                    <div className="col-3" key={key}>
                      <button
                        className={
                          typePay === item.value
                            ? "btn btn-block btn-xs btn-primary"
                            : "btn btn-block btn-xs btn-default"
                        }
                        name="payment_type"
                        value={item.value}
                        onClick={(e) => handleChangeData(e, 0)}
                      >
                        {item.label}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cash-block payment-block">
              <div className="title-block left-block">
                {typePay == "Điểm tích luỹ" || typePay == "Nhiều hình thức" && typePay != "Sử dụng thẻ tiền" && typePay != "Tiền mặt" ? "Điểm tích luỹ" : null}
                {typePay == "Tiền mặt" || typePay == "Nhiều hình thức" && typePay != "Sử dụng thẻ tiền" && typePay == "Điểm tích luỹ" ? "Tiền khách hàng đưa" : null}
                {typePay != "Tiền mặt" && typePay != "Nhiều hình thức" && typePay != "Sử dụng thẻ tiền" && typePay != "Điểm tích luỹ" ? "Tiền khách chuyển khoản" : null}
                {typePay == "Sử dụng thẻ tiền" ? "Số tiền trong thẻ dùng thanh toán" : null}
                {(props.orderSelected.customers.deposit_money > 0) ? 
                <div style={{ lineHeight: 1 }}>
                  Tiền hiện có trong tài khoản: {convert.convertCurrency(props.orderSelected.customers.deposit_money)}
                </div> : null}
                {(props.orderSelected.customers.gift_money > 0 && props.orderSelected.customers.isMember == 1) ? 
                <div style={{ lineHeight: 1, marginTop: "10px" }}>
                  Điểm tích luỹ: {convert.convertCurrency(props.orderSelected.customers.gift_money)}
                </div> : null}

                {(props.orderSelected.customers.wallet_package_money > 0 ) ? 
                <div style={{ lineHeight: 1, marginTop: "10px" }}>
                  Ví triệt: {convert.convertCurrency(props.orderSelected.customers.wallet_package_money)}
                </div> : null}
              </div>
              {typePay != "Nhiều hình thức" || !typePay === "Sử dụng thẻ tiền" ?
                <div className="cash-customer right-block">
                  <CurrencyInput
                    name="money_paid"
                    id="money_paid"
                    defaultValue={paymentItem[0].paid_amount}
                    groupSeparator={","}
                    value={valueDes}
                    decimalSeparator={"."}
                    onChange={(e) => handleMoneyPaid(e, 0)}
                  />
                  <div className="currency">₫</div>
                  {/* {typePay === "Chuyển khoản" ?
                    <div style={{marginTop: '10px', width: '120px', float: 'right'}} className={props.dataPrice > 0 && props.orderSelected.status === '4' ? "button-cent" : "button-cent disable"}
                      onClick={props.orderSelected.status === '4' ? () => {checkQrCode()} : () => {}} >
                      <span>
                        <i className="fas fa-money-bill"></i>
                      </span>
                      Tạo mã QR
                    </div>
                  : null} */}
                </div>
              : null}
            </div>
            {typePay == "Chuyển khoản" ?
              <div className="money-back-block payment-block account-payment">
                <div className="title-account-payment">Chọn tài khoản Chuyển khoản</div>
                <div className="option-account-payment">
                  
                {accountPayment[getKeyOption(accountPayment)].label}
                <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() =>  copyText(accountPayment[getKeyOption(accountPayment)].label, 0)}>Copy</button>
                  {/* <Select
                    className="select"
                    isSearchable={false}
                    defaultValue={accountPayment[getKeyOption(accountPayment)]}
                    onChange={(e) => handleSelectAccountPayment(e, 0)}
                    options={accountPayment}
                  /> */}
                </div>
              </div>
              : null}
            { typePay == "Quẹt thẻ" ?
                <div className="money-back-block payment-block account-payment">
                <div className="title-account-payment">Tài khoản Quẹt thẻ</div>
                <div className="option-account-payment">
                  {accountSwipe[getKeyOption(accountSwipe)].label}

                  <button className={isCopyText} style={{marginLeft: '10px'}} onClick={(e) => copyText(accountSwipe[getKeyOption(accountSwipe)].label, 0)}>Copy</button>
                  {/* <Select
                    className="select"
                    isSearchable={false}
                    defaultValue={accountSwipe[getKeyOption(accountSwipe)]}
                    onChange={(e) => handleSelectAccountPayment(e, 0)}
                    options={accountSwipe}
                  /> */}
                </div>
              </div>
            : null}
            
            {typePay == "Nhiều hình thức" ?
              <div className="cash-customer payment-in-many-forms">
                {paymentItem.map((val, key) => (
                  <div className="row class-payment-many-item" key={key}>
                    <div className="col-5">
                      <Select className="select-type-payment"
                        isSearchable={false}
                        onChange={(e) => onSelectTypePayment(key, e)}
                        styles={paymentMethodManyForm}
                        options={paymentMethodManyForm} />
                      <div className="cash-customer">
                        <CurrencyInput
                          name="money_paid"
                          className="input-money-paid"
                          value={val.paid_amount}
                          groupSeparator={","}
                          decimalSeparator={"."}
                          onChange={(e) => handleMoneyPaid(e, key, val.pay_type)}
                        />
                        <div className="currency">₫</div>
                      </div>
                    </div>
                    <div className="col-6">
                      {/* {val.pay_type == "Chuyển khoản" ?
                        <div style={{width: '120px'}} className={props.dataPrice > 0 && props.orderSelected.status === '4' ? "button-cent" : "button-cent disable"}
                          onClick={props.orderSelected.status === '4' ? () => {checkQrCode()} : () => {}} >
                          <span>
                            <i className="fas fa-money-bill"></i>
                          </span>
                          Tạo mã QR
                        </div>
                      : null} */}

                      {val.pay_type == "Quẹt thẻ"  ?
                        <Select
                          className="select"
                          isSearchable={false}
                          defaultValue={accountSwipe[getKeyOption(accountSwipe)]}
                          onChange={(e) => handleSelectAccountPayment(e, key)}
                          options={accountPayment}
                        />
                        : null}
                    </div>
                    <div className="col-1">
                      {paymentItem.length > 2 ?
                        <div className="button-cent-remover " onClick={() => removeModelItemPay(key)}><span><i className="fas fa-times"></i></span></div>
                        : ""}
                    </div>

                    {val.pay_type == "Chuyển khoản" ?
                      <div className="col-12" style={{marginTop: '10px'}}>
                        {accountPayment[getKeyOption(accountPayment)].label}
                        <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() =>  copyText(accountPayment[getKeyOption(accountPayment)].label, 0)}>Copy</button>
                      </div>
                    : null}
                  </div>
                ))}
                <div className="title add-more-payment">
                  <div className="add-more" onClick={addMorePayment}><i className="fas fa-plus-circle"></i>Thêm phương thức thanh toán</div>
                </div>

              </div>
              : null}
            <div className="money-back-block payment-block">
              <div className="title-block left-block"> {props.orderSelected.type == 3 && props.dataPrice == 0 ? 'Tiền hoàn lại vào tài khoản' : 'Trả lại khách'}</div>
              <div className="price-block right-block">
                {moneyBack > 0 ? convert.convertCurrency(moneyBack) : "0 đ"}
              </div>
            </div>
            {moneyOwed > 0 ? (
              <div className="owed-block payment-block">
                <div className="title-block left-block">Còn nợ</div>
                <div className="money-owed right-block">{convert.convertCurrency(moneyOwed)}</div>
              </div>
            ) : ""}

            <div className="note-transaction payment-block">
              <div className="title-block">Ghi chú</div>
              <div className="note-block">
                <textarea name="order_description" defaultValue={props.orderSelected.description} onChange={(e) => handleChangeDataNote(e)} />
              </div>
            </div>
          </div>
          <div className="bottom-popup">
          {props.statusCheckout === true && typePay ?
            <div className="button-container">
                <button className="button-cent save-and-book" onClick={(e) => funcSaveOrderPopup(e, "booking")}>
                  <span>
                    <i className="fa fa-fas fa-calendar-alt"></i>
                  </span>
                  Hoàn thành và đặt lịch
                </button>
                <button className="button-cent" onClick={(e) => funcSaveOrderPopup(e)}>
                  <span>
                    <i className="fas fa-save"></i>
                  </span>
                  Hoàn thành
                </button>
            </div>
          : null}
          </div>
        </div>
        <div className="overlay" onClick={_props.paymentOrder}></div>
      </div>
      {warningQr ?
        <div className="popup-cent payment">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Cảnh báo</div>
              <div className="close" onClick={() => {setWarningQr(false)}}>×</div>
            </div>
            <div className="body-popup">
              <div className="total-price payment-block">
                <div className="title-body">Đang có order hiển thị mã QR, bạn có muốn hiển thị đè order hiện tại lên không ?</div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                  <button className="button-cent save-and-book" onClick={() => {setWarningQr(false)}}>
                    <span><i className="fa fa-fas fa-calendar-alt"></i></span> Hủy
                  </button>
                  <button className="button-cent" onClick={() => {syncQrCode()} }>
                    <span><i className="fas fa-save"></i></span> Đồng ý
                  </button>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={() => {setWarningQr(false)}}></div>
        </div>
      : ""}
    </>
  );
};

export default Payment;
