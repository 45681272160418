import { Container, Table } from "react-bootstrap"
import { Input, Button, message, Spin } from 'antd';
import { useEffect, useState } from "react";
import axiosService from "../../utils/axios.service";
import { getData } from "../../redux/reducers/options"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import _ from 'lodash';
import moment from "moment"

export default function GroupVoucher() {
    const getOptions = useSelector((state) => state.getOptions)
    const dispatch = useDispatch()

    const [phone, setPhone] = useState("")
    const boxShadowStyle = {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
    }
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [voucher, setVoucher] = useState(null)
    const [mess, setMess] = useState('')

    const handleSubmit = async () => {
        setLoading(true)
        try {
            let error = false;
            setMess('')
            let listPhone = phone.split(",");
            
            if (listPhone.length < 2) {
                error = true;
            } else {
                let allDuplicates = _.filter(listPhone, function(value, index, iteratee) {
                    return _.includes(iteratee, value, index + 1);
                });
                let uniqueList = _.uniq(listPhone);

                if (allDuplicates.length > 0) error = true;
                if (uniqueList.length < 2) error = true
            }

            if (error) {
                message.warning("Yêu cầu tối thiểu 2 số điện thoại, và không được phép trùng nhau")
                setLoading(false)
            } else {
                const { data } = await axiosService(`api/vouchers/create-voucher-lv3`, "POST", {listPhone: listPhone})
                if (data.code === 200) {
                    if (data.message != 'success') setMess(data.message )
                    setVoucher(data.data)
                    setLoading(false)
                    setIsSuccess(true)
                    message.success("Lấy mã thành công")
                } else {
                    message.error(data.message)
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            message.error("Đã có lỗi xảy ra")
        }
    }
    const handleClear = () => {
        setIsSuccess(false)
        setVoucher(null)
    }

    useEffect(async () => {
        dispatch(getData({ key: "voucher_page" }))
    }, [])

    return (
        <Spin spinning={loading} size="large" tip="Đang tải...     Xin vui lòng đợi">
            {getOptions.data.value == 1 ?
                <Container>
                    <div className="menu-list-voucher d-flex">
                        <div className="item-menu active mr-4">
                            <Link to={{ pathname: "/create-voucher-lv3" }}>Voucher đi nhóm</Link>
                        </div>
                        <div className="item-menu">
                            <Link to={{ pathname: "/create-voucher-skin" }}>Voucher tặng da</Link>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-2 p-5" style={boxShadowStyle}>
                        {!isSuccess ? 
                            <div>
                                <h2>Voucher đi nhóm</h2>
                                <Input onChange={(e) => {
                                    setPhone(e.target.value)
                                }} allowClear defaultValue={phone} />
                                <div className="d-flex justify-content-center mt-2">
                                    <Button type="primary" onClick={handleSubmit}>Lấy ngay</Button>
                                </div>
                                <div className="">
                                    <b>Lưu ý:</b><br />
                                    - Số điện thoại khách hàng viết cách nhau bằng dấu ",". Ví dụ: 0987654321,0123456789<br />
                                    - Phải tạo trước thông tin khách hàng trên hệ thống<br />
                                    - Voucher có hạn sử dụng trong 10 ngày
                                </div>
                            </div> 
                        :
                            <div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Mã voucher</th>
                                            <th>Thông tin</th>
                                            <th>Số lần sử dụng</th>
                                            <th>Thời gian sử dụng</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{voucher?.code || "Không có thông tin"}</td>
                                            <td>{voucher?.des || "Không có thông tin"}</td>
                                            <td>{voucher?.count_used}/{voucher?.max_used}</td>
                                            <td>
                                                {moment(voucher?.start_date).format('HH:mm:ss DD-MM-YYYY')}<br />
                                                {moment(voucher?.end_date).format("HH:mm:ss DD-MM-YYYY")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {mess != '' ?
                                    <div className="d-flex justify-content-center">Note: {mess}</div>
                                : ""}
                                <div className="d-flex justify-content-center mt-2">
                                    <Button type="primary" onClick={handleClear}>Làm mới</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Container>
            : <div style={{marginTop: "50px", textAlign: "center", fontSize: "18px"}}>Đã hết thời hạn đổi voucher. <Link to={{ pathname: "/dashboard" }} style={{textDecoration: "underline !important"}}>Trở về trang chủ</Link></div>}
        </Spin>

    )
} 