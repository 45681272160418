import { Col, Row, Container } from "react-bootstrap";
import styles from "./style.module.css"
import thunkStatusEnum from "../../enums/thunkStatus.enum";
import { Divider, Pagination, Table, Input, DatePicker, Select, Spin, Tag, Modal, message, Upload, Button, Alert } from 'antd';
import { getData } from "../../redux/reducers/emulation"
import AddNewVoucher from "./Infor";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from 'use-debounce';
import { useSelector, useDispatch } from 'react-redux'
import { ActivesEnum, typeVoucher } from "../../enums/voucher.enum";
import { Link } from "react-router-dom";
import moment from "moment"
import { EditOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons"
import { deleteVoucher } from "../../redux/reducers/emulation"
import axiosService from "../../utils/axios.service";
import { importVoucher } from "../../redux/reducers/voucher";
import ChangeLanguage from "./ChangeLanguage";
import { useTranslation } from 'react-i18next';
import PopupCategoryVoucher from './PopupCategoryVoucher';
import async from "async"
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function Emulation() {
    const vouchers = useSelector((state) => state.emulation)
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("name"),
            dataIndex: 'rule_name',
            key: 'name',
        },
        {
            title: t("code"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("start.date"),
            dataIndex: 'start_date',
            key: 'start',
            render: (data, x) => {
                return (
                    <>
                        {moment(new Date(data)).format('YYYY-MM-DD')}
                    </>
                )
            },
        },
        {
            title: t("end.date"),
            dataIndex: 'end_date',
            key: 'end',
            render: (data, x) => {
                return (
                    <>
                        {moment(new Date(data)).format('YYYY-MM-DD')}
                    </>
                )
            },
        },
        {
            title: t("status"),
            dataIndex: 'active',
            key: 'status',
            render: (data, x) => {
                return (
                    <>
                        <Tag color={data === ActivesEnum.isActive ? "green" : "red"}>{data === ActivesEnum.isActive ? "active" : "inactive"}</Tag>
                    </>
                )
            },
        },
        {
            title: t("actions"),
            key: 'actions',
            width: 10,
            render: (data, x) => {
                return (
                    <div className="d-flex justify-content-between">
                        <Link to={{ pathname: "/emulation/" + data.id }}>
                            <EditOutlined style={{ color: "#EB661B", cursor: "pointer" }} />
                        </Link>
                        <a onClick={() => {
                            confirmDelete(data.id, data.rule_name)
                        }}>
                            <CloseOutlined style={{ color: "red", cursor: "pointer" }} />
                        </a>
                    </div>
                )
            },
        },
    ];
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDeleteVouchers(selectedRowKeys);
        },
    };
    const [isAdd, setIsAdd] = useState(false)
    const [isAddCategory, setIsAddCategory] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [id, setId] = useState("")
    const [code, setCode] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [status, setStatus] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [idV, setIdV] = useState(null)
    const [voucherName, setVoucherName] = useState("")
    const [messErr, setMessErr] = useState("")
    const [isErr, setIsErr] = useState(false)
    const [name, setName] = useState("")
    const [groupId, setGroupId] = useState(0)
    const [deleteVouchers, setDeleteVouchers] = useState([])
    const [optionGroupVc, setOptionGroupVc] = useState([{value: 0, label: 'Tất cả'}])
    useEffect(async () => {
        dispatch(getData({ id, code, startDate, endDate, status, limit, page, name, groupId }))
    }, [id, code, startDate, endDate, status, limit, page, name, groupId])

    useEffect(async () => {
        const {getGroupVoucher } = await async.parallel({
            getGroupVoucher: (cb) => {
                axiosService("api/category_voucher").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
        })
        var options = [...optionGroupVc]
        if (getGroupVoucher.code == 200 && getGroupVoucher.data.length > 0) {
            for (let item of getGroupVoucher.data) {
                options.push({value: item.id, label: item.group_name})
            }
        }
        setOptionGroupVc([...options])        
    }, [])

    const handleAdd = () => {
        setIsAdd(true)
    }
    const handleCancelAdd = () => {
        setIsAdd(false)
    }
    const handleChangePage = (pageX, pageSize) => {
        setPage(pageX)
        setLimit(pageSize)
    }
    const handleCloseAdd = () => {
        setIsAdd(false)
    }
    const handleChange = (value) => {
        setStatus(value)
    };

    const handleChangeGroup = (e) => {
        setGroupId(e)
    };

    const handleAddCategory = () => {
        setIsAddCategory(!isAddCategory)
    }

    const confirmDelete = (x, y) => {
        setIdV(x)
        setVoucherName(y)
        showModal()
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        if (!idV) {
            message.error(t("warning.choose.voucher"))
        } else {
            dispatch(deleteVoucher(idV)).then(rs => {
                const { payload } = rs
                if (payload.code == 200) {
                    message.success(t("success.delete"))
                    setIdV(null)
                } else if (payload.code == 404) {
                    message.error(payload.message)
                    setIdV(null)
                }
            }).catch(err => {
                console.error(err)
                message.error(t("error.delete"))
                setIdV(null)
            })
        }
    };
    const handleDeleteMany = () => {
        if (deleteVouchers.length <= 0) {
            message.error(t("warning.choose.voucher"))
        } else {
            dispatch(deleteVoucher(deleteVouchers)).then(rs => {
                const { payload } = rs
                if (payload.code == 200) {
                    message.success(t("success.delete"))
                    setDeleteVouchers([])
                    setIdV(null)
                } else if (payload.code == 404) {
                    message.error(payload.message)
                    setIdV(null)
                }
            }).catch(err => {
                console.error(err)
                message.error(t("error.delete"))
                setIdV(null)
            })
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIdV(null)
    };

    const onChangeId = useCallback((value) => {
        setId(value)
    }, [id])
    const onChangeName = useCallback((value) => {
        setName(value)
    }, [name])
    const onChangeCode = useCallback((value) => {
        setCode(value)
    }, [code])
    const functionChangeState = useCallback((type, value) => {
        const objectChangeState = {
            id: onChangeId,
            name: onChangeName,
            code: onChangeCode
        }
        return objectChangeState[type](value)
    }, [id, code, name])
    const debounced = useDebouncedCallback(
        // function
        (value, type) => {
            functionChangeState(type, value)
        },
        // delay in ms
        1000
    );
    const handleChangeDate = (x, y) => {
        setStartDate(y[0])
        setEndDate(y[1])
    }
    const props = {
        async onChange(info) {
            setIsErr(false)
            const { file } = info
            if (file.status == "done") {
                const formData = new FormData();
                formData.append("file", file.originFileObj);
                dispatch(importVoucher(formData)).then(rs => {
                    const { payload } = rs
                    if (payload.code === 200) {
                        message.success("Imported successful")
                        const { errId, data } = payload.data
                        let messageErr = `Import success: ${data.length}`
                        if (errId.length > 0) {
                            messageErr += `, fail: ${errId.length}-voucher: ${errId.toString()}`
                            setMessErr(messageErr)
                            setIsErr(true)
                        }
                    } else {
                        message.error("import error")
                    }
                }).catch(err => {
                    console.error(err)
                    message.error("import error")
                })
            }
        },
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    return (
        <Spin spinning={(vouchers.status === thunkStatusEnum.FULFILLED) ? false : true}>
            <Container fluid className={`${styles.wrapperVoucher}`}>
                <ChangeLanguage />
                <Modal title={t("delete.confirm")} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p>{`${t("delete.title")} ${voucherName}?`}</p>
                </Modal>
                {(!isAdd && isErr) && <Alert
                    message={t("error.message")}
                    description={messErr}
                    type="error"
                    action={
                        <Button size="small" danger onClick={() => {
                            setIsErr(false)
                        }}>
                            Close
                        </Button>
                    }
                />}
                <div className="d-flex align-items-center">
                    <h4 className="pt-2 ml-2">
                        {!isAdd ? t("rule.management") : t("add.new.cart.price")}
                    </h4>
                </div>
                <Divider />
                {isAdd && <AddNewVoucher styles={styles} handleCancelAdd={handleCancelAdd} handleCloseAdd={handleCloseAdd} dispatch={dispatch} />}
                {(!isAdd) &&
                    <>
                        <Row className="mb-3">
                            <Col className={`${styles.itemSearch}`}  xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span>Id:</span>
                                </div>
                                <Input placeholder={t("enter.Id")} allowClear onChange={(e) => debounced(e.target.value, "id")} />
                            </Col>
                            <Col className={`${styles.itemSearch}`} xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span>{t("name")}:</span>
                                </div>
                                <Input placeholder={t("enter.name")} allowClear onChange={(e) => debounced(e.target.value, "name")} />
                            </Col>
                            <Col className={`${styles.itemSearch}`} xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span>{t("code")}:</span>
                                </div>
                                <Input placeholder={t("enter.code")} allowClear onChange={(e) => debounced(e.target.value, "code")} />
                            </Col>
                            <Col className={`${styles.itemSearch}`} xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span>
                                        {t("date")}:
                                    </span>
                                </div>
                                <RangePicker className="w-100" onChange={handleChangeDate} />
                            </Col>
                            <Col className={`${styles.itemSearch}`} xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span>{t("status")}:</span>
                                </div>
                                <Select onChange={handleChange} className="w-100" value={status}>
                                    <Option value="">{t("all")}</Option>
                                    <Option value={ActivesEnum.isActive}>{t("active")}</Option>
                                    <Option value={ActivesEnum.isUnActive}>{t("inactive")}</Option>
                                </Select>
                            </Col>

                            <Col className={`${styles.itemSearch}`} xs={6} md={4} lg={3} xl={2}>
                                <div>
                                    <span> Chọn group mã:</span>
                                </div>
                                <Select onChange={handleChangeGroup}
                                    className="w-100"
                                    defaultValue={optionGroupVc[0]} 
                                    options={optionGroupVc}
                                >
                                </Select>
                            </Col>
                        </Row>
                        <div className={`${styles.actionBlock} d-flex justify-content-end`}>
                            {/* <div>
                                <Upload {...props} customRequest={dummyRequest} maxCount={1}>
                                    <Button icon={<UploadOutlined />}>{t("click.to.upload")}</Button>
                                </Upload>
                            </div> */}
                            <div className="d-flex">
                                <div className={`${styles.addButton}`} onClick={handleAdd}>
                                    {t("add.new.rule")}
                                </div>
                                <div className={`${styles.addCategoryButton} ml-3`} onClick={handleAddCategory}>
                                    {t("add.new.category")}
                                </div>
                            </div>
                        </div>
                        <Button type="primary" className="mt-2" disabled={deleteVouchers.length > 0 ? false : true} onClick={handleDeleteMany}>
                            {t("delete")}{deleteVouchers.length > 0 ? ` (${deleteVouchers.length})` : ""}
                        </Button>
                        <div className="mt-4">
                            <Table className={`table-voucher w-100`} dataSource={vouchers.data} columns={columns} pagination={false}
                                rowSelection={{
                                    type: "checkbox",
                                    ...rowSelection,
                                }}
                            />
                        </div>
                        <Row className="mt-3">
                            <div className="d-flex justify-content-end w-100">
                                <Pagination current={page} total={vouchers.meta.totalItems} onChange={handleChangePage} showSizeChanger pageSize={limit} />
                            </div>
                        </Row>
                    </>
                }
                {isAddCategory ? 
                    <PopupCategoryVoucher show={isAddCategory}
                    close={handleAddCategory}>
                    </PopupCategoryVoucher>
                : null}

            </Container>
        </Spin>
    )
}