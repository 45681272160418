// import 'antd/dist/antd.min.css'
import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Search from "./Search"
import Header from "../Header";

import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth, endOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line } from '@ant-design/plots';
import { Table, Spin } from "antd"
import getDates from "./getDates"
import { DataGrid } from '@mui/x-data-grid';
import "./scss/report.scss"
import StorageService from "../../utils/storage.service";

const ReportEmulation = () => {
    const columns = [
        {
            title: 'STT',
            dataIndex: 'id',
            // fixed: 'left',
            render: (data) => {
                return (
                    <p className="text_service_none">{data}</p>
                )
            },
        },
        {
            title: 'Tên chương trình',
            dataIndex: 'rule_name',
            // fixed: 'left',
            with: 150,
            render: (data) => {
                return (
                    <p className="text_service_none">{data}</p>
                )
            },
        },
        {
            title: 'Target',
            dataIndex: 'target',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text7">{data < 100 ? data + ' Sp' : convertCurrency(data)}</p>
                )
            },
        },
        {
            title: 'Bắt đầu',
            dataIndex: 'start_date',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertDateTime(data)}</p>
                )
            },
        },
        {
            title: 'Kết thúc',
            dataIndex: 'end_date',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text2">{convertDateTime(data)}</p>
                )
            },
        },
        {
            title: 'Nhân viên',
            dataIndex: 'user_name',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Đã đạt',
            dataIndex: 'is_target',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data < 1000 ? data + " Sp": convertCurrency(Number(data))}</p>
                )
            },
        },
        {
            title: 'Xếp hạng',
            dataIndex: 'rank',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text3">{ Number(data) > 0 ? String(data).padStart(2, '0') : ''}</p>
                )
            },
        },
        {
            title: 'Tổng mốc',
            dataIndex: 'total_action',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text_service_none">{data}</p>
                )
            },
        },
        {
            title: 'Đạt mốc',
            dataIndex: 'keyNa',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Thưởng',
            dataIndex: 'bonus',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text7">{convertCurrency(data)}</p>
                )
            },
        },
    ];
    const [title, setTiltle] = useState("Báo cáo Thi Đua")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalService, setTotalService] = useState(0) 
    const [totalOrder, setTotalOrder] = useState(0)
    const [userId, setUserId] = useState(0)
    const [emulationId, setEmulationId] = useState(0)

    const [userName, setUserName] = useState(null)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [emulationOption, setEmulationOption] = useState([])


    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }

    const hanldeUser = (x) => {
        setUserId(x.value)
        setUserName(x.label)
    }

    const hanldeEmulation = (x) => {
        setEmulationId(x.value)
    }

    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate();
            // hours = date.getHours(),
            // minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        // if (hours < 10) hours = '0' + hours;
        // if (minus < 10) minus = '0' + minus;
        let text = date.getFullYear() + '-' + month + '-' + day ;
        // text = text + ' ' + hours + ':' + minus;
        return text
    };
    const fetchData = async (sD, eD, s) => {
        const { emulation, data } = await async.parallel({
            emulation: (cb) => {
                axiosService(`api/emulation/`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/emulation/report/find?start=${sD}&end=${eD}&store=${store}&user_id=${userId}&emulation_id=${emulationId}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {totalPaid, totalService, totalOrder, dataChart, dataTable } = data.data

            // setChart(dataChart)
            console.log(emulation.data)
            setTable(dataTable)
            // setTotalPaid(totalPaid)
            // setTotalService(totalService)
            // setTotalOrder(totalOrder)
            const newEmulation = emulation.data.items.map(x => {
                return { label: x.rule_name, value: x.id }
            })
            setEmulationOption([{ label: "Tất cả", value: 0},...newEmulation])

        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store,keyword, userId, emulationId])

    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    const  handleChange = (e) => {
        console.log(e)
    }

    return (
        <>
        <div className={`p-2 ${styles.background}`}>
            <Search startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate} store={store}
                    hanldeStore={hanldeStore}
                    hanldeUser={hanldeUser}
                    hanldeEmulation={hanldeEmulation}
                    title={title} type={"emulation"}
                    csvData={table} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    onChangeKeyword={onChangeKeyword}
                    emulation = {emulationOption}
                    header={
                        [
                            { label: 'Nhân viên', key: "user_name" },
                            { label: "Số lịch đã xác nhận", key: "countBooking" },
                            { label: "Hoa hồng xác nhận lịch", key: "rose_confirm" },
                            { label: "Số khách đón tiếp", key: `countCustomer` },
                            { label: "Hoa hồng đón tiếp", key: `rose_welcome` },
                        ]
                    }
                    userAdmin={userAdmin}
                />
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="p-2">
                    <Col md={8} xs={12}>
                        <Row className="h-100">
                            <Col md={6} xs={12}>
                                <Tag color="blue" className="w-100 p-2 h-100">
                                <span className={styles.textTag3}>Tổng quan thi đua: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <br />
                                            <span className={styles.textTag4}></span>
                                            <span className={styles.textTag4}></span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalPaid)}</span>
                                            <br />
                                            <span className={styles.textTag2}> 0</span>
                                            <span className={styles.textTag2}> 0</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row className="mt-2" style={{alignItems: 'end'}}>
                    {/* <Col xs={12} md={5} className="hide-mobile">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <Line {...config} />
                        </div>
                    </Col> */}
                    <Col xs={12} md={12}>
                        <div style={{ backgroundColor: "white" }} >
                            <div className="border-bottom mb-1 p-2">
                                <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                            </div>
                            <div style={{ height: 495, width: '100%' }} className="p-2">
                                <Table 
                                    columns={columns} 
                                    dataSource={[...table]}
                                    pagination={{ pageSize: 15 }}
                                    scroll={{ x: 900 }}
                                    onChange={handleChange}

                                    bordered
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
        </>
    )
}
export default ReportEmulation