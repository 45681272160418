import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Bar } from '@ant-design/plots';
import { DataGrid } from '@mui/x-data-grid';
import { Spin } from "antd"
import "./scss/report.scss"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";

const ReportCashier = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [dataC, setData] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    useEffect(() => {
        setTiltle("Theo thu ngân")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    const columns = [
        { field: 'id', headerName: 'STT', width: 50 },
        { field: 'name', headerName: 'Thu ngân', width: 250, cellClassName: (params) => "text5" },
        { field: 'orders', headerName: 'Số đơn hàng', type: "number", width: 100 },
        {
            field: 'value',
            headerName: 'Doanh thu',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.value), width: 250,
            cellClassName: (params) => "text1"
        },
    ];
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(endDate, startDate, store)
    }, [endDate, startDate, store])
    const fetchData = async (eD, sD, store) => {
        let doneData = false
        let DoneLocation = false
        const { data, locations } = await async.parallel({
            data: (cb) => {
                axiosService(`api/reports/receipt?&startDate=${sD}&endDate=${eD}&store=${store}`, "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        doneData = true
                        const a = data.data.newDataTable
                        if (a[0].id) {
                            setRows([...data.data.newDataTable])
                        }
                        cb(null, data.data)
                    }
                })
            },
            locations: (cb) => {
                axiosService("api/stores", "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        DoneLocation = true
                        cb(null, data.data)
                    }
                })
            }
        })
        if (doneData && DoneLocation) {
            setLoading(false)
            if (store != 0) {
                setData(data.dataChart)
            } else {
                Promise.all(locations.map(x => {
                    const index = data.dataChart.findIndex((y) => y.name === x.name_store)
                    if (index >= 0) {
                        return { name: x.name_store, value: data.dataChart[index].value }
                    } else {
                        return {
                            name: x.name_store, value: 0
                        }
                    }
                })).then(rs => {
                    setData(rs)
                })
            }
        }
    }
    const config = {
        data: dataC,
        xField: 'value',
        yField: 'name',
        seriesField: 'name',
        legend: {
            position: 'top-left',
        },
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        },
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'bottom', 'middle',
            // 配置样式
            style: {
              fill: '#FFFFFF',
              opacity: 0.6,
            },
            formatter: (v) => {
                return `${v.value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
            },
          },
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    return (
        <div className={`container-fluid report-page ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                title={title} type={"cashiers"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thu ngân', key: "name" },
                        { label: "Số đơn hàng", key: "orders" },
                        { label: "Doanh thu thu", key: "value" },
                    ]
                }
                userAdmin={userAdmin}
            />
            <Spin spinning={loading}>
                <Row className="mt-2">
                    <Col xs={6}>
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div>
                                <p className="border-bottom">
                                    Biểu đồ doanh thu theo thu ngân
                                </p>
                            </div>
                            <div>
                                <Bar {...config} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div style={{ backgroundColor: "white", height: 400, width: '100%' }} className="p-2">
                            {rows.length > 0 && <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[6]}
                            />}
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
export default ReportCashier