import React from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import "rsuite/dist/rsuite.min.css";
import "./scss/pos.scss";
import axiosService from "../../utils/axios.service";
import Avatar from 'react-avatar';
import { Spin } from "antd"

import PosServicesList from './PosServicesList';
import PosPackagesList from './PosPackagesList';
import PosOrderItemList from "./PosOrderItemList";
import PosOrderTotal from "./PosOrderTotal";
import PosOrderInformation from "./PosOrderInformation";
import Delete from './Delete';
import Payment from './Payment';
import Payment2 from './Payment2';
import { EditCredit } from "../customer/EditCredit";
import socketIOClient from "socket.io-client";

import PrintSuccessOrder from './PrintSuccessOrder';
import { ViewImgCustomer } from "../customer/ViewImgCustomer"

import * as convert from "../constant/Convert.helper"
import * as generalData from "../constant/GeneralData"
import * as fbEvent from "../constant/fbPixelEvent"
import { optionsGender, customStylesOption } from "../constant/GeneralData";
import { listDeposit } from "./constant/PosCustomer";
import { notifications } from "../constant/Notify";
import { TransactionPoup } from "../orders/TransactionPoup"
let _ = require('lodash');

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    maxWidth: '500px',
    // color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#000',
    }
  }),
);

// Thêm trạng thái pre sales - hoàn thành luôn.
// Trong trạng thái pre sales.
// Không cho chọn nhân viên.
// Sẽ không tính buổi làm hôm đó cho khách hàng.

class Pos extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: true,
      hasError: false,
      currentPage: 'index',
      listServices: [],
      listServiceGroup: [],
      itemSelected: [],     // Order Items
      dataPrice: 0,
      dataBasePrice: 0,
      dataQuantity: 0,
      discountRule: 0,
      sale_rule_applied_ids: "",
      group_voucher_id: "",
      money_paid: 0,
      payment_type: "Tiền mặt",
      orderSelected: { // Order Information
        id: '',
        order_at: new Date(),
        description: '',
        customers: {
          gender: 2,
        },
        status: '1', // 1 = chưa có data, 2 = edit data, 3 = show data, 4 save data
        type: 0, // 0 = order bình thường, 1: nâng cấp thẻ da, 2: nâng cấp thẻ triệt, 3, upsale
      },
      serviceSearchString: '',
      value: 0,
      totalOwedOrder: 0,
      transactions: [],
      lastOrder: "",
      tabService: "",
      statusCancel: false,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      currentStore: localStorage.getItem("currentStore"),
      currentNameStore: localStorage.getItem("currentNameStore"),
      listPos: [],
      currentPos: '',
      showListPos: false,
      keywordSearchPos: '',
      showDelete: false,
      showPayment: false,
      listPackage: [],
      commission: "",
      listOperator: [],
      disableCheckboxService: false,
      disableCheckboxConsultant: false,
      statusActionItem: false,
      dataCus: "",
      transaction: [],
      statusUpdateOrder: "",
      printOrder: false,
      dataSuccessOrder: null,
      isDeposit: false,
      order_description: "",
      isAscOperator: true,
      keyCity: null,
      keyDistrict: null,
      dataDistrict: [],
      combos: [],
      amountCombo: 0,
      comboIds: "",
      isSelecting: false,
      isSelectingPackage: false,
      openLoading: true,
      showTransactionOwe: false,
      dataDiscount: { amount: 0, id: '' },
      limitPaidOrder: 0,
      statusCheckout: true,
      showEditCredit: false,
      typeCredit: 1,
      listPackage: [],
      listVoucher: [],
      vcProductPriceItem: 0,
      totalAmountUsingUpsale: 0,
      showCustomerAvatarList: 0,
      showListHanet: false,
      qrStore: [], 
      showImgCustomer: false,
      openTypeAvatar: false,
      openService: false,
      widthScreen: window.innerWidth,
      loadingButton: true,
      waxing_upgrade: "",
      waxing_head: []
    };
    this.socket = socketIOClient(process.env.REACT_APP_API_URL_WE);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ widthScreen: window.innerWidth });
  }

  handleChangeState = (value, key) => {
    this.setState({ [key]: value });
  }

  setDataCustomer = (data) => {
    let orderSelected = this.state.orderSelected;
    this.setState({
      orderSelected: {
        ...orderSelected,
        customers: {
          ...orderSelected.customers,
          deposit_money: Number(data)
        }
      }
    })
  }

  handelEditCredit = async (type) => {
    if ([1, 0].includes(type)) {
      this.setState({
        typeCredit: type
      })
    }
    let listPackage = await this.getPackageByCustomer(this.state.orderSelected.customers.id);
    let newPackage = [];
    listPackage.forEach((item) => {
      let left_price = 0
      let price = item.rule_price > 0 ? item.rule_price : item.initial_amount
      if (item.metaPrice && item.metaPrice > 0) {
        left_price = price - (item.metaPrice * item.count_used)
      } else {
        left_price = (price / item.max_used) * (item.max_used - item.count_used)
      }

      let historyUsed = '';
      if (item.max_used <= 10) historyUsed = "(" + item.count_used + "/" + item.max_used + ")";
      let label = item.package_code + " - " + item.product_name + historyUsed;
      item['money_wallet'] = 0
      if (item.transaction_wallet > 0) {
        item['money_wallet'] = Math.round(left_price/item.paid_money_order * item.transaction_wallet)
      }
      left_price = left_price - item['money_wallet']
      newPackage.push({ ...item, label: label, value: item.package_code, left_price: left_price })
    })

    this.setState({
      showEditCredit: !this.state.showEditCredit,
      listPackage: newPackage,
    });
  }

  changeCurrenDeposit = (newDeposit, giftMoney) => {
    
  }

  setShowImgCustomer = () => {
    this.setState({
      showImgCustomer: !this.state.showImgCustomer
    })
  }

  handleChangeForm = (evt, type = false, checkPos = true) => {
    const value = evt.target.value;
    let dataOrderSelected = this.state.orderSelected;

    this.setState({ [evt.target.name]: value });

    if (evt.target.title === 'true')
      dataOrderSelected.status = "2"

    if (type === true) {
      dataOrderSelected.customers = { gender: 2 }
      dataOrderSelected.status = "1"
      dataOrderSelected.id = ""
      dataOrderSelected.order_at = new Date()
      dataOrderSelected.description = ''
      this.setState({ itemSelected: [], isDeposit: false, dataQuantity: 0 });

      if (checkPos === false) {
        this.setState({ currentPos: '', keyCity: null, keyDistrict: null, dataDistrict: [] });
      }
    }

    if (this.state.widthScreen <= 1199 && evt.target.name != "serviceSearchString") {
      this.setState({ openService: !this.state.openService });
      if (this.state.currentPos && evt.target.title !== 'true' && !type) dataOrderSelected.status = '4';
    } 

    this.setState({ orderSelected: dataOrderSelected });
  };

  resetDataNewOrder = () => {
    let dataOrderSelected = this.state.orderSelected;
    dataOrderSelected.customers = { gender: 2 }
    dataOrderSelected.status = "1"
    dataOrderSelected.id = ""
    dataOrderSelected.type = 0
    this.setState({ itemSelected: [], orderSelected: dataOrderSelected });
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  updateSelecting = () => {
    this.setState({
      isSelecting: true
    })
  }
  updateSelectingPackage = () => {
    this.setState({
      isSelectingPackage: true
    })
  }

  handleSelectItem = async (itemSelected) => {
    let totalPrice = 0;
    let newItemSelected = [];
    for (const [key, value] of Object.entries(itemSelected)) {

      let discountByRule = 0
      if (this.state.listVoucher?.length > 0) {
        this.state.listVoucher.map((valV, keyV) => {
            if (valV.apply == 5) {
              if (valV.packages.includes(String(value.product_id))) {
                discountByRule = valV.amount
              }
            }
            if (valV.apply == 4) {
              discountByRule = value.price * valV.amount / 100
            }
        })
      }
      value.discount = value.discount - discountByRule
      if (value.price > 0) totalPrice += parseFloat(value.price) * Number(value.quantity) - Number(value.discount);
      if (value.quantity > 0) {
        newItemSelected.push(value)
      }
    }
    let listServices = await this.syncDataToService(this.state.listServices, itemSelected);
    let dataDeposit = await this.checkIsDeposit(itemSelected);

    if (dataDeposit.isDeposit) {
      listServices = listDeposit
    }

    let totalAmountUsingUpsale = 0
    let dataPrice = totalPrice - (this.state.currentPos?.paid_amount_before ?? 0)
    if (this.state.orderSelected.type == 3) {
      totalAmountUsingUpsale = dataPrice > 0 ? this.state.currentPos?.paid_amount_before : totalPrice
    }


    this.setState({
      itemSelected: [...newItemSelected],
      dataPrice: totalPrice - (this.state.currentPos?.paid_amount_before ?? 0),
      totalAmountUsingUpsale: totalAmountUsingUpsale,
      dataBasePrice: totalPrice,
      listServices: listServices,
      isDeposit: dataDeposit.isDeposit,
      dataQuantity: dataDeposit.quantity,
      listVoucher: [],
      vcProductPriceItem: 0
    });

    await this.actionOrder((this.state.orderSelected.id) ? "update" : "create")
  };

  checkIsDeposit = async (itemSelected) => {
    let isDeposit = this.state.isDeposit;
    let quantity = 0;

    Object.entries(itemSelected).map((item) => { quantity += item[1].quantity })

    if (this.state.tabService != 4 || (this.state.tabService == 4 && quantity == 0)) {
      isDeposit = false
    } else {
      isDeposit = true
    }
    return { isDeposit: isDeposit, quantity: quantity }
  }

  funcUpdateOrder = async (orderData) => {
    this.setState({ orderSelected: orderData });

    this.getDataLocation(orderData.customers)
    if (orderData.customers.id) {
      let listPackage = await this.getPackageByCustomer(orderData.customers.id);
      this.setState({
        listPackage: listPackage,
      });
    }
  };

  actionOrder = async (type) => {
    this.setState({
      statusUpdateOrder: this.state.orderSelected.id ? 'Đang cập nhật đơn hàng...' : "Đang tạo đơn hàng mới..."
    })
    let itemSelected = []
    if (this.state.itemSelected.length > 0) {
      this.state.itemSelected.map(x => {
        if (!x.package_code || (x.package_code && (x.customer_id ?? x.package.customer_id) == this.state.orderSelected.customers.id)) {
          itemSelected.push(x)
        }
      })
    }

    let actionAxios = {
      url: (type === 'update') ? "api/orders/" + this.state.orderSelected.id : "api/orders/",
      method: (type === 'update') ? "PUT" : "POST"
    };

    let orderItem = await this.convertOrderItem(itemSelected, false)
    
    let dataOrder = {
      "description": this.state.orderSelected.description ?? null,
      "order_at": this.state.orderSelected.order_at ?? null,
      "status": 1,
      "total_amount_using_upsale":this.state.totalAmountUsingUpsale,
      "money_paid": 0,
      "stores": this.state.currentStore,
      'orderItem': orderItem,
      "isDeposit": this.state.isDeposit,
      "customers": {
        "id": this.state.orderSelected.customers.id ?? null,
        "gender": this.state.orderSelected.customers.gender ?? null,
        "full_name": this.state.orderSelected.customers.full_name ?? "Khách lẻ",
        "mobile": this.state.orderSelected.customers.mobile ?? null,
        "city": this.state.orderSelected.customers.city ?? null,
        "district": this.state.orderSelected.customers.district ?? null,
        "address": this.state.orderSelected.customers.address ?? null,
        "email": this.state.orderSelected.customers.email ?? null,
        "facebook_id": this.state.orderSelected.customers.FacebookId ?? null,
        "birthday": this.state.orderSelected.customers.birthday ?? null,
        "day_birthday": this.state.orderSelected.customers.day_birthday ?? null,
        "month_birthday": this.state.orderSelected.customers.month_birthday ?? null,
        "year_birthday": this.state.orderSelected.customers.year_birthday ?? null,
        "identifier": this.state.orderSelected.customers.identifier ?? 0
      }
    };

    if (type === "create") dataOrder = { ...dataOrder, "created_by": this.state.userAdmin.id ?? '', "created_name": this.state.userAdmin.name ?? '' }
    if (type === "update") dataOrder = { ...dataOrder, "updated_by": this.state.userAdmin.id ?? '' }

    let findFullBody = _.filter(itemSelected, function(o){ return o.parent_package != null && o.id_item == undefined})
    let res = await axiosService(actionAxios.url, actionAxios.method, dataOrder);
    if (res.data.success == true) {
      if (this.state.openLoading) {
        this.setState({ statusUpdateOrder: "" })
      }
      // var dataProduct = await this.funcHandleSetState()
      let itemSelectedCheck = itemSelected;
      if (type == "create") {
        await this.reloadData(false)
      };

      if (type == "update") {
        let listPos = this.state.listPos;
        listPos.map((x,y) => {
          if (x.id === this.state.orderSelected.id) listPos[y].customers =  this.state.orderSelected.customers
        })
        let resOrderItem = res.data.data;
        var keyNewItem
        resOrderItem.map(function (element, key) {
          var checkKey = _.findIndex(itemSelectedCheck, function (o) { return o.id_item == element.id && o.parent_package == null; });
          if (checkKey < 0) keyNewItem = key
          
          itemSelectedCheck.map((item, key) => {
            //Child package
            if (item.parent_package != null && item.id_item == undefined && element.package_code == item.package_code) {
              itemSelectedCheck[key].id_item = element.id
              itemSelectedCheck[key].max_used = element.max_used_package
              itemSelectedCheck[key].id = element.id
              itemSelectedCheck[key].price = element.price
            }

            //Combo
            if (item.combo_id != null && item.id_item == undefined && element.product_id == item.product_id && item.combo_id == element.combo_id) {
              itemSelectedCheck[key].id_item = element.id
              itemSelectedCheck[key].max_used = element.max_used_package
              itemSelectedCheck[key].id = element.id
              itemSelectedCheck[key].price = element.price
            }

          })
        })

        itemSelectedCheck.map((item, key) => {
          if (!item.parent_package && !item.combo_id) {
            if (item.id_item == undefined && keyNewItem != undefined) {
              itemSelectedCheck[key].id_item = resOrderItem[keyNewItem].id
              itemSelectedCheck[key].max_used = resOrderItem[keyNewItem].max_used_package
              itemSelectedCheck[key].id = resOrderItem[keyNewItem].id
              itemSelectedCheck[key].price = resOrderItem[keyNewItem].price
            } else {
              var data = _.find(resOrderItem, function (o) { return o.id == item.id_item; });
              itemSelectedCheck[key].id_item = data.id
              itemSelectedCheck[key].max_used = data.max_used_package
              itemSelectedCheck[key].id = data.id
              itemSelectedCheck[key].price = data.price
            }
          }
        })
        let price = _.sumBy(itemSelectedCheck, function (o) { return o.price*o.quantity; });
        price -= (this.state.currentPos?.paid_amount_before ?? 0)
        this.setState({
          isSelectingPackage: false,
          itemSelected: itemSelectedCheck,
          listPos: listPos,
          dataPrice: price
        })
      }
    } else {
      this.setState({
        isSelectingPackage: false,
      })
      notifications({ type: 'error', mess: res.data.message });
    }
    this.setState({
      isSelecting: false
    })
  };

  funcHandleSetState = async () => {
    var listPackage = await this.getPackageByCustomer(this.state.orderSelected.customers.id)

    var listServices = await this.loadServiceList(this.state.tabService, 1)

    return [listPackage, listServices[1]]

  }

  funcSaveOrder = async (type_booking = null) => {
    this.setState({
      statusCheckout: false
    })
    let orderItem = await this.convertOrderItem(this.state.itemSelected, true)
    
    var description = this.state.orderSelected.description
    if (description == 'undefined') {
      description = ""
    }

    if (this.state.order_description) {
      description = this.state.order_description
    }
 
    let transaction = this.state.transaction;
    let total_amount = 0;
    orderItem.map(x => {
      total_amount += (x.price * x.quantity) - x.discount
    })
    total_amount = total_amount - this.state.discountRule;

    let payment_type = (transaction.length == 1) ? transaction[0].pay_type : this.state.payment_type;

    let dataOrder = {
      "description": description ?? '',
      "status": 3,
      "order_at": this.state.orderSelected.order_at ?? '',
      "stores": this.state.currentStore,
      "payment_type": payment_type,
      "money_paid": Number(this.state.money_paid),
      "transaction": transaction,
      "updated_by": this.state.userAdmin.id ?? '',
      "isDeposit": this.state.isDeposit,
      "updated_by_name": this.state.userAdmin.name ?? '',
      "store_id": this.state.currentStore,
      "store_name": this.state.currentNameStore,
      "discount_by_rule": this.state.discountRule,
      "discount_by_total_bill": this.state.amountCombo,
      "sale_rule_applied_ids": this.state.comboIds + ',' + this.state.sale_rule_applied_ids,
      "group_voucher_id": this.state.group_voucher_id,
      "total_price_discount_vc": this.state.vcProductPriceItem,
      "total_amount_using_upsale": this.state.totalAmountUsingUpsale,
      "pre_sale": 0,
      "customers": {
        "id": this.state.orderSelected.customers.id ?? null,
        "gender": this.state.orderSelected.customers.gender ?? null,
        "full_name": this.state.orderSelected.customers.full_name ?? null,
        "mobile": this.state.orderSelected.customers.mobile ?? null,
        "city": this.state.orderSelected.customers.city ?? null,
        "district": this.state.orderSelected.customers.address ?? null,
        "address": this.state.orderSelected.customers.address ?? null,
        "email": this.state.orderSelected.customers.email ?? null,
        "facebook_id": this.state.orderSelected.customers.FacebookId ?? null,
        "birthday": this.state.orderSelected.customers.birthday ?? null,
        "day_birthday": this.state.orderSelected.customers.day_birthday ?? null,
        "month_birthday": this.state.orderSelected.customers.month_birthday ?? null,
        "year_birthday": this.state.orderSelected.customers.year_birthday ?? null
      },
      "orderItem": orderItem,
      "type_booking": type_booking,
      "removeOldTransaction": true
    }

    if (this.state.tabService == 4 && Number(this.state.money_paid) < Number(this.state.dataPrice)) {
      notifications({ type: 'error', mess: 'Khách hàng phải thanh toán 100% khi mua thẻ tiền' });
      this.setState({
        statusCheckout: true
      })
      return false
    }
    if (this.state.limitPaidOrder > 0) {
      let price_paid = (this.state.limitPaidOrder <= 100) ?  Number(this.state.dataPrice) * (this.state.limitPaidOrder/100) : Number(this.state.limitPaidOrder);

      if (Number(this.state.money_paid) < price_paid) {
        notifications({ type: 'error', mess: "Khách hàng phải thanh toán ít nhất " + convert.convertCurrency(price_paid) + ' mới áp dụng được mã voucher này.' });
        this.setState({
          statusCheckout: true
        })
        return false
      }
    }

    // var pricePixel = 0;
    // dataOrder.transaction.map(x => {
    //   if (x.pay_type != "Sử dụng thẻ tiền" || x.pay_type != "Điểm tích luỹ") {
    //     pricePixel += Number(x.paid_amount)
    //   }
    // })
    // let dataPixelEvent = {
    //   price: Number(pricePixel),
    //   mobile: dataOrder.customers.mobile,
    //   name: dataOrder.customers.full_name
    // }

    let orderCancel = this.state.orderSelected
    let res = await axiosService("api/save-orders/" + this.state.orderSelected.id, "POST", dataOrder)
    if (res.data.success == true) {
      // if (pricePixel > 0) fbEvent.purchaseEvent(dataPixelEvent)
      if (type_booking == "booking") {
        window.location.replace('/booking/?action=create&cust_id=' + this.state.orderSelected.customers.id + "&order_id=" + this.state.orderSelected.id)
      } else {
        await this.reloadData(true);
        this.setState({
          dataSuccessOrder: res.data.data,
          printOrder: ([5,1].includes(this.state.userAdmin.role)) ? true : false,
          statusCheckout: true,
          showPayment: !this.state.showPayment,
        })
        await this.actionSuccessOrder();
        this.socket.emit("client-qr-code", {order: orderCancel, status: false, store_id: this.state.currentStore});
      }
    } else {
      this.setState({
        statusCheckout: true
      })
      notifications({ type: 'error', mess: res.data.message });
    }
  };

  checkPayOs = async (type_booking = null) => {
    this.setState({ statusCheckout: false })
    let dataOrder = this.state.orderSelected
    let res = await axiosService("api/check-pay-os", "POST", {id: dataOrder.id, store_id: this.state.currentStore})
    if (res.data.success == true) {
      this.socket.emit("client-qr-code", {order: dataOrder, status: false, store_id: this.state.currentStore});
      await this.reloadData(true);
      await this.actionSuccessOrder();
      this.setState({
        statusCheckout: true,
        showPayment: !this.state.showPayment,
      })
      if (type_booking == "booking") {
        window.location.replace('/booking/?action=create&cust_id=' + dataOrder.customers.id + "&order_id=" + dataOrder.id)
      }
    } else {
      notifications({ type: 'warning', mess: res.data.message });
      this.setState({ statusCheckout: true })
    }
  }

  actionSuccessOrder = async () => {
    notifications({ type: 'success', mess: 'Thanh toán thành công' });
  }

  getDataOptions = async () => {
    const { data } = await axiosService('api/options', "POST", {key: ['qr_store', 'waxing_upgrade', 'waxing_head']})
    if (data.data) {
      let qrStore = _.find(data.data, function(o){ return o.key == 'qr_store' })
      qrStore = qrStore?.value?.split(',').map(Number) || []
      let waxing_upgrade = _.find(data.data, function(o){ return o.key == 'waxing_upgrade' })
      let waxing_head = _.find(data.data, function(o){ return o.key == 'waxing_head' }).value?.split(',').map(Number) || []

      this.setState({ 
        qrStore: qrStore,
        waxing_upgrade: Number(waxing_upgrade?.value ?? 0),
        waxing_head: waxing_head
      });
    }
  }

  updateDiscountByRule = (data) => {
    if (typeof data.vcProductPriceItem == 'undefined') {
      data.vcProductPriceItem = 0
    }

    var price = (this.state.dataBasePrice - data.totalAmount) < 0 ? 0 : (this.state.dataBasePrice - data.totalAmount)
    if (this.state.tabService == 4) {
      price = this.state.dataBasePrice
    }
    let dataPrice = price - (this.state.currentPos?.paid_amount_before ?? 0) - data.vcProductPriceItem

    this.setState({
      discountRule: data.totalAmount,
      dataPrice: dataPrice,
      totalAmountUsingUpsale: (this.state.currentPos?.paid_amount_before - (price - data.vcProductPriceItem)) > 0 ? price - data.vcProductPriceItem : this.state.currentPos?.paid_amount_before,
      sale_rule_applied_ids: data.id,
      group_voucher_id: data.group_voucher_id,
      listVoucher: data.listVoucher,
      vcProductPriceItem: data.vcProductPriceItem
    })


  }
  updateDiscountByCombo = (data) => {
    const price = (this.state.dataBasePrice - data.amount) < 0 ? 0 : (this.state.dataBasePrice - data.amount) - (this.state.discountRule ?? 0)
    this.setState({
      amountCombo: data.amount,
      dataPrice: price - (this.state.currentPos?.paid_amount_before ?? 0),
      comboIds: data.id
    })
  }
  deleteOrder = async (data) => {
    let res = await axiosService("api/orders/" + this.state.orderSelected.id, "PUT", data)
    if (res.data.success == true) {
      this.socket.emit("client-qr-code", {order: this.state.orderSelected, status: false, store_id: this.state.currentStore});
      await this.reloadData(true);
      this.statusDeleteOrder()
      notifications({ type: 'success', mess: 'Xóa hóa đơn thành công' });
    } else {
      notifications({ type: 'error', mess: res.message });
    }
  }

  reloadData = async (reloadService) => {
    let order = await this.getAllPos(),
      itemSelected = [],
      listOrderItem = [],
      isDeposit = false;

    if (order[0][0]) {
      listOrderItem = order[0][0].orderItem ?? [];
      isDeposit = order[0][0].isDeposit ?? false;

      let listPackageCustom = _.groupBy(order[1], ({ package_code }) => package_code);

      order[0][0].orderItem.map(function (item, key) {
        if (listPackageCustom[item.package_code]) {
          item = { ...item, "max_used": listPackageCustom[item.package_code][0].max_used, "count_used": listPackageCustom[item.package_code][0].count_used }
        }
        order[0][0].orderItem[key] = item
      })
    }

    itemSelected = await this.getDefaultServiceSelected(listOrderItem, isDeposit, reloadService);
    var getTabService = this.state.tabService

    if (isDeposit) getTabService = 4
    if (!isDeposit && reloadService) getTabService = 2

    if (order[0][0]) this.getDataLocation(order[0][0].customers)
    this.setState({
      itemSelected: itemSelected[0],
      listPos: order[0] ?? "",
      currentPos: order[0][0] ?? '',
      orderSelected: {
        id: order[0][0] ? order[0][0].id : "",
        order_at: order[0][0] ? order[0][0].order_at : new Date(),
        description: order[0][0] ? order[0][0].description : "",
        customers: order[0][0] ? order[0][0].customers : { gender: 2 },
        status: order[0][0] ? "4" : "1",
        parent_order_id:  order[0][0] ? order[0][0].parent_order_id : null,
        type:  order[0][0] ? order[0][0].type : 0,
      },
      listPackage: order[1],
      sale_rule_applied_ids: "",
      isDeposit: order[0][0] ? order[0][0].isDeposit ?? false : false,
      tabService: getTabService,
      listServices: itemSelected[1],
      dataPrice: itemSelected[2] - (order[0][0]?.paid_amount_before ?? 0),
      totalAmountUsingUpsale: order[0][0] ? order[0][0].total_amount_using_upsale : 0,
      dataBasePrice: itemSelected[2],
      dataQuantity: itemSelected[3],
      comboIds: "",
      amountCombo: 0,
      discountRule: 0,
      listServiceGroup: itemSelected[4]
    })
  }
  // updateOrderItem = (list)=>{
  //   this.setState({
  //     itemSelected: list,
  //   })
  // }
  convertOrderItem = async (itemSelected, isSave) => {
    let orderItem = [];
    let comboId = []
    let comboIdStr = ""
    let amountComboTotal = 0
    for (const [key, value] of Object.entries(itemSelected)) {

      let dataItem = {};
      let packageData = null
      let package_code = null

      if (value.combo_id) {
        if (!_.includes(comboId, value.combo_id)) {
          comboId.push(value.combo_id)
          comboIdStr += value.combo_id + ","
        }
        amountComboTotal += value.discount
      }
      if (typeof value.package != 'undefined' && value.package) {
        packageData = value.package
        package_code = value.package.package_code
      }
      let id = value.id_item

      if (isSave) {
        id = id ?? value.id
      }

      if (value.quantity > 0) {

        if (value.id_item != undefined) {
          dataItem = {
            id: value.id_item,
            quantity: value.quantity,
            price: value.price ?? 0,
            discount: value.discount ?? 0,
            order_id: this.state.orderSelected.id,
            product_name: value.product_name,
            package: packageData,
            package_code: package_code,
            parent_package: value.parent_package ?? null,
            upgrade_by_package: value?.upgrade_by_package ?? null,
            price_package_used: value?.price_package_used ?? 0,
          }
        } else {
          dataItem = {
            quantity: value.quantity,
            price: value.price ?? 0,
            discount: value.discount ?? 0,
            order_id: this.state.orderSelected.id,
            product_name: value.product_name,
            package: packageData,
            package_code: package_code,
            parent_package: value.parent_package ?? null,
            upgrade_by_package: value?.upgrade_by_package ?? null,
            price_package_used: value?.price_package_used ?? 0,
          }
        }

        if (this.state.isDeposit) {
          dataItem = {
            ...dataItem,
            note: value.note ?? "",
            product_code: value.product_code ?? value.code,
          }
        } else {
          dataItem = {
            ...dataItem,
            product_id: value.product_id ?? null,
            type_presenter: value.type_presenter ?? null,
            commission_presenter: value.commission_presenter ?? null,
            presenter_user_id: value.presenter_user_id ?? null,
            presenter_customer_id: value.presenter_customer_id ?? null,
            presenter_name: value.presenter_name ?? null,
            presenter_mobile: value.presenter_mobile ?? null,
            combo_id: value.combo_id,
            combo_name: value.combo_name
          }

          if (value.package_code) {
            dataItem = {
              ...dataItem,
              package_code: value.package_code
            }
          }
        }

        if (value.new_package) {
          dataItem.new_package = value.new_package
          dataItem.note = value.note
          dataItem.max_used = value.max_used
        }

        if (value.service && Object.entries(value.service).length > 0) {
          Object.entries(value.service).map((item, key) => {
            dataItem = {
              ...dataItem,
              ["employee_service" + (key + 1)]: item[1].id,
              ["employee_service_name" + (key + 1)]: item[1].name,
            }
          })
        }
        if (value.consultant && Object.entries(value.consultant).length > 0) {
          Object.entries(value.consultant).map((item, key) => {
            dataItem = {
              ...dataItem,
              ["employee_consultant" + (key + 1)]: item[1].id,
              ["employee_consultant_name" + (key + 1)]: item[1].name,
            }
          })
        }
        orderItem.push(dataItem)
      }
    }
    this.setState({
      comboIds: comboIdStr.substring(0, comboIdStr.length - 1),
      amountCombo: amountComboTotal
    })  
    return orderItem;
  }

  statusDeleteOrder = () => {
    this.setState({
      showDelete: !this.state.showDelete,
    })
  }

  paymentOrder = () => {
    this.setState({
      showPayment: !this.state.showPayment,
    })
  }
  editCustomerOrderEdit = (data) => {
    let orderSelected = {...this.state.orderSelected}
    orderSelected.customers.avata_url = data
    this.setState({
      orderSelected: {...orderSelected}
    })
  }

  getPackageByCustomer = async (id) => {
    let response = await axiosService("api/package/customer/" + id, "GET");
    if (response.data.success !== true) return []
    let listPackage = response.data.data.listPackage

    return listPackage
  }

  getDefaultServiceSelected = async (dataSelector, isDeposit = false, reloadService) => {

    let listServices = this.state.listServices,
      dataServiceSelected = [],
      totalPrice = 0,
      dataQuantity = 0,
      listServiceGroup = this.state.listServiceGroup;

    if (isDeposit) listServices = listDeposit

    if (reloadService && !isDeposit) {
      listServices = await axiosService("api/product?type=2&open_sell=0", "GET")
      listServices = listServices.data.data.data
    }


    if (listServices.length > 0) {
      dataSelector.map(function (element) {
        let service = {}, consultant = {}
        element.discount = element.discount ?? 0
        for (let i = 1; i <= 5; i++) {
          if (element["employee_service" + i]) service = { ...service, [element["employee_service" + i]]: { id: element["employee_service" + i], name: element["employee_service_name" + i] ?? '' } }
          if (element["employee_consultant" + i]) consultant = { ...consultant, [element["employee_consultant" + i]]: { id: element["employee_consultant" + i], name: element["employee_consultant_name" + i] ?? '' } }
        }

        let code = (element.package_code && element.price == 0) ? element.package_code : element.product_code;
        dataQuantity += element.quantity;
        totalPrice += element.price * element.quantity - element.discount;

        let dataSelected = {
          id_item: element.id,
          quantity: element.quantity,
          price: element.price,
          product_name: element.product_name,
          code: element.product_code,
          discount: element.discount,
          service: service,
          consultant: consultant,
          max_used: element.max_used_package,
          upgrade_by_package: element.upgrade_by_package,
          price_package_used: element.price_package_used,
        }

        if (isDeposit) dataSelected.note = element.note ?? '';

        if (isDeposit === false || isDeposit === 0)
          dataSelected = {
            ...dataSelected,
            product_id: element.product_id,
            presenter_mobile: element.presenter_mobile,
            presenter_name: element.presenter_name,
            commission_presenter: element.commission_presenter,
            type_presenter: element.type_presenter,
            presenter_customer_id: element.presenter_customer_id,
            presenter_user_id: element.presenter_user_id,
            combo_id: element.combo_id,
            combo_name: element.combo_name
          }

        if (element.package_code) dataSelected.package_code = element.package_code

        if (element.new_package) {
          dataSelected.new_package = element.new_package
          dataSelected.note = element.note
        }
        dataServiceSelected.push(dataSelected)
      })

      listServices.map(function (item, key) {
        listServices[key].product_id = item.id
        dataServiceSelected.map((itemSl, keySl) => {
          listServices[key].product_id = item.id
          if (dataServiceSelected[keySl].code == item.code) {
            // listServices[key].price = itemSl.price
            dataServiceSelected[keySl].meta_object = listServices[key].meta_object
          }
        })
      })
    }

    return [dataServiceSelected, listServices, totalPrice, dataQuantity, listServiceGroup]
  }

  syncDataToService = (services, selected) => {

    services.map((item) => {
      if (selected.length > 0) {
        selected.map((itemSelect, keySelect) => {
          let code = (itemSelect.package_code && itemSelect.price == 0) ? itemSelect.package_code : itemSelect.product_code;

          if (item.code == code) {
            item.product_id = itemSelect.product_id
            item.quantity = itemSelect.quantity
            item.price = itemSelect.price
            item.service = itemSelect.service ?? []
            item.consultant = itemSelect.consultant ?? []
          }
        })
      }
    });

    return services
  }

  syncDataToServiceCombo = (services, selected) => {
    return services
  }


  changeCurrentPos = async (event) => {

    let dataOrder = await axiosService("api/order-detail?store_id=" + this.state.currentStore + "&order_code=" + event, "GET");
    dataOrder = dataOrder.data.data;

    let dataItemSelected = [];
    dataItemSelected = await this.getDefaultServiceSelected(dataOrder.orderItem, dataOrder.isDeposit, true);


    let listPackageCustom = []
    if (dataOrder.customers.id) {
      this.getDataTransaction(dataOrder.customers.id)
      let listPackage = await this.getPackageByCustomer(dataOrder.customers.id);
      listPackageCustom = _.groupBy(listPackage, ({ package_code }) => package_code);
      dataOrder.orderItem.map(function (item, key) {
        if (listPackageCustom[item.package_code]) {
          item = { ...item, "max_used": listPackageCustom[item.package_code][0].max_used, "count_used": listPackageCustom[item.package_code][0].count_used }
        }
        dataOrder.orderItem[key] = item
      })

      this.setState({
        listPackage: listPackage,
      });
    }

    this.getDataLocation(dataOrder.customers)
    this.setState({
      currentPos: dataOrder,
      orderSelected: {
        id: dataOrder.id,
        order_at: dataOrder.order_at,
        description: dataOrder.description,
        customers: dataOrder.customers,
        status: '4',
        parent_order_id: dataOrder.parent_order_id,
        type: dataOrder.type
      },
      itemSelected: dataItemSelected[0],
      showListPos: !this.state.showListPos,
      currentPage: "index",
      isDeposit: dataOrder.isDeposit ?? false,
      tabService: (dataOrder.isDeposit && dataOrder.isDeposit == 1) ? 4 : 2,
      listServices: dataItemSelected[1],
      dataPrice: dataItemSelected[2] - (dataOrder?.paid_amount_before ?? 0),
      totalAmountUsingUpsale: dataOrder?.total_amount_using_upsale,
      dataBasePrice: dataItemSelected[2],
      dataQuantity: dataItemSelected[3],
      listServiceGroup: dataItemSelected[4]
    });

    if (dataOrder.isDeposit) {
      this.setState({ listServices: []})
    }
  }

  searchPosByCode = (event) => {
    this.setState({
      keywordSearchPos: event.target.value.toLowerCase()
    })
  }

  setLimitPaidOrder = (value, check = false) => {
    let paid = this.state.limitPaidOrder
    if (value > this.state.limitPaidOrder && !check) {
      paid = value
    }
    if (check) {
      paid = value
    }

    this.setState({
      limitPaidOrder: value
    })

  }

  setOpenPopupHanet = () => {
    this.setState({
       showListHanet: !this.state.showListHanet 
    })
  }

  handleOpenLoading = (data) => {
    this.setState({ openLoading: data })
  }

  handleStatusUpdateOrder = (data) => {
    this.setState({ statusUpdateOrder: data })
  }

  loadServiceList = async (typeService = 1, typeServiceGroup = 1) => {
    const responseGroup = await axiosService("api/category/group/" + typeServiceGroup, "GET")
    const responseService = await axiosService(`api/product?type=${typeService}&open_sell=0` , "GET")

    responseService.data.data.data.map((item) => {
      item.product_id = item.id
    });

    return [responseGroup.data.data, responseService.data.data.data];
  };

  loadComboList = async (typeCombo = 1) => {
    const responseService = await axiosService("api/vouchers/list/combo/?type=" + typeCombo, "GET")
    return [[], responseService.data];
  }

  changeServiceList = async (typeService, typeServiceGroup) => {
    let dataService,
      listServices = [],
      listServiceGroup = [];

    if (typeService === 4) {
      listServices = listDeposit
    } else if (typeService === 5) {
      dataService = await this.loadComboList(1);
      listServices = dataService[1]
      listServiceGroup = dataService[0]
    } else {
      dataService = await this.loadServiceList(typeService, typeServiceGroup);
      listServices = dataService[1]
      listServiceGroup = dataService[0]
    }
    if (typeService == 5) {
      listServices = this.syncDataToServiceCombo(listServices, this.state.itemSelected)
    } else {
      listServices = this.syncDataToService(listServices, this.state.itemSelected)
    }

    this.setState({
      listServiceGroup: listServiceGroup,
      listServices: listServices,
      tabService: typeService,
    })
  }

  getAllPos = async () => {
    let listPackage = [];
    const response = await axiosService("api/orders/" + this.state.currentStore, "GET");
    if (response.data.success !== true) return
    if (response.data.data.orderList.length > 0) listPackage = await this.getPackageByCustomer(response.data.data.orderList[0].customers.id);
    return [response.data.data.orderList, listPackage];
  }
  updateQuery = async () => {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (Object.keys(params).length > 0) {
      const res = await axiosService(`api/customer/${params.customerId}`, "GET");
      const { data } = res

      return data;
    } else {
      return ''
    }
  }

  getDataLocation = async (dataCustomer) => {

    let dataLocation = convert.getDistricts(dataCustomer.city, dataCustomer.district);

    this.setState({
      keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
      keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
      dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : []
    })
  }

  getDataOwe = async (customerId) => {
    const res = await axiosService("api/customer/" + customerId, "GET");

  }
  getCombos = async () => {
    const { data } = await axiosService("api/vouchers/find/combo/" + this.state.currentStore, "GET");
    if (data.code === 200) {
      this.setState({
        combos: data.data
      })
    }
  }

  componentDidMount(error, errorInfo) {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getDataOptions();
    const getDataOperators = async () => {
      try {
        const response = await axiosService("api/users/store/" + this.state.currentStore + "?role=" + this.state.userAdmin.role, "GET")
        if (response.data.success !== true) return false;
        return response.data.data
      } catch (error) {
        return console.log(error);
      }
    }

    Promise.all([
      this.loadServiceList(1, 1),
      this.getAllPos(),
      getDataOperators(),
      this.loadServiceList(2, 3),
      this.updateQuery(),
      this.getCombos()
    ]).then((results) => {

      let listService = (results[1][0][0] && (results[1][0][0].isDeposit || results[1][0][0].isDeposit == 1)) ? [] : results[3][1],
          itemSelected = [],
          dataPrice = 0,
          dataBasePrice = 0,
          currentPos = results[1][0][0],
          dataQuantity = 0,
          isDeposit = (results[1][0][0] && (results[1][0][0].isDeposit || results[1][0][0].isDeposit == 1)) ? true : false,
          listPackages = results[3][1];

      if (results[1][0][0]) {
        this.getDataTransaction(results[1][0][0].customers.id)
        this.getDataLocation(results[1][0][0].customers);
        results[1][0][0].orderItem.map(function (element) {
          element.discount = element.discount ?? 0
          dataQuantity += element.quantity;
          dataPrice += element.price * element.quantity - element.discount;
          dataBasePrice += element.price * element.quantity - element.discount;
          let code = (element.package_code && element.price == 0) ? element.package_code : element.product_code;

          let service = {}, consultant = {};
          for (let i = 1; i <= 5; i++) {
            if (element["employee_service" + i]) service = { ...service, [element["employee_service" + i]]: { id: element["employee_service" + i], name: element["employee_service_name" + i] ?? '' } }
            if (element["employee_consultant" + i]) consultant = { ...consultant, [element["employee_consultant" + i]]: { id: element["employee_consultant" + i], name: element["employee_consultant_name" + i] ?? '' } }
          }

          let itemSelectedN = {
            id_item: element.id,
            quantity: element.quantity,
            price: element.price,
            product_name: element.product_name,
            code: element.product_code,
            discount: element.discount,
            service: service,
            consultant: consultant,
            max_used: element.max_used_package,
            package: element.package,
            upgrade_by_package: element.upgrade_by_package ?? null,
            price_package_used: element.price_package_used,
            type_radical: element.product ? element.product.type_radical : 0,
            parent_package: element.parent_package,
            package_type_using: !element.package ? 0 : element.package.package_type_using
          }

          if (isDeposit) itemSelectedN.note = element.note ?? '';
          if (isDeposit === false)
            itemSelectedN = {
              ...itemSelectedN,
              product_id: element.product_id,
              presenter_mobile: element.presenter_mobile,
              presenter_name: element.presenter_name,
              commission_presenter: element.commission_presenter,
              type_presenter: element.type_presenter,
              presenter_customer_id: element.presenter_customer_id,
              presenter_user_id: element.presenter_user_id,
              combo_id: element.combo_id,
              combo_name: element.combo_name
            }

          if (element.package_code) itemSelectedN.package_code = element.package_code
          if (element.new_package) {
            itemSelectedN.new_package = element.new_package
            itemSelectedN.note = element.note
          }
          itemSelected.push(itemSelectedN)
        })
      }

      if (currentPos) {
        let listPackage = results[1][1];
        listPackage = _.groupBy(listPackage, ({ package_code }) => package_code);
        currentPos.orderItem.map(function (item, key) {
          if (listPackage[item.package_code]) {
            item = { ...item, "max_used": listPackage[item.package_code][0].max_used, "count_used": listPackage[item.package_code][0].count_used }
          }
          currentPos.orderItem[key] = item
        })
      }

      if (listService) {
        listService.map(function (element) {
          itemSelected.map((itemSv, keySv) => {
            if (itemSv.code == element.code) {
              element.product_id = itemSelected[keySv].product_id
              element.quantity += itemSelected[keySv].quantity
              // element.price = itemSelected[keySv].price
              if (element.service) element.service = itemSelected[keySv].service ?? []
              if (element.consultant) element.consultant = itemSelected[keySv].consultant ?? []
            }
          })
        })
      }


      if (listPackages.length > 0) {
        listPackages.map(function (item, key) {

          itemSelected.map((itemPk, keyPk) => {
            if (itemPk.code == item.code) {
              itemSelected[keyPk].meta_object = listPackages[key].meta_object
              if (!itemSelected[keyPk].max_used) {
                itemSelected[keyPk].max_used = listPackages[key].meta_object.max_used
              }
            }
          })

        })
      }

      if (results[4] == '') {
        this.setState({
          itemSelected: itemSelected,
          dataPrice: dataPrice - (results[1][0][0]?.paid_amount_before ?? 0),
          totalAmountUsingUpsale: results[1][0][0]?.total_amount_using_upsale ?? 0,
          dataBasePrice: dataPrice,
          tabService: (isDeposit) ? 4 : 2,
          currentPos: currentPos ?? '',
          orderSelected: {
            id: results[1][0][0] ? results[1][0][0].id : "",
            order_at: results[1][0][0] ? results[1][0][0].order_at : new Date(),
            description: results[1][0][0] ? results[1][0][0].description : "",
            customers: results[1][0][0] ? results[1][0][0].customers : { gender: 2 },
            status: results[1][0][0] ? "4" : "1",
            parent_order_id: results[1][0][0] ? results[1][0][0].parent_order_id : null,
            type: results[1][0][0] ? results[1][0][0].type : 0
          },
          listPackage: results[1][1] ?? [],
          isDeposit: isDeposit,
          dataQuantity: dataQuantity,
        });
      } else {
        this.setState({
          dataCus: results[4],
          currentPage: "customer",
        });
      }
      this.setState({
        listPos: results[1] ? results[1][0] : [],
        listServiceGroup: results[0][0],
        listServices: listService,
        listOperator: results[2],
        isLoaded: true,
      });

    });
  }

  handleSortName = async () => {
    this.setState({
      listOperator: this.state.listOperator.reverse(),
      isAscOperator: !this.state.isAscOperator
    })
  }
  handleShowPopupOwe = () => {
    this.setState({
      showTransactionOwe: !this.state.showTransactionOwe
    })
    this.getDataTransaction(this.state.orderSelected.customers.id)
  }
  hidePopupService = () => {
    document.body.style.overflow = "auto";
    this.setState({ openService: false, currentPage: 'index' })
  }
  getDataTransaction = (id) => {
    axiosService("api/transaction/customer/" + id + '/' + null, "GET")
      .then((res) => {
        if (res.status) {
          if (res.data.data !== null) {
            this.setState({
              totalOwedOrder: res.data.data.totalOwedOrder,
              transactions: res.data.data.data,
              lastOrder: res.data.data.lastOrder
            });
          }
        }
      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  render() {
    if (this.state.isLoaded) {
      return (
        <div className="admin-page pos">
          <div className="page-title">
            <div className="title-page">
              <i className="fas fa-calculator"></i> Bán Hàng (POS)
            </div>
            {this.state.currentPage === 'index' ?
              <button className="ml-10 button-cent" name="currentPage" value="customer" onClick={(e) => this.handleChangeForm(e, true, false)}>
                <span>
                  <i className="fa-solid fa-cart-plus"></i>
                </span>
                Tạo đơn hàng
              </button>
              :
              <button className="ml-10 button-cent" name="currentPage" value="index" onClick={(e) => this.handleChangeForm(e)}>
                <span>
                  <i className="fa-solid fa-cart-plus"></i>
                </span>
                Quay về đơn hàng
              </button>
            }

            <button className="button-cent">
              <Link to={{ pathname: "/orders" }}>
                <span>
                  <i className="fa-solid fa-bars"></i>
                </span>
                Danh sách đơn hàng
              </Link>
            </button>
            {/* <div className="pull-right" style={{"display": "inline-block","width":"250px"}}>
              <input id="autocomplete-search-sustomer-input" className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Tìm khách hàng theo tên, điện thoại hoặc email"></input>
            </div> */}
          </div>
          {this.state.showTransactionOwe ?
            <div className="order-popup package-history">
              <div className="popup-cent">
                <div className="content-popup fadeInDown package-popup">
                  <TransactionPoup
                    customer={this.state.orderSelected.customers}
                    show={this.state.showTransactionOwe}
                    getDataTransaction={this.getDataTransaction}
                    setShow={this.handleShowPopupOwe}
                    setDataCustomer={this.setDataCustomer}
                    isCustomerPage={true}
                  ></TransactionPoup>

                  <div className="bottom-popup">
                    <div className="button-container">
                      <div onClick={this.handleShowPopupOwe} className="button-cent style3">
                        <span><i className="fas fa-times"></i></span>Đóng
                      </div>
                    </div>
                  </div>
                </div>
                <div onClick={this.handleShowPopupOwe} className="overlay"></div>
              </div>
            </div>
            : ''}
          <div className="fixed-content-wrapper customer-list-container">

            {/* Start Column 1 */}
            <div className="fixed-two-col-offset-1">
              <div className="panel panel-flat max-height">
                <div className="panel-heading">
                  <div className="customer-block-title select-inside-heading">
                    {this.state.widthScreen >= 768 ? "Đơn Hàng" : ""}
                    {this.state.currentPos.order_code ? <span style={{ "color": "#2196F3" }}> #{this.state.currentPos.order_code}</span> : null}
                    <div className="dropdown mega-menu mega-menu-wide active">
                      {this.state.widthScreen <= 1199 ?
                        <button className="btn btn-primary pull-right btn-xs add-more-item" onClick={() => this.setState({ openService: true })}>
                          Thêm dịch vụ
                        </button>
                      : null}
                      <button className="btn btn-primary pull-right btn-xs" onClick={() => this.setState({ showListPos: !this.state.showListPos, currentPage: "index" })}>
                        {this.state.statusUpdateOrder == "" ?
                          this.state.listPos.length > 0 ?
                            <div>
                              {this.state.currentPage == "customer" && this.state.orderSelected.id == "" ?
                                "Đang tạo đơn hàng mới..."
                                : this.state.orderSelected.id !== "" ?
                                  this.state.widthScreen >= 768 ? "#" + this.state.currentPos.order_code + " - " + this.state.orderSelected.customers.full_name : "#" + this.state.currentPos.order_code 
                                  : "Chưa chọn hóa đơn"}
                            </div>
                            : "Không có hóa đơn"
                          : <div>{this.state.statusUpdateOrder}</div>}
                      </button>
                      
                      {this.state.showListPos ?
                        <div className="dropdown-content">
                          <div className="dropdown-content-body">
                            <span className="menu-heading underlined ">Các đơn hàng chờ đang thanh toán</span>
                            <div className="customer-block-filter small-inline-filter mb-10" style={{ "display": "flex" }}>
                              <div style={{ "width": "100px", "lineHeight": '30px' }}>Tìm kiếm:</div>
                              <input className="search-input form-control input-xs" onChange={this.searchPosByCode} style={{ "width": "calc(100% - 100px)" }} placeholder="Tìm kiếm mã hóa đơn" />
                            </div>
                            <ul className="menu-list has-children dd-menu no-margin">
                              {this.state.listPos.map((data, key) => (
                                data.order_code.toLowerCase().includes(this.state.keywordSearchPos.toLowerCase()) ?
                                  <li className="dd-parent ng-scope" key={key} onClick={(event) => this.changeCurrentPos(data.order_code)}>
                                    {data.order_code.includes(this.state.keywordSearchPos)}
                                    {data.id === this.state.currentPos.id ? <span className="check"><i className="fas fa-check"></i></span> : null}
                                    <span className="code">#{data.order_code}</span>
                                    <span className="name">{data.customers.full_name}{data.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</span>
                                    <span className="date">{convert.convertDateTime(data.order_at)}</span>
                                  </li>
                                : null
                              ))}
                            </ul>
                          </div>
                          <div className="overlay-order-list" onClick={() => this.setState({ showListPos: !this.state.showListPos })}></div>
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="customer-block-details no-margin pos-customer-info">
                    {this.state.orderSelected.status == '4' && this.state.orderSelected.customers ?
                      <div className="information-order-custome">
                        <div className="customer-avatar-square-small" onClick={() => this.setState({ openTypeAvatar: true })}>
                          {this.state.orderSelected.customers.avata_url ?
                            <img src={this.state.orderSelected.customers.avata_url} alt="" />
                            : <Avatar name={this.state.orderSelected.customers.full_name} size="60" textSizeRatio={1.75} />}
                        </div>
                        <div className="right-block">
                          <div className="name-customer">
                            {this.state.orderSelected.customers.full_name}
                            {this.state.orderSelected.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                            {this.state.orderSelected.customers?.waxing_type >= 1 && this.state.orderSelected.customers?.status_head_waxing === 0 ? <span style={{fontSize: '10px', color: "#999"}}>(Chưa tặng đầu triệt)</span> : ""}
                          </div>
                          <div className="phone-customer"><i className="fas fa-phone-alt"></i> {this.state.orderSelected.customers.mobile}</div>
                          {this.state.orderSelected.customers.ranking ?
                            <div className="ranking"><i className="fas fa-trophy"></i> {generalData.optionsRanking[this.state.orderSelected.customers.ranking - 1].label}</div>
                            : null}
                          <div className="data-money">
                            {this.state.totalOwedOrder > 0 ?
                              <div style={{ cursor: "pointer" }} onClick={this.handleShowPopupOwe} className="owe">Nợ: <span>{convert.convertCurrency(this.state.totalOwedOrder)}</span>
                                <i className="fas fa-pencil-alt ml-1"></i>
                              </div>
                            : null}
                            {this.state.orderSelected.customers.deposit_money > 0 ?
                              <div className="deposit">Tài khoản: {convert.convertCurrency(this.state.orderSelected.customers.deposit_money)}</div>
                              : null}
                          </div>
                          <button className="button-edit" onClick={() => this.handleChangeForm({ target: { name: "currentPage", value: "customer", title: "true" } })}>
                            <i className="fas fa-pencil-alt"></i>
                          </button>
                          {![1,2].includes(this.state.currentPos.type) ?
                              <button className="button-edit" style={{right: '36px', background: "#fb8a25"}} onClick={() => this.handelEditCredit(0)}>
                              <i className="fas fa-tools"></i>
                            </button>
                          : null}
                        </div>
                      </div>
                      : ""}
                  </div>
                  <div className="order-cashier">
                    <span className="text-grey ">Đặt lịch từ: </span>
                    <span className="pull-right">
                      {this.state.currentPos.source_from}
                    </span>
                    <br />
                    <span className="text-grey ">Ngày tạo hóa đơn:</span>
                    <div className="pull-right ng-pristine ng-untouched ng-valid  ng-isolate-scope ng-not-empty">{convert.convertDateTime(this.state.orderSelected.order_at)}</div>
                    <br />
                    {/* <span className="text-grey">Giờ vào/ra:</span>
                    <div className="pull-right ng-hide" aria-hidden="true">
                      <span className="pull-right has-tooltip-info"></span>
                    </div>
                    <br/> */}
                    <span className="text-grey ">N.viên tạo hóa đơn:</span>
                    <span className="pull-right has-tooltip-info">
                      <drop-down-user user-id="3321" className="ng-isolate-scope">
                        <div data-toggle="dropdown" aria-expanded="false" className="">{this.state.currentPos.created_name}</div>
                      </drop-down-user>
                    </span>
                    <br />
                    <span className="text-grey ">N.viên thu ngân:</span>
                    <span className="pull-right has-tooltip-info">
                      <drop-down-user user-id="3321" className="ng-isolate-scope">
                        <div data-toggle="dropdown" aria-expanded="false" className="">{this.state.userAdmin.name}</div>
                      </drop-down-user>
                    </span>
                  </div>
                  <div className="order-details-item-list-wrapper">
                    <table className="table order-items table-striped">
                      <thead>
                        <tr>
                          <th>Sản phẩm &amp; dịch vụ</th>
                          <th className="text-center">Số lượng</th>
                          <th className="text-center">Nhân viên</th>
                          <th className="text-right">Thành tiền</th>
                          <th className="text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {(Object.keys(this.state.itemSelected).length > 0) ?
                          Object.entries(this.state.itemSelected).map((item, key) => (
                            <PosOrderItemList
                              key={key}
                              itemSelected={this.state.itemSelected}
                              onUpdateItems={this.handleSelectItem}
                              commission={this.state.commission}
                              listOperator={this.state.listOperator}
                              disableCheckboxService={this.state.disableCheckboxService}
                              disableCheckboxConsultant={this.state.disableCheckboxConsultant}
                              item={item[1]}
                              statusActionItem={this.state.statusActionItem}
                              handleChangeState={this.handleChangeState}
                              services={this.state.listPackage}
                              isDeposit={this.state.isDeposit}
                              isAscOperator={this.state.isAscOperator}
                              handleSortName={this.handleSortName}
                              typeOrder={this.state.currentPos.type}
                              waxing_head={this.state.waxing_head}
                            />
                          ))
                          : null}
                      </tbody>
                      {this.state.statusUpdateOrder !== "" ? <tfoot className="overlay-loading"><tr><td><Spin spinning={true} /></td></tr></tfoot> : null}
                    </table>
                  </div>
                  <PosOrderTotal
                    socket={this.socket}
                    notifications={notifications}
                    resetDataNewOrder={this.resetDataNewOrder}
                    currentPos={this.state.currentPos}
                    totalAmountUsingUpsale= {this.state.totalAmountUsingUpsale}
                    itemSelected={this.state.itemSelected} 
                    orderSelected={this.state.orderSelected}
                    updateDiscountByRule={this.updateDiscountByRule}
                    combos={this.state.combos}
                    setLimitPaidOrder={this.setLimitPaidOrder}
                    updateDiscountByCombo={this.updateDiscountByCombo}
                    statusDeleteOrder={this.statusDeleteOrder} 
                    paymentOrder={this.paymentOrder}
                    tabSelect={this.state.tabService}
                    editCustomerOrderEdit = {this.editCustomerOrderEdit}
                    showListHanet= {this.state.showListHanet}
                    setOpenPopupHanet= {this.setOpenPopupHanet}
                    checkChangeOrder= {this.state.checkChangeOrder}
                    funcSaveOrder={this.funcSaveOrder}
                    waxing_upgrade={this.state.waxing_upgrade}
                  />
                </div>
              </div>
            </div>

            {/* Start Column 2 */}
            {this.state.widthScreen >= 1200 || this.state.openService ?
              <div>
                <div className="fixed-two-col-offset-2">
                  {(this.state.currentPage === 'index') ? (
                    <div className="panel panel-flat"  style={{position: "relative"}}>
                      {/* Heading */}
                      <div className="panel-heading">
                        <div style={{ "marginTop": "-5px", "marginBottom": "-5px" }}>
                          <form className="ng-pristine ng-valid">
                            <div className="input-group" data-toggle="tooltip" title="Phím tắt F3">
                              <span className="input-group-addon" style={{ width: "30px" }}>
                                <i className="fa-solid fa-barcode"></i>
                              </span>
                              <input id="search-product-input"
                                name="serviceSearchString"
                                value={this.state.serviceSearchString}
                                onChange={this.handleChangeForm}
                                className="search-input customer-block-filter form-control input-xs ng-pristine ng-valid ng-empty ng-touched"
                                placeholder="Tìm kiếm theo tên hoặc mã sản phẩm &amp; dịch vụ" />
                            </div>
                          </form>
                        </div>
                      </div>

                      {/* Body */}
                      <div className="panel-body">

                        {/* Categories Section */}
                        <div style={{ "display": "flex" }}>
                          <div className="btn-group btn-group-justified mb-10 mt-10 change-service" style={{ "width": "100%" }}>
                            <div className="btn-group" onClick={this.state.isDeposit === false ? () => this.changeServiceList(2, 3) : () => { }}>
                              <div className={this.state.isDeposit === true ?
                                "btn btn-xs btn-disable"
                                : this.state.tabService === 2 ? "btn btn-xs btn-primary" : "btn btn-xs btn-default"}>
                                <i className="icon-vcard position-left"></i> Thẻ dịch vụ
                              </div>
                            </div>
                            <div className="btn-group" onClick={this.state.isDeposit === false ? () => this.changeServiceList(3, 2) : () => { }}>
                              <div className={this.state.isDeposit === true ?
                                "btn btn-xs btn-disable"
                                : this.state.tabService === 3 ? "btn btn-xs btn-primary" : "btn btn-xs btn-default"}>
                                <i className="icon-price-tags position-left"></i> Sản phẩm
                              </div>
                            </div>
                            
                            {/* {this.state.currentPos?.source_from == "Website" ? */}
                              <div className="btn-group" onClick={this.state.isDeposit === false ? () => this.changeServiceList(1, 1) : () => { }}>
                                <div className={this.state.isDeposit === true ?
                                  "btn btn-xs btn-disable"
                                  : this.state.tabService === 1 ? "btn btn-xs btn-primary" : "btn btn-xs btn-default"}>
                                  <i className="icon-furniture position-left"></i> Dịch vụ
                                </div>
                              </div>
                            {/* : null} */}
                            
                            {/* <div className="btn-group" onClick={this.state.isDeposit === false ? () => this.changeServiceList(5) : () => { }}>
                              <div className={this.state.isDeposit === true ?
                                "btn btn-xs btn-disable"
                                : this.state.tabService === 5 ? "btn btn-xs btn-primary" : "btn btn-xs btn-default"}>
                                <i className="icon-vcard position-left"></i> Combo
                              </div>
                            </div> */}

                            <div className="btn-group" onClick={this.state.isDeposit === false && this.state.dataQuantity == 0 ? () => this.changeServiceList(4) : () => { }}>
                              <div className={this.state.isDeposit === false && this.state.dataQuantity > 0 ?
                                "btn btn-xs btn-disable"
                                : (this.state.tabService === 4) ? "btn btn-xs btn-primary" : "btn btn-xs btn-default"}>
                                <i className="icon-vcard position-left"></i> Thẻ tiền
                              </div>
                            </div>

                          </div>
                        </div>

                        {/*  */}
                        {this.state.tabService !== 4 ?
                          <Box sx={{ bgcolor: 'background.paper' }}>
                            <Tabs
                              value={this.state.value}
                              onChange={this.handleChange}
                              variant="scrollable"
                              scrollButtons
                              allowScrollButtonsMobile
                              aria-label="scrollable force tabs example"
                              sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                  '&.Mui-disabled': { opacity: 0.3 },
                                }
                              }}
                            >
                              <StyledTab label="Tất cả" />
                              {this.state.listServiceGroup.map(serviceGroup => <StyledTab key={serviceGroup.id} label={serviceGroup.name} />)}
                            </Tabs>
                          </Box>
                          : ""}
                        {this.state.listPackage.length > 0 && this.state.tabService === 2 ? (
                          <Spin spinning={this.state.isSelectingPackage}>
                            <div className="list-card-member">
                              <div className="owned-membership-cards-group-title">Thẻ số lần khách hàng đang có (<span>Chọn thẻ để sử dụng</span>)</div>
                              <div className="product-grid">
                                {this.state.orderSelected.type === 3 ?
                                  <div className="text-upsale">Không thể sử dụng thẻ với đơn Up-Down Sale</div>
                                :
                                  this.state.listPackage.map(service => <PosPackagesList
                                    key={service.id}
                                    serviceSearchString={this.state.serviceSearchString}
                                    service={service}
                                    order_id={this.state.orderSelected.id}
                                    order= {this.state.orderSelected}
                                    services={this.state.listPackage}
                                    itemSelected={this.state.itemSelected}
                                    onSelectItem={(this.state.statusUpdateOrder == "") ? this.handleSelectItem : () => { }}
                                    openLoading={this.handleOpenLoading}
                                    statusUpdateOrder={this.handleStatusUpdateOrder}
                                    updateSelectingPackage={this.updateSelectingPackage}
                                  />)
                                }
                              </div>
                              <div className="selling-membership-cards-group-title">THẺ BÁN CHO KHÁCH HÀNG</div>
                            </div>
                          </Spin>
                        ) : null}
                        <Spin spinning={this.state.isSelecting}>
                          <div className="product-grid" id={this.state.tabService}>
                            {this.state.listServices.map((service, key) => (
                              (this.state.orderSelected.customers.waxing_type === 0) ?
                                service.new_waxing !== 3 ?
                                  <PosServicesList
                                    key={key}
                                    serviceSearchString={this.state.serviceSearchString}
                                    serviceGroup={this.state.listServiceGroup[this.state.value - 1]}
                                    service={service}
                                    updateSelecting={this.updateSelecting}
                                    itemSelected={this.state.itemSelected}
                                    onSelectItem={(this.state.statusUpdateOrder == "") ? this.handleSelectItem : () => { }}
                                    tabService={this.state.tabService}
                                  />
                                 :null 
                              : (this.state.orderSelected.customers.waxing_type >= 1) ?
                                service.new_waxing !== 2 ?
                                  <PosServicesList
                                    key={key}
                                    serviceSearchString={this.state.serviceSearchString}
                                    serviceGroup={this.state.listServiceGroup[this.state.value - 1]}
                                    service={service}
                                    updateSelecting={this.updateSelecting}
                                    itemSelected={this.state.itemSelected}
                                    onSelectItem={(this.state.statusUpdateOrder == "") ? this.handleSelectItem : () => { }}
                                    tabService={this.state.tabService}
                                  />
                                :null 
                              : 
                                service.new_waxing !== 3 ?
                                  <PosServicesList
                                    key={key}
                                    serviceSearchString={this.state.serviceSearchString}
                                    serviceGroup={this.state.listServiceGroup[this.state.value - 1]}
                                    service={service}
                                    updateSelecting={this.updateSelecting}
                                    itemSelected={this.state.itemSelected}
                                    onSelectItem={(this.state.statusUpdateOrder == "") ? this.handleSelectItem : () => { }}
                                    tabService={this.state.tabService}
                                  />    
                                : null  
                            ))}
                          </div>
                        </Spin>
                      </div>
                      {[1,2,4].includes(this.state.currentPos.type) ? <div className="overlay" style={{
                        background: "#000", opacity: 0.4, width: "100%", height: "100%",
                        position: "absolute", left: 0, top: 0, zIndex: 999
                      }}></div> : ''}
                    </div>
                  ) :
                    <div className="panel panel-flat max-height">
                      <PosOrderInformation
                        userAdmin={this.state.userAdmin}
                        currentPage={this.state.currentPage}
                        data={this.state.dataCus}
                        showHideForm={this.handleChangeForm}
                        optionsGender={optionsGender}
                        customStylesOption={customStylesOption}
                        currentPos={this.state.currentPos}
                        orderData={this.state.orderSelected}
                        funcUpdateOrder={this.funcUpdateOrder}
                        onUpdateItems={(this.state.statusUpdateOrder == "") ? this.handleSelectItem : () => { }}
                        itemSelected={this.state.itemSelected}
                        actionOrder={this.actionOrder}
                        keyCity={this.state.keyCity}
                        keyDistrict={this.state.keyDistrict}
                        dataDistrict={this.state.dataDistrict}
                        getDataTransaction={this.getDataTransaction}
                      />
                    </div>
                  }
                </div>
                {this.state.openService ?
                  <div className="overlay-service-pos" onClick={(e) => this.handleChangeForm({ target: { name: "currentPage", value: "index" } })}></div>
                : null}
              </div>
            : null}
          </div>
          {this.state.showPayment ? 
            <Payment2
              socket={this.socket}
              currentPos={this.state.currentPos}
              notifications={notifications}
              isDeposit={this.state.isDeposit} 
              payment_type={this.state.payment_type} 
              dataPrice={this.state.dataPrice > 0 ? this.state.dataPrice : 0} 
              handleChangeState={this.handleChangeState} 
              funcSaveOrder={this.funcSaveOrder} 
              paymentOrder={this.paymentOrder}
              totalAmountUsingUpsale= {this.state.totalAmountUsingUpsale}
              paidAmountBefore = {this.state.currentPos?.paid_amount_before?? 0}
              showPayment={this.state.showPayment} 
              orderSelected={this.state.orderSelected} 
              statusCheckout={this.state.statusCheckout}
              selectOrderItem={this.state.itemSelected}
              tabService={this.state.tabService}
              currentStore={this.state.currentStore}
              discount_by_rule={this.state.discountRule}
              discount_by_total_bill={this.state.amountCombo}
              sale_rule_applied_ids={this.state.sale_rule_applied_ids}
              group_voucher_id={this.state.group_voucher_id}
              total_price_discount_vc={this.state.vcProductPriceItem}
              total_amount_using_upsale={this.state.totalAmountUsingUpsale}
              comboIds={this.state.comboIds}
              checkPayOs={this.checkPayOs}
              qrStore={this.state.qrStore}
              userAdmin={this.state.userAdmin}
              loadingButton={this.state.loadingButton}
            />
          : null}

          {this.state.showDelete ? <Delete dataOrder={this.state.currentPos} deleteOrder={this.deleteOrder} statusDeleteOrder={this.statusDeleteOrder} showDelete={this.state.showDelete} /> : null}
          {this.state.printOrder ? <PrintSuccessOrder status={this.state.printOrder} currentPos={this.state.dataSuccessOrder} transaction={this.state.dataSuccessOrder.transaction} handleChangeState={this.handleChangeState} /> : null}
          {this.state.showEditCredit  ? 
            <EditCredit
            show={this.state.showEditCredit}
            typeCredit={this.state.typeCredit}
            changeCurrenDeposit={this.changeCurrenDeposit}
            detailCustomer={this.state.orderSelected.customers}
            listPackage={this.state.listPackage}
            onHandelPopup={this.handelEditCredit}
            saveEditCredit={this.saveEditCredit} />
          : null}
          {this.state.openTypeAvatar ?
            <div className="popup-cent openTypeAvatar">
              <div className="content-popup fadeInDown">
                <div className="head-popup">
                  <div className="title">Chọn cách thức</div>
                  <div className="close" onClick={() => this.setState({openTypeAvatar: false})}>×</div>
                </div>
                <div className="body-popup">
                  <div className="button-container">
                    <div className="button-cent style" style={{marginBottom: "20px"}} onClick={() => this.setState({showImgCustomer: true, openTypeAvatar: false})}>
                      <span><i className="fas fa-camera"></i></span>Mở camera
                    </div>
                    <div className="button-cent style2" onClick={() => this.setState({showListHanet: true, openTypeAvatar: false})}>
                      <span><i className="fas fa-list"></i></span>Danh sách ảnh chụp từ camera Hanet
                    </div>
                  </div>
                </div>
                <div className="bottom-popup">
                  <div className="button-container">
                    <div className="button-cent style3" onClick={() => this.setState({openTypeAvatar: false})}>
                      <span><i className="fas fa-times"></i></span>Đóng
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlay" onClick={() => this.setState({openTypeAvatar: false})}></div>
            </div>
          :null } 
          {this.state.showImgCustomer ?
            <ViewImgCustomer
            show={this.state.showImgCustomer}
            close={this.setShowImgCustomer}
            customer={this.state.orderSelected.customers}
            changeCurrenAvatar={this.editCustomerOrderEdit} />
          : null}
       
        </div>
      );
    } else return null;
  }
}
export default Pos;
