import { useState } from "react";
import axiosService from "../../utils/axios.service";
import moment from "moment"
import Select from 'react-select';
import DatePicker from 'rsuite/DatePicker';
import * as convert from "../constant/Convert.helper"
import * as generalData from "../constant/GeneralData"
import Avatar from 'react-avatar';
import _ from 'lodash';
import location from "../../context/vietnam.json";
import {
  optionsGender, optionsHoursBooking,
  optionsMinuteBooking, customStylesBooking,
  customStylesFilter, optionsStatusBooking,
  optionsSourceBooking, optionsViewOrderRemover
} from '../constant/GeneralData'
import { customStylesOption, customStylesMultiSelect } from '../constant/GeneralData'



export function ViewBooking(props) {

  const bookDate = new Date(props.booking.book_date)
  var hiddenPopup = () => {
    props.setShow()
  }

  return (
    <div>
      <div className="head-popup">
        <div className="title">
          <span>Lịch hẹn</span>
          <div className="create-at more-info">Tạo lúc: {convert.convertDateTime(props.booking.created_at)}</div>
          <div className="create-by more-info">Tạo bởi: <span>{ props.booking.root == 3 ? <span>{props.booking.customers.full_name} {props.booking.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</span> : props.booking.createdBy.name}</span></div>
          <div className="edit-at more-info">Sửa lần cuối lúc: {convert.convertDateTime(props.booking.updated_at)}</div>
          <div className="edit-by more-info">Sửa lần cuối bởi: <span>{props.booking.updatedBy.name}</span></div>
        </div>
        <div className="close" onClick={hiddenPopup}>×</div>
      </div>
      <div className="body-popup">
        <div className="row">
          <div className="col-md-12 col-lg-10">
            <div className="items-data row">
              <div className="col-3 col-md-2">Mã đặt lịch</div>
              <div className="value col-3 col-md-3 col-lg-2 code" style={{ "color": "#2196F3", "fontSize": "12px" }}>{props.booking.book_code}</div>
              <div className="value col-md-1 col-lg-4"></div>
              <div className="value col-12 col-md-6 col-lg-4">
                <div className="booking-type">
                  <div className={(props.booking.status === 1) ? "item-type active" : "item-type"}>Pre Order</div>
                  <div className={(props.booking.status === 2) ? "item-type active" : "item-type"}>Đặt lịch trước</div>
                  <div className={(props.booking.status === 3) ? "item-type active" : "item-type"}>Đến trực tiếp</div>
                </div>
              </div>
            </div>
            <div className="items-data row">
              <div className="title col-3 col-md-2">Khách hàng</div>
              <div className="old-customer col-9 col-md-10">
                <div className="row">
                  <div className="col-3 col-md-2 avatar view-avatar-booking">
                    {props.booking.customers.avata_url ?
                      <img src={props.booking.customers.avata_url} alt="" />
                      : <Avatar name={props.booking.customers.full_name} size="60" textSizeRatio={1.75} round="50%" />}
                  </div>
                  <div className="col-9 col-md-10 info-customer">
                    <span className="gender">{props.booking.customers.gender === 1 ? "Anh" : "Chị"}</span>
                    <span className="name">{props.booking.customers.full_name}</span>
                    {props.booking.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                    {props.booking.customers.ranking ?
                      <div className="ranking"><i className="fas fa-trophy"></i>{generalData.optionsRanking[props.booking.customers.ranking - 1].label}</div>
                      : null}
                    {props.booking.customers.mobile ?
                      <div className="phone"><i className="fas fa-phone-square"></i>{props.booking.customers.mobile}</div>
                      : null}
                    {props.booking.customers.district || props.booking.customers.city ?
                      <div className="address"><i className="fas fa-map-marker-alt"></i>
                        {props.booking.customers.district}
                        {(props.booking.customers.district && props.booking.customers.city) ? "-" : null}
                        {props.booking.customers.city}
                      </div>
                      : null}
                    <div className="address"><i className="fas fa-birthday-cake"></i>
                      {`${props.booking.customers.day_birthday > 9 ? props.booking.customers.day_birthday : `0${props.booking.customers.day_birthday}`}/${props.booking.customers.month_birthday > 9 ? props.booking.customers.month_birthday : `0${props.booking.customers.month_birthday}`}/${props.booking.customers.year_birthday}`}
                    </div>
                    {props.booking.customers.note && props.booking.customers.note != '' ?
                      <div className="note">{props.booking.customers.note}</div>
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="items-data row border-top pdt-10">
              <div className="title col-3 col-md-2">Giờ hẹn</div>
              <div className="value col-9 col-md-10">
                <div className="row">
                  <div className="col-6 col-md-3 col-lg-2">
                    <Select className="select"
                      isSearchable={false}
                      styles={customStylesOption} options={optionsHoursBooking}
                      isDisabled={true}
                      defaultValue={optionsHoursBooking[(bookDate.getHours() - 9)]} />
                  </div>
                  <div className="col-6 col-md-3 col-lg-2">
                    <Select className="select"
                      isSearchable={false} options={optionsMinuteBooking}
                      styles={customStylesOption}
                      isDisabled={true}
                      defaultValue={optionsMinuteBooking[(bookDate.getMinutes() / 5)]} />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <DatePicker
                      style={{ position: "relative", top: "-2px", zIndex: 1 }}
                      className="custom-date-picker"
                      oneTap
                      format={'dd-MM-yyyy'}
                      placement={'bottomStart'}
                      defaultValue={bookDate}
                      size="sm"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="items-data row">
              <div className="title col-3 col-md-2">Thời gian dự kiến</div>
              <div className="value col-4 col-md-2">
                <input disabled={true} className="right-text-input none-border-right" pattern="[0-9]*" value={props.booking.intend_time} type="text" />
                <span className="measure">phút</span>
              </div>
            </div>
            <div className="items-data row">
              <div className="title col-3 col-md-2">Ghi chú</div>
              <div className="value col-9 col-md-10">
                <textarea disabled={true} type="text" defaultValue={props.booking.description} />
              </div>
            </div>
            <div className="items-data row">
              <div className="title col-3 col-md-2">Số lượng khách</div>
              <div className="value col-9 col-md-2">
                <div className="row">
                  <div className="col-6">
                    <span>{props.booking.booking_item.length}</span>
                  </div>
                </div>
              </div>
              <div className="title col-3 col-md-2">Tại chi nhánh</div>
              <div className="value col-9 col-md-6 col-lg-4">
                    <span>{props.booking.stores.name_store}</span>
              </div>
            </div>
            <div className="items-data row border-top">
              <div className="title col-3 col-md-2 md-10">
                <div className="bold">Khách hàng</div>
              </div>
              <div className="title col-5 d-none d-md-block bold">Dịch vụ</div>
              <div className="title col-5 d-none d-md-block bold">Nhân viên phục vụ</div>
            </div>
            {props.booking.booking_item.map((item, key) => (
              <div className="items-data row item-more-customer" key={key}>
                <div className="value col-12 col-md-2">KHÁCH {key + 1} <span className={(item.intend_time > 0) ? "count-time active" : "count-time"}>(<i className="far fa-clock"></i> {item.intend_time} phút)</span></div>
                <div className="title col-3 d-block d-md-none bold md-10">Dịch vụ</div>
                <div className="value col-9 col-md-5 service md-10">
                    { item.product_ids.map((item, index) => (
                      <p key={index}>- {item.product_name ? item.product_name : item.name}</p>
                    ))}

                </div>
                <div className="title col-3 d-block d-md-none bold md-10">Nhân viên phục vụ</div>
                <div className="value col-9 col-md-5 user md-10">
                  {item.user_ids.map((item, index) => (
                    <p key={index}>- {item.name}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="col-md-12 col-lg-2">
            <div className="item-sidebar status">
              <div className="label-sidebar">Trạng thái</div>
              <div className="value-sidebar edit-popup">
                <div className={"list-items"}>
                  {optionsStatusBooking.map((data, key) => (
                    <div key={key} className={(props.booking.book_status === data.value) ? "item active" : "item"}>
                      <span className={"small-circle status-" + data.value}></span>{data.label}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="item-sidebar source">
              <div className="label-sidebar">Đặt lịch từ</div>
              <div className={"value-sidebar"}>
                {optionsSourceBooking.map((data, key) => (
                  <div key={key} className={(props.booking.source_from === data.value) ? "item active" : "item"}>{data.label}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-popup border-top">

        <div className="button-container">
          <div className="button-cent style3" onClick={hiddenPopup}>
            <span><i className="fas fa-times"></i></span>Đóng
          </div>
        </div>
      </div>
    </div>
  );
}