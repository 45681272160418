import { message,Divider } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Add from "./Infor";
import styles from "./style.module.css"
import axiosService from "../../utils/axios.service";
import { useParams } from "react-router-dom";
import ChangeLanguage from "./ChangeLanguage";


export default function UpdateVoucher() {
    const [voucher, setVoucher] = useState(null)
    let { id } = useParams();
    useEffect(async () => {
        try {
            if (id) {
                const res = await axiosService(`api/vouchers/${id}`, "GET")
                const { data } = res
                if (data.code === 200) {
                    setVoucher(data.data)
                } else {
                    message.error("Đã có lỗi xảy ra")
                }
            }
        } catch (error) {
            console.error(error)
            message.error("Đã có lỗi xảy ra")
        }
    }, [])
    const updateVoucher = (data)=>{
        setVoucher(data)
    }
    return (
        <Container fluid className={`${styles.wrapperVoucher}`}>
         <ChangeLanguage />
            <h4>
                {"Update voucher"}
            </h4>
            <Divider />
            <Add voucher={voucher} styles={styles} updateVoucher={updateVoucher} />
        </Container>
    )
}