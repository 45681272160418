import * as React from "react";
import "./scss/pos.scss";
import * as convert from "../constant/Convert.helper"
import { formatValue } from 'react-currency-input-field';

export class TemplatePrintOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      checked: false,
    };
  }

  countPackage = () => {
    let countPackage = 0;
    this.props.currentPos.orderItem.map((item) => {
      if (item.package_code) countPackage++
    })
    return countPackage
  }

  countUseLeftPackage = (data) => {
    let currentUse = data.quantity,
        leftUse;
    if (this.props.transaction.length > 0) currentUse = 0;

    leftUse = Number(data.package.max_used) - Number(data.package.count_used) - Number(currentUse)
    
    return leftUse;
  }
  render() {
    return (
      <div className="print-order">
        <style type="text/css" media="print">
          {"\
            @page { size: portrait; }\
          "}
        </style>
        <div className="header">
          <img src="/images/header/logo-store-header.jpeg" alt="logo" />
          <div className="title">
            <b>HÓA ĐƠN DỊCH VỤ</b>  
          </div>
          {this.props.transaction.length > 0 ? '(Đã thanh toán)' : '(Chưa thanh toán)'}
        </div>
        <div className="info-cus">
          <p>
            <label>MÃ HD:</label>
            {this.props.currentPos.order_code}
          </p>
          <p>
            <label>Ngày:</label>{" "}
            {convert.convertDateTime(this.props.currentPos.order_at)}
          </p>
          <p>
            <label>K.Hàng:</label>{" "}
            <b>{this.props.currentPos.customers.full_name}</b>
          </p>
          <p>
            <label>SĐT:</label>{" "}
            <b>{this.props.currentPos.customers.mobile}</b>
          </p>
        </div>

        <table>
          <thead className="head">
            <tr>
              <th style={{width: "8%"}}></th>
              <th style={{width: "12%"}} className="text-right"><b>SL</b></th>
              <th style={{width: "26%"}} className="text-right"><b>Giá</b></th>
              <th style={{width: "24%"}} className="text-right"><b>Giảm</b></th>
              <th><b>T.Tiền</b></th>
            </tr>
          </thead>
          
          {this.props.currentPos.orderItem.map((item, key) => (
            <tbody key={key}>
              <tr className="item">
                <td colSpan="5" style={{ border: "none" }}>{item.product_name}</td>
              </tr>
              <tr className="item">
                <td colSpan="2" className="text-right">{item.quantity}</td>
                <td className="text-right">
                  {formatValue({
                    value: String(item.price),
                    groupSeparator: '.',
                    decimalSeparator: '.',
                  })}
                </td>
                <td className="text-right">
                  {formatValue({
                    value: String(item.discount),
                    groupSeparator: '.',
                    decimalSeparator: '.',
                  })}
                </td>
                <td className="text-right">
                  {formatValue({
                    value: String((Number(item.quantity) * Number(item.price)) - Number(item.discount)),
                    groupSeparator: '.',
                    decimalSeparator: '.',
                  })}
                </td>
              </tr>
            </tbody>
          ))}
          <tfoot>
            <tr className="bottom">
              <td colSpan="3">Thành tiền</td>
              <td colSpan="2">{convert.convertCurrency(this.props.currentPos.total_price_before ?? 0)}</td>
            </tr>
            <tr className="item-price price" style={{fontSize: "14px"}}>
              <td colSpan="3"><b>Tổng cộng</b></td>
              <td colSpan="2" className="text-right"><b>{convert.convertCurrency(this.props.currentPos.total_price)}</b></td>
            </tr>
            <tr className="item-price customer">
              <td colSpan="5" className="title"><b>Khách đưa</b></td>
            </tr>
            {this.props.transaction ?
              this.props.transaction.map((item, key) => (
                <tr key={key}>
                  <td colSpan="3">{item.pay_type}</td>
                  <td colSpan="2" className="text-right">{convert.convertCurrency(item.paid_amount)}</td>
                </tr>
              ))
            : null}
            <tr>
              <td colSpan="5">Đơn vị tính: VND</td>
            </tr>
            <tr>
              <td colSpan="5" className="title"><b>Tình trạng thẻ sau khi thanh toán:</b></td>
            </tr>
            {this.countPackage() > 0 ?
                this.props.currentPos.orderItem.map((item, key) => (
                  item.package_code ? 
                    <tr className="item" key={key} style={{paddingTop: "5px", paddingBottom: "5px"}}>
                      <td colSpan="5">
                        {item.product_name} (#{item.package_code}):
                        <span className="pull-right">
                        {item.max_used > 1000 ? 
                          "không giới hạn"
                        : 
                          ((item.last_used_count || 0) + ' lần')
                        }
                        </span>
                      </td>
                    </tr>
                  : null
                ))
            : null} 
          </tfoot>
        </table>
      </div>
    );
  }
}

export default TemplatePrintOrder;
