import * as React from "react";
import * as convert from "../constant/Convert.helper"
import { Row, Col } from "react-bootstrap"

export class PrintTransactionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      checked: false,
    };
  }

//   countPackage = () => {
//     let countPackage = 0;
//     this.props.currentPos.orderItem.map((item) => {
//       if (item.package_code) countPackage++
//     })
//     return countPackage
//   }
  render() {
    return (
      <div className="print-order print-transaction-detail">
        <div className="header">
          <div className="title">
            <b>Cent Beauty Corner</b>  
          </div>
        </div>
        <div className="mt-2 transaction-item-detail">
                <p><label style={{"width":"120px"}}>Chi Nhánh:</label> <span>{this.props.transactionItem.store.name_store}</span> </p>
                <p><label style={{"width":"120px"}}>Địa chỉ: </label> <span>{this.props.transactionItem.store.address} </span> </p>
        </div>
        <div className="transaction-item">
            <p className="title">Phiếu Thu</p>
            <p>Mã phiếu: {this.props.transactionItem.transaction_code}</p>
            <p>Ngày: {convert.convertDate(this.props.transactionItem.created_at)}</p>
        </div>
        <div className="mt-2 transaction-item-detail">
           
                <p><label style={{"width":"120px"}}>Khách Hàng: </label> <span>{this.props.customer.full_name} </span> </p>
                <p><label style={{"width":"120px"}}>Lý do nộp:</label> <span>Trả nợ hoá đơn <b>{this.props.order.order_code}</b>, vào ngày {convert.convertDate(this.props.order.order_at)} áp dụng cho dịch vụ/sản phẩm
                    {this.props.order.orderItem.map((item) => (
                        item.product.product_name + ','
                    ))}
                    </span> </p>
                <p><label style={{"width":"120px"}}>Loại thanh toán:</label> <span>{this.props.transactionItem.pay_type} </span> </p>
                <p><label style={{"width":"120px"}}>Số tiền: </label> <span>{convert.convertCurrency(this.props.transactionItem.paid_amount)} </span> </p>
                <p><label style={{"width":"120px"}}>Bằng chữ: </label> <span style={{"text-transform": "capitalize"}}>{convert.to_vietnamese(this.props.transactionItem.paid_amount)} đồng</span> </p>
                <p><label style={{"width":"120px"}}>Còn nợ:</label> <span>{convert.convertCurrency(this.props.transactionItem.old_owed)}</span> </p>
            

        </div>
        <Row className="mt-2">
            <Col xs={2}></Col>
            <Col xs={2}>
                <div className="customer-user">
                    <p>
                        <label>Khách hàng</label>
                    </p>
                    <p>
                        <label>(Ký, họ tên)</label>
                    </p>
                    <p>
                        <label>----------------</label>
                    </p>
                </div>
            </Col>
            <Col xs={4}></Col>
            <Col xs={2}>
                <div className="customer-user">
                    <p>
                        <label>Người lập phiếu</label>
                    </p>
                    <p>
                        <label>(Ký, họ tên)</label>
                    </p>
                    <p>
                        <label>----------------</label>
                    </p>
                </div>
            </Col>

            <Col xs={2}></Col>
        </Row>
        
      </div>
    );
  }
}

export default PrintTransactionItem;
