import React, { useState, useEffect,useCallback } from "react";
import styles from "../../styles/popupInfor.module.css"
import { format, formatDuration, intervalToDuration } from "date-fns"
import axiosService from "../../utils/axios.service";
import { listStatusBooking } from "../../enums/bookinngStatus.enum";
import { listSourceBooking } from "../../enums/sourceFrom.enum"
import { Row, Col,Container } from "react-bootstrap"
import { Spin } from "antd"

const IncomingOrder = ({ customer }) => {
  const [orders, setOrders] = useState([])
  const getData = async () => {
    const res = await axiosService(`api/customer/order/${customer.id}`, "GET");
    const { data } = res
    if (data.code === 200) {
      const { dataUpcomingBooking } = data.data
      setOrders(dataUpcomingBooking.Data)
    }
  }
  useEffect(async () => {
    await getData()
  }, [])
  const formatDurationDate = (date) =>{
    let duration = intervalToDuration({
      start: new Date(),
      end: new Date(`${date}`),
    })
    
   let  string = (duration.years ? duration.years + " năm " : "") + 
      (duration.months? (duration.months + " tháng ") : "") + 
      (duration.days? (duration.days + " ngày ") : "") + 
      (duration.hours? (duration.hours + " giờ ") : "") +
      (duration.minutes ?(duration.minutes + " phút ") : "") +
      (duration.seconds ?(duration.seconds + " giây ") : "");
    return string

    }
  return (
 
    <Container className={styles.scrollTransaction}>
      {orders.length > 0  ? 
      orders.map((x, i) => {
        if (i === (orders.length - 1)){
          return (
              <Row key={x.id}>
                <Col xs={6}>
                  <div>
                    <p className={styles.textCountTime}>
                      {format(new Date(x.book_date), 'HH:mm dd/MM/yyyy')} (Còn {formatDurationDate(x.book_date)} )
                    </p>
                  </div>
                </Col>
                <Col xs={6} style={{ paddingRight: "3rem" }}>
                  <div>
                    <span className={styles.textIncomingOrder}>
                      {listStatusBooking[x.book_status - 1]}
                    </span>
                    <br></br>
                    <span className={styles.textPlaceBookingIncomingOrder}>
                      {listSourceBooking[x.source_from]}
                    </span>
                  </div>
                </Col>
              </Row>
          )
        }else{
          return (
              <Row key={x.id} style={{ borderBottom: "1px solid #d3dae7" }}>
                <Col xs={6}>
                  <div>
                    <p className={styles.textCountTime}>
                      {format(new Date(x.book_date), 'HH:mm dd/MM/yyyy')} (Còn {formatDurationDate(x.book_date)} )
                    </p>
                  </div>
                </Col>
                <Col xs={6} style={{ paddingRight: "3rem" }}>
                  <div>
                    <span className={styles.textIncomingOrder}>
                      {listStatusBooking[x.book_status - 1]}
                    </span>
                    <br></br>
                    <span className={styles.textPlaceBookingIncomingOrder}>
                      {listSourceBooking[x.source_from]}
                    </span>
                  </div>
                </Col>
              </Row>
          )
        }
      }):"Không có thông tin"}
    </Container>

  )
}
export default IncomingOrder