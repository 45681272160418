import React, { useRef, useState, useCallback, useEffect } from 'react';
import { axiosGet } from "../../utils/axios.service";
import { useReactToPrint } from "react-to-print";
import axiosService from "../../utils/axios.service";
import { isAfter } from "date-fns";
import Select from "react-select";
import { filterByStatus, accountPayment, selectAllElectronic, accountSwipe } from "../constant/GeneralData"
import { formatValue } from 'react-currency-input-field';
import { DatePicker } from "rsuite";
import Delete from "./Delete";
import CurrencyInput from 'react-currency-input-field';
import { notifications } from "../constant/Notify";
import { PrintTransactionItem } from "./PrintTransactionItem";
import _ from 'lodash';
import role from '../../enums/role.enum';
import * as fbEvent from "../constant/fbPixelEvent"
import socketIOClient from "socket.io-client";

var _state = {}
var _props = null;
var isLoadStore = false;
var isLoadOrderCustomerTransaction = false
var currentStore = localStorage.getItem("currentStore") ?? 1

async function requestDataStore() {
    if (_state.setSelectDataStore === undefined) return ''
    isLoadStore = true;
    let response = await axiosGet("api/stores");
    const selectDataStore = []
    if (response.data.code == 200) {
        if (response.data.data !== null) {
            for (const key in response.data.data) {
                selectDataStore.push({
                    value: response.data.data[key].id,
                    label: response.data.data[key].name_store
                })

                if (response.data.data[key].id == currentStore) {
                    _state.setDefaultSelectStore(selectDataStore.length - 1)
                }
            }
            _state.setSelectDataStore(selectDataStore)
        }
    }
    isLoadStore = false;
}

async function getDataOptions () {
    const { data } = await axiosService('api/options?key=qr', "POST", {key: ['qr_store']})
    if (data.data) {
      let qrStore = _.filter(data.data, function(o){ return o.key == 'qr_store' })
      qrStore = qrStore[0].value.split(',')
      qrStore = qrStore.map(Number)
      return qrStore
    }
}

async function requestDataTransactionCustomer(order_id) {
    if (_state.setOrderCustomerTransaction === undefined) return ''
    isLoadOrderCustomerTransaction = true;
    let id = _props.customer.id
    let response = await axiosGet("api/transaction/customer/" + id + '/' + order_id);
    if (response.data.code == 200) {
        _state.setCustomerId(id)
        _state.setOrderCustomerTransaction(response.data.data.data)
    }
    isLoadOrderCustomerTransaction = false;
}

export function TransactionPoup(props) {
    const [showDelete, setShowDelete] = useState(false)
    const [dataTransaction, setDataTransaction] = useState({})

    const [defaultRangeDate, setDefaultRangeDate] = useState((new Date()))
    const [selectDataStore, setSelectDataStore] = useState({})
    const [dataOrderCustomerTransaction, setOrderCustomerTransaction] = useState([])
    const [createdAt, setCreatedAt] = useState(new Date())
    const [payType, setPayType] = useState('Tiền mặt')
    const [payTypeElectronic, setPayElectronic] = useState('Zalo Pay')
    const [payAccount, setPayAccount] = useState('')
    const [paidAmount, setPaidAmount] = useState(0)
    const [customerId, setCustomerId] = useState(0)
    const [hiddenSubmit, setHiddenSubmit] = useState(false)
    const [storeId, setStoreId] = useState(localStorage.getItem("currentStore"))
    const [transactionItem, setTransactionItem] = useState(null)
    const [order, setOrder] = useState(null)
    const [defaultSelectStore, setDefaultSelectStore] = useState(0)
    const [error, setError] = useState('')
    const [depositMoney, setDepositMoney] = useState(props.customer.deposit_money)

    const invoiceRef = useRef(null);
    const [showPrint, setShowPrint] = useState(true)
    const onBeforeGetContentResolve = useRef(null);

    const [warningQr, setWarningQr] = useState(false)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info")))
    const [currentOrderId, setCurrentOrderId] = useState(null)
    const [qrStore, setQrStore] = useState([])
    const socketRef = useRef();
    const [bankingType, setBankingType] = useState(0)
    const [statusBankingType, setStatusBankingType] = useState(false)
    const [isCopyText, setIsCopyText] = useState('')

    useEffect(() => {
        socketRef.current = socketIOClient(process.env.REACT_APP_API_URL_WE);
        return () => {
            socketRef.current.disconnect();
        };
    }, [])

    useEffect(async () => {
        requestDataStore()
        if (userAdmin.role == role.LT) setBankingType(1)
        if (userAdmin.role == role.SALE) setBankingType(2)
        let qrStore = await getDataOptions()
        setQrStore(qrStore)
    }, []);

    _props = props
    _state.customerId = customerId
    _state.setCustomerId = setCustomerId
    _state.selectDataStore = selectDataStore
    _state.setSelectDataStore = setSelectDataStore
    _state.dataOrderCustomerTransaction = dataOrderCustomerTransaction
    _state.setOrderCustomerTransaction = setOrderCustomerTransaction
    _state.storeId = storeId
    _state.setStoreId = setStoreId
    _state.defaultSelectStore = defaultSelectStore
    _state.setDefaultSelectStore = setDefaultSelectStore

    const handleOnBeforeGetContent = useCallback(() => {
        setShowPrint(false)
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            resolve();
        });
    });

    const handleAfterPrint = useCallback(() => {
        setShowPrint(true)
    }, [setShowPrint]);

    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    const printInvoiceT = (item, val) => {
        setTransactionItem(item)
        setOrder(val)
        printInvoice()
    }

    const getDefaultStore = (idStore) => {
        let keyChoose = _.findIndex(selectDataStore, (o) => o.value == idStore);
        return selectDataStore[keyChoose];
    }

    const getDefaultPayment = (option, idStore) => {
        let keyChoose = _.findIndex(option, (o) => o.store == idStore);
        return option[keyChoose];
    }

    let customerProp = _props.customer
    if (customerProp.id === undefined) return ''

    if (!isLoadOrderCustomerTransaction && customerId != customerProp.id) requestDataTransactionCustomer(null)
    if (dataOrderCustomerTransaction[0] === undefined) return ''

    var onChangePayType = (event) => {
        setPayType(event.value)
        if (event.value == "Chuyển khoản") {
            let select = getDefaultPayment(accountPayment, storeId)
            setPayAccount(select.value)
        }
        if (event.value == "Quẹt thẻ") {
            let select = getDefaultPayment(accountSwipe, storeId)
            setPayAccount(select.value)
        }
    }

    var onChangeCreatedAt = (event) => {
        setCreatedAt(event)
    }

    var onChangePaidAmount = (event, item) => {
        setDepositMoney(props.customer.deposit_money)
        let dataPrice = event.target.value.replaceAll(",", "");
        
        if (payType == 'Sử dụng thẻ tiền') {
            setDepositMoney(props.customer.deposit_money - dataPrice)
        }
        let moneyOwed =  item.old_owed
        if (moneyOwed < dataPrice) {
            dataPrice = moneyOwed
        }
        setPaidAmount(Number(dataPrice))
    }

    var onChangeStoreId = (event) => {
        setStoreId(event.value)
    }

    var onChangePayElectronic = (event) => {
        setPayElectronic(event.value)
    }

    var onChangePayAccount = (event) => {
        setPayAccount(event.value)
    }

    var addTransaction = (val) => {
        if (!storeId) return notifications({ type: 'warning', mess: 'Vui lòng chọn chi nhánh' });
        var handlePayType = payType
        if (payType == "Ví điện tử") {
            handlePayType = payTypeElectronic
        }
        var data = {
            "created_at": createdAt,
            "store_id": storeId,
            "order_id": val.id,
            "customer_id": customerId,
            "pay_type": handlePayType,
            "paid_amount": paidAmount,
            "order_code": val.order_code,
        }

        if (handlePayType == 'Chuyển khoản' || handlePayType == 'Quẹt thẻ') data.pay_account_number = payAccount

        if (handlePayType == 'Sử dụng thẻ tiền' && (Number(paidAmount) <= 0 || Number(paidAmount) > props.customer.deposit_money)) {
            notifications({type: 'error', mess: 'Số tiền trong tài khoản không đủ'}); 
            return
        }
        if (hiddenSubmit == true) return
        setHiddenSubmit(true)
        axiosService("api/transaction", "POST", data)
        .then((res) => {
            const response = res.data;
            if (response.message == "success") {
                // props.setShow(false)
                // if ((handlePayType != "Sử dụng thẻ tiền" || handlePayType != "Điểm tích luỹ") && paidAmount > 0) {
                //     let dataPixelEvent = {
                //         price: paidAmount,
                //         mobile: props.customer.mobile,
                //         name: props.customer.full_name
                //     }
                //     fbEvent.purchaseEvent(dataPixelEvent)
                // }

                if (handlePayType == 'Sử dụng thẻ tiền' && props.isCustomerPage && typeof props.isCustomerPage != 'undefined') {
                    props.setDataCustomer(res.data.data.deposit_money)
                    setDepositMoney(res.data.data.deposit_money)
                }
                requestDataTransactionCustomer(data.order_id);
                notifications({type: 'success', mess: 'Trả nợ thành công'});
                setPaidAmount(0)
                setHiddenSubmit(false)
            } else {
                setHiddenSubmit(false)
                notifications({type: 'error', mess: res.data.message });
            }
            if (props.getDataTransaction) props.getDataTransaction(customerId);
        })
        .catch((error) => console.log(error));
    }

    const checkQrCode = async (id, banking_type = null) => {
        if (paidAmount == 0) return notifications({ type: 'warning', mess: 'Vui lòng điền số tiền lớn hơn 0' });
        if (!storeId) return notifications({ type: 'warning', mess: 'Vui lòng chọn chi nhánh' });

        if (!banking_type || banking_type == 2) return syncQrCode(id, 2)

        let res = await axiosService("api/check-qr-code", "POST", { id: id, store_id: storeId });
        if (res.data.success) {
            let countOrder = res.data.data
            if (countOrder === 0) {
                syncQrCode(id, banking_type)
            } else {
                setWarningQr(true)
                setStatusBankingType(false)
            }
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }

    }

    const syncQrCode = async (id, banking_type = null) => {
        setWarningQr(false)
        setStatusBankingType(false)
        let payload = {
            price: paidAmount,
            id: id,
            store_id: storeId,
            pay_type: payType,
            pay_account_number: payAccount,
            store_id: storeId,
            transaction_at: createdAt,
            banking_type: banking_type ?? bankingType
        }
        let res = await axiosService("api/sync-qr-code-debt", "POST", payload);
        if (res.data.success) {
            let transaction = res.data.data
            requestDataTransactionCustomer(id);
            setPaidAmount(0)
            notifications({ type: 'success', mess: 'Cập nhật mã QR thành công' });
            socketRef.current?.emit("client-qr-code", {order: order, status: true, store_id: storeId});
        } else {
          notifications({ type: 'error', mess: res.data.message });
        }
    }

    const chooseTypeBanking = async (data) => {
        setCurrentOrderId(data.id) 
        if (![role.LT,role.SALE].includes(userAdmin.role)) {
            setStatusBankingType(true)
        } else {
            if (role.LT == userAdmin.role) await checkQrCode(data.id, 1)
            if (role.SALE == userAdmin.role) await syncQrCode(data.id, 2)
        }
    }

    const copyText = (text) => {
        setIsCopyText('isCopy')
        navigator.clipboard.writeText(text)
        setTimeout(() => {
          setIsCopyText('')
        }, 1000);
    }

    const checkPayOs = async (data) => {
        let res = await axiosService("api/check-pay-os-debt", "POST", {id: data.id, store_id: storeId})
        if (res.data.success == true) {
            if (res.data.data.mess && res.data.data.mess != "") {
                notifications({ type: 'warning', mess: res.data.data.mess});
                socketRef.current?.emit("client-qr-code", {order: res.data.data.transaction.order, status: true, store_id: storeId});
            } else {
                requestDataTransactionCustomer(res.data.data.transaction.order.id);
                if (props.getDataTransaction) props.getDataTransaction(customerId);
                notifications({ type: 'success', mess: 'Giao dịch đã được xác nhận' });
                socketRef.current?.emit("client-qr-code", {order: null, status: false, store_id: storeId});
            }
        } else {
            notifications({ type: 'warning', mess: res.data.message });
        }
    }

    var deleteTransaction = async () => {
        let res = await axiosService("api/transaction/delete/" + dataTransaction.id, "PUT", { customer_id: customerId });
        if (res.data.success == true) {
            requestDataTransactionCustomer(null);
            hidePopupDelete()
            notifications({ type: 'success', mess: 'Xóa giao dịch thành công' });
            if (props.getDataTransaction) props.getDataTransaction(customerId);
            if (props.isCustomerPage && typeof props.isCustomerPage != 'undefined') {
                props.setDataCustomer(res.data.data.deposit_money)
            }
            setDepositMoney(res.data.data.deposit_money)

        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    var handleDelete = (item) => {
        setShowDelete(true)
        setDataTransaction(item)
    }

    var hidePopupDelete = (event) => {
        setShowDelete(false)
    }
    
    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };

    return (
        <div>
            <div className="head-popup">
                <div className="title">
                    Chi tiết: <span style={{color: '#2196F3'}}>{props.customer.full_name} {props.customer?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</span> 
                    <span style={{color: '#5e6d8c'}}>- Tiền trong tài khoản : {formatValue({value: String(depositMoney), groupSeparator: ',', decimalSeparator: '.', })}</span>
                    <span style={{color: 'red'}}></span>
                </div>
            </div>
            <div className="body-popup">
                {dataOrderCustomerTransaction ?
                    dataOrderCustomerTransaction.map((val, key) => (
                        <div className="order-item-transaction" key={key}>
                            <div className="debt-order-list">
                                <div className="debt-order-list-title">
                                    <ul className="list-title hide-mobile">
                                        <li className="text-bold text-primary font-18">Mã hóa đơn</li>
                                        <li className="text-bold text-primary text-center">Ngày tạo</li>
                                        <li className="text-bold text-primary text-center">Tổng tiền hóa đơn</li>
                                        <li className="text-bold text-primary text-center">Tổng số tiền đã trả</li>
                                        <li className="text-bold text-primary text-center">Tổng số tiền còn nợ</li>
                                        <li className="text-bold text-primary text-right">Người lập hóa đơn</li>
                                    </ul>
                                    <ul className="list-data">
                                        <li className="mobile-table-haft">
                                            <div className="title hide-desktop">Mã hóa đơn: </div>
                                            <div className="value">#{val.order_code}</div>
                                        </li>
                                        <li className="text-center mobile-table-haft">
                                            <div className="title hide-desktop">Ngày tạo: </div>
                                            <div className="value"> {convertDateTime(val.order_at)}</div>
                                        </li>
                                        <li className="text-center mobile-table-haft">
                                            <div className="title hide-desktop">Tổng tiền hóa đơn: </div>
                                            <div className="value">
                                                <span className="cash-vnd text-right"> {val.total_price ? formatValue({
                                                    value: String(val.total_price),
                                                    groupSeparator: ',',
                                                    decimalSeparator: '.',
                                                }) : '0'}</span>
                                            </div>
                                        </li>
                                        <li className="text-info text-center mobile-table-haft">
                                            <div className="title hide-desktop">Tổng số tiền đã trả: </div>
                                            <div className="value">
                                                <span className="cash-vnd text-info text-right"> {val.total_paid_amount ? formatValue({
                                                    value: String(val.total_paid_amount),
                                                    groupSeparator: ',',
                                                    decimalSeparator: '.',
                                                }) : '0'}</span>
                                            </div>
                                            
                                        </li>
                                        <li className="text-danger text-center mobile-table-haft">
                                            <div className="title hide-desktop">Tổng số tiền còn nợ: </div>
                                            <div className="value">
                                                <span className="cash-vnd text-danger text-right"> {val.money_owed ? formatValue({
                                                    value: String(val.money_owed),
                                                    groupSeparator: ',',
                                                    decimalSeparator: '.',
                                                }) : '0'}</span>
                                            </div>
                                            
                                        </li>
                                        <li className="text-right action-transacion mobile-table-haft">
                                            <div className="title hide-desktop">Người lập hóa đơn: </div>
                                            <div className="value"> {val.User ? val.User.name : val.created_name}</div>
                                        </li>
                                    </ul>
                                    <p className="debt-order-item-list">
                                        {val.orderItem.map((item, itemKey) => (
                                            <span key={itemKey} className="tag inline-tag">{item.product ? item.product.product_name : item.product_name}</span>
                                        ))}
                                        <br />
                                    </p>
                                    <ul className="debt-order-history"> 
                                        <li className="item-transacion hide-mobile">
                                            <div className="text-bold date-pay">Ngày thanh toán</div>
                                            <div className="text-bold coupon-code">Mã hoá đơn</div>
                                            <div className="text-bold pay-method">Phương thức</div>
                                            <div className="text-bold text-right payment-amount">Thanh toán</div>
                                            <div className="text-bold text-right owed-money">Còn nợ</div>
                                            <div className="text-bold user-collecter">Người thu</div>
                                            <div className="action-transaction"></div>
                                        </li>
                                        {val.transaction.map((item, itemKey) => (
                                            <li key={itemKey} className="item-transacion">
                                                <div className="date-pay mobile-table">
                                                    <div className="title hide-desktop">Ngày thanh toán</div>
                                                    <div className="value">{convertDateTime(item.created_at)}</div>
                                                </div>
                                                <div className="coupon-code mobile-table">
                                                    <div className="title hide-desktop">Mã hoá đơn</div>
                                                    <div className="value">#{item.transaction_code}</div>
                                                </div>
                                                <div className="pay-method mobile-table">
                                                    <div className="title hide-desktop">Phương thức</div>
                                                    <div className="value">{item.pay_type}</div>
                                                </div>
                                                <div className="text-right payment-amount mobile-table">
                                                    <div className="title hide-desktop">Thanh toán</div>
                                                    <div className="value">
                                                        <span className="cash-vnd text-right">{item.paid_amount ? formatValue({
                                                            value: String(item.paid_amount),
                                                            groupSeparator: ',',
                                                            decimalSeparator: '.',
                                                        }) : '0'} đ</span>
                                                    </div>
                                                    
                                                </div>
                                                
                                                <div className="text-right text-danger owed-money mobile-table">
                                                    <div className="title hide-desktop">Còn nợ</div>
                                                    <div className="value">
                                                        <span className="cash-vnd text-right text-danger">{item.old_owed ? formatValue({
                                                            value: String(item.old_owed),
                                                            groupSeparator: ',',
                                                            decimalSeparator: '.',
                                                        }) : '0'} đ</span>
                                                    </div>
                            
                                                </div>
                                                <div className="user-collecter-value mobile-table">
                                                    <div className="title hide-desktop">Người thu</div>
                                                    <div className="value">{item.user.name}</div>

                                                </div>
                                                {item.status == 2 ?
                                                    <div className="text-right action">
                                                        {item.payment_link_id ?
                                                            item.status_pay_os == '1' ? 
                                                                <button onClick={() => checkPayOs(item)} className="btn btn-xs bg-danger-400 pull-left mr-5 success-qr-bottom" ><i className="fas fa-check"></i></button>
                                                            : 
                                                                <button onClick={() => checkPayOs(item)} className="btn btn-xs bg-danger-400 pull-left mr-5 check-qr-bottom" ><i className="fas fa-qrcode"></i></button>
                                                        : null}
                                                        <button onClick={() => printInvoiceT(item, val)} className="btn btn-xs bg-danger-400 pull-left mr-5 print-botton" > <i className="fas fa-print"></i></button>
                                                        <button onClick={() => handleDelete(item)} className="btn btn-xs bg-danger-400 pull-left remover-botton" ><i className="fa fa-trash"></i></button>
                                                    </div>
                                                : ""}
                                                {item.pay_account_number ?
                                                    <div className="pay-account-item-list">STK: {item.pay_account_number}</div>
                                                : ""}
                                                {item.uuid && item.banking_type == 2 ? 
                                                    <div className="pay-account-item-list">
                                                        Mã QR: 
                                                        <a href={process.env.REACT_APP_QR_LINK + item.uuid} target="_blank"> {process.env.REACT_APP_QR_LINK + item.uuid}</a>
                                                        <button className={isCopyText} style={{marginLeft: '10px', lineHeight: "1.2", background: "#fff"}} onClick={() => copyText(process.env.REACT_APP_QR_LINK + item.uuid)}>Copy</button>
                                                    </div> 
                                                : null}
                                            </li>
                                        ))}
                                        <li className="form-transaction">
                                            <div className="input-transaction-date">
                                                <DatePicker
                                                    defaultValue={defaultRangeDate}
                                                    oneTap
                                                    format={"dd-MM-yyyy HH:mm"}
                                                    className="custom-date-picker"
                                                    onChange={onChangeCreatedAt}
                                                    size="sm"
                                                    disabledDate={date => isAfter(date, new Date())}
                                                />
                                            </div>
                                            <div className="option-pay-method">
                                                <Select
                                                    isSearchable={false}
                                                    className="select"
                                                    defaultValue={filterByStatus[0]}
                                                    options={filterByStatus}
                                                    onChange={onChangePayType}

                                                />
                                            </div>
                                            <div className="input-group cash-append-group customer-payment-amount">
                                                <CurrencyInput
                                                    className="text-right input-cash"
                                                    placeholder="Nhập số tiền trả nợ ₫"
                                                    defaultValue={paidAmount}
                                                    value={paidAmount}
                                                    groupSeparator={','}
                                                    decimalSeparator={'.'}
                                                    onChange={(event) => onChangePaidAmount(event, val)}
                                                />
                                            </div>

                                            <div className="button-action">
                                                {val.old_owed > 0 ?
                                                    payType == "Chuyển khoản" ? 
                                                        <button onClick={() => chooseTypeBanking(val)} className={hiddenSubmit ? "btn btn-xs btn-primary ml-10 ng-binding btn-add-transaction disable" : "btn btn-xs btn-primary ml-10 ng-binding btn-add-transaction"}>
                                                            Tạo mã QR
                                                        </button>
                                                    :
                                                        <button onClick={() => addTransaction(val)} className={hiddenSubmit ? "btn btn-xs btn-primary ml-10 ng-binding btn-add-transaction disable" : "btn btn-xs btn-primary ml-10 ng-binding btn-add-transaction"}>
                                                            Trả nợ
                                                        </button>
                                                : null}
                                            </div>
                                        </li>


                                        {payType == "Ví điện tử" ?
                                            <li className="li-form-transaction">
                                                <div className="pay-select-stores"><span className="">Chọn ví điện tử</span></div>
                                                <div className="select-option-store">
                                                    <Select
                                                        isSearchable={false}
                                                        className="select"
                                                        defaultValue={selectAllElectronic[0]}
                                                        options={selectAllElectronic}
                                                        onChange={onChangePayElectronic}
                                                    />
                                                </div>
                                            </li>
                                        : ""}

                                        {payType == "Chuyển khoản" ?
                                            <li className="li-form-transaction">
                                                <div className="pay-select-stores"><span className="">Chọn ngân hàng</span></div>
                                                <div className="select-option-store">
                                                    <Select
                                                        isSearchable={false}
                                                        className="select"
                                                        defaultValue={getDefaultPayment(accountPayment, storeId)}
                                                        options={accountPayment}
                                                        isDisabled={true}
                                                        onChange={onChangePayAccount}
                                                    />
                                                </div>
                                            </li>
                                        : ""}

                                        {payType == "Quẹt thẻ" ?
                                            <li className="li-form-transaction">
                                                <div className="pay-select-stores"><span className="">Chọn ngân hàng</span></div>
                                                <div className="select-option-store">
                                                    <Select
                                                        isSearchable={false}
                                                        className="select"
                                                        defaultValue={getDefaultPayment(accountSwipe, storeId)}
                                                        options={accountSwipe}
                                                        isDisabled={true}
                                                        onChange={onChangePayAccount}
                                                    />
                                                </div>
                                            </li>
                                        : ""}

                                        <li className="li-form-transaction">
                                            <div className="pay-select-stores"><span className="">Tại chi nhánh</span></div>
                                            <div className="select-option-store">
                                                <Select
                                                    isSearchable={false}
                                                    className="select"
                                                    defaultValue={getDefaultStore(storeId)}
                                                    options={selectDataStore}
                                                    onChange={onChangeStoreId}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) 
                : ""}
            </div>
            <Delete show={showDelete} dataTransaction={dataTransaction} deleteTransaction={deleteTransaction} close={hidePopupDelete}></Delete>
            {transactionItem && props.customer && order ?
                <div style={{ display: "none" }}><PrintTransactionItem ref={invoiceRef} transactionItem={transactionItem} customer={props.customer} order={order} /></div>
            : ""}
            {warningQr ?
                <div className="popup-cent payment mini-popup">
                    <div className="content-popup fadeInDown">
                        <div className="head-popup">
                            <div className="title">Cảnh báo</div>
                            <div className="close" onClick={() => {setWarningQr(false)}}>×</div>
                        </div>
                        <div className="body-popup" style={{height: "auto"}}>
                            <div className="total-price payment-block">
                                <div className="title-body">Đang có order hiển thị mã QR, bạn có muốn hiển thị đè order hiện tại lên không ?</div>
                            </div>
                        </div>
                        <div className="bottom-popup">
                            <div className="button-container">
                                <button className="button-cent save-and-book" onClick={() => {setWarningQr(false)}}>
                                    <span><i className="fa fa-fas fa-calendar-alt"></i></span> Hủy
                                </button>
                                <button className="button-cent" onClick={() => {syncQrCode(currentOrderId, [role.LT,role.SALE].includes(userAdmin.role) ? bankingType : 1)} }>
                                    <span><i className="fas fa-save"></i></span> Đồng ý
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => {setWarningQr(false)}}></div>
                </div>
            : ""}
            {statusBankingType ?
                <div className="popup-cent payment">
                    <div className="content-popup fadeInDown">
                        <div className="head-popup">
                        <div className="title">Thông báo</div>
                        <div className="close" onClick={() => {setStatusBankingType(false)}}>×</div>
                        </div>
                        <div className="body-popup" style={{height: "auto"}}>
                        <div className="total-price payment-block" style={{marginBottom: "30px"}}>
                            <div className="title-body">Vui lòng chọn loại chuyển khoản mà bạn muốn: </div>
                        </div>
                        <div className="button-container" style={{justifyContent: 'center', display: 'flex'}}>     
                            <div className="button-cent style" style={{marginRight: "50px"}} onClick={() => {checkQrCode(currentOrderId, 1)}}>
                            <span><i className="fas fa-store"></i></span>Tạo mã cho Cơ sở
                            </div>
                            <div className="button-cent style2" onClick={() => {syncQrCode(currentOrderId, 2)}}>
                            <span><i className="fas fa-desktop"></i></span>Tạo mã cho Sale
                            </div>
                        </div>
                        </div>
                        <div className="bottom-popup">
                        <div className="button-container">
                            <button className="button-cent style3 save-and-book" onClick={() => {setStatusBankingType(false)}}>
                                <span><i className="fas fa-trash-alt"></i></span> Hủy
                            </button>
                        </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => {setStatusBankingType(false)}}></div>
                </div>
            : ""}
        </div>
    )
}

