import React from "react";
import axiosService from "../../utils/axios.service";

class Delete extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thông báo</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn xóa nhân viên này không ?</div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Không
                </div>
                <div className="button-cent" onClick={this.props.deleteOperator}>
                  <span><i className="fas fa-save"></i></span>Có
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return <div></div>;
  }
}
export default Delete;
