import React from "react";
import Select from 'react-select'
import $, { timers } from 'jquery';
import roleTitle from '../../enums/role-title.enum';
class Add extends React.Component {
  constructor(props) {
    super();
    this.state = {
      srcImg: 'https://placehold.co/90x90',
      selectedFile: null,
      avataName:'',
      avataUrl:'',
      avataS3Name:'',
      dataStore: [],
      name: "",
      mobile: "",
      email: "",
      group: props.optionsGroup[0].value,
      stores: [],
      status: true,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      role: 2
    };
  }

  onChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  onChangeMobile = (event) => {
    let keyword = event.target.value.replace(/\s/g, '');
    this.setState({
      mobile: keyword,
    });
  };
  onChangeEmail = (event) => {
    let keyword = event.target.value.replace(/\s/g, '');
    if(keyword && keyword != '' && keyword.length > 0) keyword = keyword.toLowerCase();
    this.setState({
      email: keyword,
    });
  };
  onChangeStores = (event) => {
    const data = [];
    for (const key in event) { data.push(event[key].value) }
    this.setState({
      stores: data,
    });
  };
  onChangeGroup = (event) => {
    this.setState({
      group: event.value,
    });
  };

  onChangeRole = (event) => {
    this.setState({
      role: event.value
    });
  }

  onChangeStatus = (event) => {
    this.setState({
      status: !this.state.status,
    });
  }
  openModalUpload = event => {
    $('.customer-avatar-input').trigger('click');
  }

  onFileChange = (event) => {
    var time = Math.floor(Date.now()/1000)
    var file = event.target.files[0];
    var fileName = time + "_" + file.name
    var url = URL.createObjectURL(file);
    this.setState({ selectedFile: file, avataUrl:url, avataS3Name:fileName, avataName: file.name }); 
  }

  addNewData = () => {
    let status = (this.state.status == true ) ? 1 : 2;
    const data = {
      "name": this.state.name,
      "email": this.state.email,
      "mobile": this.state.mobile,
      "group": (this.state.role == 2) ? this.state.group : "",
      "stores": ([2, 3, 4].includes(this.state.role)) ? this.state.stores : [],  
      "status": status,
      "created_by": this.state.userAdmin.id ?? "",
      "role": this.state.role
    };

   this.props.addOperator(data)
  };

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thêm nhân viên mới</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="items-data row">
                    <div className="title col-3">Họ tên</div>
                    <div className="value col-9"><input onChange={this.onChangeName} className="required" type="text"/></div>
                  </div>
                  {this.state.userAdmin.role == 1 ?
                    <div className="items-data row">
                      <div className="title col-3">Vị trí</div>
                      <div className="value col-9">
                        <Select className="select" onChange={this.onChangeRole} defaultValue={roleTitle[0]} options={roleTitle} />
                      </div>
                    </div>
                  : null}
                  <div className="items-data row">
                    <div className="title col-3">Điện thoại</div>
                    <div className="value col-9"><input onChange={this.onChangeMobile} className="required" placeholder="012345678" type="text"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Email</div>
                    <div className="value col-9"><input onChange={this.onChangeEmail} type="text" /></div>
                  </div>
                  {this.state.role == 2 ?
                    <div className="items-data row">
                      <div className="title col-3">Nhóm nhân viên</div>
                      <div className="value col-9">
                        <Select className="select" onChange={this.onChangeGroup} defaultValue={this.props.optionsGroup[0]} options={this.props.optionsGroup} />
                      </div>
                    </div>
                  : null}
                  {[2, 3, 4].includes(this.state.role) ?
                    <div className="items-data row">
                      <div className="title col-3">Chi nhánh</div>
                      <div className="value col-9">
                        <Select className="select" isMulti closeMenuOnSelect={false} onChange={this.onChangeStores} options={this.props.dataStore} />
                      </div>
                    </div>
                  : null}
                  <div className="items-data row">
                    <div className="title col-3">Trạng thái</div>
                    <div className="value col-9">
                      <label className="checkbox">
                        <input className="inline" defaultChecked={this.state.status} onChange={this.onChangeStatus} type="checkbox"/>
                        <span className="inline">{this.state.status ? "Hoạt động" : "Tạm dừng"}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className="button-cent" onClick={this.addNewData}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Add;
