import React, { useState, useEffect } from "react";
import { optionsPaymentMethods2, paymentMethodManyForms, accountPayment, accountSwipe } from "../constant/GeneralData";
import CurrencyInput from "react-currency-input-field";
import * as convert from "../constant/Convert.helper";
import Select from "react-select";
import _ from "lodash"
import axiosService from "../../utils/axios.service";
import role from '../../enums/role.enum';

var currentStore = localStorage.getItem("currentStore")

const Payment2 = (props) => {
  const [moneyBack, setMoneyBack] = useState(0);
  const [moneyOwed, setMoneyOwed] = useState(0);
  const [typePay, setTypePay] = useState(null);
  const [countPayment, setCountPayment] = useState(1);
  const [isCopyText, setIsCopyText] = useState('')
  const [statusPaymentLoyalty, setStatusPaymentLoyalty] = useState(false)
  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([])
  const [paymentMethodManyForm, setPaymentMethodManyForm] = useState([])
  const [paidDepositMoney, setPaidDepositMoney] = useState(props.orderSelected.customers.deposit_money)
  const [paymentItem, setPaymentItem] = useState([{
    total_amount: props.dataPrice,
    paid_amount: props.dataPrice,
    pay_type: optionsPaymentMethods2[0].value,
    store_id: currentStore
  }])
  const [warningQr, setWarningQr] = useState(false)
  const [priceQr, setPriceQr] = useState(0)
  const [statusQR, setStatusQR] = useState(false)
  const [bankingType, setBankingType] = useState(0)
  const [statusBankingType, setStatusBankingType] = useState(false)
  const [linkQr, setLinkQr] = useState(null)
  const [loadingQr, setLoadingQr] = useState(false)
  
  useEffect(() => {
    funcDefault()

    if (props.userAdmin.role === role.LT) setBankingType(1)
    if (props.userAdmin.role === role.SALE) setBankingType(2)

    if (props.currentPos?.transaction?.length > 0) {
      let transactionPayOs = _.filter(props.currentPos.transaction, function (o) { return (o.payment_link_id && o.qr_code && o.banking_type === 2 && o.status_pay_os == null)})
      if (transactionPayOs.length > 0) setLinkQr(process.env.REACT_APP_QR_LINK + transactionPayOs[0].uuid)
    }
  }, [props.showPayment]);

  useEffect(() => {
    if (typePay == "Nhiều hình thức") {
      let listPayment = [];
      let total = 0
      if (props.currentPos.transaction && props.currentPos.transaction.length > 0) {
        props.currentPos.transaction.map(x => {
          total += x.paid_amount
          listPayment.push({
            total_amount: props.dataPrice,
            paid_amount: x.paid_amount,
            pay_type: x.pay_type,
            store_id: currentStore,
          })
          
          if (x.pay_type === "Chuyển khoản") {
            setPriceQr(x.paid_amount)
            setStatusQR(true)
          }
        })
        if (listPayment.length) {
          setPaymentItem(listPayment)
          setCountPayment(listPayment.length)
          setMoneyOwed(props.dataPrice - total)
          setMoneyBack(total - props.dataPrice + (props.paidAmountBefore - props.totalAmountUsingUpsale))
        }
      }

      props.handleChangeState(props.dataPrice, 'money_paid');
      props.handleChangeState((listPayment.length > 0) ? listPayment : paymentItem, 'transaction');
    } else {
      paymentItem[0].paid_amount = props.dataPrice
      setPaymentItem([
        ...paymentItem
      ])
      if (typePay === "Chuyển khoản") setPriceQr(props.dataPrice)
      setMoneyBack(props.paidAmountBefore - props.totalAmountUsingUpsale)
      setMoneyOwed(0)
      props.handleChangeState(props.dataPrice, 'money_paid');
      props.handleChangeState(paymentItem, 'transaction');
    }
    props.handleChangeState(typePay, 'payment_type');
  }, [typePay])

  if (props.showPayment === false) return "";

  const getKeyOption = (option) => {
    let key = _.findIndex(option, function (o) { return o.store == currentStore });
    return key
  }
  
  const handleChangeData = (event) => {
    if (typePay !== event.target.value) {
      if (event.target.value == "Nhiều hình thức") {
        paymentItem[0].pay_type = "Tiền mặt"
        paymentItem[0].paid_amount = props.dataPrice
        // addMorePayment(event)
        getMoneyPaid()
      }
      setTypePay(event.target.value)
      props.handleChangeState(event.target.value, event.target.name);
    }
  };

  const addMorePayment = (event) => {
    let count = Number(countPayment)
    if (count < 10) {
      setCountPayment(count + 1)
      setArrayPayItem(count + 1)
    }
  }

  const removeModelItemPay = async (key) => {
    let count = Number(countPayment)
    let paymentItemNew = [...paymentItem]
    if (count > 1) {
      paymentItemNew.splice(key, 1)

      //Get payment loyalty
      paymentItemNew = await showPaymentLoyalty(paymentItemNew)
      setCountPayment(count - 1)
      setPaymentItem(paymentItemNew)
    }
    getMoneyPaid()

    const checkBanking = _.filter(paymentItemNew, function (o) { return (o.pay_type === "Chuyển khoản") })
    setStatusQR(checkBanking.length > 0)
    props.handleChangeState(paymentItemNew, 'transaction');
  }

  const setArrayPayItem = (count) => {
    var paymentItemNew = paymentItem
    paymentItemNew = [...paymentItem, { total_amount: props.dataPrice, paid_amount: 0, pay_type: '', store_id: currentStore }]
    setPaymentItem(paymentItemNew)
    props.handleChangeState(paymentItemNew, 'transaction');
  }

  const onSelectTypePayment = async (key, e) => {
    var paymentItemNew = paymentItem;
    let totalPaid = 0;
    paymentItemNew.map((x, i) => {
      if (i != key) { 
        totalPaid += x.paid_amount
      }
    })
    let remainPrice = props.dataPrice - totalPaid;
    paymentItemNew[key].pay_type = e.value;

    if (e.value == "Chuyển khoản") {
      paymentItemNew[key] = { ...paymentItemNew[key], pay_account_number: accountPayment[getKeyOption(accountPayment)].value }
      paymentItemNew[key].paid_amount = remainPrice;
      setPriceQr(props.dataPrice)
    } else if (e.value == "Quẹt thẻ") {
      paymentItem[0] = { ...paymentItem[0], pay_account_number: accountSwipe[getKeyOption(accountSwipe)].value }
      paymentItemNew[key].paid_amount = remainPrice;
    } else if (e.value === "Sử dụng thẻ tiền") {
      paymentItemNew[key].paid_amount = (remainPrice >= props.orderSelected.customers.deposit_money) ? props.orderSelected.customers.deposit_money : remainPrice;
    } else if (e.value === "Điểm tích luỹ") {
      paymentItemNew[key].paid_amount = (remainPrice >= props.orderSelected.customers.gift_money) ? props.orderSelected.customers.gift_money : remainPrice;
    } else {
      paymentItemNew[key].paid_amount = remainPrice;
      delete paymentItemNew[key].pay_account_number
    }

    //Get payment loyalty
    paymentItemNew = await showPaymentLoyalty(paymentItemNew)

    props.handleChangeState(paymentItemNew, 'transaction');
    setPaymentItem(paymentItemNew)
    getMoneyPaid()

    // if (paymentItemNew.length > 0) {
    //   let listValue = _.map(paymentItemNew, 'value');
    //   let newPayment = []
    //   paymentMethodManyForms.map(x => {
    //     newPayment.push({
    //       value: x.value,
    //       label: x.label,
    //       disabled: listValue.includes(x.value)
    //     }) 
    //   })
    //   setPaymentMethodManyForm([...newPayment])
    // }
    const checkBanking = _.filter(paymentItemNew, function (o) { return (o.pay_type === "Chuyển khoản") })
    setStatusQR(checkBanking.length > 0)
  }

  const handleMoneyPaid = async (event, key, val) => {
    event.target.value = event.target.value.replaceAll(",", "");
    var paymentItemNew = paymentItem

    if (typePay === "Sử dụng thẻ tiền") {
      if (Number(event.target.value) <= props.orderSelected.customers.deposit_money) paymentItemNew[key].paid_amount = Number(event.target.value)
    } else if (typePay === "Điểm tích luỹ") {
      if (Number(event.target.value) <= props.orderSelected.customers.gift_money) paymentItemNew[key].paid_amount = Number(event.target.value)
    } else if (typePay === "Ví thẻ triệt") {
      if (Number(event.target.value) <= props.orderSelected.customers.wallet_package_money) paymentItemNew[key].paid_amount = Number(event.target.value)
    } else if (typePay === "Nhiều hình thức") {
      if (val === "Sử dụng thẻ tiền") {
        const remove = paymentItemNew.map((x, i) => {if (i != key) { return x }}).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Sử dụng thẻ tiền") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        setPaidDepositMoney(sum)
        if (sum + Number(event.target.value) <= props.orderSelected.customers.deposit_money) paymentItemNew[key].paid_amount = Number(event.target.value)
      } else if (val == "Điểm tích luỹ") {
        const remove = paymentItemNew.map((x, i) => {if (i != key) { return x }}).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Điểm tích luỹ") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        if (sum + Number(event.target.value) <= props.orderSelected.customers.gift_money) paymentItemNew[key].paid_amount = Number(event.target.value)
      } else if (val == "Ví thẻ triệt") {
        const remove = paymentItemNew.map((x, i) => {if (i != key) { return x }}).filter(x => x != undefined)
        const filter = _.filter(remove, function (o) { return (o.pay_type === "Ví thẻ triệt") })
        const sum = _.sumBy(filter, function (o) { return o.paid_amount });
        if (sum + Number(event.target.value) <= props.orderSelected.customers.wallet_package_money) paymentItemNew[key].paid_amount = Number(event.target.value)
      } else {
        let price = Number(event.target.value)
        if (price > props.dataPrice) price = props.dataPrice
        paymentItemNew[key].paid_amount = price
        if (val === "Chuyển khoản") setPriceQr(price)
      }
    } else {
      paymentItemNew[key].paid_amount = Number(event.target.value)()
      if (typePay === "Chuyển khoản") setPriceQr(event.target.value)
    }

    paymentItemNew = await showPaymentLoyalty(paymentItemNew)
    setPaymentItem(paymentItemNew)
    getMoneyPaid()
  }

  const handleSelectAccountPayment = (event, key) => {
    var paymentItemNew = paymentItem;
    paymentItemNew[key].pay_account_number = event.target;
    setPaymentItem(paymentItemNew)
  }

  const getMoneyPaid = () => {
    var total = _.sumBy(paymentItem, function (o) { return o.paid_amount });

    setMoneyBack(total - props.dataPrice + (props.paidAmountBefore - props.totalAmountUsingUpsale))
    setMoneyOwed(props.dataPrice - total)
    props.handleChangeState(total, 'money_paid');
    props.handleChangeState(paymentItem, 'transaction');
  }

  const handleChangeDataNote = (e) => {
    props.handleChangeState(e.target.value, e.target.name);
  }

  const funcSaveOrderPopup = (event, type_booking = null) => {
    if (paymentItem.length === 0) return props.notifications({ type: 'warning', mess: 'Vui lòng chọn hình thức thanh toán' });
    props.funcSaveOrder(type_booking)
  }

  const funcDefault = () => {
    let checkWallet = false
    var orderItemSelect = props.selectOrderItem

    let filterCheckWallet = _.filter(orderItemSelect, function(o) { return (o.type_radical == 1 || o.price == 0) ; });
    if (filterCheckWallet.length == orderItemSelect.length) {
      checkWallet = true
    }

    let optionsPayMethod = [...optionsPaymentMethods2]
    let payMethodManyForm = [...paymentMethodManyForms]

    let filterWallet = _.filter(optionsPayMethod, function(o) { return o.value == 'Ví thẻ triệt' });
    let filterDeposit = _.filter(optionsPayMethod, function(o) { return o.value == 'Sử dụng thẻ tiền' });
    let filterGiftMoney = _.filter(optionsPayMethod, function(o) { return o.value == 'Điểm tích luỹ' });
    
    let checkLength = optionsPayMethod.length + 1
    if (props.orderSelected.customers.gift_money > 0 & props.orderSelected.customers.isMember == 1 && filterGiftMoney.length == 0  && props.orderSelected.customers.deposit_money == 0) {
      payMethodManyForm.push({ value: 'Điểm tích luỹ', label: 'Điểm tích luỹ' })
      checkLength += 1
    }

    if (props.isDeposit == false) {
      if (props.orderSelected.customers.deposit_money > 0 && filterDeposit.length == 0) {
        payMethodManyForm.push({ value: 'Sử dụng thẻ tiền', label: 'Sử dụng thẻ tiền' })
        checkLength += 1
      }
      if(paymentItem.length == 1) {
        paymentItem[0].pay_type = optionsPayMethod[0].value
        paymentItem[0].paid_amount = props.dataPrice
      }
      setPaymentItem([
        ...paymentItem
      ])

      if(props.dataPrice == 0) {
        setTypePay('Không thanh toán');
      }
    }

    if (props.orderSelected.customers.wallet_package_money > 0 && filterWallet.length == 0 && checkWallet) {
      payMethodManyForm.push({ value: 'Ví thẻ triệt', label: 'Ví thẻ triệt' })
    }
    setOptionsPaymentMethod([...optionsPayMethod])
    setPaymentMethodManyForm([...payMethodManyForm])

    props.handleChangeState(typePay, 'payment_type');
    props.handleChangeState(paymentItem, 'transaction');

    let event = {
      target: {
        value: 'Nhiều hình thức'
      }
    };

    handleChangeData(event, 0)
  }

  const copyText = (text) => {
    setIsCopyText('isCopy')
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setIsCopyText('')
    }, 1000);
  }
  
  const checkBankingType = async () => {
    if (bankingType !== 0) {
      if (bankingType === 1) checkQrCode()
      if (bankingType === 2) syncQrCode()
    } else {
      setStatusBankingType(true)
    }
  }

  const checkQrCode = async (banking_type = null) => {
    if (priceQr == 0) return props.notifications({ type: 'warning', mess: 'Vui lòng điền số tiền lớn hơn 0' });

    let res = await axiosService("api/check-qr-code", "POST", { id: props.orderSelected.id, store_id: props.currentStore });
    if (res.data.success) {
      let countOrder = res.data.data
      if (countOrder === 0) {
        syncQrCode(banking_type)
      } else {
        setWarningQr(true)
        setStatusBankingType(false)
      }
    } else {
      props.notifications({ type: 'error', mess: res.data.message });
    }
  }

  const syncQrCode = async (banking_type = null) => {
    setWarningQr(false)
    setStatusBankingType(false)
    setLoadingQr(true)
    let payload = {
      price: priceQr,
      id: props.orderSelected.id,
      transactions: paymentItem,
      discount_by_rule: props.discount_by_rule,
      discount_by_total_bill: props.discount_by_total_bill,
      sale_rule_applied_ids: props.comboIds + "," + props.sale_rule_applied_ids,
      group_voucher_id: props.group_voucher_id,
      total_price_discount_vc: props.total_price_discount_vc,
      total_amount_using_upsale: props.total_amount_using_upsale,
      store_id: props.currentStore,
      banking_type: banking_type ? banking_type : bankingType
    }
    let res = await axiosService("api/sync-qr-code", "POST", payload);
    if (res.data.success) {
      let order = res.data.data
      props.notifications({ type: 'success', mess: 'Cập nhật mã QR thành công' });
      props.socket.emit("client-qr-code", {order: order, status: true, store_id: order.stores.id});
      let transactionPayOs = _.filter(order.transaction, function (o) { return (o.payment_link_id && o.qr_code && o.banking_type === 2 && o.status_pay_os == null)})
      if (transactionPayOs.length > 0) setLinkQr(process.env.REACT_APP_QR_LINK + transactionPayOs[0].uuid)
      setLoadingQr(false)
    } else {
      props.notifications({ type: 'error', mess: res.data.message });
      setLoadingQr(false)
    }
  }

  const showPaymentLoyalty = async (data) => {
    let status = 0;
    let payMethodManyForm = [...paymentMethodManyForm]
    let paymentItemNew = data

    let filterLoyalty = _.filter(payMethodManyForm, function (o) { return (o.value === "Điểm tích luỹ") })
    let filterDeposit = _.filter(paymentItemNew, function (o) { return (o.pay_type === "Sử dụng thẻ tiền") })

    if (filterDeposit.length > 0) {
      if (props.orderSelected.customers.isMember == 1) {
        if (props.orderSelected.customers.gift_money > 0 && (props.orderSelected.customers.deposit_money == 0 || (props.orderSelected.customers.deposit_money == filterDeposit[0].paid_amount))) {
          if (filterLoyalty.length === 0) status = 1
        } else {
          if (filterLoyalty.length > 0) status = 2
        }
      } else {
        if (filterLoyalty.length > 0) status = 2
      }
    } else {
      if (props.orderSelected.customers.deposit_money > 0) {
        if (filterLoyalty.length > 0) status = 2
      }
    }

    if (status === 1) payMethodManyForm.push({ value: 'Điểm tích luỹ', label: 'Điểm tích luỹ' }) 
    if (status === 2) {
      setStatusPaymentLoyalty(true)
      setTimeout(() => {
        setStatusPaymentLoyalty(false)
      }, 3000);
      _.remove(payMethodManyForm, function(n) { return n.value == 'Điểm tích luỹ' });
      _.remove(paymentItemNew, function(n) { return n.pay_type == 'Điểm tích luỹ' });
    }
    setPaymentMethodManyForm([...payMethodManyForm])
    return paymentItemNew
  }

  const successAndBooking = (e) => {
    if (statusQR) {
      props.checkPayOs("booking")
    } else {
      props.funcSaveOrder(e, "booking")
    }
  }

  return (
    <>
      <div className="popup-cent payment">
        <div className="content-popup fadeInDown">
          <div className="head-popup">
            <div className="title">Thanh toán đơn hàng</div>
            <div className="close" onClick={props.paymentOrder}>×</div>
          </div>
          <div className="body-popup">
            <div className="total-price payment-block">
              <div className="title-block left-block">Tổng tiền</div>
              <div className="price-block right-block">
                {convert.convertCurrency(props.dataPrice )}
              </div>
            </div>
            <div className="payment-method">
              <div className="title-block">Hình thức thanh toán</div>
              <div className="body-block">
                <div className="row">
                  {optionsPaymentMethod.map((item, key) => (
                    <div className="col-5 col-md-3" key={key}>
                      <button
                        className={
                          typePay === item.value
                            ? "btn btn-block btn-xs btn-primary"
                            : "btn btn-block btn-xs btn-default"
                        }
                        name="payment_type"
                        value={item.value}
                        onClick={(e) => handleChangeData(e, 0)}
                      >
                        {key+1}. {item.label}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="cash-block payment-block">
              <div className="title-block left-block">
                {(props.orderSelected.customers.deposit_money > 0) ? 
                <div style={{ lineHeight: 1 }}>
                  Tiền hiện có trong tài khoản: {convert.convertCurrency(props.orderSelected.customers.deposit_money)}
                </div> : null}
                {(props.orderSelected.customers.gift_money > 0 && props.orderSelected.customers.isMember == 1) ? 
                <div style={{ lineHeight: 1, marginTop: "10px" }}>
                  Điểm tích luỹ: {convert.convertCurrency(props.orderSelected.customers.gift_money)}
                </div> : null}
                {(props.orderSelected.customers.wallet_package_money > 0 ) ? 
                <div style={{ lineHeight: 1, marginTop: "10px" }}>
                  Ví triệt: {convert.convertCurrency(props.orderSelected.customers.wallet_package_money)}
                </div> : null}
                {statusPaymentLoyalty ?
                  <div style={{ lineHeight: 1, marginTop: "10px", color: "red" }}>
                    Điểm tích luỹ chỉ được sử dụng khi khách hàng sử dụng hết tiền trong tài khoản
                  </div>
                :null} 
              </div>
            </div>            
            {typePay == "Nhiều hình thức" ?
              <div className="cash-customer payment-in-many-forms">
                {paymentItem.map((val, key) => (
                  <div className="row class-payment-many-item" key={key}>
                    <div className="col-12 col-md-9 input-type">
                      <Select className="select-type-payment"
                        isSearchable={false}
                        value={paymentMethodManyForm.filter(o => o.value == val.pay_type)}
                        onChange={(e) => onSelectTypePayment(key, e)}
                        styles={paymentMethodManyForm}
                        options={paymentMethodManyForm}
                        // isOptionDisabled={(option) => option.disabled} 
                        // defaultValue={paymentMethodManyForm[0]}
                      />
                      <div className="cash-customer">
                        <CurrencyInput
                          name="money_paid"
                          className="input-money-paid"
                          value={val.paid_amount}
                          groupSeparator={","}
                          decimalSeparator={"."}
                          onChange={(e) => handleMoneyPaid(e, key, val.pay_type)}
                        />
                        <div className="currency">₫</div>
                      </div>
                    </div>
                    <div className="col-10 col-md-2"></div>
                    <div className="col-2 col-md-1">
                      {paymentItem.length > 1 ?
                        <div className="button-cent-remover" onClick={() => removeModelItemPay(key)}><span><i className="fas fa-times"></i></span></div>
                      : ""}
                    </div>

                    {val.pay_type == "Chuyển khoản" ?
                      <div className="col-12" style={{marginTop: '10px'}}>
                        {accountPayment[getKeyOption(accountPayment)].label}
                        <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() =>  copyText(accountPayment[getKeyOption(accountPayment)].label, 0)}>Copy</button>
                      </div>
                    : null}
                    {val.pay_type == "Quẹt thẻ"  ?
                      <div className="col-12" style={{marginTop: '10px'}}>
                        <Select
                          className="select"
                          isSearchable={false}
                          defaultValue={accountSwipe[getKeyOption(accountSwipe)]}
                          onChange={(e) => handleSelectAccountPayment(e, key)}
                          options={accountPayment}
                        />
                      </div>
                    : null}
                  </div>
                ))}
                <div className="title add-more-payment">
                  <div className="add-more" onClick={addMorePayment}><i className="fas fa-plus-circle"></i>Thêm phương thức thanh toán</div>
                </div>

              </div>
            : null}
            {linkQr ? 
              <div>
                <a href={linkQr} target="_blank">{linkQr}</a>
                <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() => copyText(linkQr)}>Copy</button>
              </div> 
            : null}
            <div className="money-back-block payment-block">
              <div className="title-block left-block"> {props.orderSelected.type == 3 && props.dataPrice == 0 ? 'Tiền hoàn lại vào tài khoản' : 'Trả lại khách'}</div>
              <div className="price-block right-block">
                {moneyBack > 0 ? convert.convertCurrency(moneyBack) : "0 đ"}
              </div>
            </div>
            {moneyOwed > 0 ? (
              <div className="owed-block payment-block">
                <div className="title-block left-block">Còn nợ</div>
                <div className="money-owed right-block">{convert.convertCurrency(moneyOwed)}</div>
              </div>
            ) : ""}

            <div className="note-transaction payment-block">
              <div className="title-block">Ghi chú</div>
              <div className="note-block">
                <textarea name="order_description" defaultValue={props.orderSelected.description} onChange={(e) => handleChangeDataNote(e)} />
              </div>
            </div>
          </div>
          <div className="bottom-popup">
          {props.statusCheckout === true && typePay ?
            <div className="button-container">
              <button className="button-cent" onClick={(e) => funcSaveOrderPopup(e)}>
                <span>
                  <i className="fas fa-save"></i>
                </span>
                Hoàn thành
              </button>
              <button className="button-cent save-and-book" onClick={(e) => successAndBooking("booking")}>
                <span>
                  <i className="fa fa-fas fa-calendar-alt"></i>
                </span>
                Hoàn thành và đặt lịch
              </button>
              {props.qrStore.includes(Number(currentStore)) ?
                <button className="button-cent check-qr" onClick={(e) => props.checkPayOs()}>
                  <span>
                    <i className="fas fa-qrcode"></i>
                  </span>
                  Kiểm tra giao dịch
                </button>
              : ''}
              {statusQR ?
                <div style={{width: '120px'}} className={props.dataPrice > 0 && props.orderSelected.status === '4' && !loadingQr ? "button-cent" : "button-cent disable"}
                  onClick={props.orderSelected.status === '4' && !loadingQr ? () => {checkBankingType()} : () => {}} >
                  <span>
                    <i className="fas fa-money-bill"></i>
                  </span>
                  Tạo mã QR
                </div>
              : 
                <button className="button-cent" onClick={(e) => funcSaveOrderPopup(e)}>
                  <span>
                    <i className="fas fa-save"></i>
                  </span>
                  Hoàn thành
                </button>
              }
            </div>
          : null}
          </div>
        </div>
        <div className="overlay" onClick={props.paymentOrder}></div>
      </div>
      {warningQr ?
        <div className="popup-cent payment">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Cảnh báo</div>
              <div className="close" onClick={() => {setWarningQr(false)}}>×</div>
            </div>
            <div className="body-popup">
              <div className="total-price payment-block">
                <div className="title-body">Đang có order hiển thị mã QR, bạn có muốn hiển thị đè order hiện tại lên không ?</div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                  <button className="button-cent style3 save-and-book" onClick={() => {setWarningQr(false)}}>
                    <span><i className="fas fa-trash-alt"></i></span> Hủy
                  </button>
                  <button className="button-cent" onClick={() => {syncQrCode(1)} }>
                    <span><i className="fas fa-save"></i></span> Đồng ý
                  </button>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={() => {setWarningQr(false)}}></div>
        </div>
      : ""}
      {statusBankingType ?
        <div className="popup-cent payment">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thông báo</div>
              <div className="close" onClick={() => {setStatusBankingType(false)}}>×</div>
            </div>
            <div className="body-popup">
              <div className="total-price payment-block" style={{marginBottom: "30px"}}>
                <div className="title-body">Vui lòng chọn loại chuyển khoản mà bạn muốn: </div>
              </div>
              <div className="button-container" style={{justifyContent: 'center'}}>     
                <div className="button-cent style" style={{marginRight: "50px"}} onClick={() => {checkQrCode(1)}}>
                  <span><i className="fas fa-store"></i></span>Tạo mã cho Cơ sở
                </div>
                <div className="button-cent style2" onClick={() => {syncQrCode(2)}}>
                  <span><i className="fas fa-desktop"></i></span>Tạo mã cho Sale
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                  <button className="button-cent style3 save-and-book" onClick={() => {setStatusBankingType(false)}}>
                    <span><i className="fas fa-trash-alt"></i></span> Hủy
                  </button>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={() => {setStatusBankingType(false)}}></div>
        </div>
      : ""}
    </>
  );
};

export default Payment2;
