import {format} from "date-fns"
function getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(format(new Date(currentDate),"yyyy-MM-dd"))
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }
  export default getDates