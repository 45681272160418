import React, { useState, useEffect } from 'react'
import { axiosGet } from "../../utils/axios.service";
import { formatValue } from 'react-currency-input-field';
import _ from 'lodash';
import { PackageStatus } from './constant/CustomerBirthday';

var _state = {}
var _props = null;

async function requestOrderHistory() {
    if (_state.setDataTransaction === undefined) return ''
    var id = _props.package.id
    let response = await axiosGet("api/package/history-item/" + id);
    if (response.data.code == 200) {
        _state.setOrderItem(response.data.data)
    }
}


export function PackagePopup(props) {
    const [dataTransaction, setDataTransaction] = useState({})
    const [orderId, setOrderId] = useState(null)
    const [orderItem, setOrderItem] = useState([])

    _state.dataTransaction = dataTransaction
    _state.setDataTransaction = setDataTransaction

    _state.orderId = orderId
    _state.setOrderId = setOrderId
    _state.orderItem = orderItem
    _state.setOrderItem = setOrderItem


    _props = props

    let packageProp = props.package
    // let cardTypeOj = _.filter(packageProp.product.meta, { meta_key: 'card_type' })
    useEffect(() => {
        requestOrderHistory()
    }, []);


    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };

    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };

    return (
        <div>
            <div className="head-popup">
                <div className="title">Lịch sử sử dụng thẻ <span></span> </div>
            </div>
            <div className="body-popup">
                <div className="panel panel-flat">
                    <div className="panel-body">
                        <div className="row mt-10 show-head">
                            <div className="col-md-3 customer-info">
                                <div className="row">
                                    <label className="control-label col-6 text-right pr-10">Khách hàng</label>
                                    <label className="control-label col-6 customer-name" >
                                        {packageProp.customer ? 
                                            <span>{packageProp.customer.full_name } {packageProp.customer?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</span>
                                        : packageProp.customer_name}
                                    </label>
                                    <label className="control-label col-9 ng-hide">(Tất cả khách hàng)</label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-6 text-right pr-10">Điện thoại</label>
                                    <label className="control-label col-6">{packageProp.customer ? packageProp.customer.mobile : packageProp.customer_mobile}</label>
                                </div>
                            </div>
                            <div className="col-md-4 package-info-name">
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">
                                        <span className="package-order">{packageProp.product ? packageProp.product.product_name : packageProp.product_name}</span>
                                    </label>
                                    <label className="control-label col-5">
                                        <span className="tag bg-green" >{packageProp.type}</span>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Mã thẻ</label>
                                    <label className="control-label col-5 text-bold text-uppercase">{packageProp.package_code}</label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Tình trạng</label>
                                    <label className="control-label col-5">
                                        <span className="tag no-margin bg-info">{PackageStatus[packageProp.status]}</span>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Ghi chú</label>
                                    <label className="control-label col-5 text-bold">{packageProp.note ? packageProp.note : '--'}</label>
                                </div>
                            </div>
                            <div className="col-md-5 package-info-date">
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Số lần đã sử dụng:</label>
                                    <label className="control-label col-5">
                                        <span className="font-20 text-bold">{packageProp.count_used}</span>
                                        <span ng-if="merberCard.use_limit > 0" className="ng-scope">
                                            <span ng-if="merberCard.use_limit < 50000" className="ng-scope">/ {packageProp.max_used > 1000 ? "Không giới hạn" : packageProp.max_used}</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Số lần khởi tạo sử dụng</label>
                                    <label className="control-label col-5 text-bold">0</label>
                                </div>
                                <div className="row ng-hide">
                                    <label className="control-label col-7 text-right pr-10">Số tiền ban đầu</label>
                                    <label className="control-label col-5">
                                        <span className="text-bold">
                                            <span className="cash-vnd text-bold">{packageProp.initial_amount ? formatValue({
                                                value: String(packageProp.initial_amount),
                                                groupSeparator: ',',
                                                decimalSeparator: '.',
                                            }) : '0'} đ</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="row ng-hide">
                                    <label className="control-label col-7 text-right pr-10">Số tiền trong thẻ</label>
                                    <label className="control-label col-5">
                                        <span className="text-bold text-danger">
                                            <span className="cash-vnd text-bold text-danger">{packageProp.initial_amount ? formatValue({
                                                value: String(packageProp.initial_amount),
                                                groupSeparator: ',',
                                                decimalSeparator: '.',
                                            }) : '0'} đ</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Ngày cấp</label>
                                    <label className="control-label col-5 text-green text-bold">
                                        <span ng-if="merberCard.expiry_date" className="ng-scope">{convertDateTime(packageProp.date_of_issue)}</span>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Ngày hết hạn</label>
                                    <label className="control-label col-5 text-danger text-bold">
                                        <span ng-if="merberCard.expiry_date" className="ng-scope">{convertDateTime(packageProp.expiration_date)}</span>
                                    </label>
                                </div>
                                <div className="row ng-scope" ng-if="merberCard.order_id">
                                    <label className="control-label col-7 text-right pr-10">Hoá đơn cấp thẻ</label>
                                    <label className="control-label col-5 text-danger text-bold">
                                        <p ng-click="showOrderInfo(merberCard.order_id)" className="ng-binding">{packageProp.order_code}</p>
                                    </label>
                                </div>
                                <div className="row">
                                    <label className="control-label col-7 text-right pr-10">Tại chi nhánh</label>
                                    <label className="control-label col-5 text-danger text-bold">
                                        <span ng-if="merberCard.expiry_date" className="ng-scope">{packageProp?.store?.name_store}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed-content-wrapper customer-list-container">
                    <div className="fixed-one-col">
                        <div className="panel panel-flat max-height">
                            <div className="report-summary-block bg-blue commission-summary" aria-hidden="false">
                                <label className="pull-left">Số lần đã sử dụng: </label>
                                <span className="text-white" style={{marginLeft: "3px"}}> {packageProp.count_used}</span>
                            </div>

                            <div className="dataTables_wrapper no-footer no-padding mt-10">
                                <table className="table table-hover datatable-columns no-padding no-footer dataTable">
                                    <thead>
                                        <tr>
                                            <th>Mã hoá đơn</th>
                                            <th>Tên thẻ</th>
                                            <th>Thời gian</th>
                                            <th>Số lần sử dụng</th>
                                            <th>Ghi chú</th>
                                            <th>Cơ sở</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderItem[0] ?
                                            orderItem.map((item, key) => (
                                                !item.new_package && item.order ? 
                                                    <tr key={key} >
                                                        <td>{item.order.order_code}</td>
                                                        <td>{item.product_name}</td>
                                                        <td>{convertDateTime(item.order.order_at)}</td>
                                                        <td>{item.quantity}</td>
                                                        <td><div className="paragraph"></div>{item.order.description}</td>
                                                        <td><div className="paragraph"></div>{item.order.stores.address}</td>
                                                    </tr>
                                                : null ))
                                        : null }
                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
