import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import thunkStatus from '../../enums/thunkStatus.enum';
import responseEnum from '../../enums/response.enum';
import axiosService from "../../utils/axios.service";
const initialState = {
  data: [],
  status: thunkStatus.INIT,
  response: responseEnum.SUCCESS,
}

export const getOptions = createSlice({
  name: 'options',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
    },
  },
  extraReducers: builder => {
    builder
      //fetch
      .addCase(getData.pending, (state, action) => {
        state.status = thunkStatus.PENDING
      })
      .addCase(getData.fulfilled, (state, action) => {
        if (!action.payload) {
          state.response = responseEnum.FAIL
        } else {
          state.response = responseEnum.SUCCESS
        }
        state.data = action.payload.data
        state.status = thunkStatus.FULFILLED
      })
      .addCase(getData.rejected, (state, action) => {
        state.status = thunkStatus.REJECT
      })
  }
})
export const { updateStatus } = getOptions.actions

export const getData = createAsyncThunk('options/fetchData', async (query) => {

  const { key } = query
  try {
    const { data } = await axiosService('api/options?key=' + key, "GET")

    return data
  } catch (error) {
    console.error(error)
  }
})


export default getOptions.reducer


