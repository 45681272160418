import React, { useState, useEffect, memo } from "react"
import { DateRangePicker, SelectPicker, Dropdown, Input, DatePicker, InputGroup } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays, subWeeks, startOfWeek, endOfWeek, addWeeks, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import axiosService from "../../utils/axios.service";
import { format } from "date-fns"
import { Button } from 'antd';
import 'antd/dist/antd.min.css'
import headerReportEnum from "../../enums/headerReport.enum";
import { CSVDownload } from "react-csv";
import * as convert from "../constant/Convert.helper";
import Add from "./Add";
import _ from "lodash";
import async from "async"

import {
    status, hourSelect, minuteSelect
  } from './constant/constant'


const Header = ({ startDate, endDate, hanldeRangeDate, store, hanldeStore, hanldeSearchVoucher, sortBy, hanldeSortBy,
    title, type, isExport, csvData, hanldeExportData, header, fileName, userAdmin, onChangeKeyword, handleCreateDataSale, addLead, headerChangeStatus
    ,headerChangeHour,  headerChangeMinute, listUser, setSelectUser, hanldeCategory}) => {
    const [locations, setLocations] = useState([])
    const [locationsWhidth, setLocationsWhidth] = useState(window.innerWidth)
    const [createData, setCreateData] = useState(false)
    const [optionCategory, setOptionCategory] = useState([])


    const [sortByList, setSortByList] = useState(['Ngày', "Tuần", 'Tháng', 'Quý'].map(
        (x, i) => ({ label: x, value: i })
    ))
    const [keyword, setKeyword] = useState('')
    let startOfWeek1 = startOfWeek(new Date())
    let endOfWeek1 = endOfWeek(new Date())
    let ranges = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần trước",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng trước",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
        {
            label: "Năm này",
            value: [startOfYear(new Date()), endOfDay(endOfYear(new Date()))],
        },
        {
            label: "Năm trước",
            value: [startOfYear(addDays(startOfYear(new Date()), -1)), endOfYear(addDays(startOfYear(new Date()), -1))],
        },
    ]
    let rangesNv = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần trước",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng trước",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
    ]
    useEffect( async () => {
        if (["daily", "customers", "cashiers", "users", "products", "sales", "saless", "ktv-tvv", "detail-ktv-tvv"].includes(type)) {
            fetchLocations()
        }
        if (type == 'detail_promotion') {
            fetchGroupVoucher()
        }

        const { category, users } = await async.parallel({
            category: (cb) => {
                axiosService(`api/category?type=1&product_type=2`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
        })

        if (category.code == 200) {
            var option = []
            option.push({value: 0, label: 'All'})
            for (let item of category.data) {
                let row = {
                    value: item.id,
                    label: item.name
                }
                option.push(row)
                
            }
            setOptionCategory([...option])
        }
    }, [sortBy])
    const onChangeDate = (type,e) => {
        if (type == 0 ) {
            startDate = format(e, 'yyyy-MM-dd HH:mm')
        }
        if (type == 1 ) {
            endDate = format(e, 'yyyy-MM-dd HH:mm')
        }
        hanldeRangeDate(startDate, endDate)
       
    }
    const fetchLocations = async () => {
        if (userAdmin && [3, 4].includes(userAdmin.role)) {
            let dataStore = convert.getStoreByRole(userAdmin)
            setLocations([...dataStore])
        } else {
            const rs = await axiosService("api/stores", "GET")
            const { data } = rs
            if (data.code === 200) {
                data.data.unshift({ name_store: "Tất cả", id: 0 })
                const newLocations = data.data.map(x => {
                    return { label: x.name_store, value: x.id }
                })
                setLocations([...newLocations])
            }
        }
    }

    const fetchGroupVoucher = async () => {
        const rs = await axiosService("api/category_voucher", "GET")
        const { data } = rs
        if (data.code === 200) {
            data.data.unshift({ group_name: "Tất cả", id: 0 })
            const newLocations = data.data.map(x => {
                return { label: x.group_name, value: x.id }
            })
            setLocations([...newLocations])
        }
    }
    const onChangeSortBy = (e) => {
        hanldeSortBy(e)
    }
    const onChangeVoucherName = _.debounce((e) => {
        if (e.length > 3) {
            hanldeSearchVoucher(e)
        }
    }, 1000)

    const onChangeKeywordHeader = _.debounce((e) => {
        if (e.length > 2 || e.length == 0) {
            setKeyword(e)
            onChangeKeyword(e)
        }
    }, 1000)
    const onChangeStore = (e) => {
        hanldeStore(e)
    }

    const onChangeCategory = (e) => {
        hanldeCategory(e)
    }
    const hanldeExport = () => {
        hanldeExportData()
    }

    const showCreateLead = () => {
        setCreateData(!createData)
    }

    const onChangeStatus = (e) => {
        headerChangeStatus(e)
    }

    const onChangeHour = (e) => {
        headerChangeHour(e)
    }

    const onChangeUser = (e) => {
        setSelectUser(e)
    }


    return (
        <div className="search-report pl-2 pr-2">
            <Row>
                {isExport && <CSVDownload data={csvData} headers={header} filename={fileName} target="_blank" />}
                <Col xs={type != 'locations' ? 12 : 6} md={12}>
                    {locationsWhidth > 765 ?
                        <Row>
                            <Col xs={2}  className="mb-2">
                                {["daily", "customers", "cashiers", "users", "products", "sales", "ktv-tvv", "detail_promotion", "saless"].includes(type) &&
                                    <SelectPicker data={locations} className="w-100" defaultValue={store} onChange={onChangeStore}
                                        cleanable={false}
                                    />
                                }
                            </Col>
                            <Col xs={2}  className="mb-2">
                                <SelectPicker data={optionCategory} className="w-100" defaultValue={optionCategory[0]} onChange={onChangeCategory}
                                            cleanable={false}
                                        />
                             </Col>

                            <Col xs={1}  className="mb-2">
                                <SelectPicker data={status} className="w-100" defaultValue={status['All']} onChange={onChangeStatus}
                                    cleanable={false}
                                />
                            </Col>

                            <Col xs={1}  className="mb-2">
                                <SelectPicker placeholder="Nhân viên" data={listUser} className="w-100" defaultValue={listUser[0]} onChange={onChangeUser}
                                    cleanable={false}
                                />
                            </Col>

                            <Col xs={2}  className="mb-2">
                                <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeywordHeader} cleanable={false} />
                            </Col>
                            <Col xs={3}  className="mb-2">
                                <InputGroup>
                                    <DatePicker defaultValue={new Date(startDate)} className="w-100" format="yyyy-MM-dd HH:mm" block onChange={(e) => onChangeDate(0, e)} appearance="subtle"  />
                                    <InputGroup.Addon>to</InputGroup.Addon>
                                    <DatePicker defaultValue={new Date(endDate)} format="yyyy-MM-dd HH:mm" block onChange={(e) => onChangeDate(1, e)} appearance="subtle" style={{ width: 230 }} />
                                </InputGroup>
                            </Col>

                            <Col xs={1} style={{ textAlign: "right" }}  className="mb-2">
                                <Button size="middle" type="primary"
                                    onClick={showCreateLead}
                                ><i className="fa-solid fa-file-excel mr-2"></i>Tạo Lead
                                </Button>
                            </Col>
                            <Col xs={1} style={{ textAlign: "right" }}  className="mb-2">
                                <Button size="middle" type="primary"
                                    onClick={hanldeExport}
                                ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                                </Button>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={6}  className="mb-2">
                                {["daily", "customers", "cashiers", "users", "products", "sales", "ktv-tvv", "detail_promotion", "saless"].includes(type) &&
                                    <SelectPicker data={locations} className="w-100" defaultValue={store} onChange={onChangeStore}
                                        cleanable={false}
                                    />
                                }
                            </Col>

                            <Col xs={6}  className="mb-2">
                                <SelectPicker data={status} className="w-100" defaultValue={status['All']} onChange={onChangeStatus}
                                    cleanable={false}
                                />
                            </Col>

                            <Col xs={6}  className="mb-2">
                                <SelectPicker placeholder="Nhân viên" data={listUser} className="w-100" defaultValue={listUser[0]} onChange={onChangeUser}
                                    cleanable={false}
                                />
                            </Col>

                            <Col xs={6}  className="mb-2">
                                <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeywordHeader} cleanable={false} />
                            </Col>
                            <Col xs={12}  className="mb-2">
                                <InputGroup>
                                    <DatePicker defaultValue={new Date(startDate)} format="yyyy-MM-dd HH:mm" block onChange={(e) => onChangeDate(0, e)} appearance="subtle"  />
                                    <InputGroup.Addon>to</InputGroup.Addon>
                                    <DatePicker defaultValue={new Date(endDate)} format="yyyy-MM-dd HH:mm" block onChange={(e) => onChangeDate(1, e)} appearance="subtle" />
                                </InputGroup>
                            </Col>

                            <Col xs={6} style={{ textAlign: "left" }}  className="mb-2">
                                <Button size="middle" type="primary"
                                    onClick={showCreateLead}
                                ><i className="fa-solid fa-file-excel mr-2"></i>Tạo Lead
                                </Button>
                            </Col>
                            <Col xs={6} style={{ textAlign: "right" }}  className="mb-2">
                                <Button size="middle" type="primary"
                                    onClick={hanldeExport}
                                ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            {createData ?
                 <Add
                 show={createData}
                 close={showCreateLead}
                 addLead={addLead}
                >
                </Add>
            : null}
        </div>
    )
}
export default memo(Header)