import React from "react";
import axiosService from "../../utils/axios.service";
import Edit from "./Edit";
import Add from "./Add";
import Delete from "./Delete";
import DisableBooking from "./DisableBooking";
import KTVBooking from "./KTVBooking";
import StoreBanking from "./StoreBanking";
import { notifications } from "../constant/Notify";
import * as convert from "../constant/Convert.helper"

import "./scss/stores.scss";

class Stores extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      showEdit: false,
      showAdd: false,
      showDelete: false,
      dataStore: [],
      lengthStore: 0,
      detailStore: {},
      user_admin: JSON.parse(localStorage.getItem("user_info")),
      dataDistrict: [],
      keyCity: null,
      keyDistrict: null,
      showDisableBooking: false,
      showKTVBooking: false,
      showStoreBanking: false
    };
  }

  componentDidMount() {
    this.getDataStores()
  }

  getDataStores = () => {
    axiosService("api/stores", "GET")
    .then((res) => {
      const response = res.data
      if (response.success) {
        this.setState({ 
          isLoaded: true
        });
        if (response.data !== null) {
          this.setState({ 
            dataStore: response.data,
            lengthStore: response.data.length
          });
        }
      }
    })
    .catch((error) => console.log(error));
  }
  showPopupEdit = (e) => {
    let dataLocation = convert.getDistricts(e.city, e.district)

    this.setState({
      keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
      keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
      dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : [],
      showEdit: true,
      detailStore: e,
    })
  };

  displayPopupDisableBooking = (e) => {
    this.setState({
      detailStore: e,
      showDisableBooking: !this.state.showDisableBooking
    })
  };

  displayPopupStoreBanking = (e) => {
    this.setState({
      detailStore: e,
      showStoreBanking: !this.state.showStoreBanking
    })
  };

  displayPopupKTVBooking = (e) => {
    this.setState({
      detailStore: e,
      showKTVBooking: !this.state.showKTVBooking
    })
  };

  hidePopupEdit = () => {
    this.setState({ showEdit: false });
  };

  showPopupAdd = () => {
    this.setState({ showAdd: true });
  };

  hidePopupAdd = () => {
    this.setState({ showAdd: false });
  };

  showPopupDelete = (e) => {
    this.setState({ showDelete: true, detailStore: e });
  };

  hidePopupDelete = () => {
    this.setState({ showDelete: false });
  };

  changeDataStore = (key, value) => {
    this.setState({
      detailStore: {
        ...this.state.detailStore,
        [key]: value
      }
    })
  }

  changeDataDistrict = (value) => {
    this.setState({
      dataDistrict: value
    })
  }

  updateStore = (event) => {
    if (!this.state.detailStore.name_store) {
      notifications({type: 'error', mess: 'Vui lòng điền đủ tên cơ sở.'});
      return
    }
    const dataUpdateStore = {
      "name_store": this.state.detailStore.name_store,
      "description": this.state.detailStore.description,
      "mobile": this.state.detailStore.mobile,
      "city": this.state.detailStore.city,
      "district": this.state.detailStore.district,
      "country": this.state.detailStore.country,
      "google_map": this.state.detailStore.google_map,
      "address": this.state.detailStore.address,
      "updated_by": this.state.user_admin.id
  };

    axiosService(
      "api/stores/" + this.state.detailStore.id,
      "PUT",
      dataUpdateStore
    )
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getDataStores()
          notifications({type: 'success', mess: 'Cập nhật thông tin cơ sở thành công'});
          this.hidePopupEdit();
        } else {
          notifications({type: 'error', mess: response.message});
        }
      })
      .catch((error) => console.log(error));
  };

  addStores = (data) => {
    if (!data.name_store) {
      notifications({type: 'error', mess: 'Vui lòng điền đủ tên cơ sở.'});
      return
    }
    axiosService("api/stores","POST", data)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getDataStores()
          notifications({type: 'success', mess: 'Tạo cơ sở thành công'});
          this.hidePopupAdd();
        } else {
          notifications({type: 'error', mess: response.message});
        }
      })
      .catch((error) => console.log(error));
  };

  deleteStore = (data) => {
    axiosService(
      "api/stores/" + data,
      "DELETE"
    )
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getDataStores()
          notifications({type: 'success', mess: 'Xóa cơ sở thành công'});
          this.hidePopupDelete();
        } else {
          notifications({type: 'error', mess: response.message});
        }
      })
      .catch((error) => console.log(error));
  };
  
  render() {
    if (this.state.isLoaded) {
      return (
        <div className="admin-page stores">
          <div className="page-title">
            <div className="title-page">
              <i className="fas fa-map-marker-alt"></i> Quản lý chi nhánh
              <span className="count-store"> (Tổng số chi nhánh: {this.state.lengthStore})</span>
            </div>
            {this.state.user_admin && this.state.user_admin.role != 4 ? 
              <div className="button-cent" onClick={this.showPopupAdd}>
                <span><i className="fas fa-map-marker-alt"></i></span> Thêm chi nhánh
              </div>
            : null }
          </div>
          <div className="admin-body">
            {this.state.lengthStore > 0 ? 
              <div className="dashboard-content">
                  <div className="wrapper">
                    {this.state.dataStore.map((val, key) => (
                      <div className="item-store" key={val.id}>
                        <h3 className="title-store">
                          {val.name_store}
                          {val.google_map && val.google_map !== '' ?
                            <a href={(val.google_map !== '') ? val.google_map : '#'} target="_blank" rel="noreferrer" className="button-cent style2">
                              <span><i className="fas fa-map-marked-alt"></i></span>
                              Xem bản đồ
                            </a>
                          : null}
                        </h3>
                        <p className="info-store">
                          <strong className="label"><i className="fas fa-home"></i> Địa chỉ: </strong>
                          <span className="value">
                            {val.address} 
                            {val.district ? ', ' + val.district : null}
                            {val.city ? ', ' + val.city : null}
                            {val.country ? ', ' + val.country : null}</span>
                        </p>
                        {val.mobile ? 
                          <p className="info-store">
                            <strong className="label"><i className="fas fa-phone"></i> Điện thoại: </strong>
                            <span className="value">{val.mobile}</span>
                          </p>
                        : null}
                        {this.state.user_admin && this.state.user_admin.role === 1 ? 
                          <div className="button-container">
                            <div className="button-cent style7" onClick={() => this.displayPopupStoreBanking(val)}>
                              <span><i className="fas fa-user-plus"></i></span> Ngân hàng
                            </div>
                            <div className="button-cent style6" onClick={() => this.displayPopupKTVBooking(val)}>
                              <span><i className="fas fa-user-plus"></i></span> Số lượng KTV
                            </div>
                            <div className="button-cent style5" onClick={() => this.displayPopupDisableBooking(val)}>
                              <span><i className="fas fa-calendar-alt"></i></span> Khoá Booking
                            </div>
                            <div className="button-cent" onClick={() => this.showPopupEdit(val)}>
                              <span><i className="fas fa-pen"></i></span>Sửa
                            </div>
                            <div className="button-cent style3" onClick={() => this.showPopupDelete(val)}>
                              <span><i className="fas fa-trash-alt"></i></span>Xóa
                            </div>
                          </div>
                        : null }
                      </div>             
                    ))}
                  </div>
              </div>
            : "" }
            <Edit show={this.state.showEdit} 
              detailStore={this.state.detailStore} 
              close={this.hidePopupEdit} 
              changeDataStore={this.changeDataStore} 
              updateStore={this.updateStore}
              dataDistrict={this.state.dataDistrict}
              keyCity={this.state.keyCity}
              keyDistrict={this.state.keyDistrict}
              changeDataDistrict={this.changeDataDistrict}>
            </Edit>
            <Add addStores={this.addStores} show={this.state.showAdd} close={this.hidePopupAdd}></Add>
            <Delete show={this.state.showDelete} detailStore={this.state.detailStore} close={this.hidePopupDelete} deleteStore={this.deleteStore}></Delete>
            {this.state.showDisableBooking ? 
              <DisableBooking displayPopupDisableBooking={this.displayPopupDisableBooking} detailStore={this.state.detailStore}></DisableBooking>
            : ''}
            {this.state.showKTVBooking ? 
              <KTVBooking displayPopupKTVBooking={this.displayPopupKTVBooking} detailStore={this.state.detailStore}></KTVBooking>
            : ''}
            {this.state.showStoreBanking ? 
              <StoreBanking displayPopupStoreBanking={this.displayPopupStoreBanking} detailStore={this.state.detailStore}></StoreBanking>
            : ''}
          </div>
        </div>
      );
    } else return null;
  }
}
export default Stores;
