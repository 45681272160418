import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LoginContext from "./context/login.context";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Provider } from 'react-redux'
import store from "./redux/stores"
import MetaPixel from "./components/MetaPixel";

ReactDOM.render(
  <LoginContext subPages={
    <Provider store={store}>
      <MetaPixel />
      <App />
    </Provider>
  } />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
