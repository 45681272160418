class StorageService {
  static getItem(key) {
    return window.localStorage.getItem(key);
  }

  static set(key, value) {
    return window.localStorage.setItem(key, value);
  }

  static delete(key) {
    return window.localStorage.removeItem(key);
  }
}

export default StorageService;
