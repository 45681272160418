import React, { useRef, useState, useCallback } from 'react';
import { formatValue } from "react-currency-input-field";
import { useReactToPrint } from "react-to-print";
import TemplatePrintOrder from './TemplatePrintOrder';

export const PrintSuccessOrder = (props) => {
    const invoiceRef = useRef(null);
    const [showPrint, setShowPrint] = useState(true)
    const [textWaiting, setTextWaiting] = useState('Bạn có muốn in hóa đơn')
    const onBeforeGetContentResolve = useRef(null);

    const handleOnBeforeGetContent = useCallback(() => {
        setShowPrint(false)
        setTextWaiting("Vui lòng chờ trong giây lát")
        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            resolve();
        });
    });

    const handleAfterPrint = useCallback(() => {
        setShowPrint(true)
        setTextWaiting('Bạn có muốn in hóa đơn')
    }, [setShowPrint, setTextWaiting]);

    const printInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    return (
        <div className="popup-cent">
            <div className="content-popup fadeInDown">
                <div className="head-popup">
                    <div className="title">Thông báo</div>
                    <div className="close" onClick={showPrint ? () => props.handleChangeState(false, 'printOrder') : () => { }}>×</div>
                </div>
                <div className="body-popup">
                    <div className="title-body">{textWaiting}</div>
                </div>
                <div className="bottom-popup">
                    {showPrint ?
                        <div className="button-container">
                            <div className="button-cent style3" onClick={() => props.handleChangeState(false, 'printOrder')}>
                                <span><i className="fas fa-times"></i></span>Không
                            </div>
                            <div className="button-cent" onClick={() => printInvoice()}>
                                <span><i className="fas fa-save"></i></span>Có

                            </div>
                        </div>
                        : null}
                </div>
            </div>
            <div className="overlay" onClick={showPrint ? () => props.handleChangeState(false, 'printOrder') : () => { }}></div>
            {props.currentPos !== '' ?
                <div style={{ display: "none" }}><TemplatePrintOrder ref={invoiceRef} transaction={props.transaction} currentPos={props.currentPos} /></div>
                : null}
        </div>
    );
}

export default PrintSuccessOrder;