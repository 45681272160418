import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Divider, Pagination, Table, Input, Select, Spin, Tag, Modal, message, Upload, Button, Alert } from 'antd';
import { ActivesEnum, typeVoucher } from "../../enums/voucher.enum";
// import Select from 'react-select'
import DatePicker from 'rsuite/DatePicker';
import DateTimePicker from 'rsuite/DatePicker';
import { EditOutlined, CloseOutlined, ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons"
import { useTranslation } from 'react-i18next';
import axiosService from "../../utils/axios.service";
import styles from "./style.module.css"
import { Link } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import StorageService from "../../utils/storage.service";
import async from "async"
import * as convert from "../constant/Convert.helper"
import { customStylesOption, customStylesMultiSelect } from '../constant/GeneralData'
import {
    optionsGender, optionsHoursBooking,
    optionsMinuteBooking, customStylesBooking,
    customStylesFilter, optionsStatusBooking,
    optionsSourceBooking, optionsViewOrderRemover,
    optionsStatusBookingForCS
  } from '../constant/GeneralData'
  import {
    optionsSourceFrom, customStylesFilterOption, hourSelect
  } from './constant/constant'
export default function Add(props) {
    const userLogin = JSON.parse(StorageService.getItem("user_info"));

    const [categoryVoucher, SetCategoryVoucher] = useState([])
    const [showPopupAddCategory, SetShowPopupAddCategory] = useState(false)
    const [dataEditCategory, setDataEditCategory] = useState([])
    const [bookHour, setBookHour] = useState(new Date().getHours())
    const [sourceFrom, setSourceFrom] = useState(optionsSourceFrom[0].value)
    const [optionCategory, setOptionCategory] = useState([])
    const [disableButtonAdd, setDisableButtonAdd] = useState(false)
    const [hour, setHour] = useState(hourSelect[(bookHour - 0)].value)
    const [minute, setMinute] = useState(0)
    const [nickName, setNickName] = useState('')
    const [date, setDate] = useState(new Date())
    const [category, setCategory] = useState([{value: 5, label: 'Triệt Lông'}])
    const [optionUser, setOptionUser] = useState([])
    const [userSelect, setUsers] = useState({})
    const [defaultU, setDefaultU] = useState({value: userLogin.id, label: userLogin.name})

    console.log(hourSelect[0])


    const [dataSource, setDataSource] = useState([])
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    useEffect(async () => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const { category, users } = await async.parallel({
            category: (cb) => {
                axiosService(`api/category?type=1&product_type=2`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            users: (cb) => {
                axiosService(`api/users/store/1?role=5`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
        })

        if (category.code == 200) {
            var option = []
            for (let item of category.data) {
                let row = {
                    value: item.id,
                    label: item.name
                }
                option.push(row)
                
            }
            setOptionCategory([...option])
        }
        if (users.code == 200) {
            var optionUser = []
            for (let item of users.data) {
                let row = {
                    value: item.id,
                    label: item.name
                }
                optionUser.push(row)
            }
            setOptionUser([...optionUser])
            setUsers({value: userLogin.id, label: userLogin.name})
        }
        
    }


    const handleAddCategory = () => {
        SetShowPopupAddCategory(!showPopupAddCategory)
        setDataEditCategory([])
    }

    const onChangeHour = (event) => {
        setHour(event)
    }

    const onChangeMinute = (event) => {
        setMinute(event)
    }

    const onChangeDate = (event) => {
        setDate(event)
    }

    const onChangeCategory = (e) => {
        let rows = []
        for(let item of optionCategory) {
            if (e.includes(item.value)) {
                rows.push(item)
            }
        }
        setCategory([...rows])
    }

    const onChangeSourceFrom = (e) => {
        setSourceFrom(e)
    }
    const onChangeUser = (e) => {
        let row = {}
        for(let item of optionUser) {
            if (item.value == e) {
                row = item
            }
        }
        setUsers({...row})
    }

    const onChangeNickName = (e) => {
        setNickName(e.target.value)
    }

    const addNewData = async (e) => {
        let book_hour = ("0" + hour).slice(-2),
            book_minute = ("0" + minute).slice(-2),
            book_date = convert.convertDate(date) + " " + [book_hour, book_minute].join(":");
        
        let onlineItems = []
        for(let item of category) {
            onlineItems.push({
                category_product_id: item.value,
                category_product_name: item.label
            })
        }
        let data = {
            online_date_start: new Date(book_date),
            nick_name: nickName,
            source_from: sourceFrom,
            sale_by: userSelect.value,
            sale_by_name: userSelect.label,
            onlineItem: onlineItems
        }

        if (nickName.length > 2) {
            props.addLead(data)
            props.close()
            //let res = await axiosService("api/sale/online", "POST", data)
        }
        
    }


    return (
        <div className="popup-cent">
            <div className="content-popup fadeInDown popup-view-services">
                <div className="head-popup">
                    <div className="title">Tạo lead</div>
                    <div className="close" onClick={props.close}>×</div>
                </div>
                <div className="body-popup">
                    <>
                        <div className="table-category_voucher create-data-sale">
                            <Row className="mt-4">
                                <div className="col-md-12 col-lg-10">

                                    <div className="items-data row">
                                        <div className="title col-3 col-md-2">Nick Name</div>
                                        <div className="value col-5 col-md-10">
                                            <input onChange={onChangeNickName} type="text" />
                                        </div>
                                    </div>
                                    <div className="items-data row pdt-10">
                                        <div className="title col-3 col-md-2">Giờ hẹn</div>
                                        <div className="value col-9 col-md-10">
                                            <div className="row">
                                                <div className="col-6 col-md-2 col-lg-2">
                                                    <Select className="select" onChange={onChangeHour}  defaultValue={hourSelect[bookHour]} options={[...hourSelect]} />
                                                </div>
                                                <div className="col-6 col-md-2 col-lg-2">
                                                    <Select className="select" onChange={onChangeMinute} styles={customStylesOption} defaultValue={optionsMinuteBooking[0]} options={optionsMinuteBooking} />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4" style={{ position: 'relative', zIndex: 1, top: '-3px' }}>
                                                    <DatePicker
                                                        onChange={onChangeDate}
                                                        className="custom-date-picker"
                                                        oneTap
                                                        format={'dd-MM-yyyy'}
                                                        defaultValue={new Date()}
                                                        placement={'bottomStart'}
                                                        size="sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="items-data row">
                                        <div className="title col-3 col-md-2">Nhóm dịch vụ</div>
                                        <div className="value col-9 col-md-10 select-option-one">
                                            <Select
                                                className="select"
                                                onChange={onChangeCategory}
                                                defaultValue={{value: 5, label: 'Triệt Lông'}}
                                                options={optionCategory}
                                                styles={customStylesFilterOption}
                                                mode="multiple"
                                            />
                                        </div>
                                    </div>
                                    <div className="items-data row">
                                        <div className="title col-3 col-md-2">Nhân viên tư vấn</div>
                                        <div className="value col-9 col-md-10 select-option-one">
                                            <Select
                                                className="select"
                                                onChange={onChangeUser}
                                                defaultValue={defaultU}
                                                options={optionUser}
                                                styles={customStylesFilterOption}
                                            />
                                        </div>
                                    </div>
                                    <div className="items-data row">
                                        <div className="title col-3 col-md-2">Nguồn đặt</div>
                                        <div className="value col-9 col-md-10 select-option-one">
                                            <Select
                                                className="select"
                                                onChange={onChangeSourceFrom}
                                                defaultValue={optionsSourceFrom[0]}
                                                options={optionsSourceFrom}
                                                styles={customStylesFilterOption}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </>

                </div>
                {!showPopupAddCategory ?
                    <div className="bottom-popup">
                        <div className="button-container">
                            <div className="button-cent style3" onClick={props.close}>
                                <span><i className="fas fa-times"></i></span>Đóng
                            </div>
                            <div className={"button-cent"} onClick={disableButtonAdd ? () => {} : addNewData}>
                                <span><i className="fas fa-save"></i></span>Lưu thông tin
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
            <div className="overlay" onClick={props.close}></div>
        </div>
    )
}
