import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Divider, Pagination, Table, Input, DatePicker, Select, Spin, Tag, Modal, message, Upload, Button, Alert } from 'antd';
import { ActivesEnum, typeVoucher } from "../../enums/voucher.enum";
import { EditOutlined, CloseOutlined, ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons"
import { useTranslation } from 'react-i18next';
import axiosService from "../../utils/axios.service";
import styles from "./style.module.css"
import { Link } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import async from "async"


export default function PopupViewServices(props) {
  
  const [categoryVoucher, SetCategoryVoucher] = useState([])
  const [showPopupAddCategory, SetShowPopupAddCategory] = useState(false)
  const [dataEditCategory, setDataEditCategory] = useState([])

  const [dataSource, setDataSource] = useState([])
  function convertCurrency(data) {
      let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
      return res;
  };
  const columns = [
      {
          title: "Tên dịch vụ",
          dataIndex: 'service_name',
          width: 200,
          key: 'service_name',
      },
      {
          title: "Số lần phục vụ",
          dataIndex: 'com_ktv',
          width: 200,
          key: 'com_ktv',
      },
      {
        title: "Hoa hồng",
        dataIndex: 'total',
        width: 200,
        key: 'total',
        render: (data) => {
          return (
              <p className="text1">{convertCurrency(Number(data))}</p>
          )
      },
    }
  ];

  useEffect(async () => {
    try {
      if (props.dataViewService.length > 0) {
        setDataSource(props.dataViewService)
      } else {
        message.error("Đã có lỗi sảy ra")
      }
       
    } catch (error) {
        console.error(error)
        message.error("Đã có lỗi sảy ra")
    }
}, [])


  const handleAddCategory = () => {
    SetShowPopupAddCategory(!showPopupAddCategory)
    setDataEditCategory([])
  }

    return (
      <div className="popup-cent">
        <div className="content-popup fadeInDown popup-view-services">
          <div className="head-popup">
            <div className="title">Thông báo</div>
            <div className="close" onClick={props.close}>×</div>
          </div>
          <div className="body-popup">
              <>
               <Row className="d-flex justify-content-between p-2">
                <div className="title-body">Danh sách dịch vụ phục vụ</div>
                <div className="d-flex">
                    <div className={`${styles.addButton} p-3 pl-5 pr-5`} onClick={handleAddCategory}>
                        Tổng tiền: <span style={{color: '#40a9ff', fontWeight: 'bold'}}>{convertCurrency(props.totalComKtv)}</span>
                    </div>
                </div>
              </Row>
              <div className="table-category_voucher">
                  <Row className="mt-4">
                    <Col xs={12} className="p-2">
                        <Table 
                          // className="w-100" 
                          dataSource={dataSource} 
                          columns={columns} 
                          pagination={false}
                          scroll={{ x: 1300 }}
                          // bordered
                        />
                    </Col>
                  </Row>
              </div>
              </>
           
          </div>
          {!showPopupAddCategory?
             <div className="bottom-popup">
             <div className="button-container">
               <div className="button-cent style3" onClick={props.close}>
                 <span><i className="fas fa-times"></i></span>Đóng
               </div>
             </div>
           </div>
          : null}
        </div>
        <div className="overlay" onClick={props.close}></div>
      </div>
    )
}
