import React from "react";
import axiosService from "../../utils/axios.service";
import Edit from "./Edit";
import Add from "./Add";
import EditGroup from "./EditGroup";
import DeleteGroup from "./DeleteGroup";
import DeleteServices from "./DeleteServices";
import { formatValue } from "react-currency-input-field";
import $ from "jquery";
import { notifications } from "../constant/Notify";

import "./scss/product.scss";

class Product extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      showAdd: false,
      showEdit: false,
      showDeleteService: false,
      showGroup: false,
      showDeleteGroup: false,
      listGroup: [],
      listServices: [],
      detailService: [],
      detailGroup: "",
      nameGroup: "",
      listSelectGroup: [],
      statusPopupCat: false,
      optionsHour: [
        { value: 0, label: "0 tiếng" },
        { value: 1, label: "1 tiếng" },
        { value: 2, label: "2 tiếng" },
        { value: 3, label: "3 tiếng" },
        { value: 4, label: "4 tiếng" },
        { value: 5, label: "5 tiếng" },
        { value: 6, label: "6 tiếng" },
      ],
      optionsStatus: [
        { value: 1, label: "Sẵn sàng" },
        { value: 2, label: "Tạm dừng" },
      ],
      optionsMinus: [
        { value: 0, label: "0 phút" },
        { value: 5, label: "5 phút" },
        { value: 10, label: "10 phút" },
        { value: 15, label: "15 phút" },
        { value: 20, label: "20 phút" },
        { value: 25, label: "25 phút" },
        { value: 30, label: "30 phút" },
        { value: 35, label: "35 phút" },
        { value: 40, label: "40 phút" },
        { value: 45, label: "45 phút" },
        { value: 50, label: "50 phút" },
        { value: 55, label: "55 phút" },
      ],
      optionsRanking: [
        { value: 1, label: "Không có hạng" },
        { value: 2, label: "Nổi bật" },
        { value: 3, label: "Top 1" },
        { value: 4, label: "Top 2" },
        { value: 5, label: "Top 3" },
      ],
      optionsUnit: [
        { value: 0, label: "ml" },
        { value: 1, label: "L" },
        { value: 2, label: "cm" },
        { value: 3, label: "m" },
        { value: 4, label: "Gr" },
        { value: 5, label: "kg" },
        { value: 6, label: "Lần" },
        { value: 7, label: "Cái" },
        { value: 8, label: "Chiếc" },
        { value: 9, label: "Túi" },
        { value: 10, label: "Viên" },
        { value: 11, label: "Tấm" },
        { value: 12, label: "Miếng" },
        { value: 13, label: "Tuýp" },
        { value: 14, label: "Bộ" },
        { value: 15, label: "Gói" },
        { value: 16, label: "Que" },
        { value: 17, label: "Bình" },
        { value: 18, label: "Hũ" },
        { value: 19, label: "Quả" },
        { value: 20, label: "Hộp" },
      ],
      optionsCountUnit: [
        { value: 0, label: "Chai" },
        { value: 1, label: "Hộp" },
        { value: 2, label: "Lọ" },
        { value: 3, label: "Thùng" },
        { value: 4, label: "Gói" },
        { value: 5, label: "Tá" },
        { value: 6, label: "Khay" },
        { value: 7, label: "Cái" },
        { value: 8, label: "Chiếc" },
        { value: 9, label: "Túi" },
        { value: 10, label: "Viên" },
        { value: 11, label: "Tấm" },
        { value: 12, label: "Miếng" },
        { value: 13, label: "Tuýp" },
        { value: 14, label: "Bộ" },
        { value: 15, label: "Gói" },
        { value: 16, label: "Que" },
        { value: 17, label: "Bình" },
        { value: 18, label: "Hũ" },
        { value: 19, label: "Quả" },
        { value: 20, label: "Hộp" },
        { value: 21, label: "Cuộn" },
        { value: 22, label: "Vỉ" },
        { value: 23, label: "Thanh" },
        { value: 24, label: "ml" },
        { value: 25, label: "L" },
      ],
      dataEditService: {},
      user_admin: JSON.parse(localStorage.getItem("user_info")),
    };
  }

  changeDataService = (key, value) => {
    let detailService = this.state.detailService;
    if (key.includes("meta")) {
      detailService = {
        ...detailService,
        meta_object: {
          ...detailService.meta_object,
          [key.split("-")[0]]: value,
        },
      };
    } else {
      detailService = {
        ...detailService,
        [key]: value,
      };
    }
    this.setState({
      detailService: detailService,
    });
  };

  //Action Popup Service
  showPopupAddService = () => {
    this.setState({ showAdd: true });
  };

  hidePopupAddService = () => {
    this.setState({ showAdd: false });
  };

  showPopupEditService = (e) => {
    this.setState({ showEdit: true, detailService: e });
  };

  hidePopupEditService = () => {
    this.setState({ showEdit: false });
  };

  showPopupDeleteService = (e) => {
    this.setState({
      showDeleteService: true,
      detailService: e,
    });
  };

  hidePopupDeleteService = () => {
    this.setState({ showDeleteService: false });
  };

  addServices = (data) => {
    if (!data.product_name) {
      notifications({type: "error",mess: "Tên sản phẩm không được trống"});
      return
    }
    axiosService("api/product","POST", data)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory()
          this.getService()
          this.hidePopupAddService()
          notifications({
            type: "success",
            mess: "Tạo sản phẩm mới thành công",
          });
        } else {
          notifications({
            type: "error",
            mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  updateService = (data) => {
    if (!data.product_name) {
      notifications({type: "error",mess: "Tên sản phẩm không được trống."});
      return
    }
    axiosService(
      "api/product/" + this.state.detailService.id,
      "PUT",
      data
    )
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory()
          this.getService();
          this.hidePopupEditService()
          notifications({
            type: "success",
            mess: "Sửa sản phẩm thành công",
          });
        } else {
          notifications({
            type: "error",
            mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  deleteService= (data) => {
    axiosService(
      "api/product/" + data,
      "DELETE"
    )
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory()
          this.getService();
          this.hidePopupDeleteService()
          notifications({ type: "success", mess: "Xóa nhóm thành công" });
        } else {
          notifications({ type: "error", mess: "Đã có lỗi xảy ra, vui lòng thử lại sau" });
        }
      })
      .catch((error) => console.log(error));
  };
  //End action Popup Service


  //Action Popup Group
  showPopupEditGroup = (e) => {
    let button = document.getElementsByClassName(
      "button-edit " + e.currentTarget.dataset.id
    );
    button[0].classList.remove("active");
    this.setState({
      showGroup: true,
      detailGroup: e.currentTarget.dataset,
    });
  };

  hidePopupEditGroup = () => {
    this.setState({ showGroup: false });
  };

  showPopupDeleteGroup = (e) => {
    let button = document.getElementsByClassName(
      "button-edit " + e.currentTarget.dataset.id
    );
    button[0].classList.remove("active");
    this.setState({
      showDeleteGroup: true,
      detailGroup: e.currentTarget.dataset,
    });
  };

  hidePopupDeleteGroup = () => {
    this.setState({ showDeleteGroup: false });
  };

  clickToShowAction = (event) => {
    event.currentTarget.parentElement.classList.toggle("active");
  };

  changeNameGroup = (e) => {
    this.setState({ nameGroup: e.target.value });
  };

  createGroup = (event) => {
    const dataGroup = {
      name: this.state.nameGroup,
      type: 2,
    };
    if (!this.state.nameGroup ) {
      notifications({type: "error",mess: "Tên nhóm khộng được trống",});
      return
    }

    axiosService("api/category", "POST", dataGroup)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory();
          notifications({
            type: "success",
            mess: "Tạo nhóm mới thành công",
          });
        } else {
          notifications({
            type: "error",
            mess: response.message,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  updateGroup = (data) => {
    const dataUpdate = {
      name: data.name,
    };
    if (!data.name ) {
      notifications({type: "error",mess: "Tên nhóm khộng được trống",});
      return
    }


    axiosService("api/category/" + data.id, "PUT", dataUpdate)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory();
          notifications({
            type: "success",
            mess: "Cập nhật nhóm thành công",
          });
          this.hidePopupEditGroup();
        } else {
          notifications({
            type: "error",
            mess: response.message,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  deleteGroup = (data) => {
    axiosService("api/category/" + data.id, "DELETE")
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.getCategory();
          notifications({
            type: "success",
            mess: "Xóa nhóm thành công",
          });
          this.hidePopupDeleteGroup();
        } else {
          notifications({
            type: "error",
            mess: response.message,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  //End action Popup Group

  searchService = (e) => {
    axiosService("api/product?keyword=" + e.target.value, "GET")
      .then((res) => {
        let response = res.data;
        if (response.code === 200) {
          this.setState({ listServices: response.data.data });
        }
      })
      .catch((error) => console.log(error));
  };

  filterServiceByGroup = (event) => {
    let id = event.currentTarget.dataset.id;
    $(".item-product").removeClass("active");
    $('.item-product[data-id="' + id + '"]').addClass("active");
    if (id != 0) {
      axiosService("api/category/product/" + id + "?type=3", "GET")
        .then((res) => {
          const response = res.data;
          if (response.code === 200) {
            this.setState({
              listServices: response.data,
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      axiosService("api/product?type=3", "GET")
        .then((res) => {
          let response = res.data;
          if (response.code === 200) {
            this.setState({
              listServices: response.data.data,
            });
          }
        })
        .catch((error) => console.log(error));
    }
    this.setState({
      statusPopupCat: false,
    });
  };

  getCategory = () => {
    axiosService("api/category?type=2&product_type=3", "GET")
      .then((res) => {
        let response = res.data;
        const selectDataStore = [];
        if (response.code === 200) {
          if (response.data !== null) {
            for (const key in response.data) {
              selectDataStore.push({
                value: response.data[key].id,
                label: response.data[key].name,
              });
            }
            this.setState({
              listSelectGroup: selectDataStore,
            });
          }
          this.setState({
            isLoaded: true,
            listGroup: response.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  getService = () => {
    axiosService("api/product?type=3", "GET")
      .then((res) => {
        let response = res.data;
        if (response.code === 200) {
          this.setState({
            isLoaded: true,
            listServices: response.data.data,
          });
        }
      })
      .catch((error) => console.log(error));
  };
  componentDidMount() {
    this.getCategory();
    this.getService();
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="admin-page product">
          <div className="page-title">
            <div className="title-page">
              <i className="fas fa-couch"></i> Quản lý sản phẩm
            </div>
            <div className="button-cent ic-none show-popup-cat" onClick={() => this.setState({statusPopupCat: true})}>Nhóm sản phẩm</div>
          </div>
          <div className="admin-body">
            <div className="wrapper">
              <div className={`column-left ${this.state.statusPopupCat ? "active" : ""}`}>
                <div className="wrapper-column">
                  <div className="head-column">
                    <i className="fas fa-list-ul"></i> Phân nhóm sản phẩm
                    <div className="button-close" onClick={() => this.setState({statusPopupCat: false})}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 35 36" fill="none">
                        <path d="M26.25 9L8.75 27" stroke="#696B73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M8.75 9L26.25 27" stroke="#696B73" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                    </div>
                  </div>
                  <div className="body-column">
                    {this.state.listGroup ? (
                      <div className="list-product">
                        <div
                          className="item-product active"
                          data-id="0"
                          onClick={this.filterServiceByGroup}
                        >
                          <div className="title">
                            <span>Tất cả sản phẩm</span>
                          </div>
                        </div>
                        {this.state.listGroup.map((val, key) => (
                          <div
                            className="item-product"
                            key={val.id}
                            data-id={val.id}
                            onClick={this.filterServiceByGroup}
                          >
                            <div className="title">
                              <span>{val.name}</span> ({val.product.length})
                            </div>
                            <div className={"button-edit " + val.id}>
                              <i className="fas fa-chevron-down" onClick={this.clickToShowAction}></i>
                              <div className="dropdown-action">
                                <div
                                  className="action edit"
                                  data-id={val.id}
                                  data-name={val.name}
                                  onClick={this.showPopupEditGroup}
                                >
                                  <i className="fas fa-pen"></i>Sửa
                                </div>
                                <div
                                  className="action remove"
                                  data-id={val.id}
                                  onClick={this.showPopupDeleteGroup}
                                >
                                  <i className="fas fa-trash-alt"></i> Xóa
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) 
                  : null}
                  </div>
                  {this.state.user_admin && this.state.user_admin.role != 4 ?
                    <div className="bottom-column">
                      <div className="row">
                        <div className="col-8">
                          <input
                            className="form-control"
                            onChange={this.changeNameGroup}
                            name="category-product"
                            type="text"
                            placeholder="nhập tên nhóm..."
                          />
                        </div>
                        <div className="col-4">
                          <div className="button-cent2" onClick={this.createGroup}>
                            <i className="fas fa-plus-circle"></i>Tạo nhóm
                          </div>
                        </div>
                      </div>
                    </div>
                  : null}
                </div>
                <div className="overlay-cat" onClick={() => this.setState({statusPopupCat: false})}></div>
              </div>
              <div className="column-right">
                <div className="head-column">
                  <div className="search-product">
                    <input
                      className="search-input"
                      onChange={this.searchService}
                      placeholder="Tìm kiếm theo tên sản phẩm"
                      name="search"
                    />
                    <i className="fas fa-search"></i>
                  </div>
                  {this.state.user_admin && this.state.user_admin.role != 4 ?
                    <div
                      className="button-cent"
                      onClick={this.showPopupAddService}
                    >
                      <span>
                        <i className="fas fa-couch"></i>
                      </span>{" "}
                      Tạo sản phẩm mới
                    </div>
                  : null}
                </div>
                <div className="body-column">
                  <table className="row-border table">
                    <thead className="hide-mobile">
                      <tr role="row">
                        <th
                          className="sorting"
                          tabIndex="0"
                          rowSpan="1"
                          colSpan="1"
                        >
                          Tên sản phẩm
                        </th>
                        <th
                          className="dt-right sorting pdr-65"
                          tabIndex="0"
                          rowSpan="1"
                          colSpan="1"
                        >
                          Giá bán
                        </th>
                        <th
                          className="dt-right sorting"
                          tabIndex="0"
                          rowSpan="1"
                          colSpan="1"
                        >
                          Đơn vị
                        </th>
                        <th
                          className="sorting"
                          tabIndex="0"
                          rowSpan="1"
                          colSpan="1"
                        >
                          Tình trạng
                        </th>
                        {this.state.user_admin && this.state.user_admin.role != 4 ?
                          <th
                            className="sorting"
                            tabIndex="0"
                            rowSpan="1"
                            colSpan="1"
                          >
                            Action
                          </th>
                        : null }
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.listServices !== null ? (
                        this.state.listServices.map((val, key) => (
                          <tr role="row" className="odd ng-scope item-mobile-table" key={val.id}>
                            <td className="mobile-table">
                              <div class="title hide-desktop">Tên sản phẩm</div>
                              <div className="value">
                              <span className="text-primary">
                                  {val.product_name}
                                </span>
                                <br />
                                <span className="sku">{val.code}</span>
                              </div>
                            </td>
                            <td className="mobile-table dt-right">
                              <div class="title hide-desktop">Giá bán</div>
                              <div className="value">
                                <span className="cash-vnd">
                                  {formatValue({
                                    value: String(val.price),
                                    groupSeparator: ".",
                                    decimalSeparator: ".",
                                  })}{" "}
                                  đ
                                </span>
                                <br />
                                {val.meta_object.status_edit_price === "1" ? (
                                  <span className="note">
                                    (có thể sửa giá khi tính tiền)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td className="mobile-table dt-right">
                              <div class="title hide-desktop">Đơn vị</div>
                              <div className="value">{val.meta_object.count_unit}</div>
                            </td>
                            <td className="mobile-table">
                              <div class="title hide-desktop">Tình trạng</div>
                              <div className="value">
                                {val.status === 1 ? <span className="tag green">Sẵn sàng</span> : <span className="tag red">Tạm dừng</span>}
                              </div>
                            </td>
                            {this.state.user_admin && this.state.user_admin.role != 4 ?
                              <td className="mobile-table">
                                <div className="btn-group">
                                  <div
                                    className="button radius-left"
                                    type="button"
                                    onClick={() => this.showPopupEditService(val)}
                                  >
                                    <i className="fas fa-pen"></i> Sửa
                                  </div>
                                  <div
                                    className="button radius-right"
                                    onClick={() => this.showPopupDeleteService(val)}
                                  >
                                    Xóa
                                  </div>
                                  <ul className="dropdown-menu pull-right">
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-trash-alt"></i> Xóa
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            : null }
                          </tr>
                        )))
                      : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Add
            show={this.state.showAdd}
            close={this.hidePopupAddService}
            listSelectGroup={this.state.listSelectGroup}
            optionsHour={this.state.optionsHour}
            optionsStatus={this.state.optionsStatus}
            optionsMinus={this.state.optionsMinus}
            optionsRanking={this.state.optionsRanking}
            addServices={this.addServices}
            optionsUnit={this.state.optionsUnit}
            optionsCountUnit={this.state.optionsCountUnit}
          ></Add>
          {this.state.showEdit ?
            <Edit
              show={this.state.showEdit}
              close={this.hidePopupEditService}
              detailService={this.state.detailService}
              listSelectGroup={this.state.listSelectGroup}
              optionsHour={this.state.optionsHour}
              optionsStatus={this.state.optionsStatus}
              optionsMinus={this.state.optionsMinus}
              optionsRanking={this.state.optionsRanking}
              changeDataService={this.changeDataService}
              updateService={this.updateService}
              optionsUnit={this.state.optionsUnit}
              optionsCountUnit={this.state.optionsCountUnit}
            ></Edit>
           : ''}
          <DeleteServices
            show={this.state.showDeleteService}
            detailService={this.state.detailService}
            close={this.hidePopupDeleteService}
            deleteService={this.deleteService}
          ></DeleteServices>

          <EditGroup
            show={this.state.showGroup}
            close={this.hidePopupEditGroup}
            detailGroup={this.state.detailGroup}
            updateGroup={this.updateGroup}
          ></EditGroup>
          <DeleteGroup
            show={this.state.showDeleteGroup}
            close={this.hidePopupDeleteGroup}
            detailGroup={this.state.detailGroup}
            deleteGroup={this.deleteGroup}
          ></DeleteGroup>
        </div>
      );
    } else return null;
  }
}
export default Product;
