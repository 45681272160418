import { useState } from "react";
import axiosService from "../../../utils/axios.service";



export function PopupConfirmReOrder(props) {
    const [description, setDescription] = useState(null)

    var acceptCancelOrder = (event) => {
  
        var order = {
          order_code: props.orderDetail.order_code,
          status: props.orderDetail.status,
          total_price:  props.orderDetail.total_price,
          order_at: props.orderDetail.order_at,
          store_name: props.orderDetail.store_name,
          payment_type: props.orderDetail.payment_type,
          money_paid: props.orderDetail.money_paid,
          money_owed: props.orderDetail.money_owed,
          id_booking: props.orderDetail.id_booking,
          isDeposit: props.orderDetail.isDeposit,
          deposit_total: props.orderDetail.deposit_total,
          created_name: props.orderDetail.created_name,
          staff_booking: props.orderDetail.staff_booking,
          source_from: props.orderDetail.source_from,
          customer_mobile:  props.orderDetail.customer_mobile,
          customer_name: props.orderDetail.customer_name,
          total_price_before: props.orderDetail.total_price_before,
          description: props.orderDetail.description,
          sale_rule_applied_ids: props.orderDetail.sale_rule_applied_ids,
          User: {
            id: props.orderDetail.User.id
          },
          stores: {
              id: props.orderDetail.stores.id,
              name_store:  props.orderDetail.stores.name_store
          },
          customers: {
              id:  props.orderDetail.customers.id
          },
          updatedBy: {
            id: props.orderDetail.updatedBy.id
          },
          orderItem: props.orderItem
        }

        props.reOrder(order)
    }
    var hiddenPopup = () => {
        props.setShow(false)
    }
    
    return (
        <div>
            <div className="head-popup">
                <div className="title">Thông báo Re Order đơn hàng <span></span> </div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn Re Order hàng không ?</div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={hiddenPopup} >
                  <span><i className="fas fa-times" ></i></span>Không
                </div>
                <div className="button-cent" onClick={acceptCancelOrder}>
                  <span><i className="fas fa-save"></i></span>Có
                </div>
              </div>
            </div>
        </div>
    )
}