import React, { useCallback, useState } from 'react'
import { Button, Checkbox, Dropdown } from 'rsuite'
import './scss/membercards.scss'
import { formatValue } from 'react-currency-input-field';
import { listStatusBooking } from './constant/ConstantPackage';
import { event } from 'jquery';
import PackageHistory from './PackageHistory'
import axiosService from "../../utils/axios.service";





var _props = null
var _state = {}
var isLoading = false

const MemberItem = ({ userAdmin, item, show, showDelete, getDataPackageDetail , showEdit}) => {

  const [getStatus, setStatus] = useState(item.status)

  const convertDateTime = (event) => {
    let date = new Date(event),
      month = date.getMonth() + 1,
      day = date.getDate(),
      hours = date.getHours(),
      minus = date.getMinutes();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minus < 10) minus = '0' + minus;
    let text = day + '-' + month + '-' + date.getFullYear();
    text = day + '/' + month + '/' + date.getFullYear();

    return text
  };
  const hendleShowHistory = (event) => {
    getDataPackageDetail(item)
    show(true)
  }

  const hendleShowDelete = (item) => {
    getDataPackageDetail(item)
    showDelete(true)
  }
  const hendleShowEidt = (item) => {
    getDataPackageDetail(item)
    showEdit(true)
  }

  const hendleCardLock = (status) => {

    axiosService(
      "api/package/status/" + item.id + "?status=" + status,
      "POST"
    )
      .then((res) => {
        const response = res.data;
        if (response.message === "success") {
          setStatus(status)
        }
      })
      .catch((error) => console.log(error));
  };
  const hendelClose = (event) => {
    this.props.close(false)
  }

  const handleRenderStatus = useCallback((status) => {
    let backgroundButton = ""
    let text = ""
    if (status === 1) {
      backgroundButton = "used"
      text = "Sử dụng"
    }
    if (status === 2) {
      backgroundButton = "lock"
      text = "Đã khóa"
    }

    if (status === 3) {
      backgroundButton = "out-of-date"
      text = "Hết hạn"
    }

    if (status === 4) {
      backgroundButton = "complete"
      text = "Hoàn thành"
    }
    if (status === 5) {
      backgroundButton = "lock"
      text = "hoàn tiền"
    }

    if (status === 6) {
      backgroundButton = "lock"
      text = "Nâng cấp"
    }

    return <div className={"status-package " + backgroundButton}>{text}</div>
  }, [])

  return (
    <div className="member-item">
      {item.customer && (
        <div className="customer">
          <div>
            <p>{item.customer.full_name} {item.customer?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</p>
            <div>
              <i className="fa-solid fa-phone-rotary"></i>
              {item.customer.mobile}
            </div>
          </div>
        </div>
      )}
      <div className="card-info-member">
        <div>
          {item.package_code}
          {/* <Button>
            <i className="fa-solid fa-pen"></i>
          </Button> */}
          <div className='ml-3'>{item.type}</div>
        </div>
        <span>{item.product_name}</span>
      </div>
      <div className="provided-day">{convertDateTime(item.date_of_issue)}</div>
      {item.expiration_date && (
        <div className={item.status != 3 ? "out-date-time cl-green" : "out-date-time cl-out-of-date"}>{convertDateTime(item.expiration_date)}</div>
      )}

      <div className="nearest-item-used">{item.last_used ? convertDateTime(item.last_used) : ""}</div>
      <div className="used-time"><span>{item.count_used ? item.count_used : 0}</span> <span>/ {item.max_used < 1000 ? item.max_used : 'Không giới hạn'}</span></div>

      <div className="money-remain"> <span>{item.price_of_card ? formatValue({
        value: String(item.price_of_card),
        groupSeparator: ',',
        decimalSeparator: '.',
      }) : '0'} đ </span></div>

      <div className="status">{handleRenderStatus(getStatus)}</div>
      {/* <div className="select-member-checkbox">
        <Checkbox />
      </div> */}
      <div className="history-used-item-th">
        <div onClick={() => hendleShowHistory(item)} className="history-used-title">L.sử sử dụng</div>
        {userAdmin && userAdmin.role != 4 ?
          <Dropdown title={<></>} placement="bottomEnd" icon={<i className="fa-solid fa-angle-down"></i>} >
            <Dropdown.Item onClick={() => hendleShowEidt(item)}>
              <i className="fa-solid fa-pen"></i>
              <span className="pl-2">Chỉnh sửa</span>
            </Dropdown.Item>
            {/* Khoá thẻ */}
            {getStatus == 2 ?
              <Dropdown.Item onClick={() => hendleCardLock(1)}>
                <i className="fa-solid fa-lock-open"></i>
                <span className="pl-2">Mở khoá thẻ</span>
              </Dropdown.Item>
              : ""}
  
            {getStatus == 1 ?
              <Dropdown.Item onClick={() => hendleCardLock(2)}>
                <i className="fa-solid fa-lock"></i>
                <span className="pl-2">Khoá thẻ</span>
              </Dropdown.Item>
              : ""}
            <Dropdown.Item>
              <i className="fa-solid fa-ban"></i>
              <span className="pl-2">Thu hồi thẻ</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => hendleShowDelete(item)}>
              <i className="fa-solid fa-trash-can"></i>
              <span className="pl-2">Xoá thẻ</span>
            </Dropdown.Item>
          </Dropdown>
        : null}
      </div>
    </div>
  )
}

export default MemberItem
