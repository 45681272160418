import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import CurrencyInput from 'react-currency-input-field';
import { DatePicker } from "rsuite";
import { isAfter } from "date-fns";
import { format } from "date-fns"
import _ from 'lodash';
import Select from "react-select";
import { accountPayment, accountSwipe } from "../constant/GeneralData"
import axiosService from "../../utils/axios.service";
import { notifications } from "../constant/Notify";

import "./scss/styles.scss"
export function UpdateTransaction(props) {
    const paymentMethod = [
        { value: "Tiền mặt", label: "Tiền mặt" },
        { value: "Chuyển khoản", label: 'Chuyển khoản' },
        { value: 'Quẹt thẻ', label: 'Quẹt thẻ' },
        { value: 'Không thanh toán', label: 'Không thanh toán' },
    ]
    const [price, setPrice] = useState(props.dataTransaction.paid_amount)
    const [dateCreate, setDateCreate] = useState(props.dataTransaction.transaction_at)
    const [payment, setPayment] = useState(props.dataTransaction.pay_type)
    const [numberBank, setNumberBank] = useState(props.dataTransaction.pay_account_number)
    const [storeId, setStoreId] = useState(props.dataTransaction.store_id)
    
    const saveUpdate = async () => {
        let data = {
            order_id: props.dataTransaction.order.id,
            paid_amount: price,
            transaction_at: dateCreate,
            pay_type: payment,
            pay_account_number: numberBank,
            store_id: storeId
        }
        
        let res = await axiosService("api/transaction/update-transaction/" + props.dataTransaction.id, "PUT", data)
        if (res.data.success) {
            props.disablePopup(false)
            props.reloadData()
            notifications({ type: 'success', mess: 'Cập nhật giao dịch thành công' });
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    const findValueSelect = (option, value) => {
        let indexSelected = _.findIndex(option, function (o) { return o.value == value; });
        return option[indexSelected];
    }

    const onChangePaidAmount = (event) => {
        let dataPrice = event.target.value.replaceAll(",", "");
        setPrice(Number(dataPrice))
    }

    return (
        <div className={`popup-report`}>
            <div className="head-popup">
                <div className="title">Sửa giao dịch</div>
            </div>
            <div className="body-popup">
                <Row>
                    <Col xs={4} className="info-transaction">
                        <div className="label-info">Mã HĐ: <b>{props.dataTransaction.order_code}</b></div>
                        <div className="label-info">Mã GD: <b>{props.dataTransaction.transaction_code}</b></div>
                    </Col>
                    <Col xs={4} className="info-transaction">
                        <div className="label-info">Số tiền:</div>
                        <div className="value-info">
                            <CurrencyInput
                                className="input-cash"
                                defaultValue={price}
                                groupSeparator={','}
                                decimalSeparator={'.'}
                                onChange={(event) => onChangePaidAmount(event)}
                            />
                        </div>
                    </Col>
                    <Col xs={4} className="info-transaction">
                        <div className="label-info">Thời gian tạo: </div>
                        <div className="value-info">
                            <DatePicker
                                disabled={props.dataTransaction.status == 1 ? true : false}
                                defaultValue={new Date(dateCreate)}
                                oneTap
                                format={"HH:mm:ss dd-MM-yyyy"}
                                className="custom-date-picker"
                                onChange={(event) => {setDateCreate(event)}}
                                size="sm"
                                disabledDate={date => isAfter(date, new Date())}
                            />
                        </div>
                    </Col>
                    
                    <Col xs={6} className="info-transaction">
                        <div className="label-info">Phương thức thanh toán: </div>
                        <div className="value-info">
                            <Select
                                isSearchable={false}
                                className="select"
                                defaultValue={findValueSelect(paymentMethod, payment)}
                                options={paymentMethod}
                                onChange={(event) => {setPayment(event.value)}}
                            />
                        </div>
                    </Col>
                    <Col xs={6} className="info-transaction">
                        <div className="label-info">Số tài khoản: </div>
                        <div className="value-info">
                            {payment == "Chuyển khoản" ?
                                <Select
                                    isSearchable={false}
                                    className="select"
                                    defaultValue={findValueSelect(accountPayment, numberBank)}
                                    options={accountPayment}
                                    onChange={(event) => {setNumberBank(event.value)}}
                                />
                            : null}
                            {payment == "Quẹt thẻ" ?
                                <Select
                                    isSearchable={false}
                                    className="select"
                                    defaultValue={findValueSelect(accountSwipe, numberBank)}
                                    options={accountSwipe}
                                    onChange={(event) => {setNumberBank(event.value)}}
                                />
                            : null}
                        </div>
                    </Col>
                    <Col xs={4} className="info-transaction">
                        <div className="label-info">Người tạo: {props.dataTransaction.created_by_name}</div>
                    </Col>
                </Row>
            </div>
            <div className="bottom-popup">
                <div className="select-store">
                    <Select
                        isSearchable={false}
                        className="select"
                        defaultValue={findValueSelect(props.listStore, props.dataTransaction.store_id)}
                        options={props.listStore}
                        onChange={(event) => {setStoreId(event.value)}}
                        isDisabled={props.dataTransaction.status == 1 ? true : false}
                    />
                </div>
                <div className="button-container">
                    <div className="button-cent style3" onClick={() => {props.disablePopup(false)}} >
                    <span><i className="fas fa-times" ></i></span>Hủy
                    </div>
                    <div className="button-cent" onClick={saveUpdate}>
                    <span><i className="fas fa-save"></i></span>Cập nhật
                    </div>
                </div>
            </div>
        </div>
    )
}

