import React, { useState, useEffect } from "react";
import { testVoucher } from "./constant/PosCustomer";
import CurrencyInput from "react-currency-input-field";
import * as convert from "../constant/Convert.helper";
import axiosService from "../../utils/axios.service";
import Select from "react-select";
import _ from "lodash"

var currentStore = localStorage.getItem("currentStore")
const VoucherItem = (props) => {
  const _props = props;
  return (
    <div>
        <div className="product-image" style={{"backgroundImage": "url('/images/placeholder/voucher.png')"} }></div>
        <div className="pull-left" style={{"width": "calc(100% - 55px)"}}>
        <div className="product-name">{_props.voucher.name}</div>
        <div className="pull-left clear">
            <div className="product-sku text-left">{_props.voucher.code}</div>
            <div className="customer-booking-total ng-isolate-scope">
                <span className="cash-vnd customer-booking-total">{_props.voucher.apply == 1 ? _props.voucher.amount + '%' :convert.convertCurrency(_props.voucher.amount)}</span>
            </div>
        </div>
        </div>                   
    </div>
  );
};

export default VoucherItem;
