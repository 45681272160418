import React, { useState, useEffect, useRef } from "react";
import { axiosGet } from "../../../utils/axios.service";
import axiosService from "../../../utils/axios.service";
import { notifications } from "../../constant/Notify";
import * as convert from "../../constant/Convert.helper"

let _ = require('lodash');

export function PopupConvertMoney(props) {
  const [isEdit, setEdit] = useState(true)
  const [priceConvert, setPriceConvert] = useState(0)
  const [priceLoyalty, setPriceLoyalty] = useState(0)
  const [priceDeposit, setPriceDeposit] = useState(0)

  useEffect( () => {
    let transaction = props.orderDetail.transaction
    let convert = _.sumBy(transaction, function (o) { return (['Tiền mặt', 'Chuyển khoản', 'Quẹt thẻ'].includes(o.pay_type)) ? o.paid_amount : 0 });
    let loyalty = _.sumBy(transaction, function (o) { return (['Điểm tích luỹ'].includes(o.pay_type)) ? o.paid_amount : 0 });
    let deposit = _.sumBy(transaction, function (o) { return (['Sử dụng thẻ tiền'].includes(o.pay_type)) ? o.paid_amount : 0 });
    setPriceConvert(convert)
    setPriceLoyalty(loyalty)
    setPriceDeposit(deposit)
  }, [props.orderDetail])


  let acceptAction = async (event) => {
      setEdit(false)
      const response = await axiosService("api/order-list/convert-money/" + props.orderDetail.id, "POST", {store_id: props.currentStore, store_name: props.currentNameStore})
      console.log(response.data)
      if (response.data.success == true) {
        setEdit(true)
        props.setShow(false)
        props.setDetailOrder(response.data.data)
        props.updateDataOrders(response.data.data)
        notifications({ type: 'success', mess: 'Chuyển đổi thành công' });
      } else {
        setEdit(true)
        notifications({ type: 'error', mess: response.data.message });
      }
  }
  
  return (
    <div>
        <div className="head-popup">
            <div className="title">Thông báo chuyển đổi<span></span> </div>
        </div>
        <div className="body-popup">
          <div className="title-body">Sau khi chuyển đổi các thẻ lên tại order này sẽ bị huỷ</div>
            <div className="panel-body">
              {priceConvert > 0 ? <p>- Số tiền chuyển đổi: {convert.convertCurrency(priceConvert)}</p> : null}
              {priceLoyalty > 0 ? <p>- Số tiền bị mất (điểm tích luỹ): {convert.convertCurrency(priceLoyalty)}</p> : null}
              {priceDeposit > 0 ? <p>- Số tiền trả lại (thẻ tiền): {convert.convertCurrency(priceDeposit)}</p> : null}
              {(priceDeposit+priceConvert) > 0 ? <p>- Tổng tiền: {convert.convertCurrency(priceDeposit+priceConvert)}</p> : null}
            </div>
        </div>
        <div className="bottom-popup">
        {isEdit ? 
          <div className="button-container">
              <div className="button-cent style3" onClick={() => props.setShow(false)} >
                <span><i className="fas fa-times" ></i></span>Không
              </div>
              <div className="button-cent" onClick={acceptAction}>
                <span><i className="fas fa-save"></i></span>Có
              </div>
          </div>
        : ''}
        </div>
    </div>
  )
}