import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { ApplyEnum } from "../../../enums/voucher.enum";
import * as convert from "../../constant/Convert.helper";
import axiosService from "../../../utils/axios.service";
let _ = require('lodash');

const PopupVoucher = (props) => {
  const [dataVoucher, setDataVoucher] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);

  useEffect(async () => {
    try {
        let res = await axiosService("api/order-list/get-voucher/" + props.orderDetail.id, "GET")
        if (res.data.success == true) {
            setDataVoucher(res.data.data.dataVoucher)
            setDataProduct(res.data.data.dataProduct)
        }
    } catch (error) {
        console.error(error)
    }
  }, [])

  const getTitleTypeVoucher = (type) => {
    let name = '';
    if (type == ApplyEnum.PERCENT) name = "Phần trăm"
    if (type == ApplyEnum.PRICE) name = "Giá mặc định"
    if (type == ApplyEnum.PRODUCT) name = "Tặng sản phẩm"
    if (type == ApplyEnum.PRODUCT_PERCENT) name = "Giảm giá % từng sản phẩm"
    if (type == ApplyEnum.PRODUCT_PRICE) name = "Giảm giá tiền từng sản phẩm"
    return name
  }

  const getValueTypeVoucher = (type, value , giftProduct) => {
    let res = [];
    if (type == ApplyEnum.PERCENT) res.push(value + "%")
    if (type == ApplyEnum.PRICE) res.push(convert.convertCurrency(value))
    if ([ApplyEnum.PRODUCT,ApplyEnum.PRODUCT_PERCENT,ApplyEnum.PRODUCT_PRICE].includes(type)) {
        let idProduct = giftProduct.split(",").map(Number);
        if (dataProduct.length > 0) {
            idProduct.map(x => {
                let product = _.filter(dataProduct, function (o) { return o.id === x })
                let string = product[0]?.product_name;
                if (ApplyEnum.PRODUCT_PERCENT == type) string = "Giảm " + value + "% - " + product[0]?.product_name
                if (ApplyEnum.PRODUCT_PRICE == type) string = "Giảm " + convert.convertCurrency(value) + " - " + product[0]?.product_name
                
                res.push(string)
            })
        }

    }
    
    return res
  }

  return (
    <div className="content-popup fadeInDown package-popup voucher">
        <div className="head-popup">
            <div className="title">Danh sách voucher</div>
            <div className="close" onClick={() => props.setStatusVoucher(!props.statusVoucher)}>×</div>
        </div>
        <div className="body-popup">
            <table className="table datatable-key-basic dataTable no-footer table-hover">
                <thead>
                    <tr>
                        <th>Mã Voucher</th>
                        <th>Tên voucher</th>
                        <th>Level</th>
                        <th>Loại ưu đãi</th>
                        <th className="text-center">Ưu đãi</th>
                        <th className="text-center">Mô tả</th>
                    </tr>
                </thead>
                <tbody>
                    {dataVoucher ? 
                    dataVoucher.map((val, key) => (
                        <tr key={key} ng-repeat="item in order.orderHistory" className="ng-scope">
                            <td className="ng-binding">{val.code}</td>
                            <td className="ng-binding">{val.rule_name}</td>
                            <td className="ng-binding">{val.level != 0 ? "LV" + val.level : ""}</td>
                            <td className="ng-binding">{getTitleTypeVoucher(val.apply)}</td>
                            <td className="text-center ng-binding">
                                {getValueTypeVoucher(val.apply, val.amount, val.packages).map((x,y) => (
                                    <p key={y}>{x}</p>
                                ))}
                            </td>
                            <td className="text-right">{val.des}</td>
                        </tr>
                    )): <tr></tr>}
                </tbody>
            </table>
        </div>
        <div className="bottom-popup">
            <div className="button-container">
            <div className="button-cent" onClick={() => props.setStatusVoucher(!props.statusVoucher)}>
                <span><i className="fas fa-save"></i></span>Đóng
            </div>
            </div>
        </div>
    </div>
  );
}

export default PopupVoucher;
