import React from "react";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

class Edit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ServiceGroupId: this.props.detailService.category ? this.props.detailService.category.id : '',
      ServiceName: this.props.detailService.product_name,
      Code: this.props.detailService.code,
      Description: this.props.detailService.description,
      Price: this.props.detailService.price,
      BasePrice: this.props.detailService.base_price,
      Ranking: this.props.detailService.ranking,
      Status: this.props.detailService.status,
      StatusEditPrice: false,
      StatusPrintHour: false,
      unit: this.props.detailService.meta_object.unit,
      count_unit:  this.props.detailService.meta_object.count_unit,
      amount: this.props.detailService.meta_object.amount,
      brand: this.props.detailService.meta_object.brand
    }
  }
  getCurrentDataSelect = (listData, currentData) => {
    let res;
    listData.findIndex((item) => {
      if (item.value === currentData) res = item
    }) 
    return res
  }

  getCurrentDataSelect2 = (listData, currentData) => {
    let res;
    listData.findIndex((item) => {
      if (item.label === currentData) res = item
    }) 
    return res
  }
  
  onChangePrice = (key, event) => {
    let dataPrice = event.replaceAll(",", "");
    this.setState({key: dataPrice})
  };

  onChangeBrand = (event) => {
    this.setState({
      brand: event.target.value,
    });
  }

  onChangeUnit = (event) => {
    this.setState({
      unit: event.label,
    });
  }

  onChangeAmount = (event) => {
    this.setState({
      amount: event.target.value,
    });
  }

  onChangeCountUnit = (event) => {
    this.setState({
      count_unit: event.label,
    });
  }
  changeDataServiceCode = (key, event) => {
    this.setState({
      Code: event,
    });
  }

  changeDataServiceName = (key, event) => {
    this.setState({
      ServiceName: event,
    });
  }

  changeDataServiceGroupId = (key, event) => {
    this.setState({
      ServiceGroupId: event,
    });
  }

  changeDataServiceRanking = (key, event) => {
    this.setState({
      Ranking: event,
    });
  }

  changeDataServiceDescription = (key, event) => {
    this.setState({
      Description: event,
    });
  }

  changeDataServiceStatus = (key, event) => {
    this.setState({
      Status: event,
    });
  }

  updateProduct = (event) => {
    const dataServices = {
      "code": this.state.Code,
      "product_name": this.state.ServiceName,
      "price": this.state.Price,
      "base_price": this.state.BasePrice,
      "category_id": this.state.ServiceGroupId,
      "ranking": this.state.Ranking,
      "description": this.state.Description,
      "status": this.state.Status,
      "type": 3,
      "meta_object": {
        "brand" : this.state.brand,
        "unit": this.state.unit,
        "amount": this.state.amount,
        "count_unit": this.state.count_unit
      }
    };
    this.props.updateService(dataServices)
  }

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Tạo dịch vụ mới</div>
              <div className="close" onClick={this.props.close}>
                ×
              </div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12">
                  <div className="items-data row">
                    <div className="title col-4">Mã dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <input
                        onChange={(e) =>
                          this.changeDataServiceCode("Code", e.target.value)
                        }
                        defaultValue={this.props.detailService.code}
                        type="text"
                      />
                      * Chú ý: Hệ thống sẽ tự sinh mã nếu bạn không nhập
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Tên dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <input
                        className="required"
                        onChange={(e) =>
                          this.changeDataServiceName(
                            "ServiceName",
                            e.target.value
                          )
                        }
                        defaultValue={this.props.detailService.product_name}
                        placeholder="Nhập tên dịch vụ"
                        name="service_name"
                      />
                    </div>
                  </div>
                  
                  <div className="items-data row">
                    <div className="title col-4">Giá dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            placeholder="Nhập giá dịch vụ"
                            defaultValue={this.props.detailService.price}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            onChange={(e) =>
                              this.onChangePrice("price", e.target.value)
                            }
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá gốc dịch vụ</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            defaultValue={this.props.detailService.base_price}
                            groupSeparator={","}
                            decimalSeparator={"."}
                            onChange={(e) =>
                              this.onChangePrice("base_price", e.target.value)
                            }
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Phân nhóm sản phẩm</div>
                    <div className="value col-8 col-md-7">
                      <Select
                        className="select"
                        options={this.props.listSelectGroup}
                        onChange={(e) =>
                          this.changeDataServiceGroupId("ServiceGroupId", e.value)
                        }
                        defaultValue={this.getCurrentDataSelect(this.props.listSelectGroup, this.props.detailService.category_id)}
                      />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Nhãn hiệu sản phẩm</div>
                    <div className="value col-8 col-md-7"><input onChange={this.onChangeBrand} defaultValue={this.props.detailService.meta_object.brand != 'brand_name' ? this.props.detailService.meta_object.brand : ''} placeholder="Nhập nhãn hiệu của sản phẩm" name="brand"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Đơn vị đếm</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeUnit} defaultValue={this.getCurrentDataSelect2(this.props.optionsCountUnit, this.props.detailService.meta_object.count_unit)} options={this.props.optionsCountUnit} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Dung tích / số lượng</div>
                    <div className="value col-4">
                    <input onChange={this.onChangeAmount} placeholder="" defaultValue={this.props.detailService.meta_object.amount} name="amount"/>
                    </div>
                    <div className="value col-4 col-md-3">
                      <Select className="select" onChange={this.onChangeCountUnit} defaultValue={this.getCurrentDataSelect2(this.props.optionsUnit, this.props.detailService.meta_object.unit)} options={this.props.optionsUnit} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Xếp hạng nổi bật (app KH)</div>
                    <div className="value col-8 col-md-7">
                      <Select
                        className="select"
                        onChange={(e) =>
                          this.changeDataServiceRanking("Ranking", e.value)
                        }
                        defaultValue={this.getCurrentDataSelect(this.props.optionsRanking, this.props.detailService.ranking)}
                        options={this.props.optionsRanking}
                      />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thông tin chi tiết</div>
                    <div className="value col-8 col-md-7">
                      <textarea
                        onChange={(e) =>
                          this.changeDataServiceDescription(
                            "Description",
                            e.target.value
                          )
                        }
                        defaultValue={this.props.detailService.description}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="status-product">
                <Select
                  className="select"
                  onChange={(e) =>
                    this.changeDataServiceStatus(
                      "Status",
                      e.value
                    )
                  }
                  defaultValue={this.getCurrentDataSelect(this.props.optionsStatus, this.props.detailService.status)}
                  options={this.props.optionsStatus}
                />
              </div>
              <div className="button-container">
                <div className="button-cent" onClick={this.updateProduct}>
                  <span>
                    <i className="fas fa-save"></i>
                  </span>
                  Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Edit;
