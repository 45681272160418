import React from "react";
import location from "../../context/vietnam.json";
import { customStylesOption } from '../constant/GeneralData'
import Select from 'react-select'

class Add extends React.Component {
  constructor() {
    super();
    this.state = {
      name_store: "",
      description: "",
      mobile: "",
      city: "",
      district: "",
      country: "",
      google_map: "",
      address: "",
      dataDistrict: [],
      keyCity: null,
      keyDistrict: null,
      user_admin: JSON.parse(localStorage.getItem("user_info")),
    };
  }
  selectDistrictRef = null;
  onChangeName = (event) => {
    this.setState({
      name_store: event.target.value,
    });
  };
  onChangeDesc = (event) => {
    this.setState({
      description: event.target.value,
    });
  };
  onChangeMobile = (event) => {
    this.setState({
      mobile: event.target.value,
    });
  };
  onChangeAddress = (event) => {
    this.setState({
      address: event.target.value,
    });
  };
  onChangeGoogleMap = (event) => {
    this.setState({
      google_map: event.target.value,
    });
  };
  onChangeCity = (event) => {
    this.setState({
      city: event.label,
      dataDistrict: event.districts,
      district: ''
    });
    this.selectDistrictRef.clearValue();
  };
  onChangeDistrict = (event) => {
    if (event) this.setState({ district: event.label });
  };
  onChangeCountry = (event) => {
    this.setState({
      country: event.target.value,
    });
  };
  addNewStores = () => {
    const dataStores = {
      "name_store": this.state.name_store,
      "description": this.state.description,
      "mobile": this.state.mobile,
      "city": this.state.city,
      "district": this.state.district,
      "country": this.state.country,
      "google_map": this.state.google_map,
      "address": this.state.address,
      "created_by": this.state.user_admin.id ?? ''
    };

    this.props.addStores(dataStores)
  };

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thêm chi nhánh</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="items-data row">
                    <div className="title col-3">Tên chi nhánh</div>
                    <div className="value col-9"><input onChange={this.onChangeName} className="required" type="text"placeholder="Tên chi nhánh" name="name"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Mô tả</div>
                    <div className="value col-9"><textarea onChange={this.onChangeDesc} placeholder="Mô tả về chi nhánh" name="description"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Điện thoại</div>
                    <div className="value col-9"><input onChange={this.onChangeMobile} className="required" placeholder="012345678" type="text" name="phone_number"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Địa chỉ</div>
                    <div className="value col-9"><input onChange={this.onChangeAddress} type="text" placeholder="Địa chỉ" name="address"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Địa chỉ google map</div>
                    <div className="value col-9"><input onChange={this.onChangeGoogleMap} type="text" name="link_google_map" placeholder="https://www.google.com/maps/place/Cent+Beauty+-+Tr%E1%BA%A7n+Duy+H%C6%B0ng/@21.0118922,105.7980533,17z/data=!3m1!4b1!4m5!3m4!1s0x3135ad362928d68b:0x804fa682efaa78c4!8m2!3d21.0118926!4d105.8002441?hl=vi-VN"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Thành phố</div>
                    <div className="value col-9">
                      <Select className="select"
                        isSearchable={true}
                        placeholder="Tỉnh/Thành"
                        onChange={this.onChangeCity}
                        styles={customStylesOption}
                        defaultValue={location[this.state.keyCity]}
                        options={location} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Quận/Huyện</div>
                    <div className="value col-9">
                      <Select className="select"
                        ref={ref => { this.selectDistrictRef = ref }}
                        isSearchable={true}
                        placeholder="Quận/Huyện"
                        onChange={this.onChangeDistrict}
                        styles={customStylesOption}
                        defaultValue={this.state.dataDistrict[this.state.keyDistrict]}
                        options={this.state.dataDistrict} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Quốc gia</div>
                    <div className="value col-9"><input onChange={this.onChangeCountry} type="text" name="country"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className="button-cent" onClick={this.addNewStores}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Add;
