import { NotificationManager } from "react-notifications";

export function notifications(data) {
    switch (data.type) {
      case "info":
        NotificationManager.info(data.mess, "", 1000);
        break;
      case "success":
        NotificationManager.success(data.mess, "Thành công", 3000);
        break;
      case "warning":
        NotificationManager.warning(data.mess, "Cảnh báo", 3000);
        break;
      case "customer":
        NotificationManager.success(data.mess, "Khách hàng đến store", 3000);
        break;
      case "error":
        NotificationManager.error(data.mess, "Lỗi", 3000);
        break;
    }
}
