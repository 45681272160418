import React from "react";
import axiosService from "../../utils/axios.service";
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Hour: this.props.optionsHour[0].value,
      Minute: this.props.optionsMinus[6].value,
      ServiceGroupId: "",
      ServiceName: "",
      Code: "",
      Description: "",
      Price: "",
      BasePrice: "",
      Ranking: this.props.optionsRanking[0].value,
      Status: 1,
      StatusEditPrice: false,
      StatusPrintHour: false,
      CreatedBy: 1,
      unit: this.props.optionsUnit[0].label,
      count_unit: this.props.optionsCountUnit[0].label,
      amount: '',
      brand: ''
    }
  }
  onChangeCode = (event) => {
    this.setState({
      Code: event.target.value,
    });
  };

  onChangeServiceName = (event) => {
    this.setState({
      ServiceName: event.target.value,
    });
  };

  onChangeTimeHour = (event) => {
    this.setState({
      Hour: event.value,
    });
  };

  onChangeTimeMinus = (event) => {
    this.setState({
      Minute: event.value,
    });
  };

  onChangeStatusPrintHour = (event) => {
    this.setState({
      StatusPrintHour: !this.state.StatusPrintHour,
    });
  };

  onChangePrice = (event) => {
    let dataPrice = event.target.value.replaceAll(",", "");
    this.setState({
      Price: dataPrice,
    });
  };

  onChangeStatusEditPrice = (event) => {
    this.setState({
      StatusEditPrice: !this.state.StatusEditPrice,
    });
  };

  onChangeBasePrice = (event) => {
    let dataBasePrice = event.target.value.replaceAll(",", "");
    this.setState({
      BasePrice: dataBasePrice,
    });
  };

  onChangeGroup = (event) => {
    this.setState({
      ServiceGroupId: event.value,
    });
  };

  onChangeRanking = (event) => {
    this.setState({
      Ranking: event.value,
    });
  };

  onChangeDescription = (event) => {
    this.setState({
      Description: event.target.value,
    });
  };

  onChangeStatus = (event) => {
    this.setState({
      Status: event.value,
    });
  };

  onChangeBrand = (event) => {
    this.setState({
      brand: event.target.value,
    });
  }

  onChangeUnit = (event) => {
    this.setState({
      unit: event.label,
    });
  }

  onChangeAmount = (event) => {
    this.setState({
      amount: event.target.value,
    });
  }

  onChangeCountUnit = (event) => {
    this.setState({
      count_unit: event.label,
    });
  }
  // amount
  // count_unit

  addNewServices = () => {
    let StatusEditPrice = (this.state.StatusEditPrice === true ) ? 1 : 2,
        StatusPrintHour = (this.state.StatusEditPrice === true ) ? 1 : 2;
    const dataServices = {
      "code": this.state.Code,
      "product_name": this.state.ServiceName,
      "price": this.state.Price,
      "base_price": this.state.BasePrice,
      "category_id": this.state.ServiceGroupId,
      "ranking": this.state.Ranking,
      "description": this.state.Description,
      "status": this.state.Status,
      "type": 3,
      "meta_object": {
        "brand" : this.state.brand,
        "unit": this.state.unit,
        "amount": this.state.amount,
        "count_unit": this.state.count_unit
      }
    };
    
    this.props.addServices(dataServices)
  };
  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Tạo sản phẩm mới</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              {/* <div className="header-tab">
                <div className={"item" + (this.props.currentAddTab === 1 ? ' active' : '')} onClick={() => this.props.changeAddTabs(1)}>Thông tin sản phẩm</div>
                <div className={"item" + (this.props.currentAddTab === 2 ? ' active' : '')} onClick={() => this.props.changeAddTabs(2)}>Tùy chọn giá</div>
                <div className={"item" + (this.props.currentAddTab === 3 ? ' active' : '')} onClick={() => this.props.changeAddTabs(3)}>Địa điểm cung cấp</div>
              </div> */}
              <div className="row">
                <div className="col-12">
                  <div className="items-data row">
                    <div className="title col-4">Mã sản phẩm</div>
                    <div className="value col-8 col-md-7">
                      <input onChange={this.onChangeCode} type="text"/>
                      * Chú ý: Hệ thống sẽ tự sinh mã nếu bạn không nhập
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Tên sản phẩm</div>
                    <div className="value col-8 col-md-7"><input className="required" onChange={this.onChangeServiceName} placeholder="Nhập tên sản phẩm" name="service_name"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá sản phẩm</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            placeholder="Nhập giá sản phẩm"
                            defaultValue={0}
                            groupSeparator={','}
                            decimalSeparator={'.'}
                            onChange={this.onChangePrice}
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Giá gốc sản phẩm</div>
                    <div className="value col-8 col-md-7">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <CurrencyInput
                            className="tr price-input"
                            defaultValue={0}
                            groupSeparator={','}
                            decimalSeparator={'.'}
                            onChange={this.onChangeBasePrice}
                          />
                          <div className="currency">₫</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Phân nhóm sản phẩm</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeGroup} options={this.props.listSelectGroup} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Nhãn hiệu sản phẩm</div>
                    <div className="value col-8 col-md-7"><input onChange={this.onChangeBrand} placeholder="Nhập nhãn hiệu của sản phẩm" name="brand"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Đơn vị đếm</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeUnit} defaultValue={this.props.optionsCountUnit[0]} options={this.props.optionsCountUnit} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Dung tích / số lượng</div>
                    <div className="value col-4">
                    <input onChange={this.onChangeAmount} placeholder="" defaultValue={0} name="amount"/>
                    </div>
                    <div className="value col-4 col-md-3">
                      <Select className="select" onChange={this.onChangeCountUnit} defaultValue={this.props.optionsUnit[0]} options={this.props.optionsUnit} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Xếp hạng nổi bật (app KH)</div>
                    <div className="value col-8 col-md-7">
                      <Select className="select" onChange={this.onChangeRanking} defaultValue={this.props.optionsRanking[0]} options={this.props.optionsRanking} />
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-4">Thông tin chi tiết</div>
                    <div className="value col-8 col-md-7">
                      <textarea onChange={this.onChangeDescription} type="text"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="status-product">
                <Select className="select" onChange={this.onChangeStatus} defaultValue={this.props.optionsStatus[0]} options={this.props.optionsStatus} />
              </div>
              <div className="button-container">
                <div className="button-cent" onClick={this.addNewServices}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Add;
