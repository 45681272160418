import React from "react";
import axiosService from "../../utils/axios.service";

class Delete extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteData = (event) => {
    axiosService(
      "api/package/" + this.props.package.id,
      "DELETE"
    )
      .then((res) => {
        const response = res.data;
        if (response.message === "success") {
          window.location.reload()
        }
      })
      .catch((error) => console.log(error));
  };
  hendelClose =(event) => {
    this.props.close(false)
  }

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thông báo</div>
              <div className="close" onClick={this.hendelClose}>×</div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn thẻ này không ?</div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.hendelClose}>
                  <span><i className="fas fa-times"></i></span>Không
                </div>
                <div className="button-cent" onClick={this.deleteData}>
                  <span><i className="fas fa-save"></i></span>Có
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.hendelClose}></div>
        </div>
      );
    } else return <div></div>;
  }
}
export default Delete;
