import React, { useState, useEffect } from 'react'
import { Table, Dropdown, Button, Checkbox, Modal } from 'rsuite'
import _ from 'lodash';


const Strapi = () => {
  var url = process.env.REACT_APP_PRODUCTION == 'production' ? 'https://api.cent.beauty/admin/auth/login' : 'http://localhost:1337/admin/auth/login'
  const user = JSON.parse(localStorage.getItem("user_info"))
  let gmail = ''
  if (user.role == 1) {
    gmail = 'admin@centbeauty.com'
  } else {
    gmail = 'marketing@centbeauty.com'
  }
  url = url + '?gmail=' + gmail
  return (
    <div className="admin-page orders member-card" style={{height: '100%'}}>
      <div  style={{height: '100%'}}>
        <iframe src={url} style={{width: '100%', height: '900px'}}></iframe>
      </div>
    </div>
  )
}

export default Strapi
