import React from "react";
import axiosService from "../../utils/axios.service";
import Select from 'react-select';
import { customStylesBirthday } from './constant/CustomerBirthday'
import StorageService from "../../utils/storage.service";
import { notifications } from "../constant/Notify";
import location from "../../context/vietnam.json";
class AddCustomer extends React.Component {
  constructor() {
    super();
    this.state = {
      code: '',
      name: '',
      email: '',
      idCard: '',
      gender: 2,
      mobile: '',
      day: '',
      month: '',
      year: '',
      zaloAccount: '',
      facebookId: '',
      pancake_id: null,
      job: '',
      company: '',
      ranking: '',
      referralSource: '',
      city: '',
      district: '',
      dataDistrict: [],
      country: 'Việt Nam',
      address: '',
      note: '',
    };
  }

  customStylesSelect = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      maxHeight: 32
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight = 0;
      const height = 32
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
  };
  customStylesBirthday = customStylesBirthday;
  onChangeCode = (event) => {
    this.setState({
      code: event.target.value,
    });
  };
  onChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  onChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  onChangeIdCard = (event) => {
    this.setState({
      idCard: event.target.value,
    });
  };
  onChangeGender = (event) => {
    this.setState({
      gender: event.target.value,
    });
  };
  onChangeMobile = (event) => {
    this.setState({
      mobile: event.target.value,
    });
  };
  onChangeDay = (event) => {
    this.setState({
      day: event.value,
    });
  };
  onChangeMonth = (event) => {
    this.setState({
      month: event.value,
    });
  }
  onChangeYear = (event) => {
    this.setState({
      year: event.target.value,
    });
  };
  onChangeZaloAccount = (event) => {
    this.setState({
      zaloAccount: event.target.value,
    });
  };
  onChangeFacebookId = (event) => {
    this.setState({
      facebookId: event.target.value,
    });
  }
  onChangeJob = (event) => {
    this.setState({
      job: event.target.value,
    });
  };
  onChangeCompany = (event) => {
    this.setState({
      company: event.target.value,
    });
  }
  onChangeRanking = (event) => {
    this.setState({
      ranking: event.value,
    });
  };
  onChangeReferralSource = (event) => {
    this.setState({
      referralSource: event.target.value,
    });
  }
  onChangeCity = (event) => {
    this.setState({
      city: event.label,
      dataDistrict: event.districts,
      district: '',
    });
    this.selectDistrictRef.clearValue();
  }
  onChangeDistrict = (event) => {
    if (event) {
      this.setState({
        district: event.label,
      });
    }
  };
  onChangeCountry = (event) => {
    this.setState({
      country: event.target.value,
    });
  }
  onChangeAddress = (event) => {
    this.setState({
      address: event.target.value,
    });
  };
  onChangeNote = (event) => {
    this.setState({
      note: event.target.value,
    });
  }

  addNewData = () => {
    this.props.hanldeClick()
    let errDate = false
    if (this.state.day == null || this.state.day == "") {
      errDate = true
    }
    if (this.state.month == null || this.state.month == "") {
      errDate = true
    }
    if (this.state.year == null || this.state.year == "" || this.state.year.length <= 3) {
      errDate = true
    }
    if (errDate) {
      notifications({ type: 'error', mess: "Ngày sinh khách hàng không hợp lệ" });
      this.props.hanldeClickTrue()
      return
    } else {
      let birthDay = this.state.day + '/' + this.state.month + '/' + this.state.year;
      const data = {
        "code": this.state.code,
        "full_name": this.state.name,
        "email": this.state.email,
        "id_card": this.state.idCard,
        "gender": Number(this.state.gender),
        "mobile": this.state.mobile,
        "birthday": birthDay,
        "zalo_account": this.state.zaloAccount,
        "facebook_id": this.state.facebookId,
        "pancake_id": this.state.pancake_id,
        "job": this.state.job,
        "company": this.state.company,
        "ranking": Number(this.state.ranking),
        "referral_source": this.state.referralSource,
        "city": this.state.city,
        "district": this.state.district,
        "address": this.state.address,
        "country": this.state.country,
        'note': this.state.note,
        "stores": [Number(StorageService.getItem("currentStore"))]
      };

      this.props.addCustomer(data)
    }

  };

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Thêm khách hàng mới</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12">
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Mã khách hàng</div>
                        <div className="value col-8"><input onChange={this.onChangeCode} type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Họ tên</div>
                        <div className="value col-8"><input className="required" onChange={this.onChangeName} type="text" /></div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Giới tính</div>
                        <div className="value col-8">
                          <label className="radio-button">
                            <input type="radio" value="1" name="gender" checked={true} onChange={this.onChangeGender} />
                            Chị
                          </label>
                          <label className="radio-button">
                            <input type="radio" value="2" name="gender" onChange={this.onChangeGender} />
                            Anh
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Số điện thoại</div>
                        <div className="value col-8"><input onChange={this.onChangeMobile} type="text" /></div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Tài khoản Zalo</div>
                        <div className="value col-8"><input onChange={this.onChangeZaloAccount} type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Ngày sinh</div>
                        <div className="value col-8">
                          <div className="row">
                            <div className="col-4">
                              <Select className="select" onChange={this.onChangeDay} placeholder="Ngày" styles={this.customStylesBirthday} options={this.props.optionsDays} />
                            </div>
                            <div className="col-4">
                              <Select className="select" onChange={this.onChangeMonth} placeholder="Tháng" styles={this.customStylesBirthday} options={this.props.optionsMonths} />
                            </div>
                            <div className="col-4">
                              <input onChange={this.onChangeYear} placeholder="Năm" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">CCCD</div>
                        <div className="value col-8"><input onChange={this.onChangeIdCard} type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-2">Email</div>
                    <div className="value col-10"><input onChange={this.onChangeEmail} type="text" /></div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Facebook ID</div>
                        <div className="value col-8"><input onChange={this.onChangeFacebookId} placeholder="Facebook ID" type="text" /></div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Pancake ID</div>
                        <div className="value col-8"><input onChange={(e) => {this.setState({pancake_id: e.target.value })}} placeholder="Pancake ID" type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Nghề nghiệp</div>
                        <div className="value col-8"><input onChange={this.onChangeJob} type="text" /></div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Công ty</div>
                        <div className="value col-8"><input onChange={this.onChangeCompany} type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Hạng</div>
                        <div className="value col-8">
                          <Select className="select" onChange={this.onChangeRanking} styles={this.customStylesSelect} options={this.props.optionsRanking} />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="title col-4">Nguồn giới thiệu</div>
                        <div className="value col-8"><input onChange={this.onChangeReferralSource} type="text" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12">
                      <div className="row">
                        <div className="title col-4 col-md-2">Địa chỉ</div>
                        <div className="value col-8 col-md-10">
                          <div className="row">
                            <div className="col-6 col-md-4">
                              <Select className="select data-birthday location"
                                placeholder="Tỉnh/Thành phố"
                                isSearchable={true}
                                onChange={this.onChangeCity}
                                styles={this.customStylesSelect}
                                options={location} />
                            </div>
                            <div className="col-6 col-md-4">
                              <Select className="select data-birthday location"
                                ref={ref => { this.selectDistrictRef = ref }}
                                placeholder="Quận/Huyện"
                                isSearchable={true}
                                onChange={this.onChangeDistrict}
                                styles={this.customStylesSelect}
                                options={this.state.dataDistrict} />
                            </div>
                            <div className="col-12 col-md-4">
                              <input onChange={this.onChangeCountry} defaultValue={this.state.country} type="text" placeholder="Quốc gia" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12">
                      <div className="row">
                        <div className="title col-4 col-md-2"></div>
                        <div className="value col-8 col-md-10">
                          <input onChange={this.onChangeAddress} placeholder="Địa chỉ" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-data row">
                    <div className="col-12">
                      <div className="row">
                        <div className="title col-4 col-md-2">Ghi chú</div>
                        <div className="value col-8 col-md-10">
                          <textarea onChange={this.onChangeNote} type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className={`button-cent ${this.props.click ? "disable-button":""}`} onClick={this.addNewData}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default AddCustomer;
