import React, { useState, useLayoutEffect, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth, startOfDay, endOfDay, addDays } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { DataGrid } from '@mui/x-data-grid';
import { Column } from '@ant-design/plots';
import { Spin } from "antd"
import StorageService from "../../utils/storage.service";
import "./scss/report.scss"
import 'antd/dist/antd.min.css'
const DetailPromotion = () => {
    const [title, setTiltle] = useState("Chi tiết promotion")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [groupId, setGroupId] = useState(0)
    const [voucherName, setVoucherName] = useState(null)
    const [receipt, setReceipt] = useState({})
    const [receiptByCash, setReceiptByCash] = useState(0)
    const [receiptByCredit, setReceiptByCredit] = useState(0)
    const [paySwipeCard, setPaySwipeCard] = useState(0)
    const [payCardMoney, setPayCardMoney] = useState(0)
    const [payLoyalty, setPayLoyalty] = useState(0)
    const [payOther, setPayOther] = useState({})
    const [dataChart, setDataChart] = useState([])
    const [rows, setRows] = useState([])
    const [totalVouchers, setTotalVouchers] = useState(0)
    const [totalOrderPrice, setTotalOrderPrice] = useState(0)
    const [totalOrderPriceBefore, setTotalOrderPriceBefore] = useState(0)

    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const history = useHistory();
     

    let currentUrl = window.location.pathname
    if (currentUrl.includes('report/detail-promotion') && [3, 4].includes(userAdmin.role)) history.push('/report/daily');

    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const columns = [
        { field: 'id', headerName: 'STT', width: 20 },
        { field: 'name', headerName: 'Tên chương trình', width: 500, cellClassName: (params) => "text" },
        { field: 'orders', headerName: 'Số đơn hàng', type: "number", width: 140, styles: { color: "red" } },
        {
            field: 'receipt',
            headerName: 'Số tiền đã giảm',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.receipt), width: 200,
            cellClassName: (params) => "text1"
        },
        {
            field: 'deposit',
            headerName: 'Tông tiền phải thu đơn hàng',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.deposit), width: 200,
            cellClassName: (params) => "text4"
        },
        {
            field: 'gift_cash',
            headerName: 'Tổng tiền chưa dùng voucher',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.gift_cash ?? 0), width: 200,
            cellClassName: (params) => "text4"
        },
        {
            field: 'percent',
            headerName: '% giảm giá/doanh số',
            width: 200,
            type: "number",
            cellClassName: (params) => "text4"
        },
    ];
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(endDate, startDate, groupId, voucherName)
    }, [endDate, startDate, groupId, voucherName])
    const fetchData = async (eD, sD, groupId, voucherName) => {
        let doneData = false
        let DoneLocation = false
        const { data, locations } = await async.parallel({
            data: (cb) => {
                axiosService(`api/reports/detail-vouchers?&startDate=${sD}&endDate=${eD}&groupId=${groupId}&voucherName=${voucherName}`, "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        doneData = true
                        let totalReceiptAl = 0
                        let receiptByCashAl = 0
                        let receiptByCreditAl = 0
                        let paySwipeCardAl = 0
                        let payCardMoney = 0
                        let payLoyalty = 0
                        let dataPayOthers = data.data.payOther
                        for(let item of dataPayOthers.total) {
                            if (item.transaction_pay_type == "Tiền mặt") receiptByCashAl += Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Chuyển khoản") receiptByCreditAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Quẹt thẻ") paySwipeCardAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Sử dụng thẻ tiền") payCardMoney +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Điểm tích luỹ") payLoyalty += Number(item.sum_paid_amount)
                        }

                        for(let item of dataPayOthers.owed) {
                            if (item.transaction_pay_type == "Tiền mặt") receiptByCashAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Chuyển khoản") receiptByCreditAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Quẹt thẻ") paySwipeCardAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Sử dụng thẻ tiền") payCardMoney +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Điểm tích luỹ") payLoyalty +=  Number(item.sum_paid_amount)
                        }

                        setReceipt(data.data.totalReceipt)
                        setReceiptByCash(receiptByCashAl)
                        setReceiptByCredit(receiptByCreditAl)
                        setPaySwipeCard(paySwipeCardAl)
                        setPayCardMoney(payCardMoney)
                        setPayLoyalty(payLoyalty)
                        setPayOther(data.data.payOther)
                        cb(null, data.data.data)
                    }
                })
            },
            locations: (cb) => {
                axiosService("api/stores", "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        DoneLocation = true
                        cb(null, data.data)
                    }
                })
            }
        })
        if (doneData && DoneLocation) {
            setLoading(false)
            Promise.all(data.map(x => {
                return { name: x.group_voucher_name, value: parseInt(x.sum_voucher_order_price) || 0 }
            })).then(rs => {
                setDataChart(rs)
            })
            Promise.all(data.map((x, i) => {
                return {
                    id: i+1, 
                    name: x.group_voucher_name,
                    orders: x.count_order_using,
                    receipt: x.sum_voucher_order_price,
                    deposit: x.sum_total_price,
                    gift_cash: x.sum_total_price_before,
                    percent: Number(x.sum_voucher_order_price/ x.sum_total_price_before * 100).toFixed(1) + ' %'
                }
            })).then(rs => {
                let sum_voucher_order_price = _.sumBy(rs, function(o) { return  Number(o.receipt); });
                let sum_total_price = _.sumBy(rs, function(o) { return Number(o.deposit); });
                let sum_total_price_before = _.sumBy(rs, function(o) { return Number(o.gift_cash); });
                setTotalOrderPriceBefore(sum_total_price_before)
                setTotalOrderPrice(sum_total_price)
                setTotalVouchers(sum_voucher_order_price)
                setRows(rs)
            })

        }

    }
    const config = {
        data: dataChart,
        xField: 'name',
        yField: 'value',
        xAxis: {
            label: {
                autoRotate: true,
            },
        },
        slider: {
            start: 0,
            end: 1,
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        },
        color: 'red'
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const handleGroupVoucher = (e) => {
        setGroupId(e)
    }
    const hanldeSearchVoucher = (e) => {
        setVoucherName(e)
    }
    return (
        <>
            <div className={`${styles.background} container-fluid report-page`}>
                <Header startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate}
                    hanldeStore={handleGroupVoucher}
                    hanldeSearchVoucher={hanldeSearchVoucher}
                    title={title} type={"detail_promotion"}
                    csvData={rows} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    header={
                        [
                            { label: 'Tên chương trình', key: "name" },
                            { label: "Số đơn hàng", key: "orders" },
                            { label: "Tổng tiền chương trình", key: "receipt" },
                            { label: "Tông tiền phải thu đơn hàng", key: "deposit" },
                            { label: "Tổng tiền chưa dùng voucher", key: "gift_cash" },
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <Spin spinning={loading}>
                    <Row className="pt-2">
                        <Col xs={12}>
                            <Row>
                                <Col xs={6} md={3}>
                                    <Tag color="blue" className="w-100 h-100 p-2">
                                        <span className={styles.textTag2}>TÔNG DOANH THU CHƯA GIẢM VOUCHER </span>
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag}>{convertCurrency(Number(totalOrderPrice) + Number(totalVouchers))}</span>
                                            {/* <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_total_price - receipt?.totalByOrder?.sum_deposit_total - receipt?.totalByOrder?.sum_gift_cash_total)}</span> */}

                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Tag color="cyan" className="w-100 h-100 p-2">
                                        <span className={styles.textTag2}>TỔNG DOANH THU ĐƠN VOUCHER</span>
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag}>{convertCurrency(Number(totalOrderPrice))}</span>
                                            {/* <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_total_price - receipt?.totalByOrder?.sum_deposit_total - receipt?.totalByOrder?.sum_gift_cash_total)}</span> */}

                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Tag color="violet" className="w-100 h-100 p-2">
                                        <span className={styles.textTag2}>Tổng giảm vocher</span>
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag}>{convertCurrency(Number(totalVouchers))}</span>
                                            {/* <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_total_price - receipt?.totalByOrder?.sum_deposit_total - receipt?.totalByOrder?.sum_gift_cash_total)}</span> */}

                                        </div>
                                    </Tag>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                              
                                {/* <Col xs={6}>
                                    <Tag color="aquamarine" className={`${styles.tag1} w-100 h-100 p-2`}>
                                        <p className={styles.textTag2}>Khoản không tính thực thu</p>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className={styles.textTag3}>Trừ tài khoản</p>
                                                <p className={styles.textTag3}>Trừ điểm tích luỹ</p>
                                                <p className={styles.textTag3}>Trừ voucher</p>
                                            </div>
                                            <div>
                                                <p className={styles.textTag}>{convertCurrency(payCardMoney)}</p>
                                                <p className={styles.textTag}>{convertCurrency(payLoyalty)}</p>
                                                <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price_before) - Number(receipt?.totalByOrder?.sum_total_price))}</p>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col> */}
                              
                            </Row>
                            <Row className="mt-2">
                               
                               
                                {/* <Col xs={3}>
                                    <Tag color="orange" className="w-100 h-100 mt-2 p-2">
                                        <span className={styles.textTag2}>Trừ trong thẻ</span>
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_deposit_total)}</span>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={3}>
                                    <Tag style={{background: "darkgreen"}} className="w-100 h-100 mt-2 p-2">
                                        <span className={styles.textTag2}>Trừ điểm thưởng</span>
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_gift_cash_total)}</span>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6}>
                                    <Tag color="red" className=" w-100 h-100 mt-2 p-2">
                                        <p className={styles.textTag2}>NỢ PHẢI THU</p>
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price) - Number(receipt?.revenueByOrder) - Number(receipt?.totalByOrder?.sum_deposit_total) - Number(receipt?.totalByOrderUpsale?.sum_money_owed))}</p>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col> */}
                            </Row>
                            {/* <Row className="mt-2">
                                <Col xs={6}>
                                    <Tag color="orange" className=" w-100 h-100 mt-2 p-2">
                                        <p className={styles.textTag2}>TRỪ VOUCHER</p>
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price_before) - Number(receipt?.totalByOrder?.sum_total_price))}</p>
                                                <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price) - Number(receipt?.revenueByOrder) - Number(receipt?.totalByOrder?.sum_deposit_total) - Number(receipt?.totalByOrder?.sum_gift_cash_total))}</p>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row> */}
                            {/* <Row className="pt-2 mt-2">
                                <Col xs={12}>
                                    <div style={{ backgroundColor: "white" }} className="p-2">
                                        <Column {...config} />
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col xs={12}>
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <div className="mb-2">
                                    <strong>Bảng dữ liệu chi tiết</strong>
                                </div>
                                <div style={{ height: 590, width: '100%' }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[6]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    )
}
export default DetailPromotion