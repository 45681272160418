import { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import { Column } from '@ant-design/plots';
import "./scss/report.scss"
import { Spin } from 'antd';
import 'antd/dist/antd.min.css'
import TableProduct from "./TableProduct"
import StorageService from "../../utils/storage.service";

const ReportProduct = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [receipt,setReceipt] = useState()
    const [packageReceipt,setPackageReceipt] = useState(0)
    const [product,setProduct] = useState(0)
    const [service,setService] = useState(0)
    const [isExport, setIsExport] = useState(false)
    const [table,setTable] = useState([])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    useEffect(() => {
        setTiltle("Theo sản phẩm / dịch vụ")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate,endDate,store)
    }, [startDate, endDate, store])
    const fetchData = async (sD,eD,s)=>{
       const rs = await axiosService(`api/reports/products?startDate=${sD}&endDate=${eD}&store=${s}`)
       const {data} = rs
      if(data.code===200){
        setPackageReceipt(data.data.packageReceipt)
        setReceipt(data.data.receipt)
        setProduct(data.data.product)
        setService(data.data.service)
        setChart(data.data.dataChart)
        setFalseLoading()
      }
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const setFalseLoading = ()=>{
        setLoading(false)
    }
    const config = {
        data: chart,
        xField: 'name',
        yField: 'sum',
        xAxis: {
            label: {
                autoRotate: true,
                autoHide: true,
            },
        },
        label:null,
        legend: false,
        slider: {
            start: 0,
            end: 1,
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.sum)
                };
            },
        }
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const getTableData = (t)=>{
        setTable(t)
    }
    return (
        <div className={`container-fluid report-page ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                title={title} type={"products"}
                csvData={table} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Tên dịch vụ', key: "object.name" },
                        { label: "Số lượng", key: "count" },
                        { label: "Doanh số", key: "sum" },
                    ]
                }
                userAdmin={userAdmin}
            />
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="mt-2">
                    <Col xs={5}>
                        <Row>
                            <Col>
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Tổng doanh thu: </span>

                                    <span className={styles.textTag}>{convertCurrency(receipt?.receipt || 0)}</span>

                                </Tag>
                            </Col>
                            <Col>
                                <Tag color="blue" className={`${styles.tag2} ${styles.textTag2} p-2 w-100 h-100`}>
                                    <span className={styles.textTag3}>Trừ tiền trong tài khoản</span>

                                    <span className={styles.textTag}>{convertCurrency(receipt?.deposit)}</span>

                                </Tag>
                            </Col>
                            <Col>
                                <Tag style={{background: 'brown'}} className={`${styles.tag2} ${styles.textTag2} p-2 w-100 h-100`}>
                                    <span className={styles.textTag3}>Trừ điểm tích luỹ</span>

                                    <span className={styles.textTag}>{convertCurrency(receipt?.gift_cash)}</span>

                                </Tag>
                            </Col>
                            <Col>
                                <Tag color="blue" className={`${styles.tag1} ${styles.textTag2} p-2 w-100 h-100`}>
                                    <span className={styles.textTag3}>Tổng thực nhận: </span>

                                    <span className={styles.textTag}>{convertCurrency(receipt?.revenue || 0)}</span>

                                </Tag>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Tag color="orange" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Tổng thu dịch vụ: </span>

                                    <span className={styles.textTag}>{convertCurrency(service)}</span>

                                </Tag>
                            </Col>
                            <Col>
                                <Tag color="green" className={`${styles.textTag2} p-2 w-100 h-100`}>
                                    <span className={styles.textTag3}>Doanh thu sản phẩm: </span>

                                    <span className={styles.textTag}>{convertCurrency(product)}</span>

                                </Tag>
                            </Col>
                            <Col>
                                <Tag color="violet" className={` ${styles.textTag2} p-2 w-100 h-100`}>
                                    <span className={styles.textTag3}>Doanh thu bán thẻ: </span>
                                    <span className={styles.textTag}>{convertCurrency(packageReceipt)}</span>

                                </Tag>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={12}>
                                <div style={{ backgroundColor: "white" }} className="p-2">
                                    <h5 className="text-center">Theo sản phẩm / dịch vụ</h5>
                                    <Column {...config} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={7}>
                            <TableProduct getTableData={getTableData}
                            startDate={startDate} endDate={endDate} store={store}  />
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
export default ReportProduct