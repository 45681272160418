import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Divider, Pagination, Table, Input, DatePicker, Select, Spin, Tag, Modal, message, Upload, Button, Alert } from 'antd';
import { ActivesEnum, typeVoucher } from "../../enums/voucher.enum";
import { EditOutlined, CloseOutlined, ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons"
import { useTranslation } from 'react-i18next';
import axiosService from "../../utils/axios.service";
import styles from "./style.module.css"
import { Link } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddNewVoucherCategory from "./infor_category"
import async from "async"


export default function PopupCategoryVoucher(props) {
  
  const [categoryVoucher, SetCategoryVoucher] = useState([])
  const [showPopupAddCategory, SetShowPopupAddCategory] = useState(false)
  const [dataEditCategory, setDataEditCategory] = useState([])

  const [dataSource, setDataSource] = useState([])

  const columns = [
      {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
      },
      {
          title: "Tên nhóm",
          dataIndex: 'group_name',
          key: 'group_name',
      },
      {
          title: "Mô tả",
          dataIndex: 'des',
          key: 'des',
      },
      {
          title: "Trạng thái",
          dataIndex: 'status',
          key: 'status',
          render: (data, x) => {
              return (
                  <>
                      <Tag color={data === 1 ? "green" : "red"}>{data === 1 ? "active" : "inactive"}</Tag>
                  </>
              )
          },
      },
      {
          title: "Thao tác",
          key: 'actions',
          width: 10,
          render: (data, x) => {
              return (
                  <div className="d-flex justify-content-between">
                      <a onClick={() => {
                        handelEditCategoryVoucher(data)
                      }}>
                          <EditOutlined style={{ color: "#EB661B", cursor: "pointer" }} />
                      </a>
                      <a onClick={() => {
                          confirmDelete(data)
                      }}>
                          <CloseOutlined style={{ color: "red", cursor: "pointer" }} />
                      </a>
                  </div>
              )
          },
      },
  ];
  const handelEditCategoryVoucher = (event) => {
    setDataEditCategory(event)
    SetShowPopupAddCategory(true)
  };

  const confirmDelete = async (event) => {
    let rs = await axiosService(`api/category_voucher/` + event.id, "DELETE")
      if (rs.data.code == 200) {
        message.success("Xóa thành công")
        setDataSource(rs.data.data)
    }
  }

  useEffect(async () => {
    try {
        const { getCategoryVoucher } = await async.parallel({
          getCategoryVoucher: (cb) => {
                axiosService(`api/category_voucher`, "GET").then(res => {
                    const { data } = res
                    cb(null, data)
                })
            },
         
        })

        if (getCategoryVoucher.code == 200) {
          setDataSource(getCategoryVoucher.data)
        } else {
          message.error("Đã có lỗi sảy ra")
        }
       
    } catch (error) {
        console.error(error)
        message.error("Đã có lỗi sảy ra")
    }
}, [])


  const handleAddCategory = () => {
    SetShowPopupAddCategory(!showPopupAddCategory)
    setDataEditCategory([])
  }

    return (
      <div className="popup-cent">
        <div className="content-popup fadeInDown">
          <div className="head-popup">
            <div className="title">Thông báo</div>
            <div className="close" onClick={props.close}>×</div>
          </div>
          <div className="body-popup">
            {showPopupAddCategory == false ? 
              <>
               <Row className="d-flex justify-content-between p-2">
                <div className="title-body">Nhóm voucher</div>
                <div className="d-flex">
                    <div className={`${styles.addButton} p-3 pl-5 pr-5`} onClick={handleAddCategory}>
                        Thêm group voucher
                    </div>
                </div>
              </Row>
              <div className="table-category_voucher">
                  <Row className="mt-4">
                        <Table className="w-100" dataSource={dataSource} columns={columns} pagination={false}/>
                  </Row>
              </div>
              </>
            : 
              <div className="form-add-category">
                 <AddNewVoucherCategory
                  styles={styles}
                  SetShowPopupAddCategory={SetShowPopupAddCategory}
                  showPopupAddCategory={showPopupAddCategory}
                  setDataSource = {setDataSource}
                  dataEditCategory = {dataEditCategory}
                 />

              </div>
            
            }
          </div>
          {!showPopupAddCategory?
             <div className="bottom-popup">
             <div className="button-container">
               <div className="button-cent style3" onClick={props.close}>
                 <span><i className="fas fa-times"></i></span>Đóng
               </div>
             </div>
           </div>
          : null}
        </div>
        <div className="overlay" onClick={props.close}></div>
      </div>
    )
}
