import { useState } from "react";
import * as convert from "../constant/Convert.helper"

export function HistoryCredit(props) {
  const [customerId, setCustomerId] = useState(null);


  return (
    <div className="order-popup history-credit">
      <div className="popup-cent">
        <div className="content-popup fadeInDown">
          <div className="head-popup">
            <div className="title">
              Lịch sử sử dụng tiền
            </div>
          </div>
          <div className="body-popup">
            <div className="panel-body">
              {props.dataHistoryCredit.length > 0 ?
                <div className="fixed-content-wrapper customer-list-container">
                  <div className="fixed-one-col">
                    <div className="dataTables_wrapper no-footer no-padding mt-10">
                        <table className="table table-hover datatable-columns no-padding no-footer ng-isolate-scope dataTable">
                            <thead>
                                <tr>
                                  <th>Trước khi sd</th>
                                  <th>Số tiền</th>
                                  <th>Lý do</th>
                                  <th>Ghi chú</th>
                                  <th>Ngày tạo</th>
                                  <th>Mã thẻ</th>
                                  <th>Mã order</th>
                                  <th>Người tạo</th>
                                  <th>Cơ sở</th>
                                </tr>
                            </thead>
                            <tbody>
                              {props.dataHistoryCredit.map((item, key) => (
                                <tr key={key} >
                                  <td>{convert.convertCurrency(item.old_price)}</td>
                                  <td className={ (item.new_price + item.change_price) <= item.old_price ? "price_credit minus" : "price_credit plus"}>
                                    {(item.new_price + item.change_price) <= item.old_price || (item.type != undefined && item.type == 0) ? '-' : '+'}{convert.convertCurrency(item.change_price)}
                                  </td>
                                  <td>{item.reason}</td>
                                  <td style={{"width": "200px"}}>{item.note ?? null}</td>
                                  <td>{convert.convertDateTime(item.created_at)}</td>
                                  <td>{item.package_code ?? ''}</td>
                                  <td>{item.order_code ?? ''}</td>
                                  <td>{item.created_by_name ?? ''}</td>
                                  <td>{item.store_name ?? ''}</td>
                                </tr>
                              ))}
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              : "Không có dữ liệu phù hợp"}
            </div>
          </div>
          <div className="bottom-popup">
            <div className="button-container">
              <div className="button-cent style3" onClick={props.onHandelPopup}>
                <span><i className="fas fa-times"></i></span>Đóng
              </div>
            </div>
          </div>
        </div>
        <div onClick={props.onHandelPopup} className="overlay"></div>
      </div>
    </div>
  );
}
