import { Col, Row } from "react-bootstrap";
import { Input, Select, DatePicker, Button, Table, Pagination, Modal, message, Spin } from 'antd';
import styles from "./style.module.css"
import { useEffect } from "react";
import { useState, useRef } from "react";
import axiosService from "../../utils/axios.service";
import moment from "moment"
import { fetchAPI } from "../../libs/fetchStrapi"
import { useDebouncedCallback } from 'use-debounce';
import preBookingEnum from "../../enums/pre-booking.enum";
import { io } from "socket.io-client"
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function PreBooking() {
    const socketRef = useRef();
    const [status, setStatus] = useState(preBookingEnum.PENDING)
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [startDate, setStartDate] = useState(moment(new Date(moment().startOf('week'))).format('YYYY/MM/DD'))
    const [endDate, setEnDate] = useState(moment(new Date(moment().endOf('week'))).format('YYYY/MM/DD'))
    const [store, setStore] = useState("")
    const [stores, setStores] = useState([])
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [nameFetch, setNameFetch] = useState("")
    const [phoneFetch, setPhoneFetch] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState("")
    const [title, setTitle] = useState("")
    const [type, setType] = useState(0)
    const [dataSelect, setDataSelect] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const onChangeStore = (value) => {
        setStore(value);
        setPage(1)
        setLimit(10)
    };
    const handleValidate = (type, dataA) => {
        if (type === 2) {
            setContent("Xác nhận lịch cho khách hàng " + dataA.customer_name)
            setTitle("Xác nhận lịch")
        } else {
            setContent("Huỷ lịch lịch cho khách hàng " + dataA.customer_name)
            setTitle("Xác nhận huỷ lịch")
        }
        setType(type)
        setDataSelect(dataA)
        showModal()
    }
    const columns = [
        {
            title: 'Ngày đặt',
            dataIndex: 'date_book',
            key: 'date_book',
            align: "center",
            sorter: (a, b) => moment(a.date_book).unix() - moment(b.date_book).unix(),
            render: (data) => {
                return (
                    <>
                        <span>{moment(new Date(data)).format('DD-MM-YYYY, HH:mm:ss')}</span>
                    </>
                )
            },
        },
        {
            title: 'Khách hàng',
            key: "name",
            align: "center",
            render: (data) => {
                return (
                    <>
                        <p>Tên: {data.customer_name}</p>
                        <p>SDT: {data.phone}</p>
                    </>
                )
            },
        },
        {
            title: 'Dịch vụ',
            key: "items",
            dataIndex: 'items',
            align: "center",
            render: (data) => {
                return (
                    <>
                        {data && data != "" && data.split(', \n').map((x, i) => {
                            return <p key={i}>{i + 1}. {x}</p>
                        })}
                    </>
                )
            },
        },
        {
            title: 'Cơ sở',
            key: "store_name",
            dataIndex: 'store_name',
            align: "center",
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: "note",
            align: "center",
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: "center",
            key: "status",
            render: (data) => {
                return (
                    <>
                        {data == preBookingEnum.PENDING && <span style={{ color: "blue" }}>{data}</span>}
                        {data == preBookingEnum.ACCEPT && <span style={{ color: "green" }}>{data}</span>}
                        {data == preBookingEnum.CANCEL && <span style={{ color: "red" }}>{data}</span>}
                        {data == preBookingEnum.EXPIRED && <span style={{ color: "brown" }}>{data}</span>}
                    </>
                )
            },
        },
        {
            title: 'Chức năng',
            dataIndex: 'status',
            align: "center",
            key: "action",
            width: "10%",
            render: (data, x) => {
                return (
                    <div className="d-flex justify-content-between">
                        {data == preBookingEnum.PENDING && <Button type="primary" onClick={() => {
                            handleValidate(2, x)
                        }}>Xác nhận</Button>}
                        {data == preBookingEnum.PENDING && <Button className="ml-2" onClick={() => {
                            handleValidate(3, x)
                        }}>Huỷ</Button>}
                    </div>
                )
            },
        },
    ];
    const onChangeStatus = (e) => {
        setPage(1)
        setLimit(10)
        setStatus(e)
    }
    const getLocation = async () => {
        try {
            const rs = await axiosService("api/stores", "GET")
            const { data } = rs
            if (data.code === 200) {
                data.data.unshift({ name_store: "Tất cả", id: "" })
                setStores([...data.data])
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setIsLoading(true)
        }
    }
    useEffect(async () => {
        getLocation()
    }, [])
    const debounced = useDebouncedCallback(
        // function
        (value, type) => {
            if (type == 1) {
                setNameFetch(value)
            } else {
                setPhoneFetch(value)
            }
        },
        // delay in ms
        1000
    );
    const onChangeName = (e) => {
        debounced(e.target.value, 1)
        setName(e.target.value)
    }
    const onChangePhone = (e) => {
        debounced(e.target.value, 2)
        setPhone(e.target.value)
    }
    const onChangeDate = (x, y) => {
        if (y[0] != "") {
            setStartDate(y[0])
            setEnDate(y[1])
        }
    }
    const handleChangePage = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)
    }
    const cancelSearch = async () => {
        setPage(1)
        setLimit(10)
        setName("")
        setPhone("")
        setStatus(preBookingEnum.PENDING)
        setStore("")
        setStartDate(moment(new Date(moment().startOf('week'))).format('YYYY/MM/DD'))
        setEnDate(moment(new Date()).format('YYYY/MM/DD'))
        setNameFetch("")
        setPhoneFetch("")
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        try {
            if (type === 0 && !dataSelect) {
                message.error("Lịch đặt không hợp lệ")
            } else {
                setIsLoading(true)
                if (type === 2) {
                    const payload = {
                        ...dataSelect,
                        book_status: type,
                        date_book: moment(new Date(dataSelect.date_book)).format('YYYY-MM-DD HH:mm:ss')
                    }
                    const rs = await axiosService('api/bookings/create/web', "POST", payload)
                    if (rs.data.code === 200) {
                        setIsModalOpen(false)
                        message.success("Xác nhận thành công")
                    } else if (rs.data.code === 404) {
                        message.error(rs.data.message)
                    } else {
                        message.error("Có lỗi xảy ra")
                    }
                } else {
                    const res = await fetchAPI(`/bookings/update/${dataSelect.id}`, '', {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ status: preBookingEnum.CANCEL, customerId: dataSelect.customer_id }),
                    })
                    if (res.success) {
                        setIsModalOpen(false);
                        message.success("Huỷ lịch thành công")
                    } else {
                        message.error("Đã có lỗi xảy ra")
                    }
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            message.error("Có lỗi xảy ra")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_API_URL_WE,
            //     {
            //     extraHeaders: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_WE}` },
            // }
            // {
            //     query: {
            //         token: process.env.REACT_APP_TOKEN_WE
            //     }
            // }
        )
        return () => {
            socketRef.current.disconnect();
        };
    }, [])

    socketRef.current?.on("addBooking", (data) => {
        const today = new Date(data.data.date_book).getTime()
        if (data && data.new && (today <= new Date(moment(endDate).startOf('day').toString()).getTime() && today >= new Date(moment(startDate).endOf('day').toString()).getTime())) {
            if (status === preBookingEnum.PENDING || status === "") {
                if (limit === list.length && list.length > 0) {
                    list.splice(list.length - 1, 1)
                } else {
                    setTotal(total + 1)
                }
                setList([data.data, ...list])
            } else {
                setList([...list])
            }
        }
    })
    socketRef.current?.on("updateBooking", (data) => {
        const today = new Date(data.data.date_book).getTime()
        if (data && data.update && (new Date(moment(startDate).startOf('day').toString()).getTime() <= today && today <= new Date(moment(endDate).endOf('day').toString()).getTime())) {
            const findIndex = list.findIndex((x) => x.id == data.data.id)
            if (findIndex >= 0) {
                if (status === preBookingEnum.PENDING || status === "") {
                    list.splice(findIndex, 1)
                    setList([...list])
                    setTotal(total - 1)
                } else {
                    list[findIndex].status = data.data.status
                    setList([...list])
                }
            }
        }
    })
    const getDataBooking = async () => {
        try {
            const res = await fetchAPI(`/bookings/find/all?limit=${limit}&page=${page}&store=${store}&phone=${phoneFetch}&customer_name=${nameFetch}&status=${status}&startDate=${startDate}&endDate=${endDate}`, {}, {}, process.env.REACT_APP_TOKEN_WE)
            if (res.success) {
                const { data } = res
                setList(data.results)
                setPage(data.pagination.page)
                setLimit(data.pagination.pageSize)
                setTotal(data.pagination.total)
                setIsLoading(false)
            } else {
                message.error("Có lỗi xảy ra")
            }
        } catch (error) {
            console.error(error)
            message.error("Có lỗi xảy ra")
        }
    }
    useEffect(() => {
        setIsLoading(true)
        getDataBooking()
    }, [limit, page, startDate, endDate, phoneFetch, nameFetch, store, status])
    // const onSelectChange = (newSelectedRowKeys) => {
    //     console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    //     setSelectedRowKeys(newSelectedRowKeys);
    //   };
    //   const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //   };

    return (
        <Spin size="large" spinning={isLoading} >
            <Modal title={title} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>{content}</p>
            </Modal>
            <Row className="pt-2 pl-5 pr-5 m-0">
                <div style={{ width: "100%" }}>
                    <h5 className="text-center">Danh sách lịch đặt của khách hàng</h5>
                </div>
            </Row>
            <Row className={`pt-2 pb-3 ${styles.background}`}>
                <Col>
                    <div>
                        <span className="ml-1">Tên khách hàng: </span>
                        <Input placeholder="Nhập tên khách hàng"
                            value={name}
                            onChange={onChangeName} allowClear
                        />
                    </div>
                </Col>
                <Col>
                    <div>
                        <span className="ml-1">Số điện thoại:</span>
                        <Input onChange={onChangePhone} placeholder="Nhập số điện thoại khách hàng" allowClear value={phone} />
                    </div>
                </Col>
                <Col className="w-100">
                    <span className="ml-1">Cơ sở:</span>
                    <br></br>
                    <Select
                        className="w-100"
                        showSearch
                        value={store}
                        placeholder="Chọn một cơ sở"
                        optionFilterProp="children"
                        onChange={onChangeStore}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {stores.map(x => {
                            return <Option value={x.id} key={x.id}>{x.name_store}</Option>
                        })}
                    </Select>
                </Col>
                <Col>
                    <span className="ml-1">Trạng thái:</span>
                    <br></br>
                    <Select
                        showSearch
                        placeholder="Chọn một trạng thái"
                        optionFilterProp="children"
                        value={status}
                        className="w-100"
                        onChange={onChangeStatus}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        <Option value="">Tất cả</Option>
                        {Object.keys(preBookingEnum).map((keyName, i) => (
                            <Option key={i} value={preBookingEnum[keyName]}>{preBookingEnum[keyName]}</Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={3}>
                    <span className="ml-1">Chọn khoảng thời gian:</span>
                    <br></br>
                    <RangePicker
                        className="w-100"
                        onChange={onChangeDate}
                        ranges={
                            {
                                "Hôm nay": [moment(), moment()],
                                "Ngày mai": [moment().add(1, 'day'), moment().add(1, 'day')],
                                'Tuần này': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                'Tuần sau': [moment().add(1, 'weeks').startOf('isoWeek'), moment().add(1, 'weeks').endOf('isoWeek')],
                                'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                'Tháng sau': [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')],
                            }
                        }
                        allowClear={false}
                        value={[moment(startDate, "YYYY/MM/DD"), moment(endDate, "YYYY/MM/DD")]} />
                </Col>
                <Col>
                    <span>{" "}</span>
                    <br />
                    <div className="d-flex justify-content-between">
                        <Button type="primary" size={"middle"} className="mr-2" onClick={() => {
                            cancelSearch(1, 10)
                        }}>
                            <span>Xoá tất cả</span>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className={`pb-3 m-0 w-100 ${styles.background2}`}>
                <div className="d-flex justify-content-end w-100 pr-5 mt-2">
                    <p>Đang hiển thị <span style={{ color: "blue" }}>{list.length}</span> trên tổng số <span style={{ color: "red" }}>{total}</span> kết quả</p>
                </div>
                <Table
                    className="w-100 text-center"
                    pagination={false}
                    // rowSelection={rowSelection} 
                    columns={columns} dataSource={list} />
                <div className={`d-flex justify-content-end w-100 mt-4 pt-2 pb-2 mr-5 ${styles.fixedPagination}`}>
                    <Pagination current={page} total={total} pageSize={limit} showSizeChanger showQuickJumper onChange={handleChangePage} />
                </div>
            </Row>
        </Spin>
    )
}