import { useState } from "react";
import axiosService from "../../../utils/axios.service";



export function PopupConfirmEditOrder(props) {
    const [description, setDescription] = useState(null)
    const [isEdit, setEdit] = useState(true)

    var acceptCancelOrder = (event) => {
        var data = {
            status : 1,
            description: description,
            orderItem: props.orderItem
        }
        if (isEdit) {
          props.editOrder(data)
        }
        setEdit(false)
    }
    var hiddenPopup = () => {
        props.setShow(false)
    }
    
    return (
        <div>
            <div className="head-popup">
                <div className="title">Thông báo sửa đơn hàng <span></span> </div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn sửa đơn hàng không ?</div>
            </div>
            <div className="bottom-popup">
            {isEdit ? 
              <div className="button-container">
                  <div className="button-cent style3" onClick={hiddenPopup} >
                    <span><i className="fas fa-times" ></i></span>Không
                  </div>
                  <div className="button-cent" onClick={acceptCancelOrder}>
                    <span><i className="fas fa-save"></i></span>Có
                  </div>
              </div>
            : ''}
            </div>
        </div>
    )
}