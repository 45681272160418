import React, { useState, useEffect } from "react";
import styles from "./styles/style.module.css"
import { Form, Row, Col, Container } from 'react-bootstrap';
import axiosService from "../../utils/axios.service";
const CreateNote = ({ createNote, action, chooseOrder, updateState, noteId }) => {
    const [content, setContent] = useState("")
    const [stateCus, setStateCus] = useState("")
    const [require, setRequire] = useState("")
    const [implement, setImplement] = useState("")
    const [result, setResult] = useState("")
    const hanldeContent = (e) => {
        setContent(e.target.value)
    }
    const hanldeState = (e) => {
        setStateCus(e.target.value)
    }
    const hanldeReeuire = (e) => {
        setRequire(e.target.value)
    }
    const hanldeImplemmet = (e) => {
        setImplement(e.target.value)
    }
    const hanldeResult = (e) => {
        setResult(e.target.value)
    }
    const hanldeClick = () => {
        if (action === 1) {
            const params = {
                content,
                state: stateCus,
                require: require,
                implement_plan: implement,
                results: result,
                order_id: chooseOrder
            }
            create(params)
        }else if(action === 2) {
            const params = {
                content,
                state: stateCus,
                require: require,
                implement_plan: implement,
                results: result,
            }
            update(params)
        }

    }
    const hanldeClose = () => {
        createNote()
        setContent("")
        setImplement("")
        setRequire("")
        setStateCus("")
        setResult("")
    }
    const create = async (params) => {
        const res = await axiosService(`api/notes`, "POST", params);
        const { data } = res
        if (data.code === 200) {
            updateState(action, data.data)
            hanldeClose()
        }
    }
    const update = async (params) => {
        const res = await axiosService(`api/notes/${noteId}`, "PUT", params);
        const { data } = res
        if (data.code === 200) {
            hanldeClose()
        }
    }
    useEffect(async () => {
        if (action === 2) {
            const res = await axiosService(`api/notes/detail/${noteId}`, "GET");
            const { data } = res
            if (data.code === 200) {
                setContent(data.data.note.content)
                setImplement(data.data.note.implement_plan)
                setRequire(data.data.note.require)
                setStateCus(data.data.note.state)
                setResult(data.data.note.results)
            }
        }
    }, [action])
    return (
        <div className="popup-cent" style={{ height: "100%" }}>
            <div className="content-popup fadeInDown">
                <div className="body-popup">
                    <div className={styles.popupHeader}>
                        <div style={{ display: "flex" }}>
                            <h5 className={styles.textHeader}>Tạo / chỉnh sửa ghi chú</h5>
                        </div>
                        <Container className="mt-2 border-top pt-3">
                            <Row>
                                <Col xs={12}>
                                    <Form.Label htmlFor="1"> Nội dung ghi chú</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        id="1"
                                        value={content}
                                        placeholder="Nhập vào nội dung ghi chú"
                                        style={{ height: '8rem' }}
                                        onChange={hanldeContent}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label htmlFor="2"> Tình trạng khách hàng</Form.Label>
                                    <Form.Control
                                        id="2"
                                        as="textarea"
                                        value={stateCus}
                                        placeholder="Nhập vào tình trạng khách hàng"
                                        style={{ height: '8rem' }}
                                        onChange={hanldeState}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Label htmlFor="3"> Yêu cầu khách hàng</Form.Label>
                                    <Form.Control
                                        id="3"
                                        as="textarea"
                                        value={require}
                                        placeholder="Nhập vào yêu cầu khách hàng"
                                        style={{ height: '8rem' }}
                                        onChange={hanldeReeuire}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Label htmlFor="4"> Phuong án tiến hành</Form.Label>
                                    <Form.Control
                                        id="4"
                                        as="textarea"
                                        placeholder="Nhập vào phuong án tiến hành"
                                        style={{ height: '8rem' }}
                                        onChange={hanldeImplemmet}
                                        value={implement}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Form.Label htmlFor="5"> Kết quả đạt được</Form.Label>
                                    <Form.Control
                                        id="5"
                                        as="textarea"
                                        placeholder="Nhập vào kết quả đạt được"
                                        style={{ height: '8rem' }}
                                        onChange={hanldeResult}
                                        value={result}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <div className="bottom-popup border-top">
                            <div className="d-flex justify-content-end p-2">
                                <div className={`button-cent mr-1 ${styles.buttn2}`} onClick={hanldeClose}>
                                    <span ><i className="fas fa-times"></i></span>Đóng
                                </div>
                                <div className="button-cent style3 mr-1" onClick={hanldeClick}>
                                    <span><i className="fa fa-fas fa-calendar-alt"></i></span>Lưu thông tin
                                </div>
                            </div>
                        </div>
                        <div className="close" onClick={hanldeClose}>×</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateNote