import React from "react";
import axiosService from "../../utils/axios.service";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameGroup: props.detailGroup.name
    };
  }

  onChangeNameGroup = (event) => {
    this.setState({
      nameGroup: event.target.value,
    });
  };

  acceptEdit = () => {
    const dataUpdate = {
      "name": this.state.nameGroup,
      "id": this.props.detailGroup.id
    };
    this.props.updateGroup(dataUpdate)
  };

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Chỉnh sửa nhóm dịch vụ</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-10">
                  <div className="items-data row">
                    <div className="title col-4">Tên nhóm dịch vụ</div>
                    <div className="value col-8"><input onChange={this.onChangeNameGroup} className="required" defaultValue={this.props.detailGroup.name} type="text"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className="button-cent" onClick={this.acceptEdit}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Edit;
