import React, { useEffect, useState } from "react";
import axiosService from "../utils/axios.service";
import { useHistory, Link } from "react-router-dom";

const ChangePassword = () => {
  const [currentPass, setCurrentPass] = useState({value: '', error: '', display: false});
  const [newPass, setNewPass] = useState({value: '', error: '', display: false});
  const [reNewPass, setReNewPass] = useState({value: '', error: '', display: false});
  const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info")));
  const [timeLeft, setTimeLeft] = useState(null);
  const [statusChange, setStatusChange] = useState('');
  const [statusButton, setStatusButton] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if(timeLeft === 0) {
      setTimeLeft(null)
      history.push("/dashboard");
    }

    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);
  
  const changePass = async () => {
    setStatusButton(false)
    let statusCurrentPass = await checkCurrentPass();
    if (statusCurrentPass != '') return setCurrentPass({...currentPass, error: statusCurrentPass})

    let statusNewPass = checkNewPass()
    if (!statusNewPass) return setNewPass({...newPass, error: "Mật khẩu phải từ 8 ký tự trở lên, có ít nhất 1 ký tự viết hoa, ít nhất 1 chữ số"})

    if (newPass.value != reNewPass.value) return setReNewPass({...reNewPass, error: "Mật khẩu không khớp"})
    
    let data = {
      id: userAdmin.id,
      password: newPass.value
    };

    const changePass = await axiosService("api/change-password", "POST", data)
    if (changePass.data && changePass.data.affected > 0) {
      setTimeLeft(5)
    } else {
      setStatusChange('Có lỗi xảy ra, vui lòng liên hệ admin để được hỗ trợ')
    }
    setStatusButton(true)
  };

  const checkNewPass = () => {
    var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    return mediumRegex.test(newPass.value)
  }

  const checkCurrentPass = async () => {
    let data = {
      username: userAdmin.email,
      password: currentPass.value
    };

    const checkPass = await axiosService("api/check-password-to-reset", "POST", data)

    return checkPass.data
  }

  return (
    <div className="ftco-section change-password">
      <div className="container">
        <div className="row justify-content-center mt-5 pb-5">
          <div className="col-md-12 col-lg-10">
            <div className="wrap d-md-flex right-wrap">
              <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last w-50">
                <div className="text w-100 text-center">
                  <img src="/images/header/logo3.png" alt=""/>
                </div>
              </div>
              <div className="login-wrap">
                  {timeLeft > 0 ?
                    <div className="mess-change text-success">
                      Đổi mật khẩu thành công, nhấn vào <Link to={{ pathname: "/dashboard" }}>Trang chủ</Link> để trở về hoặc tự động trở về sau {timeLeft} giây
                    </div>
                  : 
                    <div>
                      <div className="d-flex">
                        <div className="w-100">
                          <h3 className="mb-4">Đổi mật khẩu</h3>
                        </div>
                      </div>
                      <div className="change-password-form">
                        <div className="form-group">
                          <label className="text-label" htmlFor="name">Mật khẩu hiện tại</label>
                          
                          <input type={currentPass.display ? "text" : "password"}
                            name="password" className={currentPass.error != '' ? 'form-control error-input' : 'form-control'} 
                            placeholder="Mật khẩu hiện tại" required 
                            onChange={(e) => {setCurrentPass({...currentPass, value: e.target.value, error: ''}); setStatusChange('')}} />
                          
                          {currentPass.error != '' ? 
                            <span className="error">{currentPass.error}</span>
                          : null}
                          
                          <i className={currentPass.display ? "fas fa-eye show-pass" : "fas fa-eye-slash show-pass"}
                            onClick={() => setCurrentPass({...currentPass, display: !currentPass.display})}></i>
                        </div>

                        <div className="form-group">
                          <label className="text-label" htmlFor="password">Mật khẩu mới</label>
                          
                          <input type={newPass.display ? "text" : "password"} 
                            name="new_password" 
                            className={newPass.error != '' ? 'form-control error-input' : 'form-control'} 
                            placeholder="Mật khẩu mới" required 
                            onChange={(e) => {setNewPass({...newPass, value: e.target.value, error: ''}); setStatusChange('')}} />
                          
                          {newPass.error != '' ? 
                            <span className="error">{newPass.error}</span> 
                          : null}
                          
                          <i className={newPass.display ? "fas fa-eye show-pass" : "fas fa-eye-slash show-pass"}
                            onClick={() => setNewPass({...newPass, display: !newPass.display})}></i>
                        </div>

                        <div className="form-group">
                          <label className="text-label" htmlFor="password">Xác nhận mật khẩu mới</label>
                          
                          <input type={reNewPass.display ? "text" : "password"} 
                            name="re_new_password" 
                            className={reNewPass.error != '' ? 'form-control error-input' : 'form-control'} 
                            placeholder="Nhập lại mật khẩu mới" required 
                            onChange={(e) => {setReNewPass({...reNewPass, value: e.target.value, error: ''}); setStatusChange('')}} />
                          
                          {reNewPass.error != '' ? 
                            <span className="error">{reNewPass.error}</span> 
                          : null}
                          
                          <i className={reNewPass.display ? "fas fa-eye show-pass" : "fas fa-eye-slash show-pass"}
                            onClick={() => setReNewPass({...reNewPass, display: !reNewPass.display})}></i>
                        </div>

                        <div className="form-group mt-4">
                          <button disabled={(currentPass.value == '' || newPass.value == '' || reNewPass.value == '' && statusButton) ? true : false}
                            style={(currentPass.value == '' || newPass.value == '' || reNewPass.value == '' && statusButton) ? {opacity: 0.5} : {}} 
                            className="form-control btn-login px-3" onClick={() => (currentPass.value == '' || newPass.value == '' || reNewPass.value == '' && statusButton) ? {} : changePass()}>Tiếp tục</button>
                        </div>
                        {statusChange != '' ?
                          <div className="error-change text-danger">
                            {statusChange}
                          </div>
                        : null}
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};
export default ChangePassword;
