import React, { useState } from 'react'
import { Modal, Button} from 'rsuite'
import './scss/membercards.scss' 

const ModalRenewCard = ({open, setOpen}) => {
	return (
		<>
			<Modal size="lg" open={open} onClose={() => setOpen(!open)}>
	         <Modal.Header className="modal-header">
	          <Modal.Title>Cấp thẻ dịch vụ</Modal.Title>
	        </Modal.Header>

	        <Modal.Body className="modal-body">
	        	<form>
	        		<div className="form-item-wrapper">
	        			<div className="modal-title">THÔNG TIN CHỦ SỞ HỮU</div>
	        			<div className="flex justify-between">
	        				<label className="control-label col-3 ng-binding">Tìm khách hàng</label>
	        				<input className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Tìm khách hàng theo tên, email hoặc số điện thoại" />
	        			</div>
	        			<label className="control-label col-3 ng-binding">Khách hàng</label>
	        		</div>

	        		<div className="form-item-wrapper">
	        			<div className="modal-title">THÔNG TIN THẺ</div>
	        			<div className="flex justify-between">
	        				<label className="control-label col-3 ng-binding">Loại thẻ</label>
	        				<input className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Loại thẻ" />
	        			</div>
	        			<label className="control-label col-3 ng-binding">Mô tả chức năng thẻ</label>
	        			<div className="flex justify-between">
	        				<label className="control-label col-3 ng-binding">Giá bán thẻ</label>
	        				<div className="form-control border-none ng-pristine ng-untouched ng-valid ng-empty">0đ</div>
	        			</div>
	        			<div className="flex mt-10 justify-between">
	        				<label className="control-label col-3 ng-binding">Loại thẻ</label>
	        				<div className="flex w-100 justify-between ">
	        					<div className="form-control col-3 ng-pristine border-none ng-untouched ng-valid ng-empty">Thẻ số lần</div>
	        					<p>Số lần sử dụng tối đa:(Không giới hạn)</p>
	        				</div>
	        			</div>

	        			<div className="flex mt-10 justify-between">
	        				<label className="control-label col-3 ng-binding">Số lần đã sử dụng ban đầu</label>
	        				<div className="flex w-100 justify-between ">
	        					<input className="form-control col-3 ng-pristine ng-untouched ng-valid ng-empty" />
	        					<span className="text-red">Chú ý: Số lần sử dụng này sẽ không có hóa đơn đi kèm.</span>
	        					<div />
	        				</div>
	        			</div>

	        			<div className="flex mt-10 justify-between">
	        				<label className="control-label col-3 ng-binding">Ngày cấp</label>
	        				<div className="flex w-100 justify-between ">
	        					<input type="datepicker" className="form-control col-3 ng-pristine ng-untouched ng-valid ng-empty" />
	        					<span>Thời hạn thẻ<span className="text-red">(Không giới hạn)</span>.</span>
	        					<div />
	        				</div>
	        			</div>

	        			<div className="flex mt-10 justify-between">
	        				<label className="control-label col-3 ng-binding">Số lượng cấp</label>
	        				<div className="flex w-100 justify-between ">
	        					<input type="text" className="form-control col-3 ng-pristine ng-untouched ng-valid ng-empty" />
	        					<div className="flex pl-10 w-50 ">
	        						<div className="pr-10">Tại chi nhánh</div>
	        						<select className="form-control w-50">
	        						 <option>Trần duy hưng</option>
	        						</select>
	        					</div>
	        					<div></div>
	        				</div>
	        			</div>

	        			<div className="flex mt-10 justify-between">
	        				<label className="control-label col-3 ng-binding">Ghi chú</label>
	        				<div className=" w-100  ">
	        					<textarea placeholder="Ghi chú" className="w-80 pb-10" />
	        					<div className="text-red">Chú ý:</div>
	        					<div className="text-red"><i className="fa-solid fa-hand-point-right"></i> Cấp thẻ cho khách tại màn hình này sẽ không tính vào doanh thu của chi nhánh.</div>
	        					<div className="text-red">
	        						<i className="fa-solid fa-hand-point-right"></i>
	        						Để tính vào doanh thu chi nhánh, vui lòng bán thẻ tại màn hình thu ngân.
	        					</div>
	        				</div>
	        			</div>
	        		</div>
	        	</form>
	        </Modal.Body>
	        <Modal.Footer className="modal-footer">
	          <Button onClick={() => setOpen(!open)} className="button-close-modal">
	          <i className="fa-solid fa-x"></i>
	            Đóng
	          </Button>
	          <Button onClick={() => setOpen(!open)} className="button-renew-card-modal">
	          <i className="fa-solid fa-user-plus"></i>
	            Cấp thẻ
	          </Button>
	        </Modal.Footer>
	        </Modal>
		</>
	)
}

export default ModalRenewCard