import React, { useState } from "react";
import { Row, Col } from "react-bootstrap"
import { format } from "date-fns"
import "./scss/customer.scss";
import styles from "../../styles/popupInfor.module.css"
import { CustomerOrderPopup } from "../customer/CustomerOrderPopup";
const ChildOrder = ({ order, customer }) => {
  const [show, setShow1] = useState(false)
  function convertCurrency(data) {
    let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
    return res;
  };
  const handleShow = () => {
    setShow1(!show)
  }
  return (
    <>
      {show && <div className="order-popup">
        <div className="popup-cent" style={{ height: "600px" }}>
          <div className="content-popup fadeInDown" style={{ width: "700px", height: "auto" }}>
            <CustomerOrderPopup orderDetail={order} customer={customer} show={show} />
            <div className="bottom-popup">
              <div className="button-container">
                <div onClick={handleShow} className="button-cent style3">
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
              </div>
            </div>
          </div>
          <div onClick={handleShow} className="overlay"></div>
        </div>
      </div>}
      <div style={{ borderBottom: "1px solid #d3dae7", marginTop: "10px", }} className="w-100">
        <Row>
          <Col xs={3}>
            <ul style={{ margin: "0px", padding: "0px" }}>
              <li className={styles.textDateChilOrder}>Ngày:</li>
              <li className={styles.textFormatDateChildOrder}><span>{format(new Date(order.order_at), 'MM/dd/yyyy - HH:mm')}</span></li>
              <li className={styles.orderId} onClick={handleShow}>#{order.order_code}</li>
            </ul>
          </Col>
          <Col xs={7}>
            <div className={styles.serviceTextChildOrder}>
              Dịch vụ
            </div>
            <div className="w-100">
              {order.orderItem.map((x, i) => {
                return (
                  <div key={i} className={styles.tagChildOrder}>
                      {x.product_name}
                  </div>
                )
              })}
            </div>
            <div className="w-100">
              {order.orderItem.map((x, i) => {
                return (
                  <div key={i}>
                    {x.employee_service_name1 !== "" && <span className={styles.tagKTV}>{x.employee_service_name1}</span>}
                    {x.employee_service_name2 !== "" && <span className={styles.tagKTV}>{x.employee_service_name2}</span>}
                    {x.employee_service_name3 !== "" && <span className={styles.tagKTV}>{x.employee_service_name3}</span>}
                    {x.employee_service_name4 !== "" && <span className={styles.tagKTV}>{x.employee_service_name4}</span>}
                    {x.employee_service_name5 !== "" && <span className={styles.tagKTV}>{x.employee_service_name5}</span>}
                  </div>
                )
              })}
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <div className={StyleSheet.divStatus}>
                <span className={styles.statusTextChildOrder}>
                  Trạng Thái
                </span>
              </div>
              <div>
                <span className={styles.textCash}> {convertCurrency(order.total_price)}</span>
              </div>
              {order.money_owed > 0 &&
                <span className={styles.tagOwed}>
                  Còn nợ: : {convertCurrency(order.money_owed)}
                </span>
              }
              {
                order.status === 1 && <span className={styles.tagPending}>
                  Đang xử lý
                </span>
              }
              {
                order.status === 2 && <span className={styles.tagCancle}>
                  Đã huỷ
                </span>
              }
              {
                order.status === 3 && <span className={styles.tagDone}>
                  Hoàn thành
                </span>
              }

            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
export default ChildOrder