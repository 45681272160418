import React, { useState, useEffect } from "react";
import _ from "lodash"
import axiosService from "../../utils/axios.service";
import { Spin } from 'antd';
import { notifications } from "../constant/Notify";
import axios from "axios";
import { customStylesMultiSelect } from '../constant/GeneralData'
import Select from 'react-select';

const StoreBanking = (props) => {
    const [listBanking, setListBanking] = useState([]);
    const [dataDelete, setDataDelete] = useState([]);
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(true)
    const [detailBanking, setDetailBanking] = useState(null)
    const [banks, setBanks] = useState(null)

    const changeData = (title, value) => {
        if (title == "bank_name") {
            setDetailBanking({
                ...detailBanking,
                bank_name: value.label,
                bank_id: value.value
            })
        } else {
            setDetailBanking({
                ...detailBanking,
                [title]: value
            })
        }
    }

    const addData = () => {
        setDetailBanking({
            store_id: props.detailStore.id,
            client_id: '',
            api_id: '',
            checksum_key: '',
            bank_id: 0,
            bank_name: '',
            account_no: '',
            account_name: ''
        })
    }

    const saveData = async () => {
        if (detailBanking.client_id == '' || detailBanking.api_id == ''|| detailBanking.checksum_key == ''|| 
            detailBanking.bank_id == 0 || detailBanking.account_name == ''|| detailBanking.account_no == '') {
            notifications({ type: 'warning', mess: "Vui lòng điền đầy đủ các thông tin" }); 
        } else {
            const res = await axiosService("api/store-banking/save", "POST", detailBanking);
            if (res.data.success && res?.data?.data) {
                let dataBankChange = res?.data?.data
                let newListBank = listBanking
                let index = _.findIndex(newListBank, function(o) { return o.id == dataBankChange.id; });
                console.log(index)
                if (index !== -1) {
                    newListBank[index] = dataBankChange
                } else {
                    newListBank.push(dataBankChange)
                }
                setListBanking([...newListBank])
                setDetailBanking(null)
                notifications({ type: 'success', mess: 'Cập nhật thành công' });
            } else {
                notifications({ type: 'error', mess: res?.data?.message ?? 'Đã xảy ra lỗi, vui lòng thử lại sau' }); 
            }
        }
    }

    const deleteData = async (id, key) => {
        const res = await axiosService("api/store-banking/delete/" + id, "PUT");

        if (res.data.success && res?.data?.data?.affected === 1) {
            let newListBank = listBanking
            newListBank.splice(key, 1)
            setListBanking([...newListBank])
            notifications({ type: 'success', mess: 'Cập nhật thành công' });
        } else {
            notifications({ type: 'error', mess: res?.data?.message ?? 'Đã xảy ra lỗi, vui lòng thử lại sau' }); 
        }
    }

    const fetchData = async () => {
        const { data } = await axiosService("api/store-banking/" + Number(props.detailStore.id), "GET");
        if (data.success) {
            if (data?.data?.length > 0) {
                setListBanking(data.data)
            }
        }
        setLoading(false)
    }

    const getListBank = async () => {
        let res = await axios.get("https://api.vietqr.io/v2/banks");
        if (res.status === 200) {
            let banks = [{ value: 0, label: "Chọn ngân hàng" }]
            res.data.data.map(x => { banks.push({ value: x.bin, label: x.name }) })
            setBanks(banks)
        }
    }
    
    useEffect(async () => {
        await fetchData();
        await getListBank()
    }, [])

    return (
        !detailBanking ? 
            <div className="popup-cent store-banking">
                <div className="content-popup fadeInDown">
                    <div className="head-popup">
                        <div className="title">Danh sách tài khoản ngân hàng</div>
                        <div className="close" onClick={() => {props.displayPopupStoreBanking()}}>×</div>
                    </div>
                    <Spin spinning={loading} size="large" tip="Đang tải...">
                        <div className="body-popup">
                            <div className="block-spa">
                                {listBanking.length > 0 ?
                                    <div className="block-data">
                                        {listBanking.map((x,y) => (
                                            <div key={y} className="row items-data">
                                                <div className="col-12 col-md-10">
                                                    <div className="row">
                                                        <div className="col-12 col-md-4 info-item">
                                                            <div className="title">Tên ngân hàng: </div>
                                                            <div className="value">{x.bank_name}</div>
                                                        </div>
                                                        <div className="col-6 col-md-4 info-item">
                                                            <div className="title">Tên tài khoản: </div>
                                                            <div className="value">{x.account_name}</div>
                                                        </div>
                                                        <div className="col-6 col-md-4 info-item">
                                                            <div className="title">Số tài khoản: </div>
                                                            <div className="value">{x.account_no}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 col-md-1">
                                                    <div className="button-cent style" onClick={() => {setDetailBanking(x)}}>
                                                        <span><i className="fas fa-edit"></i></span> Sửa
                                                    </div>
                                                </div>
                                                <div className="col-2 col-md-1">
                                                    <div className="button-cent style3" onClick={() => {deleteData(x.id, y)}}>
                                                        <span><i className="fas fa-trash-alt"></i></span> Xoá
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                : null}
                                <div className="action-title"><span onClick={() => {addData()}}><i className="fas fa-plus-circle"></i> Thêm ngân hàng</span></div>
                            </div>
                        </div>
                        <div className="bottom-popup">
                            <div className="button-container">
                            <div className="button-cent style3" onClick={() => {props.displayPopupStoreBanking()}}>
                                <span><i className="fas fa-times"></i></span>Đóng
                            </div>
                            <div className={`${!status ? "button-cent disable" : "button-cent"}`} onClick={status ? saveData : () => {}}>
                                <span><i className="fas fa-save"></i></span>Lưu thông tin
                            </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <div className="overlay" onClick={() => {props.displayPopupStoreBanking()}}></div>
            </div>
        :
            <div className="popup-cent edit-bank">
                <div className="content-popup fadeInDown">
                    <div className="head-popup">
                        <div className="title">Chi tiết tài khoản</div>
                        <div className="close" onClick={() => {props.displayPopupStoreBanking()}}>×</div>
                    </div>
                    <Spin spinning={loading} size="large" tip="Đang tải...">
                        <div className="body-popup">
                            <div className="block-spa">
                                {detailBanking ?
                                    <div className="block-data">
                                        <div className="row items-data">
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">Chọn ngân hàng: </div>
                                                    <div className="col-8 col-md-9 value">
                                                    <Select className="select booking user"
                                                        menuPlacement="bottom"
                                                        isSearchable={true}
                                                        isMulti={false} 
                                                        onChange={(e) => changeData('bank_name', e)}
                                                        styles={customStylesMultiSelect}
                                                        options={banks}
                                                        defaultValue={_.find(banks, function(o) { return o.value == detailBanking.bank_id; })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">Tên tài khoản: </div>
                                                    <div className="col-8 col-md-9 value">
                                                        <input type="text" onChange={(e) => changeData('account_name', e.target.value)} className="required" value={detailBanking.account_name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">Số tài khoản: </div>
                                                    <div className="col-8 col-md-9 value">
                                                        <input type="text" onChange={(e) => changeData('account_no', e.target.value)} className="required" value={detailBanking.account_no} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">Client ID: </div>
                                                    <div className="col-8 col-md-9 value">
                                                        <input type="text" onChange={(e) => changeData('client_id', e.target.value)} className="required" value={detailBanking.client_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">API ID:</div>
                                                    <div className="col-8 col-md-9 value">
                                                        <input type="text" onChange={(e) => changeData('api_id', e.target.value)} className="required" value={detailBanking.api_id} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-4 col-md-3 title">Checksum Key:</div>
                                                    <div className="col-8 col-md-9 value">
                                                        <input type="text" onChange={(e) => changeData('checksum_key', e.target.value)} className="required" value={detailBanking.checksum_key} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className="bottom-popup">
                            <div className="button-container">
                                <div className="button-cent style" onClick={() => saveData()}>
                                    <span><i className="fas fa-save"></i></span>Lưu thông tin
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <div className="overlay" onClick={() => {props.displayPopupStoreBanking()}}></div>
            </div>                              
    );
};

export default StoreBanking;
