import React, { useState, useEffect } from "react";
import * as convert from "../constant/Convert.helper";
import _ from "lodash"
import axiosService from "../../utils/axios.service";
import { TimePicker, Spin } from 'antd';
import moment from 'moment';
import { format } from "date-fns";
import { notifications } from "../constant/Notify";

const KTVBooking = (props) => {
    const [dataSpa, setDataSpa] = useState([]);
    const [dataPx, setDataPx] = useState([]);
    const [dataDelete, setDataDelete] = useState([]);
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(true)

    const changeDataShift = (title, value, key, type) => {
        let arrayData = [];
        if (type == 1) arrayData = dataSpa
        if (type == 4) arrayData = dataPx

        if (arrayData.length > 0) {
            let val = value
            if (val instanceof Date) val = format(val, 'HH:mm').replace(":", "")

            if (title === 'total' && isNaN(val)) val = 0

            arrayData[key] = {
                ...arrayData[key],
                [title]: Number(val)
            }
            if (type == 1) setDataSpa([...arrayData])
            if (type == 4) setDataPx([...arrayData])
        }
    }

    const deleteDataShift = (key, type) => {
        let arrayData = [];
        let arrayDelete = dataDelete;
        if (type == 1) arrayData = dataSpa
        if (type == 4) arrayData = dataPx

        if (arrayData.length >= 2) {
            if (arrayData[key].id) arrayDelete.push(arrayData[key].id)
            arrayData.splice(key, 1)
        } 
        arrayDelete = _.uniqBy(arrayDelete);
        setDataDelete([...arrayDelete])
        if (type == 1) setDataSpa([...arrayData])
        if (type == 4) setDataPx([...arrayData])
    }

    const addDataShift = (type) => {
        let arrayData = [];

        let dataNew = {
            id: null,
            store_id: Number(props.detailStore.id),
            time_type_end: 930,
            time_type_start: 930,
            total: 0,
            type: type
        }
        if (type == 1) {
            arrayData = [...dataSpa, dataNew]
            setDataSpa([...arrayData])
        }
        if (type == 4) {
            arrayData = [...dataPx, dataNew]
            setDataPx([...arrayData])
        }
    }

    const saveShift = async () => {
        setLoading(true)
        let listShift = [...dataSpa, ...dataPx];
        
        let listCreate = _.filter(listShift, function (o) { return !o.id })
        let listUpdate = _.filter(listShift, function (o) { return o.id })
        let payload = {
            dataCreate: listCreate,
            dataUpdate: listUpdate,
            dataDelete: dataDelete
        }
        const res = await axiosService("api/therapist-shift/", "POST", payload);
        if (res.data.success) {
            notifications({ type: 'success', mess: 'Cập nhật thành công' });
            await fetchData();
        } else {
            setLoading(false)
        }
    }

    const fetchData = async () => {
        const { data } = await axiosService("api/therapist-shift/" + Number(props.detailStore.id), "GET");
        if (data.success) {
            if (data.data.length > 0) {
                let dataSpa = [];
                let dataPx = [];
                data.data.map(x => {
                    if (x.type == 1) dataSpa.push(x)
                    if (x.type == 4) dataPx.push(x)
                })
                setDataSpa(dataSpa)
                setDataPx(dataPx)
            }
        }
        setLoading(false)
    }
    useEffect(async () => {
        await fetchData();
    }, [])

    useEffect(async () => {
        let listShift = [...dataSpa, ...dataPx];
        let listTime = _.map(listShift, 'time_type_start')
        listTime = [...listTime, ..._.map(listShift, 'time_type_end')]

        setStatus(!listTime.includes(0))
    }, [dataSpa, dataPx])

    return (
        <div className="popup-cent edit-slot-operation">
            <div className="content-popup fadeInDown">
            <div className="head-popup">
                <div className="title">Ca làm KTV</div>
                <div className="close" onClick={() => {props.displayPopupKTVBooking()}}>×</div>
            </div>
                <Spin spinning={loading} size="large" tip="Đang tải...">
                    <div className="body-popup">
                        <div className="block-spa">
                            <div className="block-title">KTV Da - Triệt</div>
                            <div className="block-data">
                                {dataSpa.length > 0 ?
                                    dataSpa.map((x,y) => (
                                        <div key={y} className="row items-data">
                                            <div className="col-6 col-md-1 title">Số lượng: </div>
                                            <div className="col-6 col-md-2 value">
                                                <input type="text" onChange={(e) => changeDataShift('total', e.target.value, y, x.type)} className="required" value={x.total} />
                                            </div>
                                            
                                            <div className="col-6 col-md-1 title">Bắt đầu:</div>
                                            <div className="col-6 col-md-2 choose-time">
                                                <TimePicker onChange={(e) => changeDataShift('time_type_start', e?._d ?? 0, y, x.type)} value={moment(convert.addLeadingZeros(x.time_type_start, 4), 'HH:mm')} format={'HH:mm'} />
                                            </div>

                                            <div className="col-6 col-md-1 title">Kết thúc:</div>
                                            <div className="col-6 col-md-2 choose-time">
                                                <TimePicker onChange={(e) => changeDataShift('time_type_end', e?._d ?? 0, y, x.type)} value={moment(convert.addLeadingZeros(x.time_type_end, 4), 'HH:mm')} format={'HH:mm'} />
                                            </div>
                                            {dataSpa.length >= 2 ?
                                                <div className="col-2 col-md-1">
                                                    <div className="button-cent style3" onClick={() => {deleteDataShift(y, x.type)}}>
                                                        <span><i className="fas fa-trash-alt"></i></span> Xoá
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    ))
                                : null}
                                <div className="action-title"><span onClick={() => {addDataShift(1)}}><i className="fas fa-plus-circle"></i> Thêm ca làm mới</span></div>
                            </div>
                        </div>

                        <div className="block-px">
                            <div className="block-title">KTV Phun xăm</div>
                            <div className="block-data">
                                {dataPx.length > 0 ?
                                    dataPx.map((x,y) => (
                                        <div key={y} className="row items-data">
                                            <div className="col-6 col-md-1 title">Số lượng: </div>
                                            <div className="col-6 col-md-2 value">
                                                <input type="text" onChange={(e) => changeDataShift('total', e.target.value, y, x.type)} className="required" value={x.total} />
                                            </div>
                                            
                                            <div className="col-6 col-md-1 title">Bắt đầu:</div>
                                            <div className="col-6 col-md-2 choose-time">
                                                <TimePicker onChange={(e) => changeDataShift('time_type_start', e?._d ?? 0, y, x.type)} value={moment(convert.addLeadingZeros(x.time_type_start, 4), 'HH:mm')} format={'HH:mm'} />
                                            </div>

                                            <div className="col-6 col-md-1 title">Kết thúc:</div>
                                            <div className="col-6 col-md-2 choose-time">
                                                <TimePicker onChange={(e) => changeDataShift('time_type_end', e?._d ?? 0, y, x.type)} value={moment(convert.addLeadingZeros(x.time_type_end, 4), 'HH:mm')} format={'HH:mm'} />
                                            </div>
                                            {dataPx.length >= 2 ?
                                                <div className="col-2 col-md-1">
                                                    <div className="button-cent style3" onClick={() => {deleteDataShift(y, x.type)}}>
                                                        <span><i className="fas fa-trash-alt"></i></span> Xoá
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                    ))
                                : null}
                                <div className="action-title"><span onClick={() => {addDataShift(4)}}><i className="fas fa-plus-circle"></i> Thêm ca làm mới</span></div>
                            </div>
                        </div>

                    </div>
                    <div className="bottom-popup">
                        <div className="button-container">
                        <div className="button-cent style3" onClick={() => {props.displayPopupKTVBooking()}}>
                            <span><i className="fas fa-times"></i></span>Đóng
                        </div>
                        <div className={`${!status ? "button-cent disable" : "button-cent"}`} onClick={status ? saveShift : () => {}}>
                            <span><i className="fas fa-save"></i></span>Lưu thông tin
                        </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <div className="overlay" onClick={() => {props.displayPopupKTVBooking()}}></div>
        </div>
    );
};

export default KTVBooking;
