import React from "react";
import * as convert from "../constant/Convert.helper"

let _ = require('lodash');

var _props = {};

function handleAddItem(item, event) {
    _props.updateSelecting()
    let itemSelected = _props.itemSelected  
    // if (itemSelected[item.code] !== undefined) {
    //     itemSelected[item.code].quantity++;
    //     item.quantity = itemSelected[item.code].quantity
    //     item.discount = (itemSelected[item.code].discount / (item.quantity - 1))*item.quantity
    // } else {
    //     item.quantity = 1
    // }
    item.quantity = 1
    if ( _props.tabService != 5) {
        item['combo_id'] = null
        item['combo_name'] = null
        item['max_used'] = item?.meta_object?.max_used ?? null
        if (item.type == 2 ) {
            item.new_package = true
        }
    
        if (item.type == 4 ) {
            let itemTest = {
                code: item.code,
                consultant: [],
                discount: item.discount,
                price: item.price,
                product_name: item.product_name,
                quantity: item.quantity,
                service: [],
                type: item.type,
            }
            item = {...itemTest}
        }
        itemSelected.push(item)
    } else {
        for(let val of item.products) {
            val['consultant'] = []
            val['discount'] = Number(val.discount)
            val['service'] = []
            val['quantity'] = 1
            val['combo_id'] = item.id
            val['combo_name'] = item.rule_name
            val['max_used'] = Number(val.meta_object.max_used ? val.meta_object.max_used : null)
            // val['product_id'] = val.id
            if (val.type == 2 ) {
                val.new_package = true
            }
            itemSelected.push(val)
        }
    }
    
    // item.quantity = 1
    // itemSelected.push(item)
    _props.onSelectItem(itemSelected);
}

function getQuantityItem(code) {
    let itemSelected = _props.itemSelected;
    itemSelected = _.groupBy(itemSelected, ({ code }) => code);

    let quantity = itemSelected[code][0].quantity ?? 0;
    return quantity
}

export default function PosServicesList(props){
    _props = props
    let itemSelected = _props.itemSelected
    if (props.serviceGroup !== undefined) {
        let serviceGroupId = props.serviceGroup.id
        if (props.service.category_id !== serviceGroupId) return ''
    }

    let textSearch = props.serviceSearchString.toLowerCase()
    let serviceName = ''
    if ( props.tabService != 5) {
        serviceName = props.service.product_name.toLowerCase()
    } else {
        serviceName = props.service.rule_name.toLowerCase()
    }
    let serviceCode = props.service.code.toLowerCase()
    if (props.serviceSearchString.length > 0) {
        if (!serviceName.includes(textSearch) && !serviceCode.includes(textSearch)) {
            return '';   
        }
    }
    var arrayItemSelected = Object.values(itemSelected)

    var activeSelect = 'grid-two-column'
    var quantity = 0
    
    var comboId = 0
    arrayItemSelected.map((item, key) => {
        if (item.code ==  props.service.code && props.tabService != 5 && !item.combo_id) {
            activeSelect = 'grid-two-column active'
            quantity += item.quantity 
        }
        if (props.tabService == 5 && item.combo_id ==  props.service.id && comboId != item.combo_id) {
            activeSelect = 'grid-two-column active disable-package2'
            quantity += item.quantity
            comboId = item.combo_id
        }
    })

  
    return(
        props.tabService != 5 ? 
        <li className={ activeSelect} 
            role="button" tabIndex="0" onClick={handleAddItem.bind(this, {...props.service})}>
            <div>
                <div className="product-image" style={props.service.type == 2 ? {"backgroundImage": "url('/images/placeholder/orange_card.png')"} : {"backgroundImage": "url('/images/placeholder/placeholder_img_sqr.jpeg')"}}></div>
                <div className="pull-left" style={{"width": "calc(100% - 82px)"}}>
                <div className="product-name">{props.service.product_name}</div>
                <div className="pull-left clear">
                    <div className="product-sku text-left">{props.service.code}</div>
                    <div className="customer-booking-total ng-isolate-scope">
                        <span className="cash-vnd customer-booking-total">{convert.convertCurrency(props.service.price)}</span>
                    </div>
                </div>
                { quantity > 0 ? (<div className="product-cart-counter">{quantity}</div>) : '' }
                </div>                   
            </div>
        </li>
        : 
        <li className={ activeSelect } role="button" tabIndex="0" onClick={ quantity == 0 ? handleAddItem.bind(this, {...props.service}) : null}>
            <div>
                <div className="product-image" style={{"backgroundImage": "url('/images/placeholder/orange_card.png')"}}></div>
                <div className="pull-left" style={{"width": "calc(100% - 82px)"}}>
                <div className="product-name">{props.service.rule_name}</div>
                <div className="pull-left clear">
                    <div className="product-sku text-left">{props.service.code}</div>
                    <div className="customer-booking-total ng-isolate-scope">
                        <span className="cash-vnd customer-booking-total">{convert.convertCurrency(props.service.price)}</span>
                    </div>
                </div>
                {quantity > 0 ? (<div className="product-cart-counter">{quantity}</div>) : '' }
                </div>                   
            </div>
        </li>
    );
}