import React, { useRef, useState, useCallback } from 'react';
import { useReactToPrint } from "react-to-print";
import TemplatePrintOrder from '../pos/TemplatePrintOrder';

export const PrintOrder = (props) => {
    const invoiceRef = useRef(null);
    const [showPrint, setShowPrint] = useState(true)
    const onBeforeGetContentResolve = useRef(null);
  
    const handleOnBeforeGetContent = useCallback(() => {
      setShowPrint(false)
      return new Promise((resolve) => {
        onBeforeGetContentResolve.current = resolve;
        resolve();
      });
    });
  
    const handleAfterPrint = useCallback(() => {
      setShowPrint(true)
    }, [setShowPrint]);
  
    const printInvoice = useReactToPrint({
      content: () => invoiceRef.current,
      onBeforeGetContent: handleOnBeforeGetContent,
      onAfterPrint: handleAfterPrint,
      
      removeAfterPrint: true
    });

    let dataOrder = {
      ...props.currentPos,
      orderItem: props.orderItem 
    }
    return (
        <div>
          <button onClick={showPrint ? printInvoice : () => {}} className={showPrint ? 'btn btn-xs bg-teal-400 pull-left mr-5 ng-binding ng-scope' : 'btn btn-xs bg-teal-400 pull-left mr-5 ng-binding ng-scope disable'}>
              <i className="fa fa-pencil"></i> In hóa đơn
          </button>
          {dataOrder !== '' ? 
            <div style={{ display: "none" }}><TemplatePrintOrder ref={invoiceRef} currentPos={dataOrder} transaction={dataOrder.transaction} /></div>
          :null }
        </div>
    );
}
  
export default PrintOrder;