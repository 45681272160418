import React, { useEffect, useState, useLayoutEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { Tooltip } from 'antd';
import Header from "../Header";
import styles from "./style.module.css"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import "./scss/reward.scss"
import StorageService from "../../utils/storage.service";


const Reward = (props) => {
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)

    function convertCurrency(data) {
        console.log(data)
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    return (
        <>
            <div className="reward-page">
                <Container>
                    {Object.values(props.table).map((val, key) => (
                         <div className="reward-category">
                         <div className="title-reward">{val[0].emulationCategory.group_name}</div>
                         <div className="list-reward">   
                             <Row>
                                {val.map((v, k) =>(
                                    <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            {v.rule_name}
                                            <div className="count"><span>{v.countTaget}</span>/{v.max_used < 100 ? v.max_used: 'Tất cả' } KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: v.percent+'%', maxWidth: "100%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <span style={{fontSize: '13px'}}>Giải thưởng:  <span style={{color: 'red'}}>{v.value > 100 ? convertCurrency(Number(v.value)) : v.value + '%'}</span></span>
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>{v.target_action < 1000 ? v.count_services: convertCurrency(v.total_paid) }</span></div>
                                                <div className="total">Tổng số: <span>{v.target_action > 1000 ? convertCurrency(v.target_action) : v.target_action }</span></div>
                                            </div>
                                            <div className="text-success">
                                                {v.rank > 0 ? "Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: " + String(v.rank).padStart(2, '0')  : ""}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                ))}
                             </Row>
                         </div>
                     </div>
                    ))}
                    {/* <div className="reward-category">
                        <div className="title-reward">Chương trình Tiên phong (20/10/2024 - 19/11/2024)</div>
                        <div className="list-reward">   
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Đầu triệt cá nhân
                                            <div className="count"><span>5</span>/10 KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>10</span></div>
                                                <div className="total">Tổng số: <span>15</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: <b>10</b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Triệt lông Premium
                                            <div className="count"><span>5</span>/10 KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>10</span></div>
                                                <div className="total">Tổng số: <span>15</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: <b>10</b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Cent D-Freeze II
                                            <div className="count"><span>5</span>/10 KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>10.000.000</span></div>
                                                <div className="total">Tổng số: <span>20.000.000</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: <b>10</b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="reward-category">
                        <div className="title-reward">Chương trình Thi đua cuối năm (20/10/2024 - 24/01/2025)</div>
                        <div className="list-reward">   
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Đầu triệt cá nhân
                                            <div className="count">Mốc 1</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy"><span>0</span></div>
                                                <div className="count"><span>5</span> đầu triệt</div>
                                                <div className="total"><span>10</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành 10 đầu triệt cá nhân (Mốc 1)
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Triệt lông Premium
                                            <div className="count"><span>5</span>/10 KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>10</span></div>
                                                <div className="total">Tổng số: <span>15</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: <b>10</b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="item-reward">
                                        <div className="title-detail">
                                            Cent D-Freeze II
                                            <div className="count"><span>5</span>/10 KTV</div>
                                        </div>
                                        <div className="content-reward">
                                            <div className="progress-striped">
                                                <div className="progress-bar" style={{width: "50%", maxWidth: "50%"}}>
                                                    <div className="current-process"><div className="icon"></div></div>
                                                </div>       
                                                <div className="end-process"><div className="icon"></div></div>                
                                            </div>
                                            <div className="text-process">
                                                <div className="buy">Đạt: <span>10.000.000</span></div>
                                                <div className="total">Tổng số: <span>20.000.000</span></div>
                                            </div>
                                            <div className="text-success">
                                                Chúc mừng bạn đã hoàn thành thi đua với thứ hạng: <b>10</b>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div> */}
                </Container>
            </div>
        </>
    )
}
export default Reward