import React, { useState }  from "react";
import axiosService from "../../utils/axios.service";

const Delete = (props) => {
  const _props = props;
  const [dataOrder, setDataOrder] = useState(_props.dataOrder);

  if (_props.showDelete === false) return "";

  const acceptDelete = () => {
    let data = {
      status: 2,
      id_booking: dataOrder.id_booking
    };
    props.deleteOrder(data)
  };

  return (
    <div className="popup-cent">
      <div className="content-popup fadeInDown">
        <div className="head-popup">
          <div className="title">Thông báo</div>
          <div className="close" onClick={_props.statusDeleteOrder}>×</div>
        </div>
        <div className="body-popup">
          <div className="title-body">Bạn có chắc chắn muốn xóa đơn hàng này không?</div>
        </div>
        <div className="bottom-popup">
          <div className="button-container">
            <div className="button-cent style3" onClick={_props.statusDeleteOrder}>
              <span><i className="fas fa-times"></i></span>Không
            </div>
            <div className="button-cent" onClick={acceptDelete}>
              <span><i className="fas fa-save"></i></span>Có
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={_props.statusDeleteOrder}></div>
    </div>
  );
}

export default Delete;
