import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../context/login.context";
import Header from "./Header";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [user] = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search)
  const type = queryParams.get("type")
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user ?
          <div className="body-page">
            {type != "crm" ? <Header /> : ""}
            {user.role != 2 ?
              <Component {...props} />
            : 
              (!["/report-by-ktv", "/booking"].includes(window.location.pathname)) ?
                <Redirect to="/booking" />
              :null
            }
          </div>
          :
           <Redirect to="/" />
      }
    />
  );
}

export default ProtectedRoute;
