import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styles from "./styles/style.module.css"
import axiosService from "../../utils/axios.service";
import { format } from 'date-fns'
import CreateNotePage from './CreateNote';
import {Modal,Button} from 'react-bootstrap';
import {CustomerOrderPopup} from "../customer/CustomerOrderPopup"
const NotePopUp = ({ closeModal, noteCustomer,updateNoteCustomer,customer }) => {
    const [orders, setOrders] = useState([])
    const [create, setCreate] = useState(false)
    const [chooseOrder, setChooseOrder] = useState("")
    const [action, setAction] = useState(1)
    const [noteId, setNoteId] = useState("")
    const [showDelete, setShowDelete] = useState(false)
    const [order,setOrder] = useState({})
    const [show,setShow] = useState(false)
    const getData = async () => {
        const res = await axiosService(`api/notes/${customer.id}`, "GET");
        const { data } = res
        if (data.code === 200) {
            setOrders(data.data)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    const showTran = (order)=>{
        setOrder(order)
        setShow(true)
    }
    const createNote = (choose = false, order, a, id) => {
        setCreate(!create)
        setAction(a)
        if (choose) {
            setChooseOrder(order)
            setNoteId(id)
        }
    }
    const updateState = (action, data) => {
        if (action === 1) {
            const findIndex = orders.findIndex((x) => x.id === data.order.id)
            orders[findIndex].note = data
            setOrders([...orders])
        }
    }
    const hanldeNote = (e) => {
        updateNoteCustomer(e.target.value)
    }
    const hanldeSubmitNot = async () => {
        const params = {
            note:noteCustomer
        }
        const newNote = noteCustomer
        const res = await axiosService(`api/notes/customer/${customer.id}`, "PUT", params);
        const { data } = res
        if (data.code === 200) {
            alert("Cập nhật thành công")
            updateNoteCustomer(newNote)
        }
    }
    const hanldeDeleteNote = async () => {
        const res = await axiosService(`api/notes/${noteId}`, "DELETE");
        const { data } = res
        if (data.code === 200) {
            alert("Xoá thành công")
            const findIndex = orders.findIndex((x)=>x.note.id===noteId)
            orders[findIndex].note = null
            setOrders([...orders])
            hanldeDelete()
        }
    }
    const hanldeDelete = () => {
        setShowDelete(!showDelete)
    };
    const hanldeConfirm = (id)=>{
        setNoteId(id)
        setShowDelete(!showDelete)
    }
    const handleShow = ()=>{
        setShow(!show)
    }
    return (
        <>
         {show ? <div className="order-popup">
        <div className="popup-cent" style={{height:"600px"}}>
          <div className="content-popup fadeInDown" style={{width:"700px",height:"auto"}}>
            <CustomerOrderPopup orderDetail={order} customer={customer} show={show} />
            <div className="bottom-popup">
              <div className="button-container">
                <div onClick={handleShow} className="button-cent style3">
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
              </div>
            </div>
          </div>
          <div onClick={handleShow} className="overlay"></div>
        </div>
      </div>:
            showDelete ?
      
         <Modal show={true} onHide={hanldeDelete}>
        <Modal.Header closeButton className="p-2">
          <Modal.Title>Xác nhận xoá hồ sơ lưu trữ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Bạn có chắc muốn xoá không ?
        </Modal.Body>
        <Modal.Footer className="p-2">
          <Button variant="secondary" onClick={hanldeDelete}>
            Huỷ
          </Button>
          <Button variant="primary" onClick={hanldeDeleteNote}>
            Đồng ý
          </Button>
        </Modal.Footer>
      </Modal>

            :create ?
                <CreateNotePage createNote={createNote} action={action} chooseOrder={chooseOrder} updateState={updateState} noteId={noteId} />
                :
                <div className="popup-cent" style={{ height: "100%" }}>
                    <div className="content-popup fadeInDown">
                        <div className="body-popup">
                            <div className={styles.popupHeader}>
                                <div style={{ display: "flex" }}>
                                    <h5 className={styles.textHeader}>Hồ sơ ghi chú </h5>
                                </div>
                                <div className="close" onClick={closeModal}>×</div>
                            </div>
                            <div className='d-flex justify-content-between mt-2 p-2'>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Nhập vào nội dung ghi chú"
                                    style={{ height: '8rem', width: "50rem" }}
                                    value={noteCustomer}
                                    onChange={hanldeNote}
                                />
                                <div className="button-cent ml-3 mr-2" onClick={hanldeSubmitNot}>
                                    <span><i className="fas fa-save"></i></span>Lưu thông tin
                                </div>
                            </div>
                            <div className={`mt-2 p-2 ${styles.scrollTransaction}`}>
                                {orders.length > 0
                                    ?
                                    orders.map((x) => {
                                        return (
                                            <div className={`${styles.childOrder} d-flex mt-1`} key={x.id} >
                                                <div className={styles.formLeft}>
                                                    <b className={styles.textDate}> {format(new Date(x.order_at), 'MM/dd/yyyy - HH:mm')} </b>
                                                </div>
                                                <div className='pl-3'>
                                                    <span className={styles.textNone}> Khách hàng sử dụng dịch vụ với mã HĐ</span> <span
                                                    onClick={()=>{showTran(x)}}
                                                     className={styles.textCodeOrder}>{`#${x.order_code} `}</span>
                                                    {
                                                        x.note ? <>
                                                        <b className={styles.textCreateNote} onClick={() => { createNote(true, x.id, 2, x.note.id) }}>
                                                            {`Xem/Sửa!   `}
                                                        </b>
                                                        <b className={styles.textDelete} onClick={() => {hanldeConfirm(x.note.id) }}>
                                                            Xoá
                                                        </b>
                                                        </> :
                                                            <>
                                                                <span className={styles.textNone}>
                                                                    {"chưa được thêm ghi chú "}
                                                                </span>
                                                                <b className={styles.textCreateNote} onClick={() => { createNote(true, x.id, 1) }}>
                                                                    Tạo mới ngay!
                                                                </b>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    : "Không có thông tin"}
                            </div>
                            <div className="bottom-popup border-top">
                                <div className="d-flex justify-content-end p-2">
                                    <div className={`button-cent mr-1 ${styles.buttn2}`} onClick={closeModal}>
                                        <span ><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                    {/* <div className="button-cent style3 mr-1" onClick={hanldeClick}>
                                    <span><i className="fa fa-fas fa-calendar-alt"></i></span>Lưu thông tin
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}
export default NotePopUp

