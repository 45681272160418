import axios from "axios";
import langsEnum from "../enums/langs.enum";
// const API_URL = process.env.REACT_APP_API_BACKEND;
const API_URL = (process.env.REACT_APP_API_BACKEND !== undefined) ? process.env.REACT_APP_API_BACKEND : "https://api.we.cent.beauty";
const authHeader = (crmToken) => {
  const token = localStorage.getItem("access_token");
  const lang = (localStorage.getItem("lang") && langsEnum.includes(localStorage.getItem("lang"))) ? localStorage.getItem("lang") : langsEnum[0]
  const headers = {lang};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`
  } else {
    headers["Authorization"] = `Bearer ${crmToken}`
  }
  return headers;
};

export async function axiosGet(uri) {
  return await axios({
    url: `${API_URL}/${uri}`,
    method: "GET",
    data: [],
    headers: authHeader(),
  });
}

export default function axiosService(uri, method, data, token) {
  return axios({
    url: `${API_URL}/${uri}`,
    method,
    data,
    headers: authHeader(token),
  });
}
