export const listStatusBooking = {
    1:'Sử dụng',
    2: 'Đã khóa',
    3: 'Hết hạn',
    4: 'Hoàn thành',
    5: 'Hoàn tiền',
    6: 'Đã nâng cấp',
    7: "Đã hoàn ví triệt"
}



export const packageStatus = [
    { value: '0', label: 'Tất cả' },
    { value: '1', label: 'Sử dụng' },
    { value: '2', label: 'Đã khóa' },
    { value: '3', label: 'Hết hạn' },
    { value: '4', label: 'Hoàn thành' },
    { value: '5', label: 'Hoàn thành' },
    { value: '9', label: 'Hết hạn sau 1 tháng' },
    { value: '10', label: 'Hết hạn sau 1 tuần' },
]

export const customStylesBirthday = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      height: 32,
      paddingLeft: 0, 
      paddingRight: 0, 
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: 'none'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight =  0; 
      const height = 32; 
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
};

export const cardType = {
    1:'Thẻ lần',
    2: 'Thẻ tiền',
}