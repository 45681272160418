import React, { useState, useLayoutEffect, useMemo } from "react";
import { format } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import { Spin, Table, Pagination, Select } from "antd"
import StorageService from "../../utils/storage.service";
import { DatePicker, SelectPicker } from "rsuite";
import * as convert from "../constant/Convert.helper";
import { Col } from "react-bootstrap";
import Header from "../Header";

import "./scss/styles.scss"

const { Option } = Select;
const queryParams = new URLSearchParams(window.location.search)
const type = queryParams.get("type")
const ReportByKTV = () => {
    const dataType = [
        {
            key: 1,
            value: "Tư vấn"
        },
        {
            key: 2,
            value: "Phục vụ"
        }
    ]
    const columns = [
        {
            title: 'Mã order',
            key: "order",
            align: "center",
            render: (data) => {
                return (<>{data.order.order_code}</>)
            },
        },
        {
            title: 'Tên khách hàng',
            key: "customer",
            align: "center",
            render: (data) => {
                return (
                    <>
                        <p>Tên: {data.order.customers.full_name} {data.order.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</p>
                        <p>SDT: {data.order.customers.mobile}</p>
                    </>
                )
            },
        },
        {
            title: 'Tên KTV',
            key: "ktv",
            align: "center",
            render: (data) => {
                return (
                    (type == 1) ?
                        <>
                            <p>{data.employee_consultant_name1}</p>
                            <p>{data.employee_consultant_name2}</p>
                            <p>{data.employee_consultant_name3}</p>
                            <p>{data.employee_consultant_name4}</p>
                            <p>{data.employee_consultant_name5}</p>
                        </>
                    : 
                        <>
                            <p>{data.employee_service_name1}</p>
                            <p>{data.employee_service_name2}</p>
                            <p>{data.employee_service_name3}</p>
                            <p>{data.employee_service_name4}</p>
                            <p>{data.employee_service_name5}</p>
                        </>      
                )
            },
        },
        {
            title: 'Tên dịch vụ',
            key: "service",
            align: "center",
            render: (data) => {
                return (<>{data.product_name}</>)
            },
        },
        {
            title: 'Số lượng',
            key: "quantity",
            align: "center",
            render: (data) => {
                return (<>{data.quantity}</>)
            },
        },
        {
            title: 'Giá tiền',
            key: "price",
            align: "center",
            render: (data) => {
                return (<>{convert.convertCurrency(data.price)}</>)
            },
        }
    ];

    const [startDate, setStartDate] = useState(format((new Date()),"yyyy-MM-dd"))
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [type, setType] = useState(dataType[0].key)
    const [locations, setLocations] = useState([])
    const [store, setStore] = useState((JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role != 1) ? 0 : parseInt(StorageService.getItem("currentStore")))
    const [listUser, setListUser] = useState([])
    const [currentUser, setCurrentUser] = useState((JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 1) ? 0 : JSON.parse(StorageService.getItem("user_info")).id)

    const handleChangePage = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)
    }

    const changeDate = async (event) => {
        setStartDate(format(new Date(event), 'yyyy-MM-dd'))
        setPage(1)
        setLimit(10)
    }

    const onChangeType = (value) => {
        setType(value);
        setPage(1)
        setLimit(10)
    };

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, type)
    }, [startDate, type, store, limit, page, currentUser])
    
    useLayoutEffect(() => {
        getDataStores()
        getDataUser()
    }, [])

    const getDataStores = async () => {
        const rs = await axiosService("api/stores", "GET");
        const { data } = rs
        if (data.code === 200) {
            data.data.unshift({ name_store: "Tất cả", id: 0 })
            const newLocations = data.data.map(x => {
                return { label: x.name_store, value: x.id }
            })
            setLocations([...newLocations])
        }
    }

    const getDataUser = async () => {
        const rs = await axiosService("api/users", "GET");
        const { data } = rs
        if (data.code === 200) {
            data.data.unshift({ name: "Tất cả", id: 0 })
            const dataUser = data.data.map(x => {
                return { label: x.name, value: x.id }
            })
            setListUser([...dataUser])
        }
    }

    const fetchData = async (sD, sT) => {
        const rs = await axiosService(`api/transaction/report-ktv?limit=${limit}&page=${page}&startDate=${sD}&typeKTV=${sT}&store=${store}&idKTV=${currentUser}`, "GET")
        const { data } = rs

        if (data.code === 200) {
            setList(data.data.results)
            setPage(data.data.pagination.page)
            setLimit(data.data.pagination.pageSize)
            setTotal(data.data.pagination.total)
            setLoading(false)
        }
    }

    return (
        <Spin spinning={loading}>
            <Header type={type} token={""} />
            <div className={`container-fluid report-by-day`}>
                <div className="list-transaction">
                    <div className="head-list">
                        <div className="title-list">Danh sách {dataType[type-1].value}</div>
                        <div className="filter-list">
                            {(JSON.parse(StorageService.getItem("user_info")).role == 1) ?
                                <>
                                    <Col>
                                        <SelectPicker data={locations} className="w-100" defaultValue={store} onChange={(e) => {setStore(e)}} cleanable={false} />
                                    </Col>
                                    <Col>
                                        <SelectPicker data={listUser} className="w-100" defaultValue={currentUser} onChange={(e) => {setCurrentUser(e)}} cleanable={false} />
                                    </Col>
                                </>
                            : ""}
                            
                            <Col>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    value={type}
                                    className="style-select"
                                    onChange={onChangeType}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {dataType.map((x, i) => (
                                        <Option key={i} value={x.key}>{x.value}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col>
                                <DatePicker
                                    defaultValue={new Date()}
                                    oneTap
                                    format={"yyyy-MM-dd"}
                                    className="custom-date-picker date-filter"
                                    onChange={changeDate}
                                    size="sm"
                                    placement="bottomEnd" 
                                />
                            </Col>
                        </div>
                    </div>
                    <div className="table-list">
                        <Table
                            className="w-100 text-center"
                            pagination={false}
                            columns={columns} dataSource={list}
                        />
                        <div className={`d-flex justify-content-end w-100 mt-4 pt-2 pb-2 mr-5`}>
                            <Pagination current={page} total={total} pageSize={limit} showSizeChanger showQuickJumper onChange={handleChangePage} />
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default ReportByKTV