import { configureStore } from '@reduxjs/toolkit'
import VoucherSlice from "./reducers/voucher"
import EmulationSlice from "./reducers/emulation"
import getOptions from "./reducers/options"

const store = configureStore({
    reducer: {
        vouchers: VoucherSlice,
        getOptions: getOptions,
        emulation: EmulationSlice,
    },
})
export default store
