import { Row, Col } from "react-bootstrap"
import { Input, Switch, message } from 'antd';
import { useEffect,  useState } from "react";
import axiosService from "../../utils/axios.service";

export default function AddNewVoucherCategory({ styles, SetShowPopupAddCategory, showPopupAddCategory, setDataSource, dataEditCategory}) {
    const [ruleCategoryName, setRuleCategoryName] = useState("")
    const [ruleCategoryDs, setRuleCategoryDs] = useState("")
    const [ruleCategoryStatus, setRuleCategoryStatus] = useState(true)
    const [idCategory, setIdCategory] = useState(0)

    const onChangeStatus = (checked) => {
        setRuleCategoryStatus(checked)
    }
    
    useEffect(async () => {
        if (dataEditCategory.id != undefined) {
            setRuleCategoryName(dataEditCategory.group_name)
            setRuleCategoryDs(dataEditCategory.des)
            setRuleCategoryStatus(dataEditCategory.status == 1 ? true : false)
            setIdCategory(dataEditCategory.id)
        }
    }, [dataEditCategory]) 

    const  saveNewCategoryVoucher = async() => { 

        let data = {
          group_name: ruleCategoryName,
          des: ruleCategoryDs,
          status: ruleCategoryStatus
        }

        if (ruleCategoryName.length > 2) {
             let rs = []
            if (!idCategory) {
                rs = await axiosService(`api/category_voucher/`, "POST", data)
            } else {
                rs = await axiosService(`api/category_voucher/` + idCategory, "PUT", data)
            }
            if (rs.data.code == 200) {
                message.success("Tạo group thành công")
                SetShowPopupAddCategory(!showPopupAddCategory)
                setDataSource(rs.data.data)
            }
        } else {
            message.error("Tên group ko được bỏ trống bà nhiều hơn 2 ký tự")
        }
    }

    const handleAddCategory = () => {
        SetShowPopupAddCategory(!showPopupAddCategory)
    }
    return (
        <>
            <Row className="ml-1">
                <div className="w-100 mb-4">
                    <h5> Thêm group mã voucher </h5>
                </div>
                <Col md={{ span: 10, offset: 1 }}>
                    <div className="d-flex w-100 mb-4">
                        <div className={`${styles.widthLabel}`}>
                            <span> Tên nhóm : </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <Input  onChange={(e) => setRuleCategoryName(e.target.value)} value={ruleCategoryName} />
                    </div>
                    <div className="d-flex w-100 mb-4">
                        <div className={`${styles.widthLabel}`}>
                            <span> Mô tả :</span>
                        </div>
                        <Input  onChange={(e) => setRuleCategoryDs(e.target.value)} value={ruleCategoryDs} />
                    </div>

                    
                    <div className="d-flex w-100 mb-4">
                        <div className={`${styles.widthLabel}`}>Trạng thái
                        </div>
                        <Switch checked={ruleCategoryStatus} className="ml-0" onChange={onChangeStatus} />
                    </div>

                </Col>
            </Row>
            <div className="bottom-popup">
                <div className="button-container">
                <div className="button-cent style3" onClick={handleAddCategory}>
                    <span><i className="fas fa-times"></i></span>Không
                </div>
                <div className="button-cent" onClick={saveNewCategoryVoucher}>
                    <span><i className="fas fa-save"></i></span>Lưu
                </div>
                </div>
            </div>
        </>
    )
}