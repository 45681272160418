import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "../Header"
import styles from "../style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line } from '@ant-design/plots';
import { Table, Spin } from "antd"
import getDates from "../getDates"
import { DataGrid } from '@mui/x-data-grid';
import "../scss/report.scss"
import StorageService from "../../../utils/storage.service";
const ReportOperateBooking = () => {
    const columns = [
        { dataIndex: 'date', title: 'Thời gian', },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            dataIndex: 'count_booking',
            title: 'Số lượng lịch đặt',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_close',
            title: 'Số lượng lịch huỷ',
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_success',
            title: 'Số lượng lịch thành công',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            dataIndex: 'proportion',
            title: 'Tỉ lệ huỷ/tổng lịch đặt',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_customer',
            title: 'Số lượng khách hàng phục vụ',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_service',
            title: 'Lượt dịch vụ',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
    ];
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [sortBy, setSortBy] = useState(0)
    const [totalCountBooking, setTotalCountBooking] = useState(0)
    const [totalCountClose, setTotalCountClose] = useState(0)
    const [totalCountSuccess, setTotalCountSuccess] = useState(0)
    const [totalCountCustomer, setTotalCountCustomer] = useState(0)
    const [totalCountService, setTotalCountService] = useState(0)
    const [ordersByDaysCountBooking, setOrdersByDaysCountBooking] = useState([])
    const [ordersByDaysCountClose, setOrdersByDaysCountClose] = useState([])
    const [ordersByDaysCountSuccess, setOrdersByDaysCountSuccess] = useState([])
    const [totalProportion, setTotalProportion] =   useState('0%')
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const hanldeRangeDate = useCallback((sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }, [startDate, endDate])

    const hanldeStore = useCallback((e) => {
        setStore(e)
    }, [store])

    const hanldeSortBy = useCallback((e) => {
        setSortBy(e)
    }, [sortBy]);

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate, sortBy)
    }, [store, endDate, startDate, sortBy])
    
    const fetchData = async (s, eD, sD, sBy) => {
        const rs = await axiosService(`api/reports/store/work/operate/${s}?storeId=${s}&startDate=${sD}&endDate=${eD}&sortBy=${sBy}`, "GET")
        const { data } = rs.data
        if (rs.status == 200) {
            setTotalCountBooking(data.totalCountBooking)
            setTotalCountClose(data.totalCountClose)
            setTotalCountSuccess(data.totalCountSuccess)
            setTotalCountCustomer(data.totalCountCustomer)
            setTotalProportion(data.totalProportion)
            setTotalCountService(data.totalCountService)
            setRows(data.dataTable)

            let rowsCountBooking = []
            let rowsCountBookingSuccess = []
            let rowsCountBookingService = []
            for (let item of data.dataTable) {
                let rowCountBooking = {
                    date: item.date,
                    value: item.count_booking,
                    type: 'Lịch đặt'
                }
                let rowCountBookingSuccess = {
                    date: item.date,
                    value: item.count_success,
                    type: 'Lịch hoàn thành'
                }
                let rowCountBookingService = {
                    date: item.date,
                    value: item.count_service,
                    type: 'Lượt dịch vụ'
                }
                rowsCountBooking.push(rowCountBooking)
                rowsCountBookingSuccess.push(rowCountBookingSuccess)
                rowsCountBookingService.push(rowCountBookingService)
            }
            setOrdersByDaysCountBooking([...rowsCountBooking, ...rowsCountBookingSuccess, ...rowsCountBookingService])
            let value = "yyyy-MM-dd"
                if (sortBy === 1) {
                    value = "ww"
                } else if (sortBy === 2) {
                    value = "MM"
                }
            setLoading(false)
        }
    
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const config = useMemo(() => configChart(ordersByDaysCountBooking, convertCurrency), [ordersByDaysCountBooking])
    
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    

    return (
        <div className={`${styles.background} container-fluid report-page`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                sortBy={sortBy} hanldeSortBy={hanldeSortBy}
                title={"Vận hành cơ sở"} type={"daily"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thời gian', key: "date" },
                        { label: "Số lượng lịch đặt", key: "count_booking" },
                        { label: "Số lượng lịch huỷ", key: "count_close" },
                        { label: "Số lượng lịch thành công", key: "count_success" },
                        { label: "Tỉ lệ huỷ/tổng lịch đặt", key: "proportion" },
                        { label: "Số lượng khách hàng phục vụ", key: "count_customer" },
                        { label: "Lượt dịch vụ", key: "count_service" },
                    ]
                }
                userAdmin={userAdmin}
            />
            <Spin spinning={loading}>
                <Row className="mt-2">
                    <Col xs={12} md={8}>
                        <Row className="h-100">
                            <Col xs={6} md={3}>
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Số lượng khách hàng: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <span className={styles.textTag4}>Tỉ lệ huỷ/tổng lịch đặt:</span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {totalCountCustomer}</span>
                                            <span className={styles.textTag2}> {totalProportion}</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>

                            <Col xs={6} md={3} className="pay_type">                   
                                <Tag color="violet" className="w-100 h-55 mb-3 ">
                                    <span className={styles.textTag3}>Tổng lịch dặt</span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {/* <span className={styles.textTag4}>Bán mới</span> */}
                                        </div>
                                        <div>
                                            <span className={styles.textTag}>{totalCountBooking}</span>
                                        </div>
                                    </div>
                                </Tag>
                                <Tag color="green" className="w-100 h-55 mb-3" >
                                    <span className={styles.textTag3}>Số lượng lịch thành công</span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {/* <span className={styles.textTag4}>Bán mới</span> */}
                                        </div>
                                        <div>
                                            <span className={styles.textTag}>{totalCountSuccess}</span>
                                        </div>
                                    </div>
                                </Tag>

                            </Col>
                            <Col xs={6} md={3} className="pay_type">                   
                                <Tag color="violet" className="w-100 h-55 mb-3 ">
                                    <span className={styles.textTag3}>Tổng lịch huỷ</span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {/* <span className={styles.textTag4}>Bán mới</span> */}
                                        </div>
                                        <div>
                                            <span className={styles.textTag}>{totalCountClose}</span>
                                        </div>
                                    </div>
                                </Tag>
                                <Tag color="green" className="w-100 h-55 mb-3" >
                                    <span className={styles.textTag3}>tổng lượt dịch vụ</span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {/* <span className={styles.textTag4}>Bán mới</span> */}
                                        </div>
                                        <div>
                                            <span className={styles.textTag}>{totalCountService}</span>
                                        </div>
                                    </div>
                                </Tag>

                            </Col>
                          
                         
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-2" style={{alignItems: 'end'}}>
                    <Col xs={12} md={5} className="hide-mobile">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <Line {...config} />
                        </div>
                    </Col>
                    <Col xs={12} md={7}>
                        <div style={{ backgroundColor: "white" }} >
                            <div className="border-bottom mb-1 p-2">
                                <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                            </div>
                            <div style={{ height: 495, width: '100%' }} className="p-2">
                                <Table 
                                    columns={columns} 
                                    dataSource={rows}
                                    pagination={{ pageSize: 10 }}
                                    scroll={{ x: 1200 }}
                                    bordered
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div >

    )
}
const configChart = (data, convertCurrency) => {  
    var config = {
        data: data,
        xField: "date",
        yField: "value",
        seriesField: "type",
        height: 520,
        xAxis: {
          title: {
            text: "Lịch đặt",
            position: "end",
            offset: 0,
            spacing: 8,
            style: {
              fontSize: 13,
              fontWeight: 900,
              textAlign: "start"
            }
          },
          line: {
            style: {
              stroke: "black"
            }
          },
          tickLine: {
            style: {
              stroke: "black"
            }
          },
          label: {
            autoRotate: true,
            style: {
              fill: "black",
            //   fontSize: 10
            },
            formatter: (text) => {
              const oldLabel = text;
              const labelLength = oldLabel.replace(/[^x00-xff]/g, "xx").length;
              let newLabel = "";
              if (labelLength > 12) {
                let strLen = 0;
                let firstStr = "";
                let lastStr = "";
                for (let i = 0; i < labelLength; i++) {
                  if (oldLabel.charCodeAt(i) > 128) {
                    strLen += 2;
                  } else {
                    strLen++;
                  }
                  if (strLen <= 12) {
                    firstStr += oldLabel.charAt(i);
                  } else {
                    lastStr += oldLabel.charAt(i);
                  }
                }
                newLabel = `${firstStr}\n${lastStr}`;
              } else {
                newLabel = oldLabel;
              }
              return newLabel;
            }
          }
        },
        yAxis: {
          line: {
            style: {
              lineWidth: 2
            }
          },
          label: {
            style: {
              fill: "black"
            },
            offset: 15,
            formatter: (text) => _.round(_.divide(text, 1), 2).toLocaleString()
          }
        },
        color: ["#673ab7", "#2196f3", "#4caf50"],
        legend: {
          position: "right",
          marker: {
            symbol: "square"
          },
          itemName: {
            formatter: (text, item, index) => {
    
              return '';
            }
          }
        },
        point: [{
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#673ab7',
                lineWidth: 2,
            },
        }, {
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#2196f3',
                lineWidth: 2,
            },
        },
        {
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#4caf50',
                lineWidth: 2,
            },
        }],
      };
    return config
}
export default ReportOperateBooking