import React, { useEffect, useState } from "react";
import { SnippetsOutlined } from '@ant-design/icons';
import { Popover, Button, message } from "antd"
import moment from "moment"
import axiosService from "../../utils/axios.service";
import PackageChild from "./PackageChild";
import async from "async"
import comboPackagesEnum from "../../enums/comboPackages.enum";
let _ = require('lodash');
var _props = {};

export default function PosPackagesList(props) {
    _props = props
    const [isValid, setIsValid] = useState(false)
    const [diffDate, setDiffDate] = useState(0)
    const [diffDateUse, setDiffDateUse] = useState(null)
    const [packageSubs, setPackageSubs] = useState([])
    const [checkAll, setCheckAll] = useState(false)
    const [isSelect, setIsSelect] = useState(false)
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    
    const hide = () => {
        setOpen(false);
    };
    const handleOpenChange = (newOpen) => {
        newOpen = newOpen != undefined ? newOpen : true
        setOpen(newOpen);
    };
    let itemSelected = _props.itemSelected ?? []
    useEffect(async () => {
        if (!props.service.product || !props.service.last_used || props.order.parent_order_id) {
            setIsValid(true)
        } else {
            if (props.service.range_date_new > 0) {
                props.service.product.range_date = props.service.range_date_new
            }
            const date = new Date(moment(new Date(props.service.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
            date.setDate(date.getDate() + props.service.product.range_date - 2)
            setIsValid(date.getTime() <= new Date(props.order.order_at).getTime())
            const a = moment(date);
            const b = moment(new Date());
            const dateUse = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
            setDiffDateUse(dateUse)
            setDiffDate(a.diff(b, 'days') + 1)
        }
        if (props.service.chillPackage.length > 0) {
            try {
                let data = props.service.chillPackage
                async.waterfall([
                    function (callback) {
                        let count = 0
                        let minDate = 10000
                        const mapData = data.map(x => {
                            let res = { ...x }
                            const date = new Date(moment(new Date(x.last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
                            if (x.product) {
                                if (x.range_date_new > 0) {
                                    x.product.range_date = x.range_date_new
                                }
                                date.setDate(date.getDate() + x.product.range_date - 2)

                                var valid = date.getTime() <= new Date(props.order.order_at).getTime()
                                if ( props.order.parent_order_id) {
                                    valid = true
                                }
                                res.isValid = valid
                                if (!valid) {
                                    count++
                                }
                                const a = moment(date);
                                const b = moment(new Date());
                                const diff = a.diff(b, 'days') + 1
                                res.diffDate = diff

                                if (diff < minDate) {
                                    minDate = diff
                                }
                                res.checkDate = res.isValid

                            }
                            return {
                                ...res,
                            }
                        })
                        callback(null, [count, minDate, mapData]);
                    },
                ], function (err, result) {
                    let [count, minDate, mapData] = result
                    let last_used =  props.service.max_used - props.service.count_used
                    let package_type_using = props.service.package_type_using
                    if (itemSelected.length > 0) {
                        const index = itemSelected.findIndex(y => {
                            return props.service.package_code === y?.package?.package_code || y.package_code == props.service.package_code
                        })
                        if (props.service.chillPackage.length > 0 && index > -1) {
                            const mapPackages = mapData.map(x => {
                                return {
                                    ...x,
                                    isSelect: true
                                }
                            })
                            setCheckAll(true)
                            setPackageSubs([...mapPackages])
                        } else {
                            const mapPackages = mapData.map(x => {
                                const index = itemSelected.findIndex(y => {
                                    return x.package_code === y?.package?.package_code || y.package_code == x.package_code
                                })
                                return {
                                    ...x,
                                    isSelect: index > -1
                                }
                            })
                            setPackageSubs([...mapPackages])
                            setCheckAll(false)
                        }
                    } else {
                        setPackageSubs([...mapData])
                    }
                    if (count === data.length) {
                        setIsValid(false)
                        setDiffDate(minDate)
                    }
                    // console.log(mapData)
                });
            } catch (error) {
                console.error(error)
                message.error("Đã có lỗi xảy ra")
            }
        }
    }, [])
    useEffect(() => {
        if (props.service.product && packageSubs.length > 0) {
            const index = itemSelected.findIndex(y => {
                return props.service.package_code === y?.package?.package_code || y.package_code == props.service.package_code
            })
          
            if (props.service.chillPackage.length > 0 && index > -1) {
                const mapPackages = packageSubs.map(x => {
                    return {
                        ...x,
                        isSelect: true
                    }
                })
                setCheckAll(true)
                setPackageSubs([...mapPackages])
            } else {
                const mapPackages = packageSubs.map(x => {
                    const index = itemSelected.findIndex(y => {
                        return x.package_code === y?.package?.package_code || y.package_code == x.package_code
                    })
                    return {
                        ...x,
                        isSelect: index > -1
                    }
                })
                setPackageSubs([...mapPackages])
                setCheckAll(false)
            }
        }
    }, [props.itemSelected])
    let quantity = 0
    useEffect(() => {
        if (itemSelected.length >= 0) {
            const filter = itemSelected.filter(x => {
                if (!x.id) {
                    return x?.package?.parent_package === props.service.id
                } else {
                    return x?.parent_package === props.service.id
                }
            })
            if (filter.length > 0) {
                setIsSelect(true)
            } else {
                setIsSelect(false)
            }
        }
    }, [props])


    var arrayItemSelected = Object.values(itemSelected)
    var activeSelect = 'grid-two-column ng-scope '

    var countLimmitPackage = props.service.max_used


    arrayItemSelected.map((item, key) => {
        if (item.package_code == props.service.package_code && item.quantity > 0 && !item.parent_package && !item.package_type_using) {
            activeSelect = "grid-two-column ng-scope active"
            quantity += item.quantity
            countLimmitPackage -= (props.service.count_used + item.quantity)
        }

        if (item.parent_package && item.package_type_using && props.service.id == item.parent_package) {
             activeSelect = "grid-two-column ng-scope active"
             quantity += item.quantity
             countLimmitPackage -= (props.service.count_used + item.quantity)
        }
    })

    const countLimitPackage = () => {
        return props.service.max_used - props.service.count_used - quantity
    }

    const handleAddItem = (item) => {
        let itemSelected = _props.itemSelected

        let countLimit = countLimitPackage()
        if (countLimit <= 0) {
            return false
        }
        props.updateSelectingPackage()

        item.quantity = 1
        item.price = 0
        item.new_package = false
        if (item.id_item && typeof item.id_item != 'undefined') {
            delete item.id_item;
        }

        itemSelected.push(item)
        _props.onSelectItem(itemSelected);
    }

    let textSearch = props.serviceSearchString.toLowerCase()
    let serviceName = props.service.product_name.toLowerCase()
    let serviceCode = props.service.package_code.toLowerCase()
    if (props.serviceSearchString.length > 0) {
        if (!serviceName.includes(textSearch) && !serviceCode.includes(textSearch)) {
            return '';
        }
    }
    const deleteManyPackageSubs = async (list) => {
        try {
            if (list.length > 0) {
                list = list.map(x=>{
                    return x?.package?.package_code || x.package_code
                })
                let {data} = await axiosService("api/orders/delete-many-item/" + props.service.id, "PUT",{order_id:props.order_id,list})
                if(data.code === 200){
                    const filter = itemSelected.filter(x=>{
                        const index = list.findIndex(y => {
                            return (y === x?.package?.package_code || y == x.package_code)
                        })
                        return index < 0
                    })
                    itemSelected = filter
                }else{
                    message.error(data.message)
                }
            }
        } catch (error) {
            message.error("Đã có lỗi xảy ra")
            console.error(error)
        }
    }
    const handleDelete = async () => {
        hide()
        let item = props.service
        const filter = packageSubs.filter(x => {
            return x.isSelect
        })
        const index = itemSelected.findIndex(y => {
            return item.package_code === y?.package?.package_code || y.package_code == item.package_code
        })
        if (filter.length == 0) {
            message.error("Bạn chưa chọn dịch vụ nào")

        } else if (props.service.chillPackage.length > 0) {
            if (filter.length !== packageSubs.length) {
                if (index > -1) {
                    await handleButtonQuantity(itemSelected[index], -1)
                } else {
                    let list = []
                    for (let x of itemSelected) {
                        const index = packageSubs.findIndex(y => {
                            return (y.package_code === x?.package?.package_code || y.package_code == x.package_code) && y.parent_package
                        })
                        if (index > -1) {
                            list.push(packageSubs[index])
                        }
                    }
                    await deleteManyPackageSubs(list)
                }
            } else {
                let list = []
                for (let x of packageSubs) {
                    const index = itemSelected.findIndex(y => {
                        return (x.package_code === y?.package?.package_code || y.package_code == x.package_code) && x.parent_package
                    })
                    if (index > -1) {
                        list.push(itemSelected[index])
                    }
                }
                await deleteManyPackageSubs(list)
            }
        }
    }
    const handleAddAll = async () => {
        setIsLoading(true)
        try {
            await handleDelete()
        } catch (error) {
            setIsLoading(false)
        }
        const filter = packageSubs.filter(x => {
            return x.isSelect
        })
        if (filter.length == 0) {
            message.error("Bạn chưa chọn dịch vụ nào hoặc thẻ đã có trong hóa đơn")
        } else {
            try {
                if (filter.length === packageSubs.length && packageSubs[0].package_type_using == 0) {
                    if (quantity > 0) {
                        message.warning("Chỉ được chọn một lần")

                        setIsLoading(false)
                    } else {
                        const data = props.service

                        let countLimit = countLimitPackage()

                        if (countLimit <= 0) {
                            return false
                        }
                        data.quantity = 1
                        data.price = 0
                        data.new_package = false
                        if (data.id_item && typeof data.id_item != 'undefined') {
                            delete data.id_item;
                        }
                        _props.openLoading(true)
                        itemSelected.push(data)
                        _props.onSelectItem(itemSelected);
                        _props.openLoading(true)
                        setIsLoading(false)
                    }
                } else {
                    let maxUsed =  props.service.max_used
                    let countUs = 0
                    const sendPackage = x => {
                        const data = x
                        let countLimit = countLimitPackage()
                        if (countLimit <= 0) {
                            return false
                        }
                        data.quantity = 1
                        data.price = 0
                        data.new_package = false
                        if (data.id_item && typeof data.id_item != 'undefined') {
                            delete data.id_item;
                        }
                        if (x.package_type_using) {
                            countUs += data.quantity
                        } else {
                            countUs = 1
                        }
                        return data
                    }
                    var i = 0
                    for (let x of filter) {
                        const data = sendPackage(x)
                        if (countUs <= maxUsed) {
                            itemSelected.push(data)
                        }
                        i++
                    }
                    if (countUs > maxUsed) {
                        message.warning("Thẻ cha chỉ còn dùng được " + maxUsed + ' lượt dịch vụ.')
                    }
                    await _props.onSelectItem(itemSelected);
                    if (filter.length == i) {
                        _props.openLoading(true)
                        _props.statusUpdateOrder('')
                        setIsSelect(true)
                        setIsLoading(false)
                    }
                }

            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        }
    }
    const onCheckAllChange = () => {
        setCheckAll(!checkAll)
        packageSubs.forEach(x => {
            if (x.isValid) {
                x.isSelect = !checkAll
            }
        })
        setPackageSubs([...packageSubs])
    }
    const handleChange = (y, i) => {
        let last_used =  props.service.max_used - props.service.count_used
        let package_type_using = props.service.package_type_using

        const select = !packageSubs[i].isSelect
        packageSubs[i].isSelect = select
        const filter = packageSubs.filter(x => {
            return x.isSelect == select
        })

        const filterTrue = packageSubs.filter(x => {
            return x.isSelect == true
        })

        const filterValidPackages = packageSubs.filter(x => {
            return x.isValid
        })
        if (filter.length == filterValidPackages.length && !package_type_using) {
            if (select) {
                setCheckAll(true)
            } else {
                setCheckAll(false)
            }
        } else {
            setCheckAll(false)
        }

        setPackageSubs([...packageSubs])
    }
    const handleButtonQuantity = async (itemChange, quantity, evt) => {
        let { services } = _props
        let itemSelected = _props.itemSelected;

        let keySelected = _.findIndex(itemSelected, function (o) { return o.id_item == itemChange.id_item; });

        let code = (itemChange.package_code && itemChange.price == 0) ? itemChange.package_code : itemChange.product_code;
        var service = null;
        if (services.length > 0) {
            services.map((val) => {
                if (code == val.package_code) {
                    service = val;
                }
            });
        }
        let count_used = 1;
        if (service) {
            count_used = service.max_used - service.count_used - parseInt(itemSelected[keySelected].quantity) - quantity + 1;
            if (count_used < 0) {
                count_used = 0;
            }
        }
        if (count_used > 0) {
            itemSelected[keySelected].quantity =
                parseInt(itemSelected[keySelected].quantity) + quantity;
            if (itemSelected[keySelected] && itemSelected[keySelected].discount > 0) {
                itemSelected[keySelected].discount = (itemSelected[keySelected].discount / (itemSelected[keySelected].quantity - quantity)) * itemSelected[keySelected].quantity;
            }

            return await _props.onUpdateItems(itemSelected);
        }

    };
    if (isValid) {
        return <>
            {
                (packageSubs.length > 0) ?
                    <Popover className={isLoading ? "disable-package" : ""} placement="bottom" title={"Chọn các dịch vụ con của combo"} trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                        content={
                            <>

                                <PackageChild itemSelected={props.itemSelected} packageSubs={packageSubs} onCheckAllChange={onCheckAllChange} handleChange={handleChange} checkAll={checkAll} service={props.service}/>
                                <div className="d-flex justify-content-end">
                                    <Button type="primary" className="mt-2" onClick={(e) => {
                                        e.stopPropagation();
                                        handleAddAll()
                                    }}>Xác nhận</Button>
                                </div>
                            </>
                        }>
                        <li className={`${activeSelect}`}
                            title="" role="button" tabIndex="0">
                            <div>
                                <div className="product-image" style={{ "backgroundImage": "url('/images/placeholder/orange_card.png')" }}></div>
                                <div className="pull-left" style={{ "width": "calc(100% - 82px)" }}>
                                    <div className="product-name">
                                        {props.service.product_name} {props.service.is_convert_bhvv == 1 ? " - CD" : ""}
                                        {
                                            (props.service.note && props.service.note != "") &&
                                            <Popover content={props.service.note} title="Ghi chú thẻ" onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                                <SnippetsOutlined />
                                            </Popover>
                                        }
                                    </div>
                                    <div className="pull-left clear">
                                        <div className="product-sku text-left">{props.service.package_code}</div>
                                        <div className="customer-booking-total ng-isolate-scope">
                                            <span className="cash-vnd customer-booking-total">
                                                {props.service.max_used > 1000 ?
                                                    "Không giới hạn"
                                                    : "Còn " + countLimitPackage() + " lần"
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    {(quantity > 0 || isSelect) ? <div className="product-cart-counter ng-binding ng-scope">{(props.service.chillPackage.length > 0 && !props.service.chillPackage[0]).package_type_using ? 1 : quantity}</div> : ''}
                                </div>
                            </div>
                        </li>
                    </Popover>
                :
                    <li className={`${activeSelect}`}
                        title="" role="button" tabIndex="0" onClick={packageSubs.length === 0 ? handleAddItem.bind(this, {...props.service}) : () => {
                            handleAddAll()
                        }}>
                        <div>
                            <div className="product-image" style={{ "backgroundImage": "url('/images/placeholder/orange_card.png')" }}></div>
                            <div className="pull-left" style={{ "width": "calc(100% - 82px)" }}>
                                <div className="product-name">
                                    {props.service.product_name} {props.service.is_convert_bhvv == 1 ? " - CD" : ""}
                                    {
                                        (props.service.note && props.service.note != "") &&
                                        <Popover content={props.service.note} title="Ghi chú thẻ" onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                            <SnippetsOutlined />
                                        </Popover>
                                    }
                                </div>
                                <div className="pull-left clear">
                                    <div className="product-sku text-left">{props.service.package_code}</div>
                                    <div className="customer-booking-total ng-isolate-scope">
                                        <span className="cash-vnd customer-booking-total">
                                            {props.service.max_used > 1000 ?
                                                "Không giới hạn"
                                                : "Còn " + countLimitPackage() + " lần"
                                            }
                                        </span>
                                    </div>
                                </div>
                                {(quantity > 0 || isSelect) ? <div className="product-cart-counter ng-binding ng-scope">{(props.service.chillPackage.length > 0 && !props.service.chillPackage[0]).package_type_using ? 1 : quantity}</div> : ''}
                            </div>
                        </div>
                    </li>
            }
        </>
    } else {
        return (
            <Popover content={`còn ${diffDate} ( ${diffDateUse} ) ngày mới được sử dụng `} title={`Chú ý thẻ ${props.service.product_name}`}>
                <li className={"disable-package2"}
                    title="" role="button" tabIndex="0">
                    <div>
                        <div className="product-image" style={{ "backgroundImage": "url('/images/placeholder/orange_card.png')" }}></div>
                        <div className="pull-left" style={{ "width": "calc(100% - 82px)" }}>
                            <div className="product-name">
                                {props.service.product_name} {props.service.is_convert_bhvv == 1 ? " - CD" : ""}
                                {
                                    (props.service.note && props.service.note != "") &&
                                    <Popover content={props.service.note} title="Ghi chú thẻ">
                                        <SnippetsOutlined />
                                    </Popover>
                                }
                            </div>
                            <div className="pull-left clear">

                                <div className="product-sku text-left">{props.service.package_code}</div>
                                <div className="customer-booking-total ng-isolate-scope">
                                    <span className="cash-vnd customer-booking-total">
                                        {props.service.max_used > 1000 ?
                                            "Không giới hạn"
                                            : "Còn " + countLimitPackage() + " lần"}
                                    </span>
                                </div>
                            </div>
                            {(quantity > 0 || isSelect) ? (<div className="product-cart-counter ng-binding ng-scope">{(props.service.chillPackage.length > 0 && !props.service.chillPackage[0]).package_type_using ? 1 : quantity}</div>) : ''}
                        </div>
                    </div>
                </li>
            </Popover>
        )
    }
}