import React, { useState, useEffect, useRef } from "react";
import { axiosGet } from "../../../utils/axios.service";
import axiosService from "../../../utils/axios.service";
import { notifications } from "../../constant/Notify";
import * as convert from "../../constant/Convert.helper"

let _ = require('lodash');
var _props = null

export function PopupMergeBill(props) {
    const [description, setDescription] = useState(null)
    const [isEdit, setEdit] = useState(true)
    const [orders, setOrders] = useState([])
    const [checkedList, setCheckedList] = useState(false)
    const [listItemChecked, setListItemChecked] = useState([])

    useEffect( () => {
        apiListOperator()
    }, [props.orderDetail])

    async function apiListOperator () {
        const response = await axiosService("api/order-list/list-merge-order/" + props.orderDetail.id, "GET")
       
        if (response.data.success == true) {
            setOrders([...response.data.data])
        } else {
            notifications({ type: 'error', mess: "Đã có lỗi sảy ra!" });
        }
    }
    var acceptMergeOrder = (event) => {
        let data = [...listItemChecked]
        axiosService("api/order-list/merge-order/" + props.orderDetail.id, "POST", data)
            .then((res) => {
                const response = res.data;
                if (response.message == "success") {
                    props.setShow(false)
                    props.requestDataOrder()
                    notifications({ type: 'success', mess: 'Gộp bill thành công' });
                } else {
                    notifications({ type: 'error', mess: "Đã có lỗi sảy ra!" })
                }
            })
            .catch( );
    }
    var hiddenPopup = () => {
        props.setShow(false)
    }

    const handleListItem = (val, event) => {
        console.log(val, event.target.checked)
        let newListItem = []
        const checked = event.target.checked
        let newListItemChecked = [...listItemChecked]
        if (checked === true) {
            newListItemChecked.push(val.id)
            // ... lấy hêt các phần tử ra
            newListItem = [...newListItemChecked]
        } else {
            
            for (let item of newListItemChecked ) {
                if (item != val.id) {
                    newListItem.push(item)
                }
            }
        }
        setListItemChecked(newListItem)
    }

    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };
    
    return (
        <div>
            <div className="head-popup">
                <div className="title">Thông báo gộp bill <span></span> </div>
            </div>
            <div className="body-popup">
              <div className="title-body">Chọn những đơn hàng để gộp bill!</div>
                <div className="panel-body">
                    <div className="DataTables_Table_0_wrapper dataTables_wrapper no-footer">
                        <div className="table-list-table">
                            <table>
                                <thead className="hide-mobile">
                                    <tr role="row">
                                        <th className="sorting" style={{width: "54px"}}> Tích chọn</th>
                                        <th className="sorting" style={{width: "54px"}}>Mã HD</th>
                                        <th className="sorting_asc"  style={{width: "60px"}}>Khách hàng</th>
                                        <th className="sorting"  style={{width: "60px"}} >Ngày tạo</th>
                                        <th className="has-tooltip-info sorting" style={{width: "84px"}} >Người lập</th>
                                        <th className="sorting" style={{width: "76px"}} >Số tiền</th>
                                        <th className="sorting"  style={{width: "130px"}} >Dịch vụ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.length > 0 ? 
                                        orders.map((val, key) => (
                                            <tr key={key} role="row" className="odd selected item-mobile-table">
                                                <td className="ng-scope mobile-table">
                                                    <div className="title hide-desktop">Tích chọn</div>
                                                    <div className="value">
                                                        <label className="checkbox-inline">
                                                            <input type="checkbox"  onChange={(event) => handleListItem(val, event)} className="css-checkbox ng-pristine ng-untouched ng-valid ng-not-empty" />
                                                        </label>
                                                    </div>
                                                </td>
                                                <td className="ng-scope mobile-table">
                                                    <div className="title hide-desktop">Mã HD</div>
                                                    <div className="value">
                                                        <span>{val.order_code}</span>
                                                    </div>
                                                </td>
                                                <td className="ng-scope sorting_1 cl-blue mobile-table">
                                                    <div className="title hide-desktop">Khách hàng</div>
                                                    <div className="value">
                                                        <span >{val.customers.full_name}</span>
                                                        {val.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}<br />
                                                        <span className="font-10 cl-gray"><i className="icon-phone position-left"></i>{val.customers.mobile}</span>
                                                    </div>
                                                </td>
                                                <td className="ng-scope mobile-table">
                                                    <div className="title hide-desktop">Ngày tạo</div>
                                                    <div className="value">
                                                        {convertDateTime(val.order_at)}
                                                    </div>
                                                    
                                                </td>
                                                <td className="has-tooltip-info ng-scope cl-blue mobile-table">
                                                    <div className="title hide-desktop">Người lập</div>
                                                    <div className="value">
                                                        {val.User ? val.User.name : val.created_name}
                                                    </div>
                                                    
                                                </td>
                                                <td className="has-tooltip-info ng-scope cl-green mobile-table">
                                                    <div className="title hide-desktop">Số tiền</div>
                                                    <div className="value">
                                                        {convert.convertCurrency(val.total_price ?? 0)}
                                                    </div>
                                                    
                                                </td>
                                                <td className="ng-scope sorting_1 cl-blue mobile-table">
                                                    <div className="title hide-desktop">Dịch vụ</div>
                                                    <div className="value">
                                                        {val.orderItem.length > 0 ?
                                                            val.orderItem.map((val2, key2) => (
                                                                <p key={key2}>{val2.product_name}</p>
                                                            ))
                                                    : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    : null}
                                </tbody>
                            </table>
                        </div>                                
                    </div>
                </div>
            </div>
            <div className="bottom-popup">
            {isEdit ? 
              <div className="button-container">
                  <div className="button-cent style3" onClick={hiddenPopup} >
                    <span><i className="fas fa-times" ></i></span>Không
                  </div>
                  <div className="button-cent" onClick={acceptMergeOrder}>
                    <span><i className="fas fa-save"></i></span>Có
                  </div>
              </div>
            : ''}
            </div>
        </div>
    )
}