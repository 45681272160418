import { useState } from "react";
export function CancelOrder(props) {
    const [description, setDescription] = useState(null)

    var actionCancelOrder = (event) => {
        var data = {
            status : 2,
            description: description
        }
        props.cancelOrder(data)
    }

    var onChangeDescription = (event) => {
        setDescription(event.target.value)
    }
    
    var hidePopup = () => {
      props.setShow(false)
    }
    

    return (
        <div>
            <div className="head-popup">
                <div className="title">Thông báo huỷ đơn hàng <span></span> </div>
            </div>
            <div className="body-popup">
              <div className="title-body">Bạn có muốn huỷ đơn hàng này không ?</div>
              <br/>
              <div className="title-body cl-red"> Mời nhập lý do huỷ đơn hàng</div>
              <br/>
              <textarea className="form-control input-xs ng-pristine ng-valid ng-binding ng-empty ng-valid-maxlength ng-touched" onChange={onChangeDescription} rows="5"  placeholder="Lý do huỷ đơn hàng"></textarea>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={hidePopup}>
                  <span><i className="fas fa-times"></i></span>Không
                </div>
                <div className="button-cent" onClick={actionCancelOrder}>
                  <span><i className="fas fa-save"></i></span>Có
                </div>
              </div>
            </div>
        </div>
    )
}