import React, { useState, useRef, useEffect } from 'react';
import { format } from "date-fns"
import { Row, Col } from "react-bootstrap"
import styles from "../../styles/popupInfor.module.css"
import tabsInforEnum from '../../enums/tabsInfor.enum';
import Infor from './Infor';
import Transaction from "./Transaction"
import rankEnum from '../../enums/rank.enum';
import IncomingOrder from "./InComingOrder"
import Package from "./Package"
import FavoriteProduct from "./FavoriteProduct"
import { useHistory } from "react-router-dom";
import NotePopUp from '../notes';
import axiosService from "../../utils/axios.service";
import Avatar from "react-avatar";
import * as convert from "../constant/Convert.helper"
import { TransactionPoup } from "../orders/TransactionPoup"
var userAdmin = JSON.parse(localStorage.getItem("user_info"))


const InforUserPopup = ({ checkMobile, open, setShow, data }) => {
    let history = useHistory();
    const previousIndexTab = useRef(0)
    const [tabs, setTabs] = useState(tabsInforEnum)
    const [indexTab, setIndexTab] = useState(0)
    const [showNote, setShowNote] = useState(false)
    const [noteCustomer, setNoteCustomer] = useState(data.customers.note)
    const [storeCustomer, setStoreCustomer] = useState([])
    const [counts, setCounts] = useState({})
    const [showTransaction, setShowTransaction] = useState(false)
    const [totalOwedOrder, setTotalOwedOrder] = useState(0)

    const updateNoteCustomer = (data) => {
        setNoteCustomer(data)
    }

    const closeModal = () => {
        tabs.map(x => x.selected = false)
        tabs[0].selected = true
        setTabs([...tabs])
        setShow();
    }

    const handleChooseTabs = (i) => {
        tabs[previousIndexTab.current].selected = false
        tabs[i].selected = true
        setTabs([...tabs])
        setIndexTab(i)
        previousIndexTab.current = i
    }

    const handleCreatePos = () => {
        history.push({
            pathname: '/pos',
            search: '?customerId=' + data.customers.id
        });
    }

    const handleShowNote = () => {
        setShowNote(!showNote)
    }

    const hiddenOrderPopup = (item) => {
        setShowTransaction(false)
    }

    const showTransactionPopup = (item) => {
        setShowTransaction(true)
    }

    const getDataTransaction = async (id) => {
        let res = await axiosService("api/transaction/customer/" + id + '/' + null, "GET")
        setTotalOwedOrder(res.data.data.totalOwedOrder)
    }

    const getData = async () => {
        const res = await axiosService(`api/customer/order/${data.customers.id}?role=${userAdmin.role}`, "GET");
        if (res.data.code === 200) {
            setCounts(res.data.data.getCountOrderStatus)
        }
        const resCutomer = await axiosService(`api/customer/${data.customers.id}`, "GET");
        if (resCutomer.data.stores) {
            setStoreCustomer(resCutomer.data.stores)
        }
        const dataOwe = await getDataTransaction(data.customers.id)
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            {open && !showNote && <>
                <div className="popup-cent">
                    <div className="content-popup fadeInDown">
                        <div className="body-popup">
                            <div className={styles.popupHeader}>
                                <div className='d-flex'>
                                    <h5 className={styles.textHeader}>Thông tin khách hàng</h5>
                                    <span className={styles.createdText}>Khởi tạo lúc: {format(new Date(data.customers.created_at), 'dd/MM/yyyy HH:mm')} </span>
                                </div>
                                <div className="close" onClick={closeModal}>×</div>
                            </div>
                            <div className={styles.popupHeader}>
                                <Row>
                                    <Col xs={2} md={2} lg={1}>
                                        <div className='d-flex'>
                                            {data.customers.avatar_url ?
                                                <div><img
                                                    width={checkMobile ? 60 : 70}
                                                    height={checkMobile ? 60 : 70}
                                                    src={data.customers.avatar_url} alt="Avatar" 
                                                    className="avatar-"/>
                                                    <i className={`fas fa-camera ${styles.iconCamera}`}></i></div>
                                                :
                                                <Avatar
                                                    name={data.customers.full_name}
                                                    size={checkMobile ? 60 : 70}
                                                    textSizeRatio={1.75}
                                                    round="50%"
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={8} md={8} lg={10}>
                                        <div className={styles.infor1}>
                                            <p className={styles.textName}>
                                                {data.customers.full_name}
                                                {data.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                            </p>
                                            <p>
                                                <span className={styles.lastCome}>
                                                    Ghé thăm lần cuối: {data.customers.last_visited ? format(new Date(data.customers.last_visited)) : "Chưa có"}
                                                </span>
                                            </p>
                                            <br></br>
                                            <p>
                                                <span className={styles.rankText}>Xếp hạng: </span>{rankEnum[data.customers.ranking]}
                                            </p>

                                            {totalOwedOrder > 0 ?
                                                <p>
                                                    <i className="fas fa-money"></i>Số tiền còn nợ: <span onClick={showTransactionPopup} className="transaction-owed"><strong className="text-danger ng-isolate-scope">
                                                        <span className="cash-vnd text-danger">{convert.convertCurrency(totalOwedOrder)}</span>
                                                        </strong>
                                                    <i className="fas fa-pencil-alt"></i> </span>
                                                </p>
                                            : ''}
                                        </div>
                                    </Col>
                                    <Col xs={2} md={2} lg={1}>
                                        <div className={styles.ousideCircles}>
                                            <p className={styles.circle + " loyalty_point"}>{data.customers.loyalty_point}</p>
                                            <span className={styles.bottomCircle}>Điểm thường</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='d-flex justify-content-between pr-1 mg-10 mt-3'>
                                <div className='w-100'>
                                    <strong className={styles.strong}>Tổng số lần đặt trước</strong>
                                </div>
                                <div className='d-flex justify-content-end w-100'>
                                    <div style={{float: 'left', width: "calc(100% - 70px)"}}><div className={`bar-green mt-1 mr-2`} style={{ width: `${(counts?.countOrderStatus >= 100 ? 100 : counts?.countOrderStatus) || 0}%` }}></div></div>
                                    <div style={{float: 'right', width: "70px", textAlign: 'right'}}><strong>{counts?.countOrderStatus || 0} (lần)</strong></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between pr-1 mg-10'>
                                <div className='w-100'>
                                    <strong className={styles.strong}>Tổng số lần đến trực tiếp</strong>
                                </div>
                                <div className='d-flex justify-content-end w-100'>
                                    <div style={{float: 'left', width: "calc(100% - 70px)"}}><div className={`bar-blue mt-1 mr-2`} style={{ width: `${(counts?.countMeetingStatus >= 100 ? 100 : counts?.countMeetingStatus) || 0}%` }}></div></div>
                                    <div style={{float: 'right', width: "70px", textAlign: 'right'}}><strong>{counts?.countMeetingStatus} (lần)</strong></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between pr-1 mg-10'>
                                <div className='w-100'>
                                    <strong className={styles.strong}>Tổng số lần hủy đặt / không đến</strong>
                                </div>
                                <div className='d-flex justify-content-end w-100'>
                                    <div style={{float: 'left', width: "calc(100% - 70px)"}}><div className={`bar-blue mt-1 mr-2`} style={{ width: `${(counts?.countCancelStatus >= 100 ? 100 : counts?.countCancelStatus) || 0}%` }}></div></div>
                                    <div style={{float: 'right', width: "70px", textAlign: 'right'}}><strong>{counts?.countCancelStatus} (lần)</strong></div>
                                </div>
                            </div>
                            {storeCustomer[0] ?
                                <div className='d-flex'>
                                    <span className={styles.location}>Chi nhánh:</span>
                                        {storeCustomer.map(x=>{
                                            return <span className={styles.location2}>{x.name || ""}</span>
                                        })}
                                </div>
                                : ""}
                        </div>
                        <div className='mt-2'>
                            <ul className={styles.ul + " custom-table"}>
                                {tabs.map((x, i) => {
                                    return (
                                        <li key={i} className={x.selected ? styles.li1 : styles.li}
                                            onClick={() => { handleChooseTabs(i) }}
                                        >
                                            {x.value}
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                        <div className='mt-1'>
                            {indexTab === 0 && <Infor data={data.customers} />}
                            {indexTab === 1 && <Transaction customer={data.customers} />}
                            {indexTab == 2 && <IncomingOrder customer={data.customers} />}
                            {indexTab === 3 && <Package customer={data.customers} />}
                            {indexTab === 4 && <FavoriteProduct customer={data.customers} />}
                        </div>
                        <div className="bottom-popup border-top">
                            <div className="d-flex justify-content-end p-2">
                                <div className={`button-cent mr-1 ${styles.buttn2}`} onClick={closeModal}>
                                    <span ><i className="fas fa-times"></i></span>Đóng
                                </div>
                                <div className="button-cent style3 mr-1" onClick={handleCreatePos}>
                                    <span><i className="fa fa-fas fa-calendar-alt"></i></span>Tạo đơn hàng
                                </div>
                                <div className={`button-cent mr-1 ${styles.button1}`} onClick={() => { handleShowNote() }}>
                                    <span><i className="fa fa-solid fa-id-card-clip"></i></span>Hồ sơ ghi chú
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={closeModal}></div>
                </div>
            </>}
            {showNote &&
                <NotePopUp closeModal={handleShowNote} noteCustomer={noteCustomer} updateNoteCustomer={updateNoteCustomer} customer={data.customers} />
            }
            {showTransaction ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <TransactionPoup
                                customer={data.customers}
                                show={showTransaction}
                                setShow={setShowTransaction}
                                getDataTransaction={getDataTransaction}
                            ></TransactionPoup>

                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenOrderPopup} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenOrderPopup} className="overlay"></div>
                    </div>
                </div>
                : null}

            </>
    )
}

export default InforUserPopup  