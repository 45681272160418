export const dataUpgrade = {
  "556-562": [
    // Combo Full Tay 5b - Full Chân 5b
    {
      value: "557-563",
      label: "Combo Full tay 15b - Full chân 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "557-563": [
    // Combo Full Tay 15b - Full Chân 15b
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "552-558": [
    // Combo 1/2 Tay trên - 1/2 Chân trên 5b
    {
      value: "553-559",
      label: "Combo 1/2 Tay trên 15b - 1/2 Chân trên 15b",
    },
    {
      value: "556-562",
      label: "Combo Full Tay 5b - Full Chân 5b",
    },
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "553-559": [
    //  Combo 1/2 Tay trên - 1/2 Chân trên 15b
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "552-560": [
    // Combo 1/2 Tay trên - 1/2 Chân dưới 5b
    {
      value: "553-560",
      label: "Combo 1/2 Tay trên 15b - 1/2 Chân dưới 15b",
    },
    {
      value: "556-562",
      label: "Combo Full Tay 5b - Full Chân 5b",
    },
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "553-560": [
    //  Combo 1/2 Tay trên - 1/2 Chân dưới 15b
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "554-558": [
    // Combo 1/2 Tay dưới - 1/2 Chân trên 5b
    {
      value: "555-559",
      label: "Combo 1/2 Tay dưới 15b - 1/2 Chân trên 15b",
    },
    {
      value: "556-562",
      label: "Combo Full Tay 5b - Full Chân 5b",
    },
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "555-559": [
    //  Combo 1/2 Tay dưới - 1/2 Chân trên 15b
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "554-560": [
    // Combo 1/2 Tay dưới - 1/2 Chân dưới 5b
    {
      value: "555-561",
      label: "Combo 1/2 Tay dưới 15b - 1/2 Chân dưới 15b",
    },
    {
      value: "556-562",
      label: "Combo Full Tay 5b - Full Chân 5b",
    },
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "555-561": [
    //  Combo 1/2 Tay dưới - 1/2 Chân dưới 15b
    {
      value: "557-563",
      label: "Combo Full Tay 15b - Full Chân 15b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "569-571": [
    // Combo Bikini - Nách 5b
    {
      value: "529-570",
      label: "Combo Bikini 15b - Nách 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "529-570": [
    //  Combo Bikini - Nách 15b
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "530-571": [
    // Combo Bikini - Mép 5b
    {
      value: "529-531",
      label: "Combo Bikini 15b - Mép 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "529-531": [
    // Combo Bikini - Mép 15b
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "92-571": [
    // Combo Bikini 5b - 5 Buổi trẻ hóa
    {
      value: "92-529",
      label: "Combo Bikini 15b - 5 Buổi trẻ hóa",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "92-529": [
    // Combo Bikini 15b - 5 Buổi trẻ hóa
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "532-571": [
    // Combo Bikini - Dọc rốn 5b
    {
      value: "529-533",
      label: "Combo Bikini 15b - Dọc rốn 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "529-533": [
    // Combo Bikini - Dọc rốn 15b
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "92-569": [
    // Combo Nách 5b - 5 Buổi trẻ hóa
    {
      value: "92-570",
      label: "Combo Nách 15b - 5 Buổi trẻ hóa",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "92-570": [
    // Combo Nách 15b - 5 Buổi trẻ hóa
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],

  "530-569": [
    // Combo Nách - Mép 5b
    {
      value: "531-570",
      label: "Combo Nách 5b - Mép 15b",
    },
    {
      value: "566",
      label: "Triệt Full Body 5b",
    },
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
  "531-570": [
    // Combo Nách - Mép 15b
    {
      value: "567",
      label: "Triệt Full Body 15b",
    },
  ],
};
