export const optionsSourceFrom = [
    { value: 1, label: 'FB Cent' },
    { value: 2, label: 'IG Cent' },
    { value: 3, label: 'Giới Thiệu' },
    { value: 4, label: 'Website' },
    { value: 5, label: 'Zalo' },
    { value: 6, label: 'Hotline' },
    { value: 7, label: 'tiktok' }
  ]

  export const hourSelect = [
    { value: 0, label: '0 giờ' },
    { value: 1, label: '1 giờ' },
    { value: 2, label: '2 giờ' },
    { value: 3, label: '3 giờ' },
    { value: 4, label: '4 giờ' },
    { value: 5, label: '5 giờ' },
    { value: 6, label: '6 giờ' },
    { value: 7, label: '7 giờ' },
    { value: 8, label: '8 giờ' },
    { value: 9, label: '9 giờ' },
    { value: 10, label: '10 giờ' },
    { value: 11, label: '11 giờ' },
    { value: 12, label: '12 giờ' },
    { value: 13, label: '13 giờ' },
    { value: 14, label: '14 giờ' },
    { value: 15, label: '15 giờ' },
    { value: 16, label: '16 giờ' },
    { value: 17, label: '17 giờ' },
    { value: 18, label: '18 giờ' },
    { value: 19, label: '19 giờ' },
    { value: 20, label: '20 giờ' },
    { value: 21, label: '21 giờ' },
    { value: 22, label: '22 giờ' },
    { value: 23, label: '23 giờ' },
  ]

  export const minuteSelect = [
    {value: 'All', label: 'Tất cả' },
    { value: 0, label: '0' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
   
  ]

  export const optionsSourceFromShow = {
        1:'FB Cent',
        2:'IG Cent',
        3: 'Giới Thiệu',
        4: 'Website',
        5:'Zalo',
        6:'THotline',
        7:'tiktok'
  }

  export const status = [
    {value: null, label: 'Tất cả' },
    {value: 'Lead', label: 'Lead' },
    {value: 'Pre order', label: 'Pre order' },
    {value: 'Không đến', label: 'Không đến' },
  ]

  export const editStatus = [
    {value: 'Pre order', label: 'Pre order' },
    {value: 'Không đến', label: 'Không đến' },
  ]

  export const customStylesFilterOption = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      width: 120,
      textAlign: "center",
      minHeight: 32,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
  }