
import React, { useState, useLayoutEffect } from "react";
import { Table, Tabs,Tag } from "antd"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import styles from "./style.module.css"

const { TabPane } = Tabs;
export default function TableProduct({ startDate, endDate, store,getTableData }) {
    const [type, setType] = useState(0);
    const [table, setTable] = useState([])
    const [total, setTotal] = useState(0)
    const onChange = (e) => {
        setType(e);
    };
    useLayoutEffect(() => {
        fetchData(startDate, endDate, store, type)
    }, [startDate, endDate, store, type])
    const fetchData = async (sD, eD, s, t) => {
        const rs = await axiosService(`api/reports/products/table?startDate=${sD}&endDate=${eD}&store=${s}&typeProduct=${t}`)
        const { data } = rs
        if (data.code == 200) {
            setTable(data.data)
            getTableData(data.data)
            const sum = _.sumBy(data.data, (x) => x.sum)
            setTotal(sum)
        }
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const columns = [
        {
            title: 'Tên dịch vụ',
            dataIndex: 'object',
            width: 350,
            render: (data) => {
                return (
                    <>
                        <p className="text-primary">{data.name}</p>
                        <p>{data.code}</p>
                    </>
                )
            },
            sorter: (a, b) => a.object.name.length - b.object.name.length,
        },
        {
            title: 'Số lượng',
            dataIndex: 'count',
            align:"center",
            render: (data) => {
                return (
                    <>
                        <p className="text-center">{data}</p>
                    </>
                )
            },
            sorter: {
                compare: (a, b) => a.count - b.count,
                multiple: 3,
            },
        },
        {
            title: 'Doanh số',
            dataIndex: 'sum',
            align:"center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.sum - b.sum,
                multiple: 2,
            },
        },
    ];
    return (
        <div style={{ backgroundColor: "white" }} className="p-1">
            <div className="d-flex justify-content-between w-100">
                <div className="pt-2">
                    <p>
                        Bảng số liệu các dịch vụ <span className="text-note">(Không tính giảm giá hóa đơn và thuế)</span>
                    </p>
                </div>
                <div>
                    <Tag color="blue" style={{ backgroundColor: "blue" }}  className="p-2">
                        <div className="d-flex justify-content-between">
                            <span className={styles.textTag}>Tổng doanh thu: </span>
                            <span className={`ml-1 ${styles.textTag}`}>{convertCurrency(total)}</span>
                        </div>
                    </Tag>
                </div>
            </div>
            <div></div>
            <div className="p-1">
                <Tabs
                    defaultActiveKey={0}
                    onChange={onChange}
                >
                    <TabPane tab="Tất cả" key={0}>
                    </TabPane>
                    <TabPane tab="Dịch vụ" key={1}>

                    </TabPane>
                    <TabPane tab="Sản phẩm" key={2}>

                    </TabPane>
                    <TabPane tab="Bán thẻ" key={3}>

                    </TabPane>
                </Tabs>
                <div>
                    <Table 
                        columns={columns} 
                        dataSource={table}
                        pagination={{ pageSize: 10 }} 
                        scroll={{ x: 1300 }}
                        bordered
                     />
                </div>
            </div>
        </div>
    )
}