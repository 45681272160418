export const orderStatus = [
    { value: '0', label: 'Tất cả' },
    { value: '1', label: 'Đang xử lý' },
    { value: '2', label: 'Bị huỷ' },
    { value: '3', label: 'Hoàn thành' },
    { value: '4', label: 'UpSale' },
    { value: '5', label: 'Chuyển đổi cọc' },
]

export const orderStatusOject = {
  1:"Đang xử lý",
  2:"Bị huỷ",
  3:"Hoàn thành",
  4:"UpSale",
  5: 'Chuyển đổi cọc'
}

export const OwedType = [
    { value: '0', label: 'Tất cả' },
    { value: '1', label: 'Còn nợ' },
    { value: '2', label: 'Không nợ' },
    { value: '3', label: 'Nợ hoàn toàn' },
    { value: '4', label: 'UpSale' },
    { value: '5', label: 'Chuyển đổi cọc' },
]

export const orderStatusClass = {
  1: "order-incomplete",
  2: "order-canceled",
  3: "order-complete",
  4: "order-upsale",
  5: "order-swich",
}

export const paymentType = {
  1:'Chuyển khoản',
  2:'Quẹt thẻ',
  3:'Thẻ thành viên',
  4:'Tiền mặt',
  5:'Nhiều loại',
}

export const listSourceBooking = {
  1:'Gọi điện',
  2:'FB Messenger',
  3:'Instagram',
  4:'Zalo',
  5:'Website'
}

export  const filterByStatus = [
  { value: "Tiền mặt", label: "Tiền mặt" },
  { value: "Sử dụng thẻ tiền", label: "Sử dụng thẻ tiền" },
  { value: "Chuyển khoản", label: 'Chuyển khoản' },
  { value: 'Quẹt thẻ', label: 'Quẹt thẻ' },
  { value: 'Ship COD', label: 'Ship COD' },
  { value: 'Ví điện tử', label: 'Ví điện tử' },
]

export  const selectAllElectronic = [
  { value: "Zalo Pay", label: "Zalo Pay" },
  { value: "Momo", label: 'Momo' },
  { value: 'Grap Pay', label: 'Grap Pay' },
  { value: 'VN Pay', label: 'Vn Pay' },
  { value: 'Samsung Pay', label: 'Samsung Pay' },
  { value: 'AriPay', label: 'AriPay' },
  { value: 'VinID', label: 'VinID' },
  { value: 'GotIt', label: 'GotIt' },
]

export  const  statusPay = {
  1:"Tiền mặt",
  2:'Quẹt thẻ',
  3: 'Ship COD',
  4: 'Ví điện tử'
}

export const customStylesFilter = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    width: 120,
    textAlign: "center",
    minHeight: 32,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
}