import { Col, Row } from "react-bootstrap";
import { Input, Select, DatePicker, Button, Table, Pagination, Modal, message, Spin } from 'antd';
import styles from "./style.module.css"
import { useEffect } from "react";
import { useState } from "react";
import axiosService from "../../utils/axios.service";
import moment from "moment"
import * as convert from "../constant/Convert.helper";
import { fetchAPI } from "../../libs/fetchStrapi"
import { useDebouncedCallback } from 'use-debounce';
import {paymentMethod, statusOrder } from "../../enums/pre-order.enum";
import _ from 'lodash';
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function PreOrder() {
    const [status, setStatus] = useState(statusOrder[0].value)
    const [startDate, setStartDate] = useState(moment(new Date(moment().startOf('week'))).format('YYYY-MM-DD'))
    const [endDate, setEnDate] = useState(moment(new Date(moment().endOf('week'))).format('YYYY-MM-DD'))
    const [store, setStore] = useState("")
    const [stores, setStores] = useState([])
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [nameFetch, setNameFetch] = useState("")
    const [phoneFetch, setPhoneFetch] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [content, setContent] = useState("")
    const [title, setTitle] = useState("")
    const [type, setType] = useState(0)
    const [dataSelect, setDataSelect] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [reload, setReload] = useState(false)
    const onChangeStore = (value) => {
        setStore(value);
        setPage(1)
        setLimit(10)
    };
    const handleValidate = (type, dataA) => {
        if (type === 2) {
            setContent("Lên đơn cho khách " + dataA.customer_name)
            setTitle("Xác nhận lên đơn")
        } else {
            setContent("Huỷ đơn cho khách " + dataA.customer_name)
            setTitle("Xác nhận huỷ đơn")
        }
        setType(type)
        setDataSelect(dataA)
        showModal()
    }
    const columns = [
        {
            title: 'Ngày muốn đặt',
            dataIndex: 'booking_at',
            key: 'booking_at',
            align: "center",
            render: (data) => {
                return (
                    <>
                        <span>{moment(data).format('HH:mm:ss DD-MM-YYYY')}</span>
                    </>
                )
            },
        },
        {
            title: 'Khách hàng',
            key: "name",
            align: "center",
            render: (data) => {
                return (
                    <>
                        <p>Tên: {data.customer_name}</p>
                        <p>SDT: {data.customer_phone}</p>
                    </>
                )
            },
        },
        {
            title: 'Dịch vụ',
            key: "order_items",
            dataIndex: 'order_items',
            align: "center",
            render: (data) => {
                let packageList = data.filter(x => x.package_code)
                let productList = data.filter(x => !x.package_code)
                return (
                    <>
                        {productList.length > 0 ?
                            <div className={`${styles.listService} ${styles.listNew}`}>
                                <label>Mua mới</label>
                                {productList.length > 0 && productList.map((x, i) => {
                                    return <p key={i}>{i + 1}.{x.product_name}</p>
                                })}
                            </div>
                        : null}
                        {packageList.length > 0 ?
                            <div className={`${styles.listService} ${styles.listOld}`}>
                                <label>Sử dụng</label>
                                {packageList.length > 0 && packageList.map((x, i) => {
                                    return <p key={i}>{i + 1}.{x.product_name}</p>
                                })}
                            </div>
                        : null}
                    </>
                )
            },
        },
        {
            title: 'Tổng tiền',
            key: "price",
            dataIndex: 'price',
            align: "center",
            render: (data) => {
                return (
                    <div>{convert.convertCurrency(data)}</div>
                )
            },
        },
        {
            title: 'Cơ sở',
            key: "store_name",
            dataIndex: 'store_name',
            align: "center",
        },
        {
            title: 'Thanh toán',
            key: "payment_method_name",
            dataIndex: 'payment_method_name',
            align: "center",
            render: (data) => {
                return (
                    <ul className={styles.listPayment}>
                        {data.map((x, i) => {
                            return <li key={i}>{`${i + 1}. ${x}`}</li>
                        })}
                    </ul>
                )
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: "note",
            align: "center",
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            align: "center",
            key: "status",
            render: (data) => {
                let index = _.findIndex(statusOrder, function(o) { return o.value == data; });
                return (
                    <div className={styles.tagStatus}>
                        <span style={{ background: statusOrder[index].background, color: statusOrder[index].color }}>{statusOrder[index].label}</span>
                    </div>
                )
            },
        },
        {
            title: 'Chức năng',
            dataIndex: 'status',
            align: "center",
            key: "action",
            width: "10%",
            render: (data, x) => {
                return (
                    data == 1 ?
                        <div className="d-flex justify-content-between">
                            <Button type="primary" onClick={() => {handleValidate(2, x)}}>Lên đơn</Button>
                            <Button className="ml-2" onClick={() => {handleValidate(3, x)}}>Huỷ</Button>
                        </div>
                    : null
                )
            },
        },
    ];
    const onChangeStatus = (e) => {
        setPage(1)
        setLimit(10)
        setStatus(e)
    }
    const getLocation = async () => {
        try {
            const rs = await axiosService("api/stores", "GET")
            const { data } = rs
            if (data.code === 200) {
                data.data.unshift({ name_store: "Tất cả", id: "" })
                setStores([...data.data])
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            setIsLoading(true)
        }
    }
    useEffect(async () => {
        getLocation()
    }, [])
    const debounced = useDebouncedCallback(
        // function
        (value, type) => {
            if (type == 1) {
                setNameFetch(value)
            } else {
                setPhoneFetch(value)
            }
        },
        // delay in ms
        1000
    );
    const onChangeName = (e) => {
        debounced(e.target.value, 1)
        setName(e.target.value)
    }
    const onChangePhone = (e) => {
        debounced(e.target.value, 2)
        setPhone(e.target.value)
    }
    const onChangeDate = (x, y) => {
        if (y[0] != "") {
            setStartDate(y[0])
            setEnDate(y[1])
        }
    }
    const handleChangePage = (page, pageSize) => {
        setPage(page)
        setLimit(pageSize)
    }
    const cancelSearch = async () => {
        setPage(1)
        setLimit(10)
        setName("")
        setPhone("")
        setStatus(statusOrder[0].value)
        setStore("")
        setStartDate(moment(new Date(moment().startOf('week'))).format('YYYY-MM-DD'))
        setEnDate(moment(new Date()).format('YYYY-MM-DD'))
        setNameFetch("")
        setPhoneFetch("")
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        try {
            if (type === 0 && !dataSelect) {
                message.error("Đơn hàng không hợp lệ")
            } else {
                setIsLoading(true)
                const payload = {
                    ...dataSelect,
                    type: type,
                }
                const rs = await axiosService('api/pre-order/update-pre-order', "POST", payload)
                if (rs.data.code === 200) {
                    if (type === 3) {
                        setIsModalOpen(false)
                        // let dataPreOrder = list;
                        // let indexUpdate = _.findIndex(list, function(o) { return o.id == dataSelect.id; });
                        // dataPreOrder[indexUpdate] = {
                        //     ...dataPreOrder[indexUpdate],
                        //     status: 3
                        // }
                        setReload(true)
                        message.success("Xác nhận thành công")
                    }
                    if (type === 2) {
                        window.location.replace('/pos');
                    }
                } else {
                    message.error(rs?.data?.message ?? "Có lỗi xảy ra")
                }
                
                setIsModalOpen(false)
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error)
            message.error("Có lỗi xảy ra")
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getDataPreOrder = async () => {
        try {
            const res = await axiosService(`api/pre-order/get-all?limit=${limit}&page=${page}&store=${store}&phone=${phoneFetch}&customer_name=${nameFetch}&status=${status}&startDate=${startDate}&endDate=${endDate}`, "GET")
            if (res.data.success) {
                let data = res.data.data
                let newData = []
                data.results.map(x => {
                    let payment_method_name = [];
                    if (x.payment_method != 0) {
                        let indexPayment = _.findIndex(paymentMethod, function(o) { return o.value == x.payment_method; });
                        payment_method_name.push(paymentMethod[indexPayment].label);
                    }
                    let paidAmount = x.use_deposit_money + x.use_gift_money 
                    if (paidAmount > 0) payment_method_name.push("Tiền trong tài khoản");

                    newData.push({...x, key: x.id, payment_method_name: payment_method_name})
                })
                setList(newData)
                setPage(data.pagination.page)
                setLimit(data.pagination.pageSize)
                setTotal(data.pagination.total)
                setReload(false)
                setIsLoading(false)
            } else {
                message.error("Có lỗi xảy ra")
            }
        } catch (error) {
            console.error(error)
            message.error("Có lỗi xảy ra")
        }
    }
    useEffect(() => {
        setIsLoading(true)
        getDataPreOrder()
    }, [limit, page, startDate, endDate, phoneFetch, nameFetch, store, status, reload])

    return (
        <Spin size="large" spinning={isLoading} >
            <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>{content}</p>
            </Modal>
            <Row className="pt-2 pl-5 pr-5 m-0">
                <div style={{ width: "100%" }}>
                    <h5 className="text-center">Danh sách order của khách hàng</h5>
                </div>
            </Row>
            <Row className={`pt-2 pb-3 ${styles.background}`}>
                <Col>
                    <div>
                        <span className="ml-1">Tên khách hàng: </span>
                        <Input placeholder="Nhập tên khách hàng"
                            value={name}
                            onChange={onChangeName} allowClear
                        />
                    </div>
                </Col>
                <Col>
                    <div>
                        <span className="ml-1">Số điện thoại:</span>
                        <Input onChange={onChangePhone} placeholder="Nhập số điện thoại khách hàng" allowClear value={phone} />
                    </div>
                </Col>
                <Col className="w-100">
                    <span className="ml-1">Cơ sở:</span>
                    <br></br>
                    <Select
                        className="w-100"
                        showSearch
                        value={store}
                        placeholder="Chọn một cơ sở"
                        optionFilterProp="children"
                        onChange={onChangeStore}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {stores.map(x => {
                            return <Option value={x.id} key={x.id}>{x.name_store}</Option>
                        })}
                    </Select>
                </Col>
                <Col>
                    <span className="ml-1">Trạng thái:</span>
                    <br></br>
                    <Select
                        showSearch
                        placeholder="Chọn một trạng thái"
                        optionFilterProp="children"
                        value={status}
                        className="w-100"
                        onChange={onChangeStatus}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        <Option value="">Tất cả</Option>
                        {statusOrder.map((x, i) => (
                            <Option key={i} value={x.value}>{x.label}</Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={3}>
                    <span className="ml-1">Chọn khoảng thời gian:</span>
                    <br></br>
                    <RangePicker
                        className="w-100"
                        onChange={onChangeDate}
                        ranges={
                            {
                                "Hôm nay": [moment(), moment()],
                                "Ngày mai": [moment().add(1, 'day'), moment().add(1, 'day')],
                                'Tuần này': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                'Tuần sau': [moment().add(1, 'weeks').startOf('isoWeek'), moment().add(1, 'weeks').endOf('isoWeek')],
                                'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                'Tháng sau': [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')],
                            }
                        }
                        allowClear={false}
                        value={[moment(startDate, "YYYY-MM-DD"), moment(endDate, "YYYY-MM-DD")]} />
                </Col>
                <Col>
                    <span>{" "}</span>
                    <br />
                    <div className="d-flex justify-content-between">
                        <Button type="primary" size={"middle"} className="mr-2" onClick={() => {
                            cancelSearch(1, 10)
                        }}>
                            <span>Xoá tất cả</span>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className={`pb-3 m-0 w-100 ${styles.background2}`}>
                <div className="d-flex justify-content-end w-100 pr-5 mt-2">
                    <p>Đang hiển thị <span style={{ color: "blue" }}>{list.length}</span> trên tổng số <span style={{ color: "red" }}>{total}</span> kết quả</p>
                </div>
                <Table
                    className="w-100 text-center"
                    pagination={false}
                    columns={columns} dataSource={list}
                />
                <div className={`d-flex justify-content-end w-100 mt-4 pt-2 pb-2 mr-5 ${styles.fixedPagination}`}>
                    <Pagination current={page} total={total} pageSize={limit} showSizeChanger showQuickJumper onChange={handleChangePage} />
                </div>
            </Row>
        </Spin>
    )
}