import React from "react";
import axiosService from "../../utils/axios.service";
import Select from "react-select";
import * as convert from "../constant/Convert.helper"
import * as generalData from "../constant/GeneralData"
import ReactTooltip from "react-tooltip";
import _ from 'lodash';
import InforUserPopup from "../infor/InforCustomerPopup";
import Avatar from 'react-avatar';
import { idCategory, idCategoryLt, idCategorySale } from './constant/BookingConstant'
import { startOfDay, format, isToday } from 'date-fns'

class BookingItemsSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      showInforPopup: false,
      showLoading:"block",
      statusNew: false,
      totalOwedOrder: 0,
      waitingTime: "",
    }
  }

  getServiceTime = (time) => {
    let hours = (time / 60),
        rhours = Math.floor(hours),
        minutes = (hours - rhours) * 60,
        rminutes = '';

    rhours = (rhours > 0) ? rhours = rhours + " giờ " : ""
    if (Math.round(minutes) > 0) rminutes = Math.round(minutes) + " phút";

    return rhours + rminutes;
  };

  getNameService = (data) => {
    let nameService = [];

    data.map(function (element) {
      element.product_ids.map(function (item) {
        nameService.push(item.product_name ?? item.name)
      })
    });

    return nameService;
  };

  getUserService = (data) => {
    let nameUser = [];

    data.map(function (element) {
      element.user_ids.map(function (item) {
        nameUser.push({name: item.name, image:item.image})
      })
    });

    return nameUser;
  };

  nameCreateBooking = async (id, root) => {
    let url = (root == 3) ? "api/customer/" : "api/users/";
    let res = await axiosService(url + id, "GET");

    return root == 3 ? res.data.full_name : res.data.data.name
  }

  changeToPos = async (event) => {
    // this.props.handleSpin()
    this.setState({
      showLoading: "none"
    })
    let nameCreateBy = await this.nameCreateBooking(this.props.dataBookingList.created_by, this.props.dataBookingList.root);

    let booking_item = []
    if (this.props.dataBookingList.booking_type != 47) {
      booking_item = this.props.dataBookingList.booking_item;
      booking_item = await this.convertBookingItemToOrderItem(booking_item)
    }

    
    // orderItem = {
    //   ...orderItem, 
    //   employee_service1: operator_id[0] ?? "",
    //   employee_service2: operator_id[1] ?? "",
    //   employee_service3: operator_id[2] ?? "",
    //   employee_service4: operator_id[3] ?? "",
    //   employee_service5: operator_id[4] ?? ""
    // }

    const dataUpdate = {
      "description": this.props.dataBookingList.description,
      "id_booking": this.props.dataBookingList.id,
      "created_by": this.state.userAdmin.id ?? '',
      "source_from": this.props.optionsSourceBooking[this.props.dataBookingList.source_from-1].label,
      "staff_booking": nameCreateBy,
      "money_paid": 0,
      "created_name": this.state.userAdmin.name ?? "",
      "order_at": convert.convertDateTime(new Date()),
      "stores": this.props.dataBookingList.stores.id,
      "status": 1,
      "booking_voucher_code": this.props.dataBookingList.voucher_code,
      "booking_voucher_id": this.props.dataBookingList.voucher_id,
      "customers": {
        "id": this.props.dataBookingList.customers.id,
        "full_name": this.props.dataBookingList.customers.full_name,
        "gender": this.props.dataBookingList.customers.gender,
        "mobile": this.props.dataBookingList.customers.mobile,
        "address": this.props.dataBookingList.customers.address,
        "district": this.props.dataBookingList.customers.district,
        "city": this.props.dataBookingList.customers.city,
      },
      "orderItem": booking_item
    };

    axiosService(
      "api/orders", "POST", dataUpdate
    ).then((res) => {
        const response = res.data;
        if (response.message == "success") {
          window.location.replace('/pos')
        }
      })
      .catch((error) => console.log(error));
  };

  convertBookingItemToOrderItem = async (data) => {
    let dataOrderItem = [];
    let listPackage = await this.props.getServiceByCustomer(this.props.dataBookingList.customers.id);

    listPackage = _.groupBy(listPackage, ({ package_code }) => package_code);
    
    data.map((booking_item) => {
      booking_item.product_ids.map((item) => {
        let dataItem = null;
        if (item.package_code) {
          if (listPackage[item.package_code]) {
            dataItem = {
              id_item: item.id,
              package_code: item.package_code,
              new_package: false,
              product_name: item.product_name ?? item.name,
              product_id: item.product_id,
              price: 0,
              parent_package: item.parent_package && item.parent_package != undefined ? item.parent_package : null
            }
          }
        } else {
          let product_id = item.product_id ?? item.id
          dataItem = {
              id_item: item.id,
              price: item.price,
              product_id: product_id
            }
        }
        if (dataItem != null) dataOrderItem.push(dataItem)
      })
    })

    dataOrderItem = _.groupBy(dataOrderItem, ({ id_item }) => id_item)

    dataOrderItem = Object.entries(dataOrderItem).map(function (item) {
      let quantity = item[1].length;
      item[1] = item[1].map((value) => {
        if (listPackage[value.package_code]) quantity = (quantity > listPackage[value.package_code][0].left_use) ? listPackage[value.package_code][0].left_use : quantity;
        return {...value, quantity: quantity, discount: 0}
      });
      return _.uniqBy(item[1], 'id_item');
    });

    dataOrderItem = _.flatten(dataOrderItem);

    return dataOrderItem;
  }

  onChangeStatusBooking = (event) => {
    const dataUpdate = {
      "id": this.props.dataBookingList.id,
      "book_status": event.value,
      "updated_id": this.state.userAdmin.id ?? '',
      "customers": {
        "id": this.props.dataBookingList.customers.id ?? '',
        "full_name": this.props.dataBookingList.customers.full_name,
        "mobile": this.props.dataBookingList.customers.mobile,
        "gender": this.props.dataBookingList.customers.gender,
        "city": this.props.dataBookingList.customers.city,
        "district": this.props.dataBookingList.customers.district,
        "address": this.props.dataBookingList.customers.address,
      },
      "stores": this.props.dataBookingList.stores.id,
      "booking_item": this.props.dataBookingList.booking_item,
      "status":  this.props.dataBookingList.status,
      "description": this.props.dataBookingList.description,
      "book_date": this.props.dataBookingList.book_date,
      "source_from": this.props.dataBookingList.source_from,
      "intend_time": this.props.dataBookingList.intend_time,
    };

    this.props.updateBooking(dataUpdate)
  }
  showInfor = async () => {
    this.setState({
      showInforPopup: !this.state.showInforPopup
    });
  }

  onChangeHandleViewRemover = (event, data)=> {
    if (event.value == 1) {
      this.props.showEditBooking(data)
    }
    if (event.value == 2) {
      this.props.deleteBooking(data)
    }
  }

  checkNewBooking = async () => {
    let dataBooking = this.props.dataBookingList.created_at;
    let currentDate = new Date(dataBooking);
    let timer = Date.now() - currentDate.getTime();
    let status = (timer >  600000) ? false : true;

    this.setState({statusNew: status})
  }

  getStatusBooking = async (data) => {
    let keyStatus = _.findKey(this.props.optionsStatusBookingForCS, function(o) { return o.value == data; });
    return keyStatus
  }

  changeDateRange = async () => {
    let book_date = new Date(this.props.dataBookingList.book_date)
    let intBookDate = startOfDay(new Date(book_date)).getTime()
    let intStart = this.props.defaultRangeDate[0].getTime()
    let inEnd = this.props.defaultRangeDate[1].getTime()

    if ((intBookDate < intStart && intBookDate < inEnd) || (intBookDate > intStart && intBookDate > inEnd)) {
      this.props.changeDateRange([startOfDay(book_date), startOfDay(book_date)])
    }
  }
  waitingTime = () => {
    const time = new Date().getTime() - new Date(this.props.waitingType == 2 ? this.props.dataBookingList.waiting_time : this.props.dataBookingList.created_at).getTime();

    let hours = (Math.floor((time / (1000 * 60 * 60))));
    let minutes = (Math.floor((time / 1000 / 60) % 60));

    let waitingTime = ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2)
    this.setState({
      waitingTime: waitingTime
    })
  };

  async componentDidMount() {
    await this.checkNewBooking();
    // await this.getStatusBooking(this.props.dataBookingList.book_status)
    if (this.state.statusNew) setTimeout(() => { this.checkNewBooking(); }, 600000) 
    this.waitingTime()
    setInterval(() => this.waitingTime(), 1000);
  }

  render() {
    let textSearch = '', bookingName = '', bookingMobile = '', bookingStatus = '', bookingCode = '';

    if (this.props.dataBookingList && this.props.dataBookingList.customers) {
      textSearch = convert.convertViToEn(this.props.keywordSearchBooking.toLowerCase())
      if (this.props.dataBookingList.customers.full_name) bookingName = convert.convertViToEn(this.props.dataBookingList.customers.full_name.toLowerCase())
      if (this.props.dataBookingList.customers.mobile) bookingMobile = convert.convertViToEn(this.props.dataBookingList.customers.mobile.toLowerCase())
      if (this.props.dataBookingList.book_status) bookingStatus = this.props.dataBookingList.book_status
      if (this.props.dataBookingList.book_code) bookingCode = this.props.dataBookingList.book_code.toLowerCase()
    }

    if (textSearch.length > 0) {
      if (!bookingName.includes(textSearch) && !bookingMobile.includes(textSearch) && !bookingCode.includes(textSearch)) {
        return '';   
      }
    }

    if (this.props.searchByStatus !== 0 && bookingStatus != this.props.searchByStatus && this.props.waitingType === 1) return '';
    return (
      <>
        {this.state.showInforPopup && <InforUserPopup totalOwedOrder={this.state.totalOwedOrder} checkMobile={this.props.checkMobile} open={this.state.showInforPopup} setShow={this.showInfor} data={this.props.dataBookingList} />}
        <div
          onClick={(e)=>{
            e.stopPropagation();
            return((this.props.dataBookingList.book_status === 5) || (this.props.dataBookingList.book_status === 6)) ? () => this.props.showEditBooking(this.props.dataBookingList) : () => false
          }}
          className={((this.props.dataBookingList.book_status === 5) || (this.props.dataBookingList.book_status === 6)) ? "booking_object disable-booking" : "booking_object"}>
          <div className="edit-booking">
            <div className={this.state.statusNew ? "event-time new-booking-effect" : "event-time"}>
              {this.state.statusNew ? <div className="new">new</div> : null}
              {convert.convertTime(this.props.dataBookingList.book_date)}
            </div>
            <div className="event-booking-code" onClick={(e) => {
              e.stopPropagation();
              this.props.showEditBooking(this.props.dataBookingList)
            }}>
              <span>#{this.props.dataBookingList.book_code}</span>
              <span className="ml-20">{(this.props.dataBookingList.intend_time < 60) ? this.props.dataBookingList.intend_time + 'phút' : this.getServiceTime(this.props.dataBookingList.intend_time)}</span>
            </div>
            <div className="event-customer">
              <div className="event-customer-name">
                <span onClick={(e) => {
                  e.stopPropagation();
                  this.props.showEditBooking(this.props.dataBookingList)
                }}>
                {this.props.dataBookingList.customers.full_name} {this.props.dataBookingList.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""} &nbsp;
                </span>
                <i className="fas fa-info-circle"
                  onClick={this.showInfor} 
                >
                </i>
                {this.props.dataBookingList.booking_type == 1 ?
                  this.state.userAdmin.role == 5 ?
                    idCategorySale.map((data, key) => (
                      (this.props.dataBookingList?.list_category_id).includes(data.id) ?
                        <span key={key} className="label type_booking" style={{background: data.color }}>{data.label}</span>
                      : null
                    ))
                  : 
                    idCategoryLt.map((data, key) => (
                      (this.props.dataBookingList?.list_category_id).includes(data.id) ?
                        <span key={key} className="label type_booking" style={{background: data.color }}>{data.label}</span>
                      : null
                    ))
                :  
                  idCategory.map((data, key) => (
                    this.props.dataBookingList.booking_type == data.id ?
                      <span key={key} className="label type_booking" style={{background: data.color}}>{data.label}</span>
                    : null
                  ))
                }
                {this.props.dataBookingList.description ||  (this.props.dataBookingList.description_internal && this.props.dataBookingList.description_internal.length > 1)?
                  <i onClick={(e) => {
                    e.stopPropagation();
                    this.props.showEditBooking(this.props.dataBookingList)
                  }} className="fas fa-clipboard" style={{ "color": "#8BC34A", marginLeft: "5px" }}></i> : null
                }
                {this.props.dataBookingList.booking_item.length > 1 ?
                  <span className="booking-customer-group" onClick={(e) => {
                    e.stopPropagation();
                    this.props.showEditBooking(this.props.dataBookingList)
                  }}>
                    <i className="fas fa-users"
                    ></i>
                     {this.props.dataBookingList.booking_item.length}
                  </span>
                : ""}
              </div>
              {this.props.dataBookingList.customers.ranking ?
                <div onClick={(e) => {
                  e.stopPropagation();
                  this.props.showEditBooking(this.props.dataBookingList)
                }} className="ranking" style={{ fontSize: "11px" }}>
                  {generalData.optionsRanking[this.props.dataBookingList.customers.ranking - 1].label}
                </div>
                : ""}

              <div onClick={(e) => {
              e.stopPropagation();
              this.props.showEditBooking(this.props.dataBookingList)
            }} className="event-service" data-tip data-for={"sidebar-" + this.props.dataBookingList.id}>
                <span>{this.getNameService(this.props.dataBookingList.booking_item).join(", ")}</span>
                <ReactTooltip className="tooltip-booking" place="bottom" type="dark" id={"sidebar-" + this.props.dataBookingList.id}>
                  {this.getNameService(this.props.dataBookingList.booking_item).map((item, key) => (
                    <p key={key}>- {item}</p>
                  ))}
                </ReactTooltip>
              </div>
              <div className="event-status" onClick={(e) => {
                  e.stopPropagation();
                  this.props.showEditBooking(this.props.dataBookingList)
                }} >
                <span className="label bg-green">{this.props.optionsSourceBooking[this.props.dataBookingList.source_from - 1]?.label}</span>
                <span className={"label status-" + this.props.dataBookingList.book_status}>{this.props.optionsStatusBooking[this.props.dataBookingList.book_status - 1].label}</span>
              </div>
              {this.props.waitingType === 3 ?
                <div className="created_at">
                  Đặt ngày: <span onClick={this.changeDateRange}>{format(new Date(this.props.dataBookingList.book_date), "dd-MM-yyyy")}</span>
                </div>
              : null}
            </div>
          </div>
          <div className="operator-booking" onClick={(e) => {
              e.stopPropagation();
              this.props.showEditBooking(this.props.dataBookingList)
            }}>
            {this.getUserService(this.props.dataBookingList.booking_item).length > 0 ?
              <div className="list-operator">
                <div className="avatar" data-tip data-for={"user-" + this.props.dataBookingList.id}>
                  {this.getUserService(this.props.dataBookingList.booking_item).map((val, key) => (
                    val.image ? 
                    <img src={val.image} alt="" key={key} />
                    : <Avatar className="avatar-user" key={key} name={val.name}  size="36" textSizeRatio={1.75} round="50%"/> 
                  ))}
                  {/* {this.getUserService(this.props.dataBookingList.booking_item).length} */}
                  </div>
                <ReactTooltip className="tooltip-booking" place="bottom" type="dark" id={"user-" + this.props.dataBookingList.id}>
                  {this.getUserService(this.props.dataBookingList.booking_item).map((item, key) => (
                    <p key={key}>- {item.name}</p>
                  ))}
                </ReactTooltip>
              </div>
              : null}
          </div>
          {this.state.userAdmin.role != 2 ? 
            <div className="event-action" >
              <div className="action-change-status">
                {/* {this.getStatusBooking(this.props.dataBookingList.book_status)} */}
                {![5,7].includes(this.props.dataBookingList.book_status) ? 
                  this.state.userAdmin.role == 5 ?
                    ![3,4,6,7].includes(this.props.dataBookingList.book_status) ?
                      <Select
                        isDisabled={((this.props.dataBookingList.book_status === 5) || (this.props.dataBookingList.book_status === 6)) ? true : false}
                        styles={this.props.customStylesBooking}
                        isSearchable={false}
                        className="select"
                        value={this.props.optionsStatusBookingForCS.map((item, key) => ((this.props.dataBookingList.book_status === item.value) ? this.props.optionsStatusBookingForCS[key] : ''))}
                        options={this.props.optionsStatusBookingForCS}
                        onChange={this.onChangeStatusBooking}
                      />
                    :
                      <Select
                        isDisabled={((this.props.dataBookingList.book_status === 5) || (this.props.dataBookingList.book_status === 6)) ? true : false}
                        styles={this.props.customStylesBooking}
                        isSearchable={false}
                        className="select"
                        value={{value: this.props.dataBookingList.book_status, label: this.props.optionsStatusBooking[this.props.dataBookingList.book_status - 1].label}}
                        options={[{value: this.props.dataBookingList.book_status, label: this.props.optionsStatusBooking[this.props.dataBookingList.book_status - 1].label}]}
                      />
                  :
                    <Select
                      isDisabled={((this.props.dataBookingList.book_status === 5) || (this.props.dataBookingList.book_status === 6)) ? true : false}
                      styles={this.props.customStylesBooking}
                      isSearchable={false}
                      className="select"
                      value={this.props.optionsStatusBooking[this.props.dataBookingList.book_status - 1]}
                      options={this.props.optionsStatusBooking}
                      onChange={this.onChangeStatusBooking}
                    />
                :  
                  <Select
                    isSearchable={false}
                    className="select"
                    value={this.props.optionsViewOrRemover[0]}
                    options={this.props.optionsViewOrRemover}
                    onChange={(e) => this.onChangeHandleViewRemover(e, this.props.dataBookingList)}
                  />
              }
                
              </div>
              {this.props.waitingType === 2 ?
                (isToday(new Date(this.props.dataBookingList.created_at)) && this.props.dataBookingList.root !== 3) ? <div className="just-now">Mới tạo hôm nay</div> : null
              :
                this.props.dataBookingList.order_status !== 2 && this.props.dataBookingList.book_status != 5 ? (
                  <div className="checkout" onClick={this.changeToPos} style={{display:this.state.showLoading}}>
                    <i className="fas fa-money-bill"></i> Thanh toán ngay
                  </div>
                ) : null
              }
              
              {this.props.waitingType > 1 && this.props.dataBookingList.waiting_time ?
                <div className="waiting-time">Đã chờ: {this.state.waitingTime}</div>
              : null}
            </div>
          : null}
        </div>
      </>
    );
  }
}
export default BookingItemsSidebar;
