export default {
    "rule.management": "Rule Management",
    "add.new.cart.price": "New Cart Price Rule",
    "name": "Name",
    "code": "Code",
    "date": "Date",
    "status": "Status",
    "start.date": "Start date",
    "end.date": "End date",
    "type": "Type",
    "actions": "Actions",
    "add.new.rule": "Add new rule",
    "all":"All",
    "active":"Active",
    "inactive":"Inactive",
    "enter.Id":"Enter ID to search",
    "enter.name":"Enter name to search",
    "enter.code":"Enter Coupon code to search",
    "click.to.upload":"Click to Upload",
    "delete.confirm":"Delete Confirm",
    "delete.title":"Do you want to delete",
    "error.message":"Error Message",
    "warning":{
        "choose.voucher":"Please choose a voucher!!!!"
    },
    "success":{
        "delete":"Successful delete",
        "add":"Successful add",
        "refresh":"Successfully refresh",
        "update":"Successfully update"
    },
    "error":{
        "delete":"Fail delete",
        "occurred":"An error has occurred",
        "add":"Error add",
        "update":"Error update"
    },
    "reset.title":"Do you want to reset ?",
    "reset.confirm":"Confirm",
    "rule.info":"Rule Information",
    "rule.name":"Rule name",
    "voucher.code":"Voucher code",
    "only.one": "Use one time",
    "use.unique.code":"Use Unique Code",
    "des":"Description",
    "stores":"Stores",
    "choose.store":"Choose a store to add",
    "select.all":"Select all",
    "conditions":"Conditions",
    "condition":{
        "title":"Apply the rule only if the following conditions are met (leave blank for all products)",
        "value.greater":"Total order value is greater than",
        "value.less":"Total order value is less than",
        "contain":"Order contains",
        "not.contain":"Order does not contains",
        "combo":"Order contains combo",
        "cate":"Order contains categories",
        "not.cate":"Order not contains categories",
    },
    "then":"of these conditions are",
    "choose.product":"Choose a product to add",
    "apply.voucher":"Apply for voucher",
    "choose.cate":"Choose a category to add",
    "choose.condition":"Choose a condition to add",
    "percent":"Percentage",
    "fix.amount":"Fix Amount",
    "discount.amount":" Discount Amount",
    "max.amount":"Max amount",
    "back":"Back",
    "reset":"Reset",
    "save":"Save",
    "apply":"Apply",
    "product":"Product",
    "expired":"Expired",
    "month":"month",
    "delete":"Delete"
}