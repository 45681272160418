import React from "react";
import axiosService from "../../utils/axios.service";
import Edit from "./Edit";
import Add from "./Add";
import Delete from "./Delete";
import { notifications } from "../constant/Notify";

import "./scss/operators.scss";

class Operators extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      showEdit: false,
      showAdd: false,
      showDelete: false,
      dataOperators: [],
      detailOperator: [],
      dataStore: [],
      currentStore: "",
      optionsGroup: [
        { value: 'KTV Spa', label: 'KTV Spa' },
        { value: 'KTV Phun Xăm', label: 'KTV Phun Xăm' },
        { value: 'KTV Gội', label: 'KTV Gội' },
        { value: 'KTV Nails', label: 'KTV Nails' },
        { value: 'KTV Mi', label: 'KTV Mi' },
        { value: 'Lễ Tân', label: 'Lễ Tân' },
      ],
      currentGroup: 0,
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      keywordName: ""
    };
  }

  showPopupEdit = (e, key) => {
    let currentStore = [];
    if (e.stores !== null) {
      for (const key in this.state.dataStore) {
        e.stores.map(object => {
          if (object.id === this.state.dataStore[key].value)
            currentStore.push(object.id);
        });
      }
      this.setState({ currentStore: currentStore });
    }

    this.setState({ showEdit: true, detailOperator: {...e,key: key}});
  };

  hidePopupEdit = () => {
    this.setState({ showEdit: false });
  };

  showPopupAdd = () => {
    this.setState({ showAdd: true });
  };

  hidePopupAdd = () => {
    this.setState({ showAdd: false });
  };

  showPopupDelete = (e, key) => {
    this.setState({ showDelete: true, detailOperator: {...e, key: key} });
  };

  hidePopupDelete = () => {
    this.setState({ showDelete: false });
  };

  changeDataOperator = (key, value) => {
    if (key === "stores") {
      this.setState({
        currentStore: value
      })
    }

    if (key === 'mobile' || key === 'email') {
      value = value.replace(/\s/g, '');
    }

    if(key === 'email' && value && value != '' && value.length > 0) value = value.toLowerCase();

    this.setState({
      detailOperator: {
        ...this.state.detailOperator,
        [key]: value
      }
    })
  }

  addOperator = (data) => {
    if (!data.name) {
      notifications({ type: "error", mess: "Tên nhân viên không được trống." });
      return
    }
    if (!data.email) {
      notifications({ type: "error", mess: "Email nhân viên không được trống." });
      return
    }
    let dataOperators = this.state.dataOperators
    axiosService("api/register","POST", data)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.hidePopupAdd();
          notifications({ type: "success", mess: "Tạo nhân viên mới thành công" });
          dataOperators.unshift(response.data)
        } else {
          notifications({ type: "error", mess: response.message });
        }
        this.setState({
          dataOperators: dataOperators
        })
      })
      .catch((error) => console.log(error));
  };

  updateOperator = () => {
    if (!this.state.detailOperator.name) {
      notifications({ type: "error", mess: "Tên nhân viên không được trống." });
      return 
    }
    let dataOperators = this.state.dataOperators;
    const dataUpdate = {
      "name": this.state.detailOperator.name,
      "email": this.state.detailOperator.email,
      "mobile": this.state.detailOperator.mobile,
      "status": this.state.detailOperator.status,
      "updated_by": this.state.userAdmin.id ?? "",
      "group": (this.state.detailOperator.role == 2) ? this.state.detailOperator.group : "",
      "stores": ([2,3,4].includes(this.state.detailOperator.role)) ? this.state.currentStore : [],
      "role": this.state.detailOperator.role
    };

    axiosService("api/users/" + this.state.detailOperator.id, "PUT", dataUpdate)
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.hidePopupEdit();
          notifications({ type: "success", mess: "Sửa nhân viên thành công" });
          dataOperators.splice(this.state.detailOperator.key, 1, response.data);
        } else {
          notifications({ type: "error", mess: response.message });
        }
        this.setState({
          dataOperators: dataOperators
        })
      })
      .catch((error) => console.log(error));
  };

  deleteOperator = () => {
    let dataOperators = this.state.dataOperators;
    axiosService(
      "api/users/" + this.state.detailOperator.id,
      "DELETE"
    )
      .then((res) => {
        const response = res.data;
        if (response.success == true) {
          this.hidePopupDelete();
          notifications({ type: "success", mess: "Xóa nhân viên thành công" });
          dataOperators.splice(this.state.detailOperator.key, 1);
        } else {
          notifications({ type: "error", mess: response.message });
        }
        this.setState({
          dataOperators: dataOperators
        })
      })
      .catch((error) => console.log(error));
  };

  getDataStores = async () => {
    const selectDataStore = []
    let response = await axiosService("api/stores", "GET");
    response = response.data
    if (response.success && response.data.length > 0) {
        for (const key in response.data) {
          selectDataStore.push({
            value: response.data[key].id,
            label: response.data[key].name_store
          })
        }
    }

    return selectDataStore
  }

  getDataUsers = async () => {
    const listOperators = []
    let response = await axiosService("api/users/operators", "GET")
    response = response.data
    if (response.success && response.data.length > 0) {
        for (const key in response.data) {
          listOperators.push(response.data[key])
        }
    }
    return listOperators
  }

  searchUser = async () => {
    const listOperators = []
    let response = await axiosService("api/users/operators?keyword=" + this.state.keywordName, "GET")
    response = response.data
    if (response.success && response.data.length > 0) {
      for (const key in response.data) {
        listOperators.push(response.data[key])
      }
    }
    this.setState({
      isLoaded: true,
      dataOperators: listOperators,
    });
  }
  componentDidMount() {
    Promise.all([this.getDataUsers(), this.getDataStores()]).then((results) => {
      this.setState({
        isLoaded: true,
        dataOperators: results[0] ?? [],
        dataStore: results[1] ?? [],
      });
    });
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="admin-page operators">
          <div className="page-title">
            <div className="title-page">
              <i className="fas fa-users"></i> Nhân viên phục vụ
            </div>
            {this.state.userAdmin && this.state.userAdmin.role != 4 ?
              <div className="button-cent" onClick={this.showPopupAdd}>
                <span><i className="fas fa-user-plus"></i></span> Tạo nhân viên mới
              </div>
            : null}
          </div>
          <div className="page-search">
            <input value={this.state.keywordName} placeholder="Tên, Email, SĐT" onChange={(e) => {this.setState({keywordName: e.target.value})}} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" />
            <div className="button-cent" onClick={this.searchUser}>
              <span><i className="fas fa-user-plus"></i></span> Tìm kiếm
            </div>
          </div>
          <div className="admin-body">
            <div className="dashboard-content">
                <div className="wrapper">
                    <table className="row-border table">
                      <thead className="hide-mobile">
                        <tr role="row">
                          <th className="sorting" tabIndex="0" rowSpan="1" colSpan="1">Họ Tên</th>
                          <th className="dt-right sorting" tabIndex="0" rowSpan="1" colSpan="1">Nhóm nhân viên</th>
                          <th className="sorting" tabIndex="0" rowSpan="1" colSpan="1">Chi nhánh</th>
                          <th className="sorting" tabIndex="0" rowSpan="1" colSpan="1">Điện thoại</th>
                          <th className="dt-right sorting" tabIndex="0" rowSpan="1" colSpan="1">Email</th>
                          <th className="sorting" tabIndex="0" rowSpan="1" colSpan="1">Trạng thái</th>
                          {this.state.userAdmin && this.state.userAdmin.role != 4 ?
                            <th className="sorting" tabIndex="0" rowSpan="1" colSpan="1">Action</th>
                          : null}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dataOperators.map((val, key) => (
                          <tr role="row" className="odd item-mobile-table" key={val.id}>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Họ Tên</div>
                              <div className="value">{val.name}</div>
                            </td>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Nhóm nhân viên</div>
                              <div className="value">{val.group}</div>
                            </td>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Chi nhánh</div>
                              <div className="value">{val.stores.length > 0 ? val.stores[0].name_store :''}</div>
                            </td>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Điện thoại</div>
                              <div className="value">{val.mobile}</div>
                            </td>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Email</div>
                              <div className="value">{val.email}</div>
                            </td>
                            <td className="ng-scope mobile-table">
                              <div className="title hide-desktop">Trạng thái</div>
                              <div className="value"><span className={(val.status == 1) ? 'tag green' : 'tag red'}>{(val.status == 1) ? "Hoạt động" : "Tạm dừng"}</span></div>
                            </td>
                            {this.state.userAdmin && this.state.userAdmin.role != 4 ?
                              <td className="text-right ng-scope mobile-table">
                                <div className="button-container">
                                  <div className="button-cent" onClick={() => this.showPopupEdit(val, key)}><span><i className="fas fa-pen"></i></span>Sửa</div>
                                  <div className="button-cent style3" onClick={() => this.showPopupDelete(val, key)}><span><i className="fas fa-trash-alt"></i></span>Xóa</div>
                                </div>
                              </td>
                            : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                </div>
            </div>
            <Edit show={this.state.showEdit}
                  close={this.hidePopupEdit}
                  detailOperator={this.state.detailOperator}
                  dataStore={this.state.dataStore}
                  currentStore={this.state.currentStore}
                  optionsGroup={this.state.optionsGroup}
                  currentGroup={this.state.currentGroup}
                  updateOperator={this.updateOperator}
                  changeDataOperator={this.changeDataOperator}>
            </Edit>
            <Add show={this.state.showAdd}
                 close={this.hidePopupAdd}
                 dataStore={this.state.dataStore}
                 optionsGroup={this.state.optionsGroup}
                 addOperator={this.addOperator}>
            </Add>
            <Delete show={this.state.showDelete} close={this.hidePopupDelete} deleteOperator={this.deleteOperator}></Delete>
          </div>
        </div>
      );
    } else return null;
  }
}
export default Operators;
