import React, { useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import { Tag } from "rsuite"
import { DataGrid } from '@mui/x-data-grid';
import { UpdateTransaction } from "./Update"
import { DeleteTransaction } from "./Delete"
import { format } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import { Spin, InputNumber } from "antd"
import StorageService from "../../utils/storage.service";
import { DatePicker } from "rsuite";
import CurrencyInput from 'react-currency-input-field';
import { notifications } from "../constant/Notify";

import "./scss/styles.scss"
const ReportByDay = () => {
    const columns = [
        { field: 'order_code', headerName: 'Mã HD', width: 110 },
        { field: 'transaction_code', headerName: 'Mã GD', width: 110 },
        {
            field: 'paid_amount',
            headerName: 'Số tiền',
            type: "number",
            width: 120,
            cellClassName: (params) => "price"
        },
        { 
            field: 'status',
            headerName: 'Loại thanh toán',
            width: 120,
            valueGetter: (params) => (params.row.status == 1) ? "Bán mới" : (params.row.status == 2) ? "Thu nợ" :  (params.row.status == 3) ? "Hoàn tiền" : "",
        }, 
        { field: 'pay_type', headerName: 'Phương thức', width: 150, },
        { field: 'pay_account_number', headerName: 'Số tài khoản', width: 350 },
        { field: 'created_by_name', headerName: 'Người tạo', width: 170, },
        { field: 'transaction_at_format', headerName: 'Thời gian tạo', width: 160 },
        {
            field: 'edit',
            headerName: '',
            width: 70,
            valueGetter: (params) => params.row.action == 1 ? "SỬA" : "",
            cellClassName: (params) => "action_row"
        },
        {
            field: 'del',
            headerName: '',
            width: 70,
            valueGetter: (params) => params.row.action == 1 ? "XÓA" : "",
            cellClassName: (params) => "action_row"
        },
    ];

    const [editTrans, setEditTrans] = useState(false)
    const [delTrans, setDelTrans] = useState(false)
    const [currentTrans, setCurrentTrans] = useState(null)
    const [startDate, setStartDate] = useState(format((new Date()),"yyyy-MM-dd"))
    const [searchByOrderCode, setSearchByOrderCode] = useState('')
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [loading, setLoading] = useState(true);
    const [listTransaction, setListTransaction] = useState([]);
    const [dataPrice, setDataPrice] = useState();
    const [listStore, setListStore] = useState([]);

    const [totalCash, setTotalCash] = useState([]);
    const [totalBanking, setTotalBanking] = useState([]);
    const [totalCard, setTotalCard] = useState([]);

    const updateTransaction = (params) => {
        if (params.field == 'edit') {
            setEditTrans(true)
            setCurrentTrans(params.row)
        } else if (params.field == 'del') {
            setDelTrans(true)
            setCurrentTrans(params.row)
        }
    }

    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    
    const reloadData = async () => {
        setLoading(true)
        fetchData(store, startDate, searchByOrderCode)
    }
    
    const searchOrder = _.debounce(async (event) => {
        setSearchByOrderCode(event.target.value)
    }, 1000)

    const changeDate = async (event) => {
        setStartDate(format(new Date(event), 'yyyy-MM-dd'))
        setSearchByOrderCode('')
    }

    const confirmTransaction = async () => {
        let payload = {
            store: store,
            startDate: startDate,
            searchByOrderCode: searchByOrderCode,
            dataPrice: {
                cash: Number(totalCash),
                banking: Number(totalBanking),
                card: Number(totalCard)
            }
        }

        const rs = await axiosService(`api/transaction/confirm-transaction`, "POST", payload)

        if (rs.data.success) {
            notifications({ type: 'success', mess: 'Xác nhận thành công' });
        } else {
            notifications({ type: 'error', mess: rs.data.message });
        }
    }

    const changeTotalCash = (x) => {
        const value = x ? x : 0
        setTotalCash(value)
    }

    const changeTotalBanking = (x) => {
        const value = x ? x : 0
        setTotalBanking(value)
    }

    const changeTotalCard = (x) => {
        const value = x ? x : 0
        setTotalCard(value)
    }
    
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, startDate, searchByOrderCode)
    }, [store, startDate, searchByOrderCode])
    
    const fetchData = async (s, sD, sBOC) => {
        const rs = await axiosService(`api/transaction/report-transaction?store=${s}&startDate=${sD}&searchByOrder=${sBOC}`, "GET")
        const { data } = rs

        if (data.code === 200) {
            setDataPrice(data.data?.dataPrice)

            let storeArray = []
            data.data?.dataStore.map(item => {
                storeArray.push({
                    value: item.id,
                    label: item.name_store
                })
            })
            setListStore(storeArray)

            let transaction = []
            data.data?.listTransaction.map(item => {
                transaction.push({
                    ...item,
                    transaction_at_format: format(new Date(item.created_at), 'HH:mm:ss dd/MM/yyyy'),
                    order_code: item.order.order_code,
                    created_by_name: item.user.name,
                    action: 1
                })
            })
            setTotalCash(data.data?.dataPrice?.cashPaid?.total ?? 0)
            setTotalBanking(data.data?.dataPrice?.bankingPaid?.total ?? 0)
            setTotalCard(data.data?.dataPrice?.cardPaid?.total ?? 0)
            setListTransaction(transaction)
            setLoading(false)
        }
    }

    return (
        <Spin spinning={loading}>
            <div className={`container-fluid report-by-day`}>
                <Row className="mt-2">
                    <Col xs={3}>
                        <Tag color="red" className="w-100 tag-header">
                            <div className="title-tag d-flex justify-content-between">
                                <div className="label">Tổng tiền đã thu</div>
                                <div className="value">{convertCurrency(dataPrice?.total?.total ?? 0)}</div>
                            </div>
                            <div className="items-tag">
                                <div className="item price-new d-flex justify-content-between">
                                    <div className="label">BÁN MỚI</div>
                                    <div className="value">{convertCurrency(dataPrice?.total?.newOrder ?? 0)}</div>
                                </div>
                                <div className="item price-owe d-flex justify-content-between">
                                    <div className="label">THU NỢ</div>
                                    <div className="value">{convertCurrency(dataPrice?.total?.oweOrder ?? 0)}</div>
                                </div>
                            </div>
                        </Tag>
                    </Col>
                    <Col xs={3}>
                        <Tag color="violet" className="w-100 tag-header">
                            <div className="title-tag d-flex justify-content-between">
                                <div className="label">Tiền mặt</div>
                                <div className="value">{convertCurrency(dataPrice?.cashPaid?.total ?? 0)}</div>
                            </div>
                            <div className="items-tag">
                                <div className="item price-new d-flex justify-content-between">
                                    <div className="label">BÁN MỚI</div>
                                    <div className="value">{convertCurrency(dataPrice?.cashPaid?.newOrder ?? 0)}</div>
                                </div>
                                <div className="item price-owe d-flex justify-content-between">
                                    <div className="label">THU NỢ</div>
                                    <div className="value">{convertCurrency(dataPrice?.cashPaid?.oweOrder ?? 0)}</div>
                                </div>
                            </div>
                        </Tag>
                    </Col>
                    <Col xs={3}>
                        <Tag color="cyan" className="w-100 tag-header">
                            <div className="title-tag d-flex justify-content-between">
                                <div className="label">Chuyển khoản</div>
                                <div className="value">{convertCurrency(dataPrice?.bankingPaid?.total ?? 0)}</div>
                            </div>
                            <div className="items-tag">
                                <div className="item price-new d-flex justify-content-between">
                                    <div className="label">BÁN MỚI</div>
                                    <div className="value">{convertCurrency(dataPrice?.bankingPaid?.newOrder ?? 0)}</div>
                                </div>
                                <div className="item price-owe d-flex justify-content-between">
                                    <div className="label">THU NỢ</div>
                                    <div className="value">{convertCurrency(dataPrice?.bankingPaid?.oweOrder ?? 0)}</div>
                                </div>
                            </div>
                        </Tag>
                    </Col>
                    <Col xs={3}>
                        <Tag color="orange" className="w-100 tag-header">
                            <div className="title-tag d-flex justify-content-between">
                                <div className="label">Quẹt thẻ</div>
                                <div className="value">{convertCurrency(dataPrice?.cardPaid?.total ?? 0)}</div>
                            </div>
                            <div className="items-tag">
                                <div className="item price-new d-flex justify-content-between">
                                    <div className="label">BÁN MỚI</div>
                                    <div className="value">{convertCurrency(dataPrice?.cardPaid?.newOrder ?? 0)}</div>
                                </div>
                                <div className="item price-owe d-flex justify-content-between">
                                    <div className="label">THU NỢ</div>
                                    <div className="value">{convertCurrency(dataPrice?.cardPaid?.oweOrder ?? 0)}</div>
                                </div>
                            </div>
                        </Tag>
                    </Col>
                </Row>
                <div className="list-transaction">
                    <div className="head-list">
                        <div className="title-list">Danh sách giao dịch</div>
                        <div className="filter-list">
                            <input onChange={searchOrder} defaultValue={searchByOrderCode} className="search-filter" placeholder="Tìm theo mã order" />
                            <DatePicker
                                defaultValue={new Date()}
                                oneTap
                                format={"yyyy-MM-dd"}
                                className="custom-date-picker date-filter"
                                onChange={changeDate}
                                size="sm"
                                placement="bottomEnd" 
                            />
                        </div>
                    </div>
                    <div className="table-list">
                        <DataGrid
                            autoHeight
                            onCellClick={updateTransaction}
                            rows={listTransaction}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                </div>
                <div className="total-price-input">
                    <Row>
                        <Col xs={3}>
                            <div className="label">Tiền mặt</div>
                            <div className="value-input">
                                <InputNumber
                                    style={{ width: "200px" }}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={changeTotalCash}
                                    value={totalCash}
                                />
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className="label">Chuyển khoản</div>
                            <div className="value-input">
                                <InputNumber
                                    style={{ width: "200px" }}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={changeTotalBanking}
                                    value={totalBanking}
                                />
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className="label">Quẹt thẻ</div>
                            <div className="value-input">
                                <InputNumber
                                    style={{ width: "200px" }}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={changeTotalCard}
                                    value={totalCard}
                                />
                            </div>
                        </Col>
                        <Col xs={3}>
                            <div className="button-cent" onClick={confirmTransaction}>
                                <span><i className="fas fa-save"></i></span> Xác nhận
                            </div>
                        </Col>
                    </Row>
                </div>
                {editTrans ? 
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <UpdateTransaction
                                disablePopup={setEditTrans}
                                dataTransaction={currentTrans}
                                listStore={listStore}
                                reloadData={reloadData}
                            />
                        </div>
                        <div onClick={() => {setEditTrans(false)}} className="overlay"></div>
                    </div>
                : null}

                {delTrans ? 
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <DeleteTransaction
                                disablePopup={setDelTrans}
                                dataTransaction={currentTrans}
                                reloadData={reloadData}
                            />
                        </div>
                        <div onClick={() => {setDelTrans(false)}} className="overlay"></div>
                    </div>
                : null}
            </div>
        </Spin>
    )
}

export default ReportByDay