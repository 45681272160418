import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";

const ReportSales = () => {
    const [title, setTiltle] = useState("Báo cáo tư vấn Online")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    useEffect(() => {
        setTiltle("Báo cáo tư vấn Online")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/reports/sales?startDate=${sD}&endDate=${eD}&store=${store}&keyword=${keyword}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const { dataChart, dataTable } = data.data
            setChart(dataChart)
            setTable(dataTable)
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const columns = [
        {
            title: 'Nhân viên',
            dataIndex: 'name',
            render: (data) => {
                return (
                    <>
                        <p>{data}</p>
                    </>
                )
            },
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Số lịch đã đặt',
            dataIndex: 'countBooking',
            align: "center",
            sorter: {
                compare: (a, b) => a.countBooking - b.countBooking,
                multiple: 3,
            },
        },
        {
            title: 'Số đơn đã hoàn thành từ đặt lịch',
            dataIndex: 'countOrder',
            align: "center",
            sorter: {
                compare: (a, b) => a.countOrder - b.countOrder,
                multiple: 2,
            },
        },
        {
            title: 'Tổng doanh số',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data.total)}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.total - b.total,
                multiple: 1,
            },
        },
    ];
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    return (
        <div className={`p-2 ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                title={title} type={"salesj"}
                csvData={table} isExport={isExport}
                hanldeExportData={hanldeExportData}
                onChangeKeyword={onChangeKeyword}
                header={
                    [
                        { label: 'Nhân viên', key: "name" },
                        { label: "Số lịch đã đặt", key: "countBooking" },
                        { label: "Số đơn đã hoàn thành từ đặt lịch", key: "countOrder" },
                        { label: "Tổng doanh số", key: `total` }
                    ]
                }
                userAdmin={userAdmin}
            />
            <Spin spinning={loading} tip="Đang tải...">
                <Row className="p-2">
                    {/* <Col xs={4} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Biểu đồ nhân viên đặt lịch
                            </div>
                            <Bar {...config} />
                        </div>
                    </Col> */}
                    <Col xs={12} className="p-2">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <div className="border-bottom w-100 mb-2">
                                Bảng số liệu chi tiết
                            </div>
                            <Table 
                                columns={columns}
                                dataSource={table}
                                pagination={{ pageSize: 10 }} 
                                scroll={{ x: 1300 }}
                                bordered
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
export default ReportSales