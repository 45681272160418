import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { TranslationOutlined } from "@ant-design/icons"
import langsEnum from '../../enums/langs.enum';
export default function () {
    const {i18n } = useTranslation();
    const [select, setSelect] = useState("vn")
    useEffect(() => {
        const lang = localStorage.getItem("lang")
        if (!lang || !langsEnum.includes(lang)) {
            localStorage.setItem("lang", langsEnum[0])
        } else {
            setSelect(lang)
        }
    }, [])
    const [options, setOptions] = useState([
        {
            value: 'en',
            label: 'English',
        },
        {
            value: 'vn',
            label: 'VietNam',
        }
    ])
    const changeToEnglish = useCallback(() => {
        setOptions([
            {
                value: 'en',
                label: 'English',
            },
            {
                value: 'vn',
                label: 'VietNam',
            }
        ])
    }, [])
    const changeToVietNam = useCallback(() => {
        setOptions([
            {
                value: 'en',
                label: 'Tiếng anh',
            },
            {
                value: 'vn',
                label: 'Tiếng Việt',
            }
        ])
    }, [])

    const objectChange = useCallback((value) => {
        const object = {
            'vn': changeToVietNam,
            'en': changeToEnglish
        }
        object[value]()
    }, [])
    const handleChange = (value) => {
        setSelect(value)
        objectChange(value)
        localStorage.setItem("lang",value)
    };
    useEffect(() => {
        i18n.changeLanguage(select || "en")
    }, [select])
    return (
        <>
            <div className='d-flex justify-content-end'>
                <TranslationOutlined className='mr-2' style={{ fontSize: "30px" }} />
                <Select
                    value={select}
                    style={{
                        width: 120,
                    }}
                    onChange={handleChange}
                    options={options}
                />
            </div>
        </>
    )
}