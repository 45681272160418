import { useEffect, useState } from "react";
import { axiosGet } from "../../utils/axios.service";
import { listStatusBooking, listSourceBooking, listStatusOrder, PackageStatus } from './constant/CustomerBirthday';
import { formatValue } from 'react-currency-input-field';
import { CustomerOrderPopup } from "./CustomerOrderPopup";
import { Link } from "react-router-dom";
import { PackagePopup } from "./PackagePopup";
import { EditCredit } from "./EditCredit";
import * as convert from "../constant/Convert.helper"
import Collapse from 'react-bootstrap/Collapse';
import { TransactionList } from "../orders/TransactionList"
import _ from "lodash"
import { format } from 'date-fns'
import { QRCode } from "./QRCode"
import { accountPaymentQR } from '../constant/GeneralData'
import {orderStatusClass } from '../orders/constant/OrderConstant';


var _props = null;
var _state = {};
var isLoading = false;

async function requestDate() {
    if (_state.setCustomerData === undefined) return ''
    isLoading = true;
    let id = _props.customer.id
    let response = await axiosGet("api/customer/order/" + id);
    if (response.status == 200) {
        _state.setCustomerId(id)
        _state.setCustomerData(response.data)
    }
    isLoading = false;
}

async function getPackageByCustomer (id) {
    let response =  await axiosGet("api/package/customer/" + id, "GET");
    if (response.data.success !== true) return
    let listPackage = response.data.data.listPackage

    return listPackage
}

function convertDateTime(event) {
    let date = new Date(event),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hours = date.getHours(),
        minus = date.getMinutes();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minus < 10) minus = '0' + minus;
    let text = day + '-' + month + '-' + date.getFullYear();
    text = text + ' ' + hours + ':' + minus;
    return text
};

function showPopup(val, key) {
    _state.setOrderPopup(true)
    _state.setOrderItemDetail(val)
}

function hiddenOrderPopup(event) {
    _state.setOrderPopup(false)
}

export function CustomerRightBar(props) {
    const [customerId, setCustomerId] = useState(null)
    const [customerData, setCustomerData] = useState({})
    const [orderPopup, setOrderPopup] = useState(false)
    const [orderItemDetail, setOrderItemDetail] = useState({})
    const [showHistory, setShowHistory] = useState(false)
    const [dataPackageDetail, setDataPackageDetail] = useState({})
    const [openInComingBooking, setOpenInComingBooking] = useState(false)
    const [openDoneBooking, setOpenDoneBooking] = useState(false)
    const [openPayments, setOpenPayments] = useState(false)
    const [openPackage, setOpenPackage] = useState(false)
    const [openFavorite, setOpenFavorite] = useState(false)
    const [openRemind, setOpenRemind] = useState(false)
    const [tabQrCode, setTabQrCode] = useState(false)
    const [showTransactionList, setShowTransactionList] = useState(false)
    const [showEditCredit, setShowEditCredit] = useState(false)
    const [listPackage, setListPackage] = useState([])
    const [packageId, setPackageId] = useState(null)
    const [type, setType] = useState('switch')
    const [displayQrCode, setDisplayQrCode] = useState(false)
    const [currentQrCode, setCurrentQrCode] = useState(null)
    
    useEffect(() => {
        if (props.type == "crm") {
            setOpenPackage(true)
        }
    }, [props.type])

    _props = props

    _state.customerId = customerId
    _state.setCustomerId = setCustomerId
    _state.customerData = customerData
    _state.setCustomerData = setCustomerData
    _state.orderPopup = orderPopup
    _state.setOrderPopup = setOrderPopup
    _state.orderItemDetail = orderItemDetail
    _state.setOrderItemDetail = setOrderItemDetail
    let customerDataProp = _props.customer
    if (customerDataProp.id === undefined) return ''
    if (customerId !== customerDataProp.id && !isLoading) requestDate()
    let customerBooking = _state.customerData
    if (customerBooking.data === undefined) return ''

    let dataMadeOrder = customerBooking.data.dataMadeOrder
    let dataUpcomingBooking = customerBooking.data.dataUpcomingBooking
    let dataPackage = customerBooking.data.dataPackage
    let dataOrderItemCard = customerBooking.data.dataOrderItemCard
    let dataBookingUnfulfilled = customerBooking.data.dataBookingUnfulfilled
    let listQrCode = customerBooking.data.getDataQrCode

    const handleShowPackage = (val) => {
        setShowHistory(true)
        setDataPackageDetail(val)
    }

    const hiddenPackageHistory = (event) => {
        setShowHistory(false)
    }
    const handleOpenIncomingBooking = () => {
        setOpenInComingBooking(!openInComingBooking)
    }
    const handleOpenDoneBooking = () => {
        setOpenDoneBooking(!openDoneBooking)
    }
    const handleOpenPayments = () => {
        setOpenPayments(!openPayments)
    }
    const handleOpenPackage = () => {
        setOpenPackage(!openPackage)
    }
    const handleOpenFavorite = () => {
        setOpenFavorite(!openFavorite)
    }
    const handleOpenRemind = () => {
        setOpenRemind(!openRemind)
    }

    const hiddenOrderPopupTransactionList = (event) => {
        setShowTransactionList(false)
    }

    const hiddenShowTransactionList = (event) => {
        setShowTransactionList(true)
    }

    const handleExchange = async (itemPackage) => {
        setType('switch')
        let newPackage = await handlePackage(itemPackage);
        setListPackage([...newPackage])
        setShowEditCredit(true)
    }

    const handleTransferCustomerPackage = async (itemPackage) => {
        setType('transfer')
        let newPackage = await handlePackage(itemPackage);
        setListPackage(newPackage)
        setShowEditCredit(true)
    }

    const handlePackage = async (itemPackage) => {
        let listPackage =  await getPackageByCustomer(_props.customer.id);
        let newPackage = [];
        listPackage.forEach((item) => {
            if ((item.max_used < 99 || item.count_used == 0) && (item.rules == "" || !item.rules?.includes("combo")) && itemPackage.id == item.id) {
                let left_price = 0
                let price = item.rule_price > 0 ? item.rule_price : item.initial_amount
                if (item.metaPrice && item.metaPrice > 0) {
                left_price = price - (item.metaPrice * item.count_used)
                } else {
                left_price = (price / item.max_used) * (item.max_used - item.count_used)
                }
                item['money_wallet'] = 0
                if (item.transaction_wallet > 0) {
                  item['money_wallet'] = Math.round(left_price/item.paid_money_order * item.transaction_wallet)
                }
                left_price = left_price - item['money_wallet']
                let label = item.package_code + " - " + item.product_name + "(" + item.count_used + "/" + item.max_used + ")";
                newPackage.push({ ...item, label: label, value: item.package_code, left_price: left_price })
            }
        })


        if (itemPackage.rules?.includes("combo")) {
            const filter = listPackage.filter((x) => {
                return (x.rules !== "" && x.rules?.includes("combo")) && x.order_id == itemPackage.order_id
            })
            newPackage = [...filter]
        }
        // const filter = newPackage.filter((x) => {
        //     return x.max_used < 99 && (x.rules == "" || !x.rules?.includes("combo"))
        //   })
        const grouped = _.groupBy(newPackage.filter(x => {
            return x.rules !== "" && x.rules?.includes("combo")
        }), (x) => {
            return x.rules && x.order_id
        })
        const mapObject = []
        for (const [key, value] of Object.entries(grouped)) {
            const mapName = value.map(x => {
                return x.product_name + "-" + x.count_used + "/" + x.max_used
            })
            mapObject.push({
                id: key,
                label: `Combo (${mapName.join(", ")})`,
                type: "rule",
                value: value
            })
        }
        if (mapObject.length > 0) {
            return mapObject
        }

        return newPackage
    }

    const handelEditCredit = (vale) => {
        setShowEditCredit(!showEditCredit)
    }

    const handleStatusPackage = (data) => {
        setPackageId(data.id)
    }

    const openInfoQrCode = (data) => {
        setDisplayQrCode(true);
        setCurrentQrCode(data)
    }

    const copyText = (text, key) => {
        document.getElementById(`copy-${key}`).classList.add('isCopy');
        navigator.clipboard.writeText(text)
        setTimeout(() => {
            document.getElementById(`copy-${key}`).classList.remove('isCopy');
        }, 1000);
    }

    const updateNewQrCode = (data) => {
        let index = _.findIndex(listQrCode.Data, function (o) { return o.id === data.id; });

        listQrCode.Data[index] = {
            ...listQrCode.Data[index],
            price: data.price,
            store_id: data.store_id
        }
    }   

    return (
        <div className="fixed-column more-info-customer">
            <div className="header-col">
                <div className="title-tab active">
                    L.Sử giao dịch
                </div>
                <div className="title-tab">
                    Ảnh Khách Hàng
                </div>
            </div>
            <div className="body-col">
                <div className="list-information">
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenIncomingBooking}>Lịch hẹn sắp tới ({dataUpcomingBooking.Total}) {dataUpcomingBooking.Data.length > 0 && (openInComingBooking ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}</div>
                        <Collapse in={openInComingBooking}>
                            <div className="detail">
                                {dataUpcomingBooking.Data ?
                                    <ul>
                                        {dataUpcomingBooking.Data.map((val, key) => (
                                            <li key={key}>
                                                {props.type == "crm" ?
                                                    <Link>
                                                        <div className="item-booking">
                                                            <div className="col-6 right">
                                                                {/* {customerData.xx} */}
                                                                <div className="customer-booking-time ">{val.book_date == "0001-01-01T00:00:00Z" ? '' : convertDateTime(val.book_date)} <i className="icon-eye"></i></div>
                                                                <div className="customer-booking-location"><i className="icon-location3"></i>{val.stores.name_store} </div>
                                                            </div>
                                                            <div className="col-6 left">
                                                                <div className={val.book_status == 5 ? "customer-booking-total cl-red" : "customer-booking-total"}>{listStatusBooking[val.book_status]}</div>
                                                                <div className="customer-booking-items">{listSourceBooking[val.source_from]}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link to={{ pathname: "/booking", search: "?action=view&booking_id=" + val.id + "&cust_id=" + customerDataProp.id }}>
                                                        <div className="item-booking">
                                                            <div className="col-6 right">
                                                                {/* {customerData.xx} */}
                                                                <div className="customer-booking-time ">{val.book_date == "0001-01-01T00:00:00Z" ? '' : convertDateTime(val.book_date)} <i className="icon-eye"></i></div>
                                                                <div className="customer-booking-location"><i className="icon-location3"></i>{val.stores.name_store} ({val.book_code})</div>
                                                            </div>
                                                            <div className="col-6 left">
                                                                <div className={val.book_status == 5 ? "customer-booking-total cl-red" : "customer-booking-total"}>{listStatusBooking[val.book_status]}</div>
                                                                <div className="customer-booking-items">{listSourceBooking[val.source_from]}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                    : ''
                                }

                            </div>
                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenDoneBooking}>Đơn hàng đã thực hiện ({dataMadeOrder.Data.length}) {dataMadeOrder.Data.length > 0 && (openDoneBooking ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}</div>
                        <Collapse in={openDoneBooking}>
                            <div className="detail">
                                {dataMadeOrder.TotalPrices ?
                                    <div className="total-price-detail">Tổng: <span className="cl-blue">{convert.convertCurrency(dataMadeOrder.TotalPrices)} </span></div>
                                : ""}
                                {dataMadeOrder.Data ?
                                    <ul>
                                        {dataMadeOrder.Data.map((val, key) => (
                                            <li onClick={() => { showPopup(val, key) }} key={key}>
                                                <div className="col-6 right">
                                                    <div className="customer-booking-time ">{convertDateTime(val.order_at)}<i className="icon-eye"></i></div>
                                                    <div className="customer-booking-location-view">
                                                        <span className="text-grey-300">#{val.order_code} </span>
                                                        Chi tiết
                                                    </div>
                                                </div>
                                                <div className="col-6 left">
                                                    <div className="customer-booking-total">{val.total_price ? formatValue({
                                                        value: String(val.payByOther),
                                                        groupSeparator: ',',
                                                        decimalSeparator: '.',
                                                    }) : '0'} đ</div>
                                                    <div className="customer-booking-items">
                                                        {val.money_owed > 0 && val.status == 3 ?
                                                            <span className="order-complete bg-red">còn nợ:{formatValue({
                                                                value: String(val.money_owed),
                                                                groupSeparator: ',',
                                                                decimalSeparator: '.',
                                                            })} đ</span>
                                                            : <span className={orderStatusClass[val.status]}>{listStatusOrder[val.status]}</span>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    : ""}
                            </div>

                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenPayments}>Các lần trả tiền ({dataMadeOrder.TotalPay})
                            {dataMadeOrder.TotalPay > 0 && (openPayments ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}
                        </div>
                        <Collapse in={openPayments}>
                            <div>
                                {dataMadeOrder.Data ?
                                    <ul>
                                        {dataMadeOrder.Data.map((val, key) => (
                                            val.total_price != 0 && val.payByOther != 0 ?
                                                <li onClick={() => { showPopup(val, key) }} key={key}>
                                                    <div className="col-6 right">
                                                        <div className="customer-booking-time ">{convertDateTime(val.order_at)}<i className="icon-eye"></i></div>
                                                        <div className="customer-booking-location-view">
                                                            <span className="text-grey-300">#{val.order_code} </span>
                                                            Chi tiết
                                                        </div>
                                                    </div>
                                                    <div className="col-6 left">
                                                        <div className="customer-booking-total">{formatValue({
                                                            value: String(val.payByOther),
                                                            groupSeparator: ',',
                                                            decimalSeparator: '.',
                                                        })} đ</div>
                                                    </div>
                                                </li>
                                            : ''
                                        ))}
                                    </ul>
                                : null}
                            </div>
                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenPackage}>Thẻ dịch vụ của khách ({dataPackage.Total})
                            {dataPackage.Total > 0 && (openPackage ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}
                        </div>
                        <Collapse in={openPackage}>
                            {dataPackage.Data ?
                                <ul>
                                    {dataPackage.Data.map((val, key) =>{ 
                                        return (
                                        <li key={key}>
                                            <div className="col-6 right">
                                                <div className="customer-booking-time " onClick={() => handleShowPackage(val)}>{val.product_name}<i className="icon-eye"></i></div>
                                                <div className="customer-booking-location-view" >
                                                    <span className="text-grey-300">#{val.package_code} </span>
                                                    <span onClick={() => handleShowPackage(val)}>Chi tiết</span>
                                                    {/* {(val.max_used < 99 || val.count_used == 0) && val.status == 1? <span onClick={() => handleExchange(val)} style= {{ color: '#66bb6a'}}> - Chuyển đổi thẻ</span> : ''}
                                                    {(val.max_used < 99 || val.count_used == 0) && val.status == 1? <span onClick={() => handleTransferCustomerPackage(val)} style= {{ color: '#ff5722'}}> - Nhượng thẻ</span> : ''} */}
                                                </div> 
                                            </div>
                                            <div className="col-6 left" onClick={() => handleShowPackage(val)}>
                                                <div className="customer-booking-total-card">
                                                    {val.type == "Thẻ lần" ?
                                                        val.max_used < 999 ? val.count_used + "/" + val.max_used : 'Không giới hạn lần'
                                                    :
                                                        format(new Date(val.expiration_date), "dd-MM-yyyy")
                                                    }
                                                </div>
                                                <div className="customer-booking-items">
                                                    <span className={`
                                                        ${val.status==3 ? "package-expired" :""}
                                                        ${val.status==1 ? "package-using" :""}
                                                        ${val.status==2 ? "package-block" :""}
                                                        ${val.status==4 ? "package-complete" :""}
                                                        ${val.status==5 ? "package-block" :""}
                                                        ${val.status==6 ? "package-block" :""}
                                                        ${val.status==7 ? "package-complete" :""}
                                                    `} 
                                                    >{PackageStatus[val.status]}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )})}
                                </ul>
                                : ""}
                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenFavorite}>Dịch vụ & Sản phẩm yêu thích ({dataOrderItemCard.Total})
                            {dataOrderItemCard.Total > 0 && (openFavorite ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}
                        </div>
                        <Collapse in={openFavorite}>
                            {dataOrderItemCard.Data ?
                                <ul>
                                    {dataOrderItemCard.Data.map((val, key) => (
                                        <li key={key} >
                                            <div className="col-6 right">
                                                <div className="customer-booking-time ">{val.order_item_product_name ?? val.product_product_name}<i className="icon-eye"></i></div>
                                                <div className="customer-booking-location-view">
                                                    <span className="text-grey-300">Giá: {formatValue({
                                                        value: String(val.product_price),
                                                        groupSeparator: ',',
                                                        decimalSeparator: '.',
                                                    })} đ </span>
                                                </div>
                                            </div>
                                            <div className="col-6 left">
                                                <div className="customer-booking-total-service">Số Lượng</div>
                                                <div className="customer-booking-items">
                                                    {val.sum_quantity}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                : null}
                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={handleOpenRemind}>Nhắc nhở chưa thực hiện ({dataBookingUnfulfilled.Total})
                            {dataBookingUnfulfilled.Total > 0 && (openRemind ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}
                        </div>
                        <Collapse in={openRemind}>
                            <div className="detail">
                                {dataBookingUnfulfilled.Data ?
                                    <ul>
                                        {dataBookingUnfulfilled.Data.map((val, key) => (
                                            <li key={key}>
                                                <Link to={{ pathname: "/booking", search: "?action=view&booking_id=" + val.id }}>
                                                    <div className="item-booking">
                                                        <div className="col-6 right">
                                                            {/* {customerData.xx} */}
                                                            <div className="customer-booking-time ">{val.book_date == "0001-01-01T00:00:00Z" ? '' : convertDateTime(val.book_date)} <i className="icon-eye"></i></div>
                                                            <div className="customer-booking-location"><i className="icon-location3"></i>{val.stores.name_store} </div>
                                                        </div>
                                                        <div className="col-6 left">
                                                            <div className={val.book_status == 5 ? "customer-booking-total cl-red" : "customer-booking-total"}>{listStatusBooking[val.book_status]}</div>
                                                            <div className="customer-booking-items">{listSourceBooking[val.source_from]}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                : null}
                            </div>
                        </Collapse>
                    </div>
                    <div className="item">
                        <div className="title d-flex justify-content-between" onClick={()=> {setTabQrCode(!tabQrCode)}}>Mã QR 
                            {listQrCode.Total > 0 &&  (tabQrCode ? <i className="fa-solid fa-circle-up mt-1"></i> : <i className="fa-solid fa-circle-down mt-1"></i>)}
                        </div>
                        <Collapse in={tabQrCode}>
                            <div className="detail">
                                {listQrCode.Data ?
                                    <ul>
                                        {listQrCode.Data.map((val, key) => (
                                            <li key={key}>
                                                <div className="col-6 right" onClick={() => {openInfoQrCode(val)}}>
                                                    <div className="customer-booking-time">Mã GD: {val.uuid}</div>
                                                    <div className="customer-booking-location-view">Ngày: {convertDateTime(val.created_at, 'createdAt')}</div>
                                                    <div className="customer-booking-location-view">Số tiền: {convert.convertCurrency(val.price)}</div>
                                                </div>
                                                <div className="col-6 left">
                                                    <div className="customer-booking-time">
                                                        <button id={`copy-${key}`} style={{marginLeft: '10px'}} onClick={() => copyText(process.env.REACT_APP_QR_BOOKING_LINK + val.uuid, key)}>Copy</button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                : null}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
            {_state.orderPopup ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown">
                            <CustomerOrderPopup show={_state.orderPopup}
                                customer={customerDataProp}
                                orderDetail={_state.orderItemDetail} />
                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenShowTransactionList} className="button-cent style-history-pay">
                                        <span><i className="fa fa-printer"></i></span> Lịch sử thanh toán
                                    </div>
                                    <div onClick={hiddenOrderPopup} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenOrderPopup} className="overlay"></div>
                    </div>
                </div>
            : ''}

            {showTransactionList ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <TransactionList
                                orderDetail={_state.orderItemDetail}
                                show={showTransactionList}
                                setShow={setShowTransactionList}
                            />

                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenOrderPopupTransactionList} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenOrderPopupTransactionList} className="overlay"></div>
                    </div>
                </div>
            : null}


            {showHistory ?
                <div className="order-popup package-history">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PackagePopup
                                package={dataPackageDetail}
                                show={showHistory}
                                setShow={setShowHistory}
                            />

                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenPackageHistory} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenPackageHistory} className="overlay"></div>
                    </div>
                </div>
            : ''}

            {showEditCredit ?
              <EditCredit
                show={showEditCredit}
                changeCurrenDeposit={props.changeCurrenDeposit}
                detailCustomer={customerDataProp}
                listPackage={listPackage}
                onHandelPopup={handelEditCredit}
                type= {type}
                saveEditCredit={props.saveEditCredit}
                handleStatusPackage={handleStatusPackage}
                />
            : null}

            {displayQrCode ? 
              <QRCode currentQrCode={currentQrCode} customerId={customerId} updateNewQrCode={updateNewQrCode} closePopupQRCode={(e) => {setDisplayQrCode(e)}} />
            :null}

        </div>
    )
}