export default {
    GET_RECEIPT_FROM_SERVICE: "api/receipts/service",
    GET_COUNT_PACKAGE: "api/receipts/count-package",
    GET_RECEIPT_PER_BILL: "api/receipts/receipt-per-bill",
    GET_RECEIPT_PERCENT: "api/receipts/receipt-percent",
    GET_USE_SERVICE_PERCENT: "api/receipts/count-package-percent",
    GET_COUNT_USER: "api/receipts/count-user",
    GET_COUNT_BILL: "api/receipts/count-bill",
    GET_BILL_PER_CUSTOMER: "api/receipts/bill-per-customer",
    GET_ACTIVE_CUSTOMER:"api/receipts/active-customer",
    GET_AGE_CUSTOMER:"api/receipts/age-customer"
}