import { Container, Table } from "react-bootstrap"
import { Input, Button, message, Spin } from 'antd';
import { useEffect, useState } from "react";
import axiosService from "../utils/axios.service";
import { getData } from "../redux/reducers/options"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom";

export default function TakeVoucher() {
    const getOptions = useSelector((state) => state.getOptions)
    const dispatch = useDispatch()

    const [orderCode, setOrderCode] = useState("")
    const boxShadowStyle = {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
    }
    const [loading, setLoading] = useState(false)
    const [voucher, setVoucher] = useState(null)
    const [order, setOrder] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            if (orderCode.length < 3) {
                message.warning("Mã đơn hàng không hợp lệ")
                setLoading(false)
            } else {
                const { data } = await axiosService(`api/vouchers/take/${orderCode}`, "POST")
                if (data.code === 200) {
                    const { voucher, order } = data.data
                    setOrder(order)
                    setVoucher(voucher)
                    setLoading(false)
                    setIsSuccess(true)
                    message.success("Lấy mã thành công")
                } else {
                    message.error(data.message)
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            message.error("Đã có lỗi xảy ra")
        }
    }
    const handleClear = () => {
        setIsSuccess(false)
        setVoucher(null)
        setOrder(null)
        setOrder("")
    }

    useEffect(async () => {
        dispatch(getData({ key: "voucher_page" }))
    }, [])

    return (
        <Spin spinning={loading} size="large" tip="Đang tải...     Xin vui lòng đợi">
            {getOptions.data.value == 1 ?
                <Container>
                    <div className="d-flex justify-content-center mt-2 p-5" style={boxShadowStyle}>
                        {!isSuccess ? 
                            <div>
                                <h2>Nhập mã đơn hàng để lấy voucher</h2>
                                <Input onChange={(e) => {
                                    setOrderCode(e.target.value)
                                }} allowClear defaultValue={orderCode} />
                                <div className="d-flex justify-content-center mt-2">
                                    <Button type="primary" onClick={handleSubmit}>Lấy ngay</Button>
                                </div>
                                <div className="">
                                    <b>Lưu ý:</b><br />
                                    - Đơn hàng phải thanh toán tối thiểu 50%<br />
                                    - Đơn hàng phải mua sản phẩm thẻ Triệt - Full body - BHVV<br />
                                    - Đơn hàng phải được tạo trong khoảng ngày 11/2 - 12/2 hoặc 18/2 - 19/2
                                </div>
                            </div> 
                        :
                            <div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Mã khách hàng</th>
                                            <th>Tên khách hàng</th>
                                            <th>Số điện thoại khách hàng</th>
                                            <th>Mã đơn hàng</th>
                                            <th>Mã voucher</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{order?.customers.id || "Không có thông tin"}</td>
                                            <td>{order?.customers.full_name || "Không có thông tin"}</td>
                                            <td>{order?.customers?.mobile || "Không có thông tin"}</td>
                                            <td>{order?.order_code || "Không có thông tin"}</td>
                                            <td>{voucher?.code || "Không có thông tin"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-center mt-2">
                                    <Button type="primary" onClick={handleClear}>Làm mới</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Container>
            : <div style={{marginTop: "50px", textAlign: "center", fontSize: "18px"}}>Đã hết thời hạn đổi voucher. <Link to={{ pathname: "/dashboard" }} style={{textDecoration: "underline !important"}}>Trở về trang chủ</Link></div>}
        </Spin>

    )
} 