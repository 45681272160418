import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios.service";
import { Table, Badge, Container } from 'react-bootstrap';
import { format } from "date-fns"
import { PackageStatus, PackageStatusColor } from "../../enums/packageStatus.enum"
import styles from "../../styles/popupInfor.module.css"
import "./scss/customer.scss";
import { PackagePopup } from "../customer/PackagePopup";
const Package = ({ customer }) => {
  const [packages, setPackages] = useState([])
  const [show,setShow] = useState(false)
  const getData = async () => {
    const res = await axiosService(`api/customer/order/${customer.id}`, "GET");
    const { data } = res
    if (data.code === 200) {
      const { dataPackage } = data.data
      setPackages(dataPackage.Data)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  function convertCurrency(data) {
    let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
    return res;
  };
  const hiddenPackageHistory = () =>{
    setShow(!show)
  }
  return (
    <Container className={styles.scrollTransaction}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {/* <th></th> */}
            <th>Ngày cấp</th>
            <th>Tên thẻ</th>
            <th>Tình trạng</th>
            <th>Số lần sử dụng</th>
            <th>Số tiền trong thẻ</th>
            <th>Ghi chú</th>
          </tr>
        </thead>
        <tbody>
          {packages.length > 0 ?
            packages.map((x,i) => {
              return (
                // {show ?
                //   <div className="order-popup package-history">
                //     <div className="popup-cent">
                //         <div className="content-popup fadeInDown package-popup">
                //           <PackagePopup package={x} show={show} />
                //           <div className="bottom-popup">
                //               <div className="button-container">
                //                   <div onClick={hiddenPackageHistory} className="button-cent style3">
                //                       <span><i className="fas fa-times"></i></span>Đóng
                //                   </div>
                //               </div>
                //           </div>
                //         </div>
                //         <div onClick={hiddenPackageHistory} className="overlay"></div>
                //     </div>
                //   </div>
                // : ''}
                <tr key={i}>
                  {/* <td>
                    <a onClick={hiddenPackageHistory} style={{color:"blue",cursor:"pointer"}}>Xem L.Sử</a>
                  </td> */}
                  <td>  {format(new Date(x.created_at), 'HH:mm dd/MM/yyyy')}</td>
                  <td>{x.product_name}</td>
                  <td>
                    <Badge bg={PackageStatusColor[x.status]} text="white">
                      {PackageStatus[x.status]}
                    </Badge>
                  </td>
                  <td>{x.count_used}/{x.max_used}</td>
                  <td>{convertCurrency(x.price_of_card)}</td>
                  <td>{x.note}</td>
                </tr>
              )
            })
          :
            <tr><td>Chưa có thông tin</td></tr>
          }
        </tbody>
      </Table>
    </Container>
  )
}
export default Package