import { Tabs, Row, Spin, Table, message } from 'antd';
import { useEffect, useState } from 'react';
import { tabEnums } from '../../enums/income.enum';
import apiReceipt from "../../enums/apiReceipt.enum"
import axiosService from "../../utils/axios.service";
export default function IncomeFunction() {
  const [list, setList] = useState([])
  const [text, setText] = useState("Doanh thu từ các dịch vụ")
  const [key, setKey] = useState(0)
  const [endpoint, setEnpoint] = useState(apiReceipt.GET_RECEIPT_FROM_SERVICE)
  const [isLoading, setIsLoading] = useState(true)
  function convertCurrency(data) {
    let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
    return res;
  };
  const columns = [
    {
      title: text,
      key: "text",
      fixed: 'left',
      width: 100,
      render: (data, x) => {
        return (
          <>
            <p>
              {x[0]}
            </p>
          </>
        )
      },
    },
    {
      title: 'Tháng 1',
      key: 'one',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 1
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 2',
      key: 'two',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 2
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 3',
      key: 'three',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 3
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },

    },
    {
      title: 'Tháng 4',
      key: 'four',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 4
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 5',
      key: 'five',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 5
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 6',
      key: 'six',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 6
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 7',
      key: 'seven',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 7
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 8',
      key: 'eight',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 8
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 9',
      key: 'nine',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 9
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 10',
      key: 'ten',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 10
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 11',
      key: 'eleven',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 11
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
    {
      title: 'Tháng 12',
      key: 'twelve',
      width: 100,
      render: (data, x) => {
        const findIndex = x[1].findIndex(x => {
          return x.month == 12
        })
        return (
          <>
            {[2, 5, 6, 7, 8].includes(parseInt(key)) && (findIndex >= 0 ? x[1][findIndex].value : 0)}
            {[3, 4].includes(parseInt(key)) && `${(findIndex >= 0 ? x[1][findIndex].value : 0)}%`}
            {[0, 1].includes(parseInt(key)) && (findIndex >= 0 ? convertCurrency(x[1][findIndex].value || 0) : 0)}
          </>
        )
      },
    },
  ];
  const onChangeKey = (key) => {
    setText(tabEnums[key])
    setKey(key)
    switch (parseInt(key)) {
      case 0: {
        setEnpoint(apiReceipt.GET_RECEIPT_FROM_SERVICE)
        break
      }
      case 1: {
        setEnpoint(apiReceipt.GET_RECEIPT_PER_BILL)
        break
      }
      case 2: {
        setEnpoint(apiReceipt.GET_COUNT_PACKAGE)
        break
      }
      case 3: {
        setEnpoint(apiReceipt.GET_RECEIPT_PERCENT)
        break
      }
      case 4: {
        setEnpoint(apiReceipt.GET_USE_SERVICE_PERCENT)
        break
      }
      case 5: {
        setEnpoint(apiReceipt.GET_COUNT_USER)
        break
      }
      case 6: {
        setEnpoint(apiReceipt.GET_COUNT_BILL)
        break
      }
      case 7: {
        setEnpoint(apiReceipt.GET_BILL_PER_CUSTOMER)
        break
      }
      case 8: {
        setEnpoint(apiReceipt.GET_ACTIVE_CUSTOMER)
        break
      }
      case 9: {
        setEnpoint(apiReceipt.GET_AGE_CUSTOMER)
        break
      }
      default: {
        setEnpoint(apiReceipt.GET_RECEIPT_FROM_SERVICE)
        break
      }
    }
  }
  useEffect(async () => {
    setIsLoading(true)
    try {
      const res = await axiosService(`${endpoint}`, "GET")
      const { data } = res
      if (data.code === 200) {
        setIsLoading(false)
        setList(data.data)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      message.error("Có lỗi xảy ra vui lòng thử lại")
    }
  }, [endpoint])
  return (
    <Spin tip="Đang tải dữ liệu..." spinning={isLoading}>
      <div className='m-2'>
        <h1 className="text-center">Báo cáo dịch vụ</h1>
        <Row>
          <div className='w-100'>
            <Tabs defaultActiveKey={key} onChange={onChangeKey} className="w-100">
              <Tabs.TabPane tab="Doanh thu" key={0}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Bình quân/ bill" key={1}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Số lượt dịch vụ" key={2}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tỉ lệ doanh thu" key={3}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tỉ lệ lượt dịch vụ" key={4}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Số lương khách hàng" key={5}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Bill khách mua" key={6}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Số lượng bill/ khách hàng" key={7}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Số lượng khách hàng active" key={8}>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Độ tuổi khách hàng dùng" key={9}>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Row>
        <Row>
          {key != 9 && <Table className='w-100' columns={columns} dataSource={list} pagination={{
            pageSize: 50,
          }}
            scroll={{
              y: 600,
              x: 1500,
            }} />}
          {key == 9 &&
            <>
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th>Độ tuổi</th>
                    <th>Dịch vụ</th>
                    <th>Tháng 1</th>
                    <th>Tháng 2</th>
                    <th>Tháng 3</th>
                    <th>Tháng 4</th>
                    <th>Tháng 5</th>
                    <th>Tháng 6</th>
                    <th>Tháng 7</th>
                    <th>Tháng 8</th>
                    <th>Tháng 9</th>
                    <th>Tháng 10</th>
                    <th>Tháng 11</th>
                    <th>Tháng 12</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((x, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <th rowspan={x[1].length}>{x[0]}</th>
                          {
                            x[1].map((y, j) => {
                              if (j == 0) {
                                return (
                                  <>
                                    <td>{y[0]}</td>
                                    {
                                      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((z, k) => {
                                        const findIndex = y[1]?.findIndex(l => {
                                          return l.month == z
                                        })
                                        return <td>
                                          {findIndex >= 0 ? y[1][findIndex].value : 0}
                                        </td>
                                      })
                                    }
                                  </>
                                )
                              }

                            })
                          }
                        </tr>
                        {
                          x[1].map((y, j) => {
                            if (j != 0) {
                              return (
                                <tr>
                                  <td>{y[0]}</td>
                                  {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((z, k) => {
                                      const findIndex = y[1]?.findIndex(l => {
                                        return l.month == z
                                      })
                                      return <td>
                                        {findIndex >= 0 ? y[1][findIndex].value : 0}
                                      </td>
                                    })
                                  }
                                </tr>
                              )
                            }

                          })
                        }
                      </>
                    )
                  })}
                </tbody>
              </table>
            </>
          }
        </Row>
      </div>
    </Spin>
  )
}
