import React, { useState, useEffect } from 'react'
import { Table, Dropdown, Button, Checkbox, Modal } from 'rsuite'
import './scss/cards.scss'
import { Link } from 'react-router-dom'
import { cards } from './fakeData'
import CardItem from './CardItem'
import ModalCreateCard from './ModalCreateCard'
import { axiosGet } from "../../utils/axios.service";
import axiosService from "../../utils/axios.service";
import { notifications } from "../constant/Notify";



var _state = {}
var isLoading = false;

async function requestDataCart() {
  if (_state.setDataCart === undefined) return ''
  isLoading = true;
  let response = await axiosGet("api/product/?type=2");
  if (response.data.code == 200) {
    _state.setDataCart(response.data.data)
  }
}



const Cards = () => {
  const [isShowModalCreateCard, setIsShowModalCreateCard] = useState(false)
  const [dataCart, setDataCart] = useState(false)
  const [tabActive, setTabActive] = useState(0)
  const [dataParam, setDataParm] = useState({})
  const [search, setSearch] = useState('')
  const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info") ?? null))
  const [products, setProducts] = useState([])
  const [listSelectGroup, setListSelectGroup] = useState([])
  const [listGroup, setListGroup] = useState([])


  _state.dataCart = dataCart
  _state.setDataCart = setDataCart
  _state.dataParam = dataParam
  _state.setDataParm = setDataParm

  const  getCategory = () => {
    axiosService("api/category?product_type=2", "GET")
      .then((res) => {
        let response = res.data;
        const selectDataStore = [];
        if (response.code === 200) {
          if (response.data !== null) {
            for (const key in response.data) {
              selectDataStore.push({
                value: response.data[key].id,
                label: response.data[key].name,
              });
            }
            setListSelectGroup(selectDataStore)
          }
          setListGroup(response.data)
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    requestDataCart()
    getCategory()
  }, []);

  useEffect(async () => {
    const { data } = await axiosService(`api/product/card/get`, "GET")
    if (data && data.code === 200) {
      setProducts(data.data)
    }
  }, [])
  const tabs = [
    {
      id: 0,
      text: "Tất cả",
      number: _state.dataCart.total,
    },
    {
      id: 1,
      text: "Thẻ số lần",
      number: _state.dataCart.totalMumberCard,
    },
    {
      id: 2,
      text: "Thẻ số tiền",
      number: _state.dataCart.totalMoneyCard,
    }
  ]


  const handleSetActiveTab = (id) => {
    axiosService("api/product/?type=2&type_meta=" + id + "&keyword=" + search, "GET")
      .then((res) => {
        let response = res;
        if (response.data.code == 200) {
          _state.setDataCart(response.data.data)
        }
      })
      .catch((error) => console.log(error));
    setTabActive(id)
  }

  const handleRenderTabs = () => {
    return (
      <div className="flex card-tab-container">
        {tabs.map((item) => {
          return (
            <div onClick={() => handleSetActiveTab(item.id)} className={`item ${tabActive === item.id ? 'active' : ''}`} key={item.id}>{item.text} ({item.number})</div>
          )
        })}
      </div>
    )
  }


  const hendleSearch = (e) => {
    setSearch(e.target.value)
    axiosService("api/product/?type=2&keyword=" + e.target.value, "GET")
      .then((res) => {
        let response = res.data;
        if (response.code === 200) {
          _state.setDataCart(response.data)
        }
        setTabActive(0)
      })
      .catch((error) => console.log(error));
  }

  const addPackage = (data) => {
    axiosService("api/product", "POST", data)
      .then((res) => {
        const response = res.data;
        if (response.code == 200) {
          requestDataCart()
          setIsShowModalCreateCard(false)
          notifications({
            type: "success",
            mess: "Tạo thẻ mới thành công",
          });
        } else {
          setIsShowModalCreateCard(false)
          notifications({
            type: "error",
            mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const deletePackage = (id) => {
    axiosService("api/product/" + id, "DELETE")
      .then((res) => {
        const response = res.data;
        if (response.code == 200) {
          requestDataCart()
          notifications({
            type: "success",
            mess: "Xoá thẻ thành công",
          });
        } else {
          notifications({
            type: "error",
            mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
          });
        }
      })
      .catch((error) => console.log(error));
  };
  const editPackage = (id, data) => {
    axiosService("api/product/" + id, "PUT", data)
      .then((res) => {
        const response = res.data;
        if (response.code == 200) {
          requestDataCart()
          setIsShowModalCreateCard(false)
          notifications({
            type: "success",
            mess: "Sửa thẻ thành công ",
          });
        } else {
          setIsShowModalCreateCard(false)
          notifications({
            type: "error",
            mess: "Đã có lỗi xảy ra, vui lòng thử lại sau",
          });
        }
      })
      .catch((error) => console.log(error));
  }


  return (
    <div className="admin-page orders member-card">
      <div className="page-title">
        <div className="title-page">
          <i className="fa-solid fa-credit-card"></i>
          <span className="ml-1">Các loại thẻ</span>
        </div>
        <div className="header-right">
          <div className="button-cent blue-green-bg">
            <Link to={{ pathname: '/membercards' }}>
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
              Danh sách khách hàng dùng thẻ
            </Link>
          </div>
          {userAdmin && userAdmin.role != 4 ?
            <div className="button-cent">
              <div onClick={() => setIsShowModalCreateCard(true)}>
                <span>
                  <i className="fa-solid fa-user-plus"></i>
                </span>
                Tạo thẻ dịch vụ
              </div>
            </div>
          : null}
          {/* <div className="button-cent">
            <Link to={{ pathname: '/pos' }}>
              <span>
                <i className="fa-solid fa-file-excel"></i>
              </span>
              Xuất excel
            </Link>
          </div>
          {userAdmin && userAdmin.role != 4 ?
            <div className="button-cent">
              <Link to={{ pathname: '/pos' }}>
                <span>
                  <i className="fa-solid fa-file-excel"></i>
                </span>
                Nhập từ excel
              </Link>
            </div>
          : null} */}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="panel panel-flat max-height height-750">
          <div className="panel-body">
            <div className="flex header-filter">
              <li className="flex filter-item">
                <div className="ng-binding mr-5">Tìm kiếm </div>
                <div>
                  <input
                    onChange={hendleSearch}
                    className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty"
                    placeholder="Tìm mã thẻ, tên thẻ"
                  />
                </div>

                <div>
                  {handleRenderTabs()}
                </div>
              </li>
            </div>
            <div className="alert-danger flex  m-10">
              {/* <Checkbox />
              <Button className="button-trash">
              <i className="fa-solid fa-trash"></i>
                Xóa
              </Button> */}
            </div>
            <div className="border-black">
              <div className="table-container">
                {/* <div className="select-card-checkbox">
                  <Checkbox />
                </div> */}
                <div className="card-status"></div>
                <div className="card-info">
                  Mã thẻ
                </div>
                <div className="name">Tên thẻ</div>
                <div className="type">Loại thẻ</div>
                <div className="price">Giá bán thẻ</div>
                <div className="number-used">Số lần sử dụng</div>
                {/* <div className="account">Tài khoản thẻ</div> */}
                <div className="due-date">Thời hạn</div>
                <div className="function">
                  Mô tả chức năng
                </div>
                {userAdmin && userAdmin.role != 4 ?
                  <div className="action">Action</div>
                  : null}
              </div>
              <div className="card-item-container">
                {_state.dataCart.data ?
                  _state.dataCart.data.map((item) => (
                    <CardItem userAdmin={userAdmin} item={item} products={products} key={item.id} deletePackage={deletePackage} editPackage={editPackage} listSelectGroup={listSelectGroup} listGroup={listGroup}/>
                  )) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowModalCreateCard && (
        <ModalCreateCard open={isShowModalCreateCard} setOpen={setIsShowModalCreateCard} addPackage={addPackage} products={products} listSelectGroup={listSelectGroup} listGroup={listGroup}/>
      )}

    </div>
  )
}

export default Cards
