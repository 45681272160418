import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin, Button } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import PopupViewServices from "./PopupViewServices"
import Loading from "../customer/Loading";

const ViewDetailOrderServiceTvv = (props) => {
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)

    // useEffect(() => {
    //     setTiltle("Theo nhân viên đặt lịch")
    // }, [])

 
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate();
            // hours = date.getHours(),
            // minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        // if (hours < 10) hours = '0' + hours;
        // if (minus < 10) minus = '0' + minus;
        let text = date.getFullYear() + '-' + month + '-' + day ;
        // text = text + ' ' + hours + ':' + minus;
        return text
    };

    const columns = [
        {
            title: 'Ngày thu tiền',
            dataIndex: 'transaction_at',
            // align: "center",
            width: 250,
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Mã HD',
            dataIndex: 'order_code',
            // align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="">{data}</p>
                )
            },
        },
        {
            title: 'Mã giao dịch',
            dataIndex: 'transaction_code',
            // align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customer_name',
            // align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="">{data}</p>
                )
            },
        },
        {
            title: 'Hoa hồng tư vấn',
            dataIndex: 'paid',
            // align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data*0.05)}</p>
                )
            },
        },
        
        {
            title: 'Loại giao dịch',
            dataIndex: 'transaction_status',
            width: 150,
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Cơ sở thanh toán',
            dataIndex: 'store',
            align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="">{data}</p>
                )
            },
        },
    ];

    const handelExportUser = () => {
        // if (_state.loadingShowClickItem) return
        setShowloading(true)
      
        var url = `api/mysql/export/detail-user?type=2&user_id=${props.userId}&user_name=${props.userName}&start=${props.startDate}&end=${props.endDate}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
  
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="p-2">
                
                <div className="border-bottom w-100 mb-2">
                    <Row>
                        <Col xs={6} className="p-2">
                            <div>
                                Bảng số liệu chi tiết
                            </div>
                        </Col>
                        <Col xs={6} className="p-2">
                            <Button size="middle" type="primary"
                             onClick={handelExportUser}
                            ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                            </Button>
                        </Col>
                    </Row>
                </div>
                    
                <Table 
                    columns={columns} 
                    dataSource={props.table}
                    pagination={{ pageSize: 8 }}
                    scroll={{ x: 600 }}
                    bordered
                />
            </div>
            <Loading
                show={showloading}
            ></Loading>
        </>
    )
}
export default ViewDetailOrderServiceTvv