export const posCustomer = [
    {
        "type": "label",
        "title": "Thông tin đơn hàng"
    },
    {
        "id": "BookDatetimeString",
        "title": "Ngày ghi hóa đơn",
        "type": "datepicker"
    },
    {
        "id": "Notes",
        "title": "Ghi chú hoá đơn",
        "type": "textarea",
        "placeholder": "Thông tin ghi chú",
    },
    {
        "type": "hr"
    },
    {
        "type": "label",
        "title": "Thông tin khách hàng"
    },
    {
        "type": "customer"
    },
    {
        "id": "Tags",
        "title": "Nhóm khách hàng",
        "type": "multiselect"
    }
];

export const customStylesSelect = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0, 
      paddingRight: 0, 
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      maxHeight: 32
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight = 0; 
      const height = 32
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
};

export const customDiscount = [
  {value: 'none', label: "Lựa chọn khác"},
  {value: 0, label: "0%"},
  {value: 5, label: "5%"},
  {value: 10, label: "10%"},
  {value: 15, label: "15%"},
  {value: 20, label: "20%"},
  {value: 25, label: "25%"},
  {value: 30, label: "30%"},
  {value: 35, label: "35%"},
  {value: 40, label: "40%"},
  {value: 45, label: "45%"},
  {value: 50, label: "50%"},
  {value: 55, label: "55%"},
  {value: 60, label: "60%"},
  {value: 65, label: "65%"},
  {value: 70, label: "70%"},
  {value: 70, label: "70%"},
  {value: 80, label: "80%"},
  {value: 85, label: "85%"},
  {value: 90, label: "90%"},
  {value: 95, label: "95%"},
  {value: 100, label: "100%"},
]

export const selectOptionTypePresenter = [
  {value: 1, label: "Nhân viên phục vụ"},
  {value: 2, label: "Khách hàng"},
  // {value: 3, label: "Khác"},
]

export const testVoucher = [
  {value: "KKKKKK", label: "KKKKKK - Nhân viên phục vụ"},
  {value: "BBBBBB", label: "KKKKKK - Khách hàng"},
  // {value: 3, label: "Khác"},
]


export const selectOptionTypeConsultant = [
  // {value: 0, label: ""},
  {value: 1, label: "$"},
  // {value: 2, label: "đ"},
]

export const listDeposit = [
  {
    code: "TC05",
    product_name: "Thẻ tiền",
    type: 4,
    price: 0,
    discount: 0
  },
  {
    code: "TT03",
    product_name: "Thẻ tiền - 3.000.000",
    type: 4,
    price: 3000000,
    discount: 0
  },
  {
    code: "TT05",
    product_name: "Thẻ tiền - 5.000.000",
    type: 4,
    price: 5000000,
    discount: 0
  },
  {
    code: "TT10",
    product_name: "Thẻ tiền - 10.000.000",
    type: 4,
    price: 10000000,
    discount: 0
  },
  {
    code: "TT20",
    product_name: "Thẻ tiền - 20.000.000",
    type: 4,
    price: 20000000,
    discount: 0
  },
  {
    code: "TT50",
    product_name: "Thẻ tiền - 50.000.000",
    type: 4,
    price: 50000000,
    discount: 0
  }
]
