import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import thunkStatus from '../../enums/thunkStatus.enum';
import responseEnum from '../../enums/response.enum';
import axiosService from "../../utils/axios.service";
import { message } from 'antd';
const initialState = {
  data: [],
  status: thunkStatus.INIT,
  response: responseEnum.SUCCESS,
  meta: {}
}

export const EmulationSlice = createSlice({
  name: 'emulation',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      console.log("aaaaa")
    },
  },
  extraReducers: builder => {
    builder
      //fetch
      .addCase(getData.pending, (state, action) => {
        state.status = thunkStatus.PENDING
      })
      .addCase(getData.fulfilled, (state, action) => {
        if (!action.payload) {
          state.response = responseEnum.FAIL
        } else {
          state.response = responseEnum.SUCCESS
        }
        const mapData = action.payload.data.items.map(x => {
          return {
            ...x,
            key: x.id
          }
        })
        state.data = mapData
        state.status = thunkStatus.FULFILLED
        state.meta = action.payload.data.meta
      })
      .addCase(getData.rejected, (state, action) => {
        state.status = thunkStatus.REJECT
      })
      //create
      .addCase(create.pending, (state, action) => {
        state.status = thunkStatus.PENDING
      })
      .addCase(create.fulfilled, (state, action) => {
        if (action.payload && action.payload.code === 200) {
          state.response = responseEnum.SUCCESS
          action.payload.data.forEach(x => {
            state.data.unshift(x)
          })
        } else {
          state.response = responseEnum.FAIL
        }
        state.status = thunkStatus.FULFILLED
      })
      .addCase(create.rejected, (state, action) => {
        state.status = thunkStatus.REJECT
      })
      //delete
      .addCase(deleteVoucher.pending, (state, action) => {
        state.status = thunkStatus.PENDING
      })
      .addCase(deleteVoucher.fulfilled, (state, action) => {
        state.status = thunkStatus.FULFILLED
        state.data = state.data.filter((x => {
          const findIndex = action.payload.data.findIndex(y => {
            return y.id == x.id
          })
          return findIndex < 0
        }));
      })
      //import excel
      .addCase(importVoucher.pending, (state, action) => {
        state.status = thunkStatus.PENDING
      })
      .addCase(importVoucher.fulfilled, (state, action) => {
        state.status = thunkStatus.FULFILLED
        const { data } = action.payload
        const filter = data.data.filter(x => {
          return x != null
        })
        state.data.unshift(...filter)
      })

  }
})
export const { updateStatus } = EmulationSlice.actions

export const getData = createAsyncThunk('emulation/fetchData', async (query) => {

  const { id, code, startDate, endDate, status, limit, page, name, groupId } = query
  try {
    const res = await axiosService(`api/emulation?id=${id}&code=${code}&startDate=${startDate}&endDate=${endDate}&status=${status}&limit=${limit}&page=${page}&name=${name}&groupId=${groupId}`, "GET")
    console.log(res)
    return res.data
  } catch (error) {
    console.error(error)
  }
})

export const create = createAsyncThunk('emulation/create', async (payload) => {
  try {
    const res = await axiosService(`api/emulation`, "POST", payload)
    return res.data
  } catch (error) {
    console.error(error)
  }
})

export const deleteVoucher = createAsyncThunk('emulation/delete', async (id) => {
  try {
    const res = await axiosService(`api/emulation/${id}`, "DELETE")
    return res.data
  } catch (error) {
    console.error(error)
  }
})

export const importVoucher = createAsyncThunk('voucher/import', async (data) => {
  try {
    const rs = await axiosService("api/emulation/import/excel", "post", data)
    return rs.data
  } catch (error) {
    console.error(error)
  }
})


export default EmulationSlice.reducer


