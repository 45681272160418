import React, { useState, useEffect } from "react";
import { accountPaymentQR } from '../constant/GeneralData'
import axiosService from "../../utils/axios.service";
import { Select, InputNumber, Spin } from 'antd';

var _ = require('lodash');
var axios = require('axios');
const { Option } = Select;

export function QRCode(props) {
  const [isCopyText, setIsCopyText] = useState('')
  const [loading, setLoading] = useState(false)
  const [dataQR, setDataQR] = useState('')
  const [price, setPrice] = useState(0)
  const [selectStores, setSelectStores] = useState([])
  const [statusButton, setStatusButton] = useState(true)

  const copyText = (text) => {
    setIsCopyText('isCopy')
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setIsCopyText('')
    }, 1000);
  }

  const createQrCode = async () => {
    setLoading(true)
    let payload = {
      customer_id: props.customerId,
      store_id: selectStores,
      price: price,
    }

    const rs = await axiosService(`api/qrcode/create`, "POST", payload);

    if (rs.data.success) {
      await assignQrCode(rs.data.data)
      setLoading(false)
    }
  }

  const editQrCode = async () => {
    setLoading(true)
    let payload = {
      customer_id: props.customerId,
      store_id: selectStores,
      price: price,
      uuid: dataQR.uuid
    }

    const rs = await axiosService(`api/qrcode/edit`, "POST", payload);

    if (rs.data.success) {
      await assignQrCode(rs.data.data)
      props.updateNewQrCode(rs.data.data)
      setLoading(false)
    }
  }

  const assignQrCode = async (data) => {
    let accountByStore = _.find(accountPaymentQR, function(o) { return o.store == data.store_id });
    if (accountByStore) {
      let price = data.price
      let payload = {
        "accountNo": accountByStore.bank_number,
        "accountName": accountByStore.account_name,
        "acqId": accountByStore.bank_key,
        "amount": price,
        "addInfo": data.uuid,
        "format": "text",
        "template": "VW6ZAxb"
      }

      let genQR = await axios.post("https://api.vietqr.io/v2/generate", payload);
      let dataQR = {
        ...accountByStore,
        uuid: data.uuid,
        price: data.price,
        url_qr: genQR?.data?.data?.qrDataURL ?? `https://api.vietqr.io/image/${accountByStore.bank_key}-${accountByStore.bank_number}-VW6ZAxb.jpg?accountName=${accountByStore.account_name.replaceAll(" ", "%20")}&amount=${price}&addInfo=${data.uuid}`
      }

      setDataQR(dataQR)
    }
  }

  useEffect(async () => {
    if (props.currentQrCode) {
      setPrice(props.currentQrCode.price)
      setSelectStores(props.currentQrCode.store_id)
      await assignQrCode(props.currentQrCode)
    }
  }, [])

  useEffect(() => {
    if (price > 0 && selectStores > 0) setStatusButton(false)
  }, [price, selectStores])

  return (
    <div className="popup-cent payment">
      <div className="content-popup fadeInDown">
        <div className="head-popup">
          <div className="title">QR code{props.currentQrCode ? ": " + props.currentQrCode.uuid : ""}</div>
          <div className="close" onClick={() => {props.closePopupQRCode(false)}}>×</div>
        </div>
        <Spin spinning={loading} size="large" tip="Đang tải...">
          <div className="body-popup">
            <div className="input-qr">
              <div className="row">
                <div className="col-6">
                  <div className="price">
                    <div className="mb-2">Tiền khách chuyển khoản</div>
                    <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e) => {
                          const value = e ? e : 0
                          setPrice(value)
                        }}
                        value={price}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="list-store">
                    <div className="mb-2">Cơ sở khách chuyển khoản</div>
                    <Select
                      className="w-100 mr-2"
                      showSearch
                      optionFilterProp="children"
                      placeholder={'Chọn cơ sở'}
                      onChange={(e) => {setSelectStores(e)}}
                      value={selectStores}
                    >
                      {accountPaymentQR.map((x,y) => {return (<Option key={y} value={x.store} >{`${x.store_name}`}</Option>)})}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            {dataQR ? 
              <div className="result-qr mt-4">
                <p>
                  Link QR Code: <a href={`${process.env.REACT_APP_QR_BOOKING_LINK}${dataQR.uuid}`} target="_blank" className="link-qr-code">{`${process.env.REACT_APP_QR_BOOKING_LINK}${dataQR.uuid}`}</a>
                  <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() => copyText(process.env.REACT_APP_QR_BOOKING_LINK + dataQR.uuid)}>Copy</button>
                </p>
                <p>Ngân hàng: {dataQR.bank_name}</p>
                <p>Tên tài khoản: {dataQR.account_name}</p>
                <p>Tài khoản: {dataQR.bank_number}</p>
                <p>Số tiền thanh toán: {dataQR.price.toLocaleString('it-IT', {style : 'currency', currency : 'VND'})}</p>
                <p>Nội dung thanh toán: {dataQR.uuid}</p>
              </div>
            : ''}
          </div>
          <div className="bottom-popup">
            <div className="button-container">
              <button disabled={statusButton} className={`${statusButton ? "disable" : ''} button-cent`} onClick={dataQR ? editQrCode : createQrCode}>
                <span><i className="fas fa-save"></i></span>{dataQR ? 'Sửa thông tin' : 'Tạo mã'}
              </button>
            </div>
          </div>
        </Spin>
      </div>
      <div className="overlay" onClick={() => {props.closePopupQRCode(false)}}></div>
    </div>
  );
};

