import React from "react";
import axiosService from "../../utils/axios.service";
import { notifications } from "../constant/Notify";

import "./scss/styles.scss"
export function DeleteTransaction(props) {
    const saveDelete = async () => {
      let data = {
        order_id: props.dataTransaction.order.id
      }
      let res = await axiosService("api/transaction/delete-transaction/" + props.dataTransaction.id, "PUT", data)
      if (res.data.success) {
        props.disablePopup(false)
        props.reloadData()
        notifications({ type: 'success', mess: 'Xóa giao dịch thành công' });
      } else {
          notifications({ type: 'error', mess: res.data.message });
      }
    }

    return (
        <div className={`report-by-day popup-report`}>
            <div className="head-popup">
                <div className="title">Thông báo hủy giao dịch</div>
            </div>
            <div className="body-popup">
              <div className="title-body">
                {props.dataTransaction.status == 1 ? "Không thể hủy giao dịch mua hoặc sử dụng" : "Bạn có muốn hủy giao dịch này không?"}</div>
            </div>
            <div className="bottom-popup">
                {props.dataTransaction.status == 1 ?
                  <div className="button-container">
                    <div className="button-cent style3" onClick={() => {props.disablePopup(false)}} >
                      <span><i className="fas fa-times" ></i></span>Trở về
                    </div>
                  </div>
                : 
                  <div className="button-container">
                    <div className="button-cent style3" onClick={() => {props.disablePopup(false)}} >
                      <span><i className="fas fa-times" ></i></span>Không
                    </div>
                    <div className="button-cent" onClick={saveDelete}>
                      <span><i className="fas fa-save"></i></span>Có
                    </div>
                  </div>
                }
                
              </div>
            
        </div>
    )
}

export default DeleteTransaction