import React from "react";
import axiosService from "../../utils/axios.service";
import { axiosGet } from "../../utils/axios.service";
import DateRangePicker from "rsuite/DateRangePicker";
import { event } from "jquery";
import Select from 'react-select';
import { CSVLink } from "react-csv";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

import {
    optionsGender, optionsHoursBooking,
    optionsMinuteBooking, customStylesBooking,
    customStylesFilter, optionsStatusBooking, optionsViewOrderRemover
  } from '../constant/GeneralData'
  
import {optionsSourceBooking } from './constant/BookingConstant';



class BookingShowHistory extends React.Component {
    constructor() {
        super();
        this.state = {
            startDateOrderAt: '',
            endDateOrderAt: '',
            startCustomer: '',
            endCustomer: '',
            stores: [],
            keyword: '',
            store_id: '',
            status: '',
            source: '',
            start: '',
            end: '',
            defaultRangeDate: [new Date(), addDays(new Date(), 0)],
        }
    }

    acceptExport = (event) => {
        let data = {
            keyword: this.state.keyword,
            store_id: this.state.store_id,
            status: this.state.status,
            source: this.state.source,
            start: this.state.start,
            end: this.state.end
        }
        this.props.handleExportDataBooking(data)
    };

    changeDateRangeOrder = (event) => {
        let start = ''
        let end = ''
        if (event) {
            start = [event[0].getFullYear(), ("0" + (event[0].getMonth() + 1)).slice(-2), ("0" + event[0].getDate()).slice(-2)].join('-');
            end = [event[1].getFullYear(), ("0" + (event[1].getMonth() + 1)).slice(-2), ("0" + event[1].getDate()).slice(-2)].join('-');
        }
        this.setState({
            start: start,
            end: end
        })

    }

    async requestDataStore() {
        let response = await axiosGet("api/stores");
        const selectDataStore = [{ value: 0, label: 'Tất cả các chi nhánh' }]
        if (response.data.code == 200) {
          if (response.data.data !== null) {
            for (const key in response.data.data) {
              selectDataStore.push({
                value: response.data.data[key].id,
                label: response.data.data[key].name_store
              })
            }
          }
        }
        this.setState({
            stores: selectDataStore
        })
    }

    changeKeyword = (event) => {
        this.setState({
            keyword: event.target.value
        })
    }

    changeStore = (event) => {
        this.setState({
            store_id: event.value
        })
    }

    changeStatusBooking = (event) => {
        this.setState({
            status: event.value
        })
    }

    changeSourceBooking = (event) => {
        this.setState({
            source: event.value
        })
    }


    render() {
        if (this.props.show) {
            if (this.state.stores.length == 0) {
                 this.requestDataStore()
            }
            var listStores = this.state.stores

            return (
                <div className="popup-cent">
                    <div className="content-popup fadeInDown">
                        <div className="head-popup">
                            <div className="title">Xuất lịch sử đặt lịch</div>
                            <div className="close" onClick={this.props.close}>×</div>
                        </div>
                        <div className="body-popup body-popup-export-booking">
                            <form className="action-form popup-export-booking">
                                <ul className="order-filter-block">
                                    <li>
                                        <label>Tìm kiếm khách hàng</label>
                                        <input placeholder="Tìm khách hàng hoặc mã đặt lịch" onChange={this.changeKeyword} className="form-control" />
                                    </li>
                                    { listStores.length > 0 ? 
                                        <li>
                                            <label>Chi nhánh</label>
                                            <Select className="select" onChange={this.changeStore} isSearchable={false} defaultValue={listStores[0]} options={listStores} />
                                        </li>
                                    : ''
                                    }
                                    <li>
                                        <label>Trạng thái</label>
                                        <Select className="select" onChange={this.changeStatusBooking} isSearchable={false} defaultValue={this.props.filterByStatus[0]} options={this.props.filterByStatus} />
                                    </li>
                                    <li>
                                        <label>Nguồn</label>
                                        <Select className="select" onChange={this.changeSourceBooking} isSearchable={false}  defaultValue={optionsSourceBooking[0]} options={optionsSourceBooking} />

                                    </li>
                                    <li>
                                        <label>Thời gian đặt lịch</label>
                                        <DateRangePicker
                                            className="date-range-picker-order-at"
                                            placement={"bottomEnd"}
                                            defaultValue={this.state.defaultRangeDate}
                                            style={{ height: 56 }}
                                            format={"dd-MM-yyyy"}
                                            onChange={this.changeDateRangeOrder}
                                        />
                                    </li>

                                </ul>

                            </form>
                        </div>
                        <div className="bottom-popup bottom-popup-">
                            <div className="button-container">
                                <div className="button-cent style3" onClick={this.props.close}>
                                    <span><i className="fas fa-times"></i></span>Không
                                </div>
                                <div className="button-cent" onClick={this.acceptExport}>
                                     <span><i className="fas fa-save"></i></span>Export
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={this.props.close}></div>
                </div>
            );
        } else return <div></div>;
    }
}
export default BookingShowHistory;
