import React from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import Select from 'react-select'
import { optionsDays, optionsMonths, optionsRanking } from "../customer/constant/CustomerBirthday";

class FilterExportData extends React.Component {
  constructor() {
    super();
    this.state = {
      startDateOrderAt: '',
      endDateOrderAt: '',
      startCustomer: '',
      endCustomer: '',
      birthDay: null,
      birthMonth: null,
      birthYear: '',
      ranking: null
    }
  }

  customStylesSelect = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      maxHeight: 32
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight = 0;
      const height = 32
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
  };

  acceptExport = (event) => {
    let data = {
      startOrderAt: this.state.startDateOrderAt,
      endOrderAt: this.state.endDateOrderAt,
      startCustomer: this.state.startCustomer,
      endCustomer: this.state.endCustomer,
      birthDay: this.state.birthDay,
      birthMonth: this.state.birthMonth,
      birthYear: this.state.birthYear,
      ranking: this.state.ranking
    }
    this.props.handleExportDataCustomer(data)
  };

  changeDateRangeOrder = (event) => {
    let startDateOrderAt = ''
    let endDateOrderAt = ''
    if (event) {
        startDateOrderAt = [event[0].getFullYear(), ("0" + (event[0].getMonth() + 1)).slice(-2), ("0" + event[0].getDate()).slice(-2)].join('-');
        endDateOrderAt = [event[1].getFullYear(), ("0" + (event[1].getMonth() + 1)).slice(-2), ("0" + event[1].getDate()).slice(-2)].join('-');
    }
    this.setState({
      startDateOrderAt: startDateOrderAt,
      endDateOrderAt: endDateOrderAt
    })

  }
  
  changeDateRangeCustomer = (event) => {
    let startCustomer = ''
    let endCustomer = ''
    if (event) {
      startCustomer = [event[0].getFullYear(), ("0" + (event[0].getMonth() + 1)).slice(-2), ("0" + event[0].getDate()).slice(-2)].join('-');
      endCustomer = [event[1].getFullYear(), ("0" + (event[1].getMonth() + 1)).slice(-2), ("0" + event[1].getDate()).slice(-2)].join('-');
    }
    this.setState({
      startCustomer: startCustomer,
      endCustomer: endCustomer
    })
  }
  
  changeDataBirth = (event, key) => {
    if (key == 'birthYear') {
      if (event.target.validity.valid) this.setState({ birthYear: event.target.value })
    } else {
      this.setState({
        [key]: event
      })
    }

  }

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Xuất khách hàng </div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="bottom-popup">
              <form>
                  <div className="filter-date-order">
                    <div className="form-input">
                      <label>Thời gian đặt hàng</label>
                      <DateRangePicker
                          className="date-range-picker-order-at"
                          placement={"bottomEnd"}
                          size="sm"
                          style={{ height: 32}}
                          format={"dd-MM-yyyy"}
                          onChange={this.changeDateRangeOrder}
                        />
                      </div>

                    <div className="form-input">
                      <label>Thời gian điểm tạo khách hàng</label>
                      <DateRangePicker
                          className="date-range-picker-order-at"
                          placement={"bottomEnd"}
                          size="sm"
                          style={{ height: 32}}
                          format={"dd-MM-yyyy"}
                          onChange={this.changeDateRangeCustomer}
                        />
                    </div>
                    <div className="form-input">
                      <label>Sinh nhật</label>
                      <div className="select-birth">
                        <Select className="select half data-birthday" onChange={(e) => this.changeDataBirth(e?.value ?? null, 'birthDay')} styles={this.customStylesSelect} defaultValue={optionsDays[this.state.birthDay - 1]} isSearchable={false} isClearable={true} options={optionsDays} />
                        <Select className="select half data-birthday" onChange={(e) => this.changeDataBirth(e?.value ?? null, 'birthMonth')} styles={this.customStylesSelect} defaultValue={optionsMonths[this.state.birthMonth - 1]} isSearchable={false} isClearable={true} options={optionsMonths} />
                        <div className="data-birthday"><input onChange={(e) => this.changeDataBirth(e, 'birthYear')} pattern="[0-9]*" value={this.state.birthYear} type="text" /></div>
                      </div>
                    </div>
                    <div className="form-input">
                      <label>Hạng thành viên</label>
                      <div className="select-ranking">
                        <Select className="select half data-birthday" 
                          onChange={(e) => this.changeDataBirth(e?.value ?? null, 'ranking')} 
                          styles={this.customStylesSelect} isSearchable={false} isClearable={true} 
                          defaultValue={optionsRanking[this.state.ranking - 1]}
                          options={optionsRanking} />
                      </div>
                    </div>
                  </div>


                </form>
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Không
                </div>
                <div className="button-cent" onClick={this.acceptExport}>
                  <span><i className="fas fa-save"></i></span>Export
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return <div></div>;
  }
}
export default FilterExportData;
