import React from "react";
import Select from 'react-select'
import roleTitle from '../../enums/role-title.enum';

class Edit extends React.Component {
  constructor() {
    super();
    this.state = {
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
    };
  }

  onChangeMultipleSelect = (key, event) => {
    const data = [];
    for (const key in event) {
      data.push(event[key].value)
    }

    this.props.changeDataOperator(key, data);
  };
  
  onChangeCheckbox = (key, event) => {
    let data = event.target.checked === true ? 1 : 2;
    this.props.changeDataOperator(key, data);
  };

  getCurrentDataSelectMulti = (listData, currentData) => {
    let res = [];
    listData.map((item, key) => {
      if (currentData.includes(item.value)) res.push(listData[key])
    })
    return res
  }

  getCurrentDataSelect = (listData, currentData) => {
    let res = [];
    listData.map((item, key) => {
      if (currentData == item.value) res.push(listData[key])
    })
    console.log(res)
    return res
  }

  render() {
    if (this.props.show) {
      return (
        <div className="popup-cent">
          <div className="content-popup fadeInDown">
            <div className="head-popup">
              <div className="title">Sửa thông tin nhân viên</div>
              <div className="close" onClick={this.props.close}>×</div>
            </div>
            <div className="body-popup">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="items-data row">
                    <div className="title col-3">Họ tên</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataOperator('name', e.target.value)} defaultValue={this.props.detailOperator.name} className="required" type="text"/></div>
                  </div>
                  {this.state.userAdmin.role == 1 ?
                    <div className="items-data row">
                      <div className="title col-3">Vị trí</div>
                      <div className="value col-9">
                        <Select className="select" onChange={(e) => {this.props.changeDataOperator('role', e)}} defaultValue={this.getCurrentDataSelect(roleTitle, this.props.detailOperator.role)} options={roleTitle} />
                      </div>
                    </div>
                  : null}
                  <div className="items-data row">
                    <div className="title col-3">Điện thoại</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataOperator('mobile', e.target.value)} className="required" defaultValue={this.props.detailOperator.mobile} type="text"/></div>
                  </div>
                  <div className="items-data row">
                    <div className="title col-3">Email</div>
                    <div className="value col-9"><input onChange={(e) => this.props.changeDataOperator('email', e.target.value)} defaultValue={this.props.detailOperator.email} type="text" /></div>
                  </div>
                  {this.props.detailOperator.role == 2 ?
                    <div className="items-data row">
                      <div className="title col-3">Nhóm nhân viên</div>
                      <div className="value col-9">
                        <Select className="select" onChange={(e) => this.props.changeDataOperator('group', e.value)} defaultValue={this.getCurrentDataSelect(this.props.optionsGroup, this.props.detailOperator.group)} options={this.props.optionsGroup} />
                      </div>
                    </div>
                  : null}
                  {[2, 3, 4].includes(this.props.detailOperator.role) ?
                    <div className="items-data row">
                      <div className="title col-3">Chi nhánh</div>
                      <div className="value col-9">
                        <Select className="select" isMulti closeMenuOnSelect={false} onChange={(e) => this.onChangeMultipleSelect('stores', e)} defaultValue={this.getCurrentDataSelectMulti(this.props.dataStore, this.props.currentStore)} options={this.props.dataStore} />
                      </div>
                    </div>
                  :null}
                  <div className="items-data row">
                    <div className="title col-3">Trạng thái</div>
                    <div className="value col-9">
                      <label className="checkbox">
                        <input className="inline" onChange={(e) => this.onChangeCheckbox('status', e)} defaultChecked={this.props.detailOperator.status == 1 ? true : false} type="checkbox"/>
                        <span className="inline">{(this.props.detailOperator.status == 1) ? "Hoạt động" : "Tạm dừng"}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                <div className="button-cent style3" onClick={this.props.close}>
                  <span><i className="fas fa-times"></i></span>Đóng
                </div>
                <div className="button-cent" onClick={this.props.updateOperator}>
                  <span><i className="fas fa-save"></i></span>Lưu thông tin
                </div>
              </div>
            </div>
          </div>
          <div className="overlay" onClick={this.props.close}></div>
        </div>
      );
    } else return null;
  }
}
export default Edit;
