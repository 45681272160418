import { useState } from "react";
import { axiosGet } from "../../utils/axios.service";
import axiosService from "../../utils/axios.service";
import { isAfter } from "date-fns";
import Select from "react-select";
import { filterByStatus, statusPay } from "./constant/OrderConstant"
import { formatValue } from 'react-currency-input-field';
import { DatePicker } from "rsuite";
import Delete from "./Delete";



var _state = {}
var _props = null;
var isLoad = false;

async function requestDataTransaction() {
    if (_state.setDataTransaction === undefined) return ''
    isLoad = true;
    var id = _props.orderDetail.id
    let response = await axiosGet("api/transaction/order/" + id);
    const selectDataStore = []
    if (response.data.code == 200) {
            _state.setOrderId(id)
            console.log(response.data.data)
            let res = []
            response.data.data.map(x => {
                let data = x;
                if (x.pay_account_number) {
                    data = {
                        ...data,
                        banking: x.pay_account_number.split(" - ")
                    }
                }
                res.push(data)
            })
            
            _state.setDataTransaction(res)
    }
    isLoad = false;
}


export function TransactionList(props) {
    const [dataTransaction, setDataTransaction] = useState({})
    const [orderId, setOrderId] = useState(null)

    _state.dataTransaction = dataTransaction
    _state.setDataTransaction = setDataTransaction

    _state.orderId = orderId
    _state.setOrderId = setOrderId


    _props = props
    let orderDetail = props.orderDetail

    if(!isLoad && orderDetail.id !== orderId)  requestDataTransaction()
    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate(),
            hours = date.getHours(),
            minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        if (hours < 10) hours = '0' + hours;
        if (minus < 10) minus = '0' + minus;
        let text = day + '-' + month + '-' + date.getFullYear();
        text = text + ' ' + hours + ':' + minus;
        return text
    };

    return (
        <div className="transaction-history-popup">
            <div className="head-popup">
                <div className="title">Lịch sử thanh toán <span></span> </div>
            </div>
            <div className="body-popup">
                <table className="table datatable-key-basic dataTable no-footer table-hover">
                    <thead className="hide-mobile">
                        <tr>
                            <th>Ngày thanh toán</th>
                            <th>Mã hóa đơn</th>
                            <th>Hành động</th>
                            <th className="text-center">Loại tiền</th>
                            <th className="text-center">Tài khoản</th>
                            <th className="text-right">Số tiền trả</th>
                            <th>Người thu tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataTransaction[0] ? 
                        dataTransaction.map((val, key) => (
                            <tr key={key} className="item-mobile-table">
                                <td className="mobile-table">
                                    <div className="title hide-desktop">Ngày thanh toán</div>
                                    <div className="value">{convertDateTime(val.created_at)}</div>
                                </td>
                                <td className="mobile-table">
                                    <div className="title hide-desktop">Mã hóa đơn</div>
                                    <div className="value">{val.transaction_code}</div>
                                </td>
                                <td className="mobile-table">
                                    <div className="title hide-desktop">Hành động</div>
                                    <div className="value">{val.status == 1 ? "Thanh toán" : "Trả nợ"}</div>
                                </td>
                                <td className="text-center mobile-table">
                                    <div className="title hide-desktop">Loại tiền</div>
                                    <div className="value">{val.pay_type}</div>
                                </td>
                                {val.banking ?
                                    <td className="text-center mobile-table">
                                        <div className="title hide-desktop">Tài khoản</div>
                                        <div className="value">{val.banking[0]} <br/> {val.banking[2]} <br/> {val.banking[1]}</div>
                                    </td>
                                : 
                                    <td className="text-center mobile-table">
                                        <div className="title hide-desktop">Tài khoản</div>
                                        <div className="value">- -</div>
                                    </td>} 
                                <td className="text-right mobile-table">
                                    <div className="title hide-desktop">Số tiền trả</div>
                                    <div className="value">
                                        <span className="cash-vnd">
                                            {val.paid_amount? formatValue({ value: String(val.paid_amount), groupSeparator: ',', decimalSeparator: '.', }) : '0'} đ
                                        </span>
                                    </div>
                                </td>
                                <td className="mobile-table">
                                    <div className="title hide-desktop">Người thu tiền</div>
                                    <div className="value">{val.user ? val.user.name : ""}</div>
                                </td>
                            </tr>
                        )): <tr></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}