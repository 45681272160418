import React, { useContext, useState } from "react";
import axiosService from "../utils/axios.service";
import StorageService from "../utils/storage.service";
import { UserContext } from "../context/login.context";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import  { Redirect } from 'react-router-dom'

const Admin = () => {
  const [user, setUser] = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [isDisable,setIsDisable] = useState(false)
  const history = useHistory();

  const css = "body{background-color: #3b3b3b;}";
  var currentDay = new Date().getTime() / 1000,
    end_session = (new Date(localStorage.getItem("end_session")).getTime()) / 1000;

  if (end_session > currentDay && localStorage.getItem("access_token")) {
    return <Redirect to='/dashboard' /> 
  }
  
  const login = (event) => {
    setIsDisable(true)
    event.preventDefault()
    const dataLogin = {
      email,
      password,
    };

    if (dataLogin.username === "" || dataLogin.password === "") {
      setErrorLogin("Vui lòng điền thông tin đăng nhập");
    } else {
      axiosService("api/login", "POST", { ...dataLogin })
        .then((res) => {
          const response = res.data;
          if (response?.token) {
            StorageService.set("access_token", response.token);
            StorageService.set("user_info", JSON.stringify(response.user));
            StorageService.set("end_session", response.expiresIn);

            setUser(response.user);
            history.push((response.user.role == 2) ? "/booking" : "/dashboard");
          } else {
            setErrorLogin(response.error);
            setIsDisable(false)
          }
        })
        .catch((error) => {
          message.error("Đăng nhập thất bại")
          console.log(error)
          setIsDisable(false)
        });
    }
  };

  const getUsername = (event) => {
    setEmail(event.target.value);
  };

  const getPassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center mt-5">
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-md-12 col-lg-10">
              <div className="wrap d-md-flex right-wrap">
                <div className="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last w-50">
                  <div className="text w-100 text-center">
                    <img src="/images/header/logo3.png" />
                  </div>
                </div>
                <div className="login-wrap p-4 p-lg-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Đăng nhập</h3>
                    </div>
                  </div>
                  <form className="signin-form">
                    <div className="form-group mb-3">
                      <label className="text-label" htmlFor="name">Tài khoản</label>
                      <input type="text" className="form-control" placeholder="Nhập tài khoản" required onChange={getUsername} />
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-label" htmlFor="password">Mật khẩu</label>
                      <input type="password" className="form-control" placeholder="Nhập mật khẩu" required onChange={getPassword} />
                    </div>
                    <div className="form-group">
                      <button disabled={isDisable} className="form-control btn-login px-3" onClick={(e)=>{login(e)}}>Tiếp tục</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>

  );
};
export default Admin;
