import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios.service";
import { Table, Container } from "react-bootstrap"
import styles from "../../styles/popupInfor.module.css"
const FavoriteProduct = ({customer}) => {
  const [products, setProducts] = useState([])
  const getData = async () => {
    const res = await axiosService(`api/customer/features-item/${customer.id}`, "GET");
    const { data } = res
    if (data.code === 200) {
      setProducts(data.data)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  function convertCurrency(data) {
    let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
    return res;
  };
  return (
    <Container>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Tên sản phẩm</th>
            <th>Số lượng</th>
            <th>Giá</th>
          </tr>
        </thead>
        <tbody>
          {products.map((x,i) => {
            return(
              <tr key={i}>
                <td>{x.order_item_product_name ?? x.product_product_name}</td>
                <td>{x.sum_quantity}</td>
                <td>
                  <p className={styles.textCash}>
                    {convertCurrency(x.product_price)}
                  </p>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default FavoriteProduct