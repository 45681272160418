import React from "react"
import {Row,Col,Container} from "react-bootstrap"
import styles from "../../styles/popupInfor.module.css"
import genderEnnum from "../../enums/gender.ennum"
const Infor = ({data}) =>{
  return (
    <>
    <Container>
    <Row >
      <Col xs={12} md={4}>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Mã khách hàng</div>
          <div>{data.code}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Số điện thoại</div>
          <div>{data.mobile}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Email</div>
          <div>{data.email}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Giới tính</div>
          <div>{genderEnnum[data.gender]}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Ngày sinh</div>
          <div>{data.day_birthday}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Facebook</div>
          <div>{data.facebook_id}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Tài khoản zalo</div>
          <div>{data.zalo_account}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Địa chỉ</div>
          <div>{data.address}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Quận/huyện</div>
          <div>{data.district}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Thành phố</div>
          <div>{data.city}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Nghề nghiệp</div>
          <div>{data.job}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Mã thẻ</div>
          <div>{data.id_card}</div>
        </div>
        <div className="d-flex justify-content-between mg-10">
          <div className={styles.textInfor}>Ghi chú</div>
          <div>{data.note}</div>
        </div>
      </Col>
    </Row>
    </Container>
    </>
  )
}
export default Infor