export default {
    "rule.management": "Quản lý rule",
    "add.new.cart.price": "Quy tắc giá giỏ hàng mới",
    "name": "Tên",
    "code":"Mã",
    "date":"Ngày",
    "status":"Trạng thái",
    "isActiveCustomer":"Chỉ áp dụng với thành viên loyalty",
    "start.date":"Ngày bắt đầu",
    "end.date":"Ngày kết thúc",
    "type":"Loại",
    "actions":"Chức năng",
    "emulation.actions":"Tính thưởng",
    "add.new.rule":"Thêm rule mới",
    "add.new.category":"Group voucher",
    "all":"Tất cả",
    "active":"Hoạt động",
    "inactive":"Không hoạt động",
    "enter.Id":"Nhập Id để tìm kiếm",
    "enter.name":"Nhập tên để tìm kiếm",
    "enter.code":"Nhập mã để tìm kiếm",
    "click.to.upload":"Chọn tệp",
    "delete.confirm":"Xác nhận xóa",
    "delete.title":"Bạn có muốn xóa",
    "error.message":"Thông báo lỗi",
    "warning":{
        "choose.voucher":"Vui lòng chọn một voucher!!!!"
    },
    "success":{
        "delete":"Xóa thành công",
        "add":"Thêm thành công",
        "refresh":"Làm mới thành công",
        "update":"Cập nhật thành công"
    },
    "error":{
        "delete":"Xóa thất bại",
        "occurred":"Đã có lỗi xảy ra",
        "add":"Thêm thất bại",
        "update":"Cập nhật thất bại"
    },
    "reset.title":"Bạn có muốn làm mới lại ?",
    "reset.confirm":"Xác nhận",
    "rule.info":"Thông tin rule",
    "rule.name":"Tên rule",
    "only.one": "Dùng 1 lần",
    "voucher.code":"Voucher code",
    "use.unique.code":"Dùng unique code",
    "max.used": "Số lần dùng tối đa",
    "voucher.level": "Level",
    "limit.paid.order": "Thanh toán tối thiểu",
    "customer.sing": "Khách hàng được sử dụng",
    "categoryVoucher": "Group mã voucher",
    "des":"Mô tả",
    "stores":"Các cơ sở áp dụng",
    "groupUser": "Các nhóm nhân viên",
    "custmerRank": "Chọn hạng của khách hàng được sử dụng ",
    "users": "Nhân viên được áp dụng",
    "choose.store":"Chọn một cơ sở",
    "choose.user":"Chọn nhân viên",
    "choose.group":"Chọn nhóm",
    "choose.rank":"Chọn rank",
    "choose.customer": "Không chọn khách hàng mặc đinh áp dụng tất cả",
    "select.all":"Chọn tất",
    "conditions":"Điều kiện",
    "condition":{
        "title":"Chỉ áp dụng rule nếu đáp ứng các điều kiện sau (để trống cho tất cả các sản phẩm)",
        "value.greater":"Giá trị đơn hàng lớn hơn",
        "value.less":"Giá trị đơn hàng nhỏ hơn",
        "contain":"Đơn hàng bao gồm",
        "not.contain":"Đơn hàng không bao gồm",
        "up.contain": "Đơn hàng nâng cấp từ",
        "combo":"Đơn hàng có combo",
        "setup.money.cart": "Khuyến mại đăng ký thẻ tiền giá trị lớn hơn",
        "cate":"Đơn hàng bao gồm một trong các danh mục",
        "not.cate":"Đơn hàng không bao gồm một trong các danh mục",
    },
    "then":"Những điều kiện này là",
    "choose.product":"Chọn một sản phẩm để thêm",
    "apply.voucher":"Dùng với voucher",
    "choose.cate":"Chọn một danh mục",
    "choose.condition":"Chọn một điều kiện",
    "percent":"Phần trăm",
    "fix.amount":"Giá mặc định",
    "discount.amount":"Số lượng giảm",
    "max.amount":"Giá tối đa",
    "back":"Quay lại",
    "reset":"Làm mới",
    "save":"Lưu",
    "apply":"Loại",
    "product":"Tặng sản phẩm",
    "product.discount":"Chọn sản phẩm áp dụng",
    "discount.product.percent":"Giảm giá % từng sản phẩm",
    "discount.product.price":"Giảm giá tiền từng sản phẩm",
    "expired":"Hết hạn",
    "month":"tháng",
    "delete":"Xoá"
}