import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import { Column } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Modal, message, Divider, Pagination } from 'antd';
import 'antd/dist/antd.min.css'
import InforUserPopup from "../infor/InforCustomerPopup";
import { CustomerOrderPopup } from "../customer/CustomerOrderPopup";
import "../customer/scss/customer.scss"
import { Spin } from "antd"
import StorageService from "../../utils/storage.service";

const ReportCustomer = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [receipt, setReceipt] = useState()
    const [newCustomers, setNewCustomers] = useState()
    const [combackCustomers, setCombackCustomers] = useState()
    const [avgPerCus, setAvgPerCus] = useState(0)
    const [dataChart, setDataChart] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [showInfor, setShowInfor] = useState(false)
    const [customerInfor, setCustomerInfor] = useState()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(4)
    const [total, setTotal] = useState(0)
    const [show, setShow] = useState(false)
    const [name, setName] = useState()
    const [id, setId] = useState(0)
    const [orders, setOrders] = useState([])
    const [showTransaction, setShowTransaction] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isExport, setIsExport] = useState(false)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    useEffect(() => {
        setTiltle("Theo khách hàng")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate)
    }, [store, endDate, startDate])
    const fetchData = async (s, eD, sD) => {
        const rs = await axiosService(`api/reports/customers?store=${s}&startDate=${sD}&endDate=${eD}`, "GET")
        const { data } = rs
        if (data.code === 200) {
            setLoading(false)
            const { receipt, newCustomers, comback, dataChart, dataTable } = data.data
            setReceipt(receipt)
            setNewCustomers(newCustomers)
            setCombackCustomers(comback)
            const avgReceiptPerCustomer = hanldeAvg(newCustomers.count, comback.count, receipt.receipt)
            setAvgPerCus(avgReceiptPerCustomer)
            setDataChart(dataChart)
            setDataTable(dataTable)
        }
    }
    const hanldeAvg = (countNew, countComback, receipt) => {
        if (receipt.length > 0) {
            receipt = receipt[0].sum_total_price
        } else {
            receipt = 0
        }
        return parseInt(receipt) / (parseInt(countNew) + countComback)
    }
    const config = {
        data: dataChart,
        xField: 'name',
        yField: 'value',
        xAxis: {
            label: {
                autoRotate: true,
            },
        },
        slider: {
            start: 0,
            end: 1,
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    const columns = [
        {
            title: 'Khách hàng',
            dataIndex: 'cus',
            render: (data, x) => {
                return (
                    <>
                        <p className="click-name" onClick={() => { hanldeShowPopupInfor(x.id) }}>{data.name}</p>
                        <p>{data.phone}</p>
                    </>
                )
            },
            sorter: (a, b) => a.cus.name.length - b.cus.name.length,
        },
        {
            title: 'Loại khách hàng',
            dataIndex: 'type',
            sorter: {
                compare: (a, b) => a.type - b.type,
                multiple: 3,
            },
        },
        {
            title: 'Số đơn',
            dataIndex: 'orders',
            sorter: {
                compare: (a, b) => a.orders - b.orders,
                multiple: 2,
            },
        },
        {
            title: 'Doanh số hoá đơn',
            dataIndex: 'value',
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.value - b.value,
                multiple: 2,
            },
        },
        {
            title: 'Tiền đã nhận',
            dataIndex: 'receipt',
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.receipt - b.receipt,
                multiple: 1,
            },
        },
        {
            title: 'Tiền nạp đã sử dụng',
            dataIndex: 'deposit',
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
            // sorter: {
            //     compare: (a, b) => a.receipt - b.receipt,
            //     multiple: 1,
            // },
        },
        {
            title: 'Điểm tích luỹ sử dụng',
            dataIndex: 'gift_cash',
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
            // sorter: {
            //     compare: (a, b) => a.receipt - b.receipt,
            //     multiple: 1,
            // },
        },
        {
            title: 'Còn nợ',
            dataIndex: 'owed',
            render: (data) => {
                return (
                    <p className="text2">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) => a.owed - b.owed,
                multiple: 1,
            },
        },
        {
            title: '',
            dataIndex: "id",
            render: (data, x) => {
                return (
                    <div className="button-cent style" onClick={() => { hanldeDetail(data ?? x.cus.id, x.cus.name, "open") }}>
                        <span>
                            <i className="fa fa-eye"></i>
                        </span>
                        Xem
                    </div>
                )
            },
        },
    ];

    const onChange = (pagination, filters, sorter, extra) => {
        // console.log('params', pagination, filters, sorter, extra);
    };
    const hanldeShowInfor = () => {
        setShowInfor(!showInfor)
    }
    const hanldeShowPopupInfor = async (id) => {
        const rs = await axiosService(`api/customer/${id}`, "GET")
        const { data } = rs
        setCustomerInfor(data)
        hanldeShowInfor()
    }
    const hanldeDetail = (id, name, type) => {
        getDataOrders(id, limit, page, type)
        setName(name)
        setId(id)
    }
    const handleCancel = () => {
        setShow(!show)
    }
    const getDataOrders = async (id, limit, page, type) => {
        const rs = await axiosService(`api/reports/orders/customer/${id}?limit=${limit}&page=${page}`, "GET")
        const { data } = rs
        if (data.code === 200) {
            if (type === "open") {
                handleCancel()
            }
            const { meta, items } = data.data
            setOrders(items)
            setPage(meta.currentPage)
            setTotal(meta.totalItems)
            setLimit(meta.itemsPerPage)
        } else if (data.code === 404) {
            message.error(data.message)
        }
    }
    const onChangePage = (pageC) => {
        getDataOrders(id, limit, pageC)
        setPage(page);
    };
    const handleShowTransaction = () => {
        setShowTransaction(!showTransaction)
    }
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    return (
        <>
            <Modal title={
                <>
                    <span>Danh sach hoá đơn của khách hàng :</span><span style={{ color: "blue" }}> {name}</span>
                    <br></br>
                    <span className="text-date">Thời gian: {format(new Date(startDate), "dd-MM-yyy")}-{format(new Date(endDate), "dd-MM-yyy")}</span>
                </>
            } visible={show} onCancel={handleCancel} footer={<Pagination current={page} onChange={onChangePage} total={total} defaultPageSize={4} />} width={1000} style={{
                top: 20,
            }}
            >
                <div className="w-100">
                    {orders.length > 0 ?
                        orders.map((x) => {
                            return (
                                <div key={x.id} className="w-100 p-1">
                                    {showTransaction && <div className="order-popup">
                                        <div className="popup-cent" style={{ height: "600px" }}>
                                            <div className="content-popup fadeInDown" style={{ width: "700px", height: "auto" }}>
                                                <CustomerOrderPopup orderDetail={x} customer={x.customers} show={showTransaction} />
                                                <div className="bottom-popup">
                                                    <div className="button-container">
                                                        <div onClick={handleShowTransaction} className="button-cent style3">
                                                            <span><i className="fas fa-times"></i></span>Đóng
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={handleShowTransaction} className="overlay"></div>
                                        </div>
                                    </div>}
                                    <Divider />
                                    <div className="order" onClick={() => { handleShowTransaction() }}>
                                        <div className="d-flex justify-content-between">
                                            <span className="text-primary">Mã hoá đơn: #{x.order_code}</span>
                                            <b>{format(new Date(x.order_at), "dd/MM/yyyy HH:ss")}</b>
                                        </div>
                                        <span className="text-date">
                                            Sử dụng
                                        </span>
                                        {
                                            x.orderItem.map(y => <span className="text-date" key={y.id}>{y.product_name}, </span>)
                                        }
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="text5">Tổng tiềng:</p>
                                                <p className="text5">Tiền khách đưa:</p>
                                                <p className="text5">Còn nợ:</p>
                                            </div>
                                            <div>
                                                <p className="text5 text1">{convertCurrency(x.total_price)}</p>
                                                <p className="text5 text4">{convertCurrency(x.total_price - x.money_owed)}</p>
                                                <p className="text5 text2">{convertCurrency(x.money_owed)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : "Không có thông tin"
                    }
                </div>
            </Modal>
            {showInfor && <InforUserPopup open={showInfor} setShow={hanldeShowInfor} data={{ customers: customerInfor }} />}
            <div className={`container-fluid report-page ${styles.background}`}>
                <Header startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate} store={store}
                    hanldeStore={hanldeStore}
                    title={title} type={"customers"}
                    csvData={dataTable} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    header={
                        [
                            { label: 'Tên khách hàng', key: "cus.name" },
                            { label: 'Số điện thoại', key: "cus.phone" },
                            { label: "Loại khách hàng", key: "type" },
                            { label: "Số đơn hàng", key: "orders" },
                            { label: "Doanh số hoá đơn", key: "value" },
                            { label: "Tiền đã nhận", key: "receipt" },
                            { label: "Còn nơ", key: "owed" }
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <Spin spinning={loading}>
                    <Row className="mt-2">
                        <Col xs={5}>
                            <Row>
                                <Col xs={6}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag4}>Hoá đơn </span>
                                                <span className={styles.textTag4}>Tiền đã nhận:</span>
                                                <span className={styles.textTag4}>Tiền nạp đã sd:</span>
                                                <span className={styles.textTag4}>Điểm tích luỹ đã sd:</span>
                                                <span className={styles.textTag4}>Tiền thu nợ:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>{convertCurrency(receipt?.receipt.length > 0 ? receipt?.receipt[0]?.sum_total_price - Number(receipt?.receipt[0]?.sum_deposit_total) - Number(receipt?.receipt[0]?.sum_deposit_total ?? 0) - Number(receipt?.receipt[0]?.sum_gift_cash_total ?? 0) : 0)}</span>
                                                <span className={styles.textTag}>{convertCurrency(receipt?.revenue.length > 0 ? (Number(receipt?.revenue[0]?.sum_paid_amount ?? 0) - Number(receipt?.receipt[0]?.sum_deposit_total ?? 0)) + Number(receipt?.receiptOwed[0]?.sum_paid_amount ?? 0) - Number(receipt?.receipt[0]?.sum_gift_cash_total ?? 0) : 0)}</span>
                                                <span className={styles.textTag}>{convertCurrency(receipt?.revenue.length > 0 ? receipt?.receipt[0]?.sum_deposit_total : 0)}</span>
                                                <span className={styles.textTag}>{convertCurrency(receipt?.revenue.length > 0 ? receipt?.receipt[0]?.sum_gift_cash_total : 0)}</span>
                                                <span className={styles.textTag}>{convertCurrency(receipt?.receiptOwed.length > 0 ? receipt?.receiptOwed[0]?.sum_paid_amount : 0)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <div className="d-flex justify-content-start">
                                            <span className={styles.textTag2}>DOANH SỐ TB/ KHÁCH HÀNG</span>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <span className={styles.textTag}>{convertCurrency(avgPerCus ||0)}</span>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={6}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag2}>Số khách hàng mới </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag4}>Số khách</span>
                                                <span className={styles.textTag4}>D.số hoá đơn</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>{newCustomers?.count}</span>
                                                <span className={styles.textTag}>{convertCurrency(newCustomers?.receipt)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag2}>Số khách hàng quay lại </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag4}>Số khách</span>
                                                <span className={styles.textTag4}>D.số hoá đơn</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>{combackCustomers?.count}</span>
                                                <span className={styles.textTag}>{convertCurrency(combackCustomers?.receipt)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={12}>
                                    <div style={{ backgroundColor: "white" }} className="p-2">
                                        <h5 className="text-center mb-2">Theo khách hàng</h5>
                                        <Column {...config} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={7}>
                            <div className="mb-1">
                                <strong>
                                    Top khách hàng
                                </strong>
                            </div>
                            <Table 
                                columns={columns} 
                                dataSource={dataTable}
                                onChange={onChange}
                                pagination={{ pageSize: 10 }} 
                                scroll={{ x: 1300 }}
                                bordered
                             />
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    )
}
export default ReportCustomer