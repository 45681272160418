import React, {useState, useEffect} from "react";
import Avatar from "react-avatar";
import * as convert from "../constant/Convert.helper"

export default function PosOrderItemListUser(props) {

  const [item, setItem] = useState(props.item)

  const handleChangeState = async (key, data, e) => {
    props.changeDataCommission(key, data, e)

    var newItem = item
    if (!e.target.className.includes("active")) {
      newItem[key] = { ...newItem[key], [data.id]: data }
    } else {
        Object.entries(newItem[key]).map((item, keyN) => {
            if (item[0] == data.id) delete newItem[key][data.id];
        });
    }
    setItem({...newItem})

  }
  
  let textSearch = convert.convertViToEn(props.searchOperators.toLowerCase());
  let nameOperator = convert.convertViToEn(props.operator.name.toLowerCase());


  if (textSearch.length > 0) {
    if (!nameOperator.includes(textSearch)) {
      return "";
    }
  }

  return (
    <div className="item-operator">
      <div className="operator-name">
        <div className="avatar">
          {props.operator.avatar_url ? (
            <img className="image-ktv" src={props.operator.avatar_url} alt="" />
          ) : (
            <Avatar
              name={props.operator.name}
              size="36"
              textSizeRatio={1.75}
              round="50%"
            />
          )}
        </div>
        <div className="info">
          <div className="name">{props.operator.name}</div>
          <div className="position">{props.operator.position}</div>
        </div>
      </div>
      <div className="operator-service">
        <div
          className={
            item.service[props.operator.id]
              ? "checkbox active"
              : "checkbox"
          }
          onClick={(e) => handleChangeState(
              "service",
              {name: props.operator.name,id: props.operator.id},
              e
            )}
        ></div>
      </div>
      <div className="operator-consultant">
        <div
          className={
            item.consultant[props.operator.id]
              ? "checkbox active"
              : "checkbox"
          }
          onClick={(e) => handleChangeState(
            "consultant",
            {name: props.operator.name,id: props.operator.id},
            e
          )}
        ></div>
      </div>
    </div>
  );
}
